import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Redirect } from "react-router-dom";
import { deleteProject } from "./../actions/projectActions";
import Spinner from "./Spinner";
import UserAction from "./UserAction";
import translate from "../i18n/translate";
import ReactTooltip from "react-tooltip";

function Dashboard({ projects, auth, deleteProject }) {
  const _deleteProject = (id) => {
    const confirm = window.confirm("Porfavor, confirme esta acción");
    if (confirm) {
      deleteProject(id);
    }
  };

  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <div
      className="grid gap-5 pr-5"
      style={{ gridTemplateColumns: "auto 1fr" }}
    >
      <div className="flex">
        <div className="sticky top-0 z-40 w-10 h-screen flex flex-col justify-end items-center pb-3 pt-7 bg-gray-200 dark:text-gray-600 dark:bg-gray-900">
          <div />
          <Link
            to="/settings"
            className="hover:text-gray-500 dark:hover:text-gray-200"
          >
            <svg
              data-tip="Configuration"
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clipRule="evenodd"
              />
            </svg>
            <ReactTooltip />
          </Link>
        </div>
      </div>
      <div className="w-full mx-auto space-y-4 p-5">
        <h1 className="text-3xl font-black">Inici</h1>
        <h2>{translate("hello")}</h2>
        <Link className="btn btn--icon" to="/c">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <span>Nova sala</span>
        </Link>
        <div>
          <h2 className="text-lg mb-2 font-bold">Recent</h2>
          <ul className="border-2 dark:border-gray-700">
            {projects ? (
              projects.map((project, index) => {
                // const date = new Intl.DateTimeFormat("en").format(project.createdAt)
                return (
                  <li
                    key={project.id}
                    className={`flex items-center justify-between dark:border-gray-700 px-3 py-2 ${
                      index !== projects.length - 1
                        ? `border-b-2`
                        : `border-b-0`
                    }`}
                  >
                    <Link
                      to={`/${project.id}/admin`}
                      className="flex items-center space-x-2 hover:text-gray-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                        />
                      </svg>
                      <span>
                        <span className="font-semibold">{project.title}</span>
                        {/* <span className="text-xs text-gray-600 ml-1" format="DD MMMM HH:MM">{date}</span> */}
                        {project.widgets?.map((widget, index) => (
                          <span
                            key={widget + index}
                            className="text-xs text-gray-600 ml-1.5"
                          >
                            {widget}
                            {project.widgets.length !== index + 1 ? "," : ""}
                          </span>
                        )) ?? null}
                      </span>
                    </Link>
                    <button
                      type="button"
                      onClick={() => _deleteProject(project.id)}
                      className="hover:text-gray-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </li>
                );
              })
            ) : (
              <Spinner className="mx-3 my-2" />
            )}
          </ul>
        </div>
        <UserAction className="fixed top-0 right-0" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteProject: (id) => dispatch(deleteProject(id)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.auth.uid) return [];
    return [
      {
        collection: "projects",
        orderBy: ["createdAt", "desc"],
      },
      /* {
        collection: "projects",
        where: [["authorId", "==", props.auth.uid]],
        orderBy: ["createdAt", "desc"],
      }, */
    ];
  })
)(Dashboard);
