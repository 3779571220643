import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";
import CardOverlay from "./Overlays/card";
import { saveItem } from '../actions/projectActions'
import Spinner from './Spinner'

import '../../node_modules/react-quill/dist/quill.snow.css';


import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { connect } from 'react-redux'

import SlateEditorCards from './SlateEditorCards'
import { serialize } from './../utils/'


import { Draggable } from "react-beautiful-dnd";


function Card(props) {

  const {
    deleteItem,
    message,
    project,
    role,
    toggleItemIsReady,
    setEditedMessage,
    setIsEmitted,
    index,
    selectedItem,
    setSelectedItem,
    overlay,
    setOverlay,
    setPositions,
    addOverlay,
    removeOverlay,
    setItemPosition,
    setDragCompleted,
    dragCompleted,
    setIsEditing,
    firebase,


    dragId
  } = props;

  const {
    id,
    position,

    showImageBox, 
    showPrimaryBox, 
    showSecondaryBox, 
    pictureURL,
    hiddenTitle,
    category,
    title, 
    subtitle, 
    body, 
    secondaryBody,
    isReady,
    lastUpdate,
    isEmitted,
    savedAt,
    emittedAt,
  } = props.message;

  const [isDragging, setIsDragging] = useState(false);
  const [editor, setEditor] = useState(null);
  const ref = useRef(null);

  const [showMessagePreview, setShowMessagePreview] = useState(false)

  var svg_image = <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="currentColor"><path d="M11.8 34.15H36.25L28.9 24.35L22.3 32.9L17.65 26.55ZM9 42Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H39Q40.2 6 41.1 6.9Q42 7.8 42 9V39Q42 40.2 41.1 41.1Q40.2 42 39 42ZM9 39H39Q39 39 39 39Q39 39 39 39V9Q39 9 39 9Q39 9 39 9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39ZM9 9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39Q9 39 9 39Q9 39 9 39V9Q9 9 9 9Q9 9 9 9Z"/></svg>
  var svg_edit = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" ><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" /></svg>
  var svg_trash = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" ><path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" /></svg>




  

  const submitOverlay = (cardId) => {
    if (project.overlay) {
      removeOverlay();
      setSelectedItem(null);
      setOverlay(null);
      setIsEmitted(true, cardId);
    } else {
      addOverlay(overlay);
    }
  };

  const handleSelected = () => {
    const isSelected = index !== selectedItem;
    setSelectedItem(isSelected ? index : null);
    setOverlay(isSelected ? message : null);
    // aquesta funció te un problema de responsabilitat compartida ja que els condicionals serveixen perque a l'hora selecciona i deselecciona
  };

  const edit = (cardId) => {
    setEditor(editor === index ? null : index);
    setIsEditing(true, cardId);
  };

  const cancelEdit = (cardId) => {
    setEditor(null);
    setIsEditing(false, cardId);
  };


  const defaultContent = [
    {
      type: 'paragraph',
      children: [
        { text: '' }
      ],
    },
  ]




  const [titleToEdit, setTitleToEdit] = useState(defaultContent)
  const [subtitleToEdit, setSubtitleToEdit] = useState(defaultContent)
  const [bodyToEdit, setBodyToEdit] = useState(defaultContent)
  const [secondaryBodyToEdit, setSecondaryBodyToEdit] = useState(defaultContent)


  const [pictureIsLoading, setPictureIsLoading] = useState(false)
  const [uploadInfo, setUploadInfo] = useState(null)
  const [pictureURLTMP, setPictureURLTMP] = useState(null)
  const [fileName, setFileName] = useState(null)


  const handleFileOnChange = event => {
    const file = event.target.files[0]
    if (file?.size < 5000000) {
      setPictureIsLoading(true)

      const storageRef = firebase.storage().ref(`images/${file.name}`)
      const task = storageRef.put(file)

      task.on('state_changed', snapshoot => {}, error => setUploadInfo(error.message), () => {
        storageRef.getDownloadURL().then(url => {
          setPictureIsLoading(false)
          setPictureURLTMP(url)
        })
      })
      
      setFileName(event.target.files[0].name)
    } else {
      setUploadInfo("L'arxiu ha de ser inferior a 5000KB")
    }
  }
  const deletePicture = () => {
    firebase.storage().ref(`images`).child(`${fileName}`).delete()
    setPictureURLTMP(null)
    document.getElementById("uploadeFile1").value = null;
  }









  const saveEdit = (event, index, id) => {
    
    var showImageBoxTMP = document.getElementById(`showImageBox${index}`)?.checked;
    var showPrimaryBoxTMP = document.getElementById(`showPrimaryBox${index}`)?.checked;
    var showSecondaryBoxTMP = document.getElementById(`showSecondaryBox${index}`)?.checked;
    var categoryTMP = document.getElementById(`category${index}`).value;
    var hiddenTitleTMP = document.getElementById(`hiddenTitle${index}`).value;
    var titleTMP = titleToEdit.map(node => serialize(node)).join('').replaceAll('\n', '') /* document.getElementById(`title${index}`).value */;
    var subtitleTMP = subtitleToEdit.map(node => serialize(node)).join('').replaceAll('\n', '') /* document.getElementById(`subtitle${index}`)?.value */;
    var bodyTMP = bodyToEdit.map(node => serialize(node)).join('').replaceAll('\n', '') /* document.getElementById(`body${index}`)?.value */;
    var secondaryBodyTMP = secondaryBodyToEdit.map(node => serialize(node)).join('').replaceAll('\n', '') /* document.getElementById(`secondaryBody${index}`)?.value */;


    setEditedMessage(
      {
        showImageBox: showImageBoxTMP, 
        showPrimaryBox: showPrimaryBoxTMP, 
        showSecondaryBox: showSecondaryBoxTMP, 
        pictureURL: pictureURLTMP ?? pictureURL,
        category: categoryTMP ?? null,
        hiddenTitle: hiddenTitleTMP ?? null,
        title: titleTMP ?? null,
        subtitle: subtitleTMP ?? null,
        body: bodyTMP ?? null,
        secondaryBody: secondaryBodyTMP ?? null,
      },
      id
    );
    setEditor(null);
    setIsEditing(false, id);
  };

  const _deleteItem = (id) => {
    const confirm = window.confirm("Si us plau, confirmeu aquesta acció");
    if (confirm) {
      deleteItem(id);
    }
    // TODO: borrar imatge
  };

  const onTop = { zIndex: 100 };
  const flat = {
    zIndex: 5,
    transition: { delay: 0.3 },
  };


















  /* useEffect(() => {
    if (dragCompleted) setItemPosition(index, id);
    // eslint-disable-next-line
  }, [dragCompleted, setItemPosition]);
 */



  const time = lastUpdate?.toDate() ?? savedAt.toDate();
  const emittedTime = emittedAt?.toDate() ?? null;

  const displayTimeHour = (time) =>
    new Intl.DateTimeFormat("es", {
      hour: "numeric",
      minute: "numeric",
    }).format(time);

  const displayFullTime = (time) =>
    new Intl.DateTimeFormat("es", {
      dateStyle: "full",
      timeStyle: "long",
    }).format(time);



  if (/card/.test(message.type)) {
    return (
      
      <div key={message.id}>
    
        <Draggable isDragDisabled={role === "subscriber"} draggableId={'card-' + message.id} index={position}>

          {(provided, snapshot) => (

            <div 
              className={`card-${message.id}`}
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            >
              <form
                onSubmit={(event) => event.preventDefault()}
                style={{ maxWidth: '999999px', margin: role === "subscriber"? 10 : 0, borderTop: (snapshot.isDragging)? '2px rgb(200,200,200) solid' : '', borderLeft: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderRight: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderBottom: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '' }}
                className={`selected-message flex items-start justify-between p-[1em] pl-2 border-b-2 ${
                  role !== "presenter" && `dark:border-gray-600`
                } ${selectedItem === index && `active`} ${
                  isReady && !isEmitted && role !== "presenter"
                    ? `bg-green-50 dark:bg-green-900`
                    : isEmitted
                    ? `bg-red-50 dark:bg-red-900`
                    : role === "presenter"
                    ? "bg-[#fff] dark:bg-[#000] border-[#000] dark:border-[#fff]"
                    : "bg-gray-50 dark:bg-gray-700"
                }`}
              >


                <div className="flex items-center space-x-2">
                  {(role !== "presenter" && role !== "subscriber") && (
                    <button
                      className="text-gray-400 hover:text-gray-500"
                      style={{ cursor: "grab" }}
                      type="button"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm-8-8a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM8 2a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z"
                        />
                      </svg>
                    </button>
                  )}
                </div>


                <div className="flex-1 flex flex-col">

                  <div className="flex flex-wrap">
                    <div className="space-x-1 cursor-default flex items-center">

                      {editor === index ? (
                        <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500" style={{ padding: 0 }}>
                          <select id={`category${index}`} defaultValue={category} className="p-1" style={{ fontSize: 14 }}>
                            {project?.categories?.map(element => {
                              return <option key={element.id} value={element.id}>{element.txt}</option>
                            })}
                          </select>
                        </div>
                      ) : null }

                      {editor === index ? (
                        <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500" style={{ padding: 0 }}>
                          <input
                            id={`hiddenTitle${index}`}
                            type='text'
                            placeholder='Text de la caixa principal'
                            className="w-full h-6 bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:active:bg-gray-800 bg-opacity-40 focus:outline-none transition duration-150 ease-in-out"
                            defaultValue={hiddenTitle}
                            style={{ minWidth: 300, fontSize: 14 }}
                          />
                        </div>
                      ) : (
                        <span className={`font-semibold whitespace-nowrap pl-2 pt-1`}>{hiddenTitle}</span>
                      )}




                      {role !== "presenter" && (
                        <p className="inline text-xs whitespace-nowrap">
                          {lastUpdate ? `Actualitzat` : `Creat`} a las{" "}
                          <span data-tip={displayFullTime(time)}>
                            {displayTimeHour(time)}
                          </span>
                        </p>
                      )}
                    </div>

                    <div className="w-full flex">
                      
                      {/* {((pictureURL && showImageBox) || (editor === index)) && <div className="w-48 h-60 mt-2 ml-2 mr-1" style={{ minWidth: '192px' }}>
                        <img 
                          className={ `object-cover w-full h-full` }
                          src={pictureURL}
                          alt=""
                        />
                      </div>} */}

                      <div className="w-full flex flex-wrap">
                        {editor === index ? 
                          <div className="mb-2">
                            <label className="whitespace-nowrap" style={{ userSelect: 'none' }}>
                              <input type="checkbox" id={`showImageBox${index}`} style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={showImageBox}/> 
                              Mostrar caixa imatge
                            </label>

                            <label className="whitespace-nowrap" style={{ userSelect: 'none' }}>
                              <input type="checkbox" id={`showPrimaryBox${index}`} style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={showPrimaryBox}/> 
                              Mostrar caixa principal
                            </label>

                            <label className="whitespace-nowrap" style={{ userSelect: 'none' }}>
                              <input type="checkbox" id={`showSecondaryBox${index}`} style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={showSecondaryBox}/> 
                              Mostrar caixa inferior
                            </label>
                          </div> 
                        : <></> }


                        {title && showPrimaryBox && <>
                          {editor === index ? (<>
                            <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500">
                              <SlateEditorCards key="title" contentValue={titleToEdit} setContentValue={setTitleToEdit} toDeserialize={title} placeholder='Títol de la caixa principal'/>
                            </div>
                          </>) : null /* (
                            <span className={`font-semibold pl-2 py-1`}>{title?.replaceAll('</p><p>', " ").replaceAll('<p class="small">', "").replaceAll('<p class="big">', "").replaceAll('<p style="color: #f54d45">', '').replaceAll('<p class="text-gray-50">', '').replaceAll('<strong>', "").replaceAll('</strong>', "").replaceAll('<i>', "").replaceAll('</i>', "").replaceAll('</p>', "").replaceAll('<p>', "").replaceAll('</h1>', "")}</span>
                          ) */}
                        </>}


                        {subtitle && showPrimaryBox && <>
                          {editor === index ? (<>
                            <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500">
                              <SlateEditorCards key="subtitle" contentValue={subtitleToEdit} setContentValue={setSubtitleToEdit} toDeserialize={subtitle} placeholder='Subtítol de la caixa principal'/>
                            </div>

                          </>) : (
                            <span className={`font-semibold pl-2 py-1`} dangerouslySetInnerHTML={{__html: subtitle?.replaceAll('</p><p>', " ").replaceAll('<p style="color: #f54d45">', '').replaceAll('<p class="text-gray-50">', '').replaceAll('<strong>', "").replaceAll('</strong>', "").replaceAll('<i>', "").replaceAll('</i>', "").replaceAll('</p>', "").replaceAll('<p>', "").replaceAll('</h1>', "")}}></span>
                          )}
                        </>}


                        {secondaryBody && showSecondaryBox && <>
                          {editor === index ? (<>
                            <p className="text-gray-400 ml-2 text-sm w-full">Textos inferiors:</p>
                            <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500">
                              <SlateEditorCards key="body" contentValue={bodyToEdit} setContentValue={setBodyToEdit} toDeserialize={body} placeholder='Text de la caixa principal'/>
                            </div>
                            <div className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500">
                              <SlateEditorCards key="secondaryBody" contentValue={secondaryBodyToEdit} setContentValue={setSecondaryBodyToEdit} toDeserialize={secondaryBody} placeholder='Text de la caixa secundaria'/>
                            </div>
                          </>) : (<>
                            {/* <p className="text-gray-400 ml-2 text-sm">Textos de la caixa inferior:</p>
                            <span 
                              className={`textBoxClasses w-full whitespace-nowrap ml-2 mb-2 pl-2 py-1 max-h-20 overflow-auto bg-gray-200`} 
                              dangerouslySetInnerHTML={{__html: body}}
                            ></span>
                            <span
                              className={`textBoxClasses w-full whitespace-nowrap ml-2 pl-2 py-1 max-h-20 overflow-auto bg-gray-200`}
                              dangerouslySetInnerHTML={{__html: secondaryBody}}
                            ></span> */}
                          </>)}
                        </>}








                      {editor === index && <div className="flex flex-wrap items-center space-x-2 my-4 ml-2">
                    
                          <div className="relative w-30 h-20 overflow-hidden bg-gray-200 select-none flex items-center justify-center">
                            {pictureURL?
                              <img src={pictureURL} className="w-30 h-20 object-cover" alt="profile" />
                            : pictureIsLoading? 
                              <Spinner className="w-20 h-20 grid place-items-center p-6"/> 
                              :
                              <div className='text-gray-300 w-full h-full flex items-center justify-center scale-125'>
                                {svg_image}
                              </div>
                            }
                          </div>

                          {uploadInfo && <div className="bg-red-500 mb-2 w-full text-gray-50 px-2 py-1 rounded-sm text-sm flex items-center justify-between space-x-2">
                            <div className='flex'>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                              <span>{uploadInfo}</span>
                            </div>
                            <button onClick={() => setUploadInfo(null)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                            </button>
                          </div> }

                          <div className='flex flex-nowrap items-center space-x-2'>
                            {pictureURL && <button onClick={deletePicture} className="btn--tool" type="button" style={{ userSelect: 'none' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                              <span>Esborrar</span>
                            </button> }

                            <label className="btn--tool" style={{ userSelect: 'none' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg>
                              <span>Pujar Imatge</span>
                              <input type="file" id="uploadeFile1" onChange={handleFileOnChange} style={{ display: "none" }}/>
                            </label>
                          </div>

                        </div>}



                        
                      </div>
                    </div>
                  </div>


                  {(role !== "presenter") &&
                    (editor !== index? (
                      role !== "subscriber"? (<div className="flex items-center px-2 py-1">
                        <>

                          <button type="button" onClick={() => _deleteItem(id)} className="btn--tool--gray" >
                            {svg_trash}
                            <span>Esborrar</span>
                          </button>

                          
                          <button type="button" onClick={() => edit(id)} className="ml-2 btn--tool--gray" >
                            {svg_edit}
                            <span>Editar</span>
                          </button>


                          {/* <button type="button" onClick={() => setShowMessagePreview(!showMessagePreview)} className={`ml-2 ${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`}>
                            {!showMessagePreview?
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                            : 
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                            }
                            <span>Vista prèvia</span>
                          </button> */}

                        </>
                      </div>) : null
                    ) : (
                      <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 text-xs">

                        <button type="button" onClick={() => cancelEdit(id)} className="btn--tool--gray"> 
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                          </svg>
                          <span>Cancel·lar</span>
                        </button>

                        <button type="submit" className="btn--tool--blue" onClick={(event) => saveEdit(event, index, id)}> 
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                          </svg>
                          <span>Desa</span>
                        </button>

                      </div>
                    ))}
                </div>

                <div className="flex flex-wrap justify-end items-start">

                  {isEmitted && role !== "presenter" && (
                    <div className="flex space-x-1 text-sm items-center text-gray-500 dark:text-gray-300 cursor-default whitespace-nowrap w-full justify-end" style={{ margin: '2px', marginRight: '10px' }}>
                      <span className="text-xs uppercase font-semibold px-1">
                        Emès a les{" "}
                        <span data-tip={displayFullTime(emittedTime)} data-place="bottom"> {displayTimeHour(emittedTime)} </span>
                      </span>
                      <button onClick={() => setIsEmitted(false, id)}>X</button>
                    </div>
                  )}

                  {editor === index ? null : (
                    <span className={`font-semibold whitespace-nowrap px-2 py-1 text-sm mr-2 rounded-sm bg-gray-300 text-gray-700`}>
                      {project?.categories?.map(element => { if (element.id === category) { return element.txt } })}
                    </span>
                  )}
                  
                  {/* {selectedItem != index && <div className="bg-gray-200 mx-2 rounded px-2 opacity-50" style={{ paddingBlock: '3px' }}>
                    <h1 className={`font-bold whitespace-nowrap ${role !== "presenter"? 'text-gray-600 text-sm' : ''}`}>Targeta</h1>
                  </div>} */}

                  {role === "subscriber" && (
                    <div className="transform -translate-y-1/2 flex space-x-2 text-sm items-center" style={{ marginTop: '18px' }}>


                      {(editor !== index? (
                        <div className="flex items-center px-2 py-1">
                          <>

                            <button type="button" onClick={() => _deleteItem(id)} className="btn--tool--gray" >
                              {svg_trash}
                            </button>

                            
                            <button type="button" onClick={() => edit(id)} className="ml-2 btn--tool--gray" >
                              {svg_edit}
                            </button>

                          </>
                        </div>
                      ) : ( null ))}






                      <button
                        onClick={handleSelected}
                        className={`disabled:opacity-20 disabled:cursor-not-allowed z-10 text-gray-500 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-100 uppercase font-semibold flex px-3 py-2 border-2 rounded-sm text-xs ${
                          selectedItem === index && "border-none"
                        }`}
                        type="button"
                        disabled={!Boolean(!project.overlay)}
                      >
                        {selectedItem === index ? "Cancel·lar" : "Seleccionar"}
                      </button>

                      {(overlay?.title ?? null) === title && (
                        <button
                          onClick={() => submitOverlay(id)}
                          type="button"
                          className=" flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-md"
                        >
                          {!project.overlay ? (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                                />
                              </svg>
                              <span>EMETRE</span>
                            </>
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                                />
                              </svg>
                              <span>DEIXAR D'EMETRE</span>
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  )}

                  {role === "admin" && (
                    <div className="w-6 h-6 rounded-sm border-2 dark:border-gray-600  hover:text-green-400">
                      <motion.button
                        onClick={handleSelected}
                        whileHover={{ scale: 1.5 }}
                        whileTap={{ scale: 0.9 }}
                        animate={{ scale: selectedItem === index ? 1.4 : 1 }}
                        transition={{ type: "linear" }}
                        className="selected-message__tool selected-message__tool--checkbox"
                      >
                        {selectedItem !== index ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        )}
                      </motion.button>
                    </div>
                  )}
                </div>

              </form>
              <ReactTooltip place="top" effect="solid" delayShow={500} />

              <CardOverlay 
                messageToShowTMP={{ 
                  id: '',
                  snippet: {
                    title: title,
                    isReady: false
                  },
                  type: 'card',
                  show: showMessagePreview,
                  setShowMessagePreview: setShowMessagePreview,
                }} 
              />

            </div>
          )}
        </Draggable>
      </div>
    );
  }


}







const mapStateToProps = (state, ownProps) => {
  return {};
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(Card)

/* export default Card; */
