import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { saveItem } from '../actions/projectActions'
import { getInitials } from '../utils'
import Spinner from './Spinner'
import PollOverlay from "./Overlays/poll";
import TextareaAutosize from "react-textarea-autosize";


function PollParticipants(props) {

  const { saveItem, firebase, simple, polls } = props
  const [visible, setVisible] = useState(simple? simple : false)

  const [votes, setVotes] = useState([])
  const [searchText, setSearchText] = useState('')






  const keyUp = (event) => {
    if (event.keyCode === 27) {
      setVisible(false)
    }
  }


  useEffect(() => {

    var participantsTMP = [];

    polls.map(pollTMP => {
      pollTMP.pollOptions.map(pollOptionTMP => {
        pollOptionTMP.votes.map((votesTMP) => {
          
          var repeated = false;
          participantsTMP = participantsTMP.map(participantTMP => {
            if (participantTMP.name === votesTMP.name) {
              repeated = true;
              return {
                name: votesTMP.name ?? 'Sense nom',
                email: votesTMP.email ?? 'Sense email',
                phone: votesTMP.phone ?? 'Sense telefón',
                birthDate: votesTMP.birthDate ?? 'Sense data',
                votes: participantTMP.votes + 1,
              }
            } else {
              return               {
                name: participantTMP.name ?? 'Sense nom',
                email: participantTMP.email ?? 'Sense email',
                phone: participantTMP.phone ?? 'Sense telefón',
                birthDate: participantTMP.birthDate ?? 'Sense data',
                votes: participantTMP.votes,
              }
            }
          });

          if (!repeated) {
            participantsTMP.push(
              {
                name: votesTMP.name ?? 'Sense nom',
                email: votesTMP.email ?? 'Sense email',
                phone: votesTMP.phone ?? 'Sense telefón',
                birthDate: votesTMP.birthDate ?? 'Sense data',
                votes: 1,
              }
            );
          }

        });
      });
    });

    setVotes(participantsTMP)

  }, [polls]);



  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700 mt-3`}>
      {simple != true && <button onClick={() => setVisible(!visible)} onKeyUp={keyUp} className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Participants de les enquestes</h2>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} className="hover:text-gray-200">
          {!visible ?
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" />
            </svg>
          } 
        </motion.div>
      </button>}
      <AnimatePresence>
      {visible &&
        <motion.div
          key="pollPart"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`p-4 ${simple != true? 'border-t-2' : ''} dark:border-gray-700`}
        >
          
        
          <input
            id="question"
            onKeyUp={keyUp}
            autoComplete="off"
            type="text"
            className="w-full mb-2"
            onChange={(event) => {setSearchText(event.target.value)} }
            placeholder="Cercador..."
            required
          />
  



          <hr className='pt-1'></hr>

          <div className='overflow-y-auto' style={{ maxHeight: 205 }}>
            {votes?.filter( element => (
                (!searchText) || 
                (element.name?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) ||
                (element.email?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))) ||
                (element.phone?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
              ))?.sort((a, b) => a.votes < b.votes? 1 : -1)?.map(element => {

              return <div key={element.email} className="text-sm py-1 px-2 flex justify-between space-x-2 hover:bg-gray-200 cursor-pointer whitespace-nowrap">

                <div className=''>
                  <p>{element.name}</p>
                  <p>{element.email}</p>
                  <p>{element.phone}</p>
                </div>

                <p>{element.votes} {element.votes === 1? 'vot' : 'vots'}</p>

              </div>
              
            })}
          </div>


        </motion.div>
      }
      </AnimatePresence>
    </div>
  )
}





const mapStateToProps = (state, ownProps) => {
  return { };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(PollParticipants)