export const setUserDarkMode = (value, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    firestore.collection('users').doc(userId).update({
      "settings.darkMode": value
    })
  }
}

export const settingApearance = (overlay, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    firestore.collection('users').doc(userId).update({
      "settings.appearance": overlay
    })
  }
}

export const setLocale = (lang, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore()
    firestore.collection('users').doc(userId).update({
      "settings.locale": lang
    })
  }
}