import { useState, useRef, useEffect } from "react";
import { CompactPicker } from 'react-color';


function ColorPicker(props) {

  const { setColor, color, setClose } = props
  var svg_close = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"/><line x1="6" y1="6" x2="18" y2="18"/></svg>


  return <>
    <div className='fixed z-40 w-screen h-screen top-0 left-0 flex justify-center items-center' style={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }}>
      <div className=''>
        <div className='w-full flex justify-end'>

          <div className="w-full mb-1 mr-1 rounded shadow" style={{ backgroundColor: color, border: '1px solid lightgray' }}></div>
          <div onClick={ () => setClose(false)} className="w-8 h-8 shadow cursor-pointer bg-gray-50 hover:bg-gray-200 py-1 px-2 rounded mb-1 flex items-center" style={{ border: '1px solid lightgray' }}>
            {svg_close}
          </div>
        </div>

        <div className="rounded overflow-hidden shadow" style={{ border: '1px solid lightgray' }}>
          <CompactPicker color={color} onChangeComplete={ (pickedColor) => { setColor(pickedColor.hex) } }/>
        </div>
      </div>
    </div>
  </>
}

export default ColorPicker;
