import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { motion, AnimatePresence } from 'framer-motion'





function CardOverlay(props) {
  const { message, defaultMessage, dummy, project, show } = props;
  const [overlay, setOverlay] = useState(null)



  useEffect(() => {
    if (dummy) {
      setOverlay(defaultMessage);
    } else {
      setOverlay(message?.snippet);
    }
  }, [message, dummy]);


  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);
  

  function checkFunc() {
    return show != null && overlay
  }



  return (
    show || show === null? <div className={`z-50 fixed top-0 left-0 w-screen h-screen 
        ${checkFunc()? 'flex justify-center items-center bg-gray-300 bg-opacity-50 cursor-default overflow-hidden' : 'pointer-events-none'}
        ${(show === false && show != null)? 'hidden ' : ''}
      `}
    >
      
      <div className={`fixed z-50 w-screen h-screen ${
        checkFunc()? 'bg-gray-300 border border-gray-600 rounded-sm overflow-hidden' : 'pointer-events-none top-0 left-0' } `}
        style={{ 
          transform: checkFunc()? 'scale(0.45) translate(-111%, -111%)' : null,
          minWidth: checkFunc()? '1920px' : null,
          minHeight: checkFunc()? '1080px' : null,
          maxWidth: checkFunc()? '1920px' : null,
          maxHeight: checkFunc()? '1080px' : null,
          top: checkFunc()? '50%' : null,
          left: checkFunc()? '50%' : null,    
        }}
      >
        
        {checkFunc() && <div onClick={() => message.setShowMessagePreview(!message.show)} className="text-right top-0 right-0 fixed font-bold text-gray-500 z-50 hover:text-gray-900 transform transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-16 w-16" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </div>}



        <AnimatePresence>
          {(show || show === null) && overlay && (
            <motion.div
              key={overlay.id}
              initial={{ opacity: 0, height: "100%",  width: "auto" }}
              animate={{ height: "100%",  width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, height: "100%",  width: "auto" }}
              transition={{ type: "easeInOut", duration: 0.5, delay: 0.5 }}
              className={`flex justify-end items-center h-screen w-screen`}
            >
              <div
                className={`flex justify-end items-center h-screen w-screen`}
                style={{
                  paddingTop: '13.2vh',
                  paddingBottom: '10vh',
                  maxWidth: '76.3vw',
                }}
              >



                <div className={`flex flex-col h-full space-y-3 relative`} style={{ maxWidth: overlay.showImageBox? '100vw' : '76.3vw', minWidth: '36.3vw', flexGrow: 4, backgroundColor: 'white' }}>
                  {overlay.showPrimaryBox && (
                    overlay.showImageBox ?

                      <div className={`bottom-extra-space w-full h-full overflow-hidden`} style={{ backgroundColor: '#64B6CF', padding: '2vw', paddingLeft: '2.2vw' }}>

                        <div className={`w-full`} style={{ fontSize: '3.2vw', color: '#234752', lineHeight: '5.5vh', fontWeight: 800, fontFamily: 'Averta bold', marginBottom: 19 }} dangerouslySetInnerHTML={{__html: overlay.title}}></div>
                        <div className={`w-full h-auto`} style={{ fontSize: '2.3vw', color: '#234752', lineHeight: '5vh', fontWeight: 800, fontFamily: 'Averta bold', marginBottom: 30 }} dangerouslySetInnerHTML={{__html: overlay.subtitle}}></div>
                        {overlay.body && 
                          <div className={`textBoxClassesXXL w-full h-full`} style={{ lineHeight: '3.5vh', color: '#234752', fontWeight: 800, fontFamily: 'Averta' }} dangerouslySetInnerHTML={{__html: overlay.body }}></div>
                        }

                      </div>

                      :

                      <div className={`w-full h-full overflow-hidden ${overlay.showImageBox? '' : 'flex flex-wrap justify-center items-center'}`} style={{ backgroundColor: '#64B6CF', padding: '5vw' }}>
                        <div>
                          <div className={`w-full mb-16`} style={{ fontSize: '65px', color: '#234752', lineHeight: '7vh', fontWeight: 800, fontFamily: 'Averta bold' }} dangerouslySetInnerHTML={{__html: overlay.title}}></div>
                          <div className={`w-full text-3xl mb-8`} style={{ fontSize: '3vw', color: 'white', lineHeight: '7vh', fontWeight: 800, fontFamily: 'Averta bold' }} dangerouslySetInnerHTML={{__html: overlay.subtitle}}></div>
                        </div>
                      </div>
                    
                  )}

                  {/* <div className={`w-full overflow-hidden ${overlay.showPrimaryBox? 'h-2/6' : 'h-full'} bg-gray-300 p-8`}> */}
                  {overlay.showSecondaryBox && <div className={`w-full whitespace-nowrap`} style={{ backgroundColor: '#64B6CF' }}>
                    <div className='whitespace-normal text-gray-900 text-bold' style={{ fontSize: '1.7vw', marginTop: '2.5vh', marginBottom: '2.5vh', marginLeft: '3.8vh', marginRight: '3.8vh', lineHeight: '3.7vh', fontFamily: 'Averta bold' }} dangerouslySetInnerHTML={{__html: overlay.secondaryBody}}></div>
                  </div>}
                </div>


              
                {overlay.showImageBox && 
                  <div className='h-full' style={{ maxWidth: '40vw', minWidth: '30vw' }}>
                    <img 
                      className={`object-cover w-full`}
                      style={{ height: '76.8vh' }}
                      src={overlay.pictureURL}
                      alt=""
                    />
                  </div>
                }

              

              </div> 
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div> : null 
  );
}

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const messageToShowTMP = ownProps.messageToShowTMP ?? null;
  const messages = state.firestore.ordered.overlays;

  if (!projectId && !messageToShowTMP ) return {};

  if (projectId) {
    const projects = state.firestore.data.projects;
    const users = state.firestore.data.users;
    const message = messages ? messages[0] : null;
    const project = projects ? projects[projectId] : null;
    const user = users && project ? users[project.authorId] : null;
  
    return {
      message: {
        ...message,
      },
      project,
      show: null,
    };
  } else if (messageToShowTMP.show) {
    const user = null;
    const message = messageToShowTMP;

    return {
      message: {
        ...message,
      },
      show: messageToShowTMP.show,
    };
  } else {
    return {}
  }

  /* const cards = state.firestore.ordered.overlays
  const card = cards ? cards[0] : null
  return {
    card: {
      ...card
    }
  } */
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match) return []
    return [
      { 
        collection: 'projects',
        doc: props.match.params.id,
        subcollections: [
          {
            collection: 'overlays',
            where: [
              ['type', '==', 'card']
            ],
          }
        ],
        storeAs: 'overlays'
      }
    ]
  })
)(CardOverlay)