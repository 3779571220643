import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createProject } from './../actions/projectActions'
import { authenticate, broadcastList, messagesList } from './../actions/youtubeActions'
import { uniqueNamesGenerator,  adjectives, colors } from 'unique-names-generator';

function ProjectCreate(props) {
  const { 
    createProject,
    auth,
    newProjectId,
    history,
  } = props
  
  const [redirect, setRedirect] = useState(false)
  const [randomName, setRandomName] = useState('')  
  useEffect(() => {
    setRandomName(uniqueNamesGenerator({
      dictionaries: [adjectives, colors],
      separator: ' ',
      style: 'capital',
      length: 2,
    }))
  }, [])
  const handleSubmit = (event) => {
    event.preventDefault()
    createProject({ 
      title: event.target['projectName'].value,
      publicURL: event.target['projectName'].value.toLowerCase().split(/[_\s]/).join("-").replaceAll('/', ''),
      // subtitles: { broadcastId: null, clipboard: '', history: [] }
    })
    setRedirect(true)
  }
  useEffect(() => {
    if (redirect) history.push(`/${newProjectId}/admin`)
    // eslint-disable-next-line
  }, [newProjectId])

  if(!auth.uid) return <Redirect to="/signin" />

  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <div className="max-w-md mx-auto space-y-4 p-5">
        <h1 className="text-3xl font-black">Sala nova</h1>
        <form onSubmit={handleSubmit} className="space-y-3">
          <div className="flex items-center">
            <label htmlFor="projectName" className="mb-0 bg-gray-100 dark:bg-gray-700 bg-opacity-30 py-1 px-2 text-gray-500 border-r dark:border-gray-700">Name</label>
            <input id="projectName" className="w-full m-0 text-gray-500 focus:text-gray-400" type="text" defaultValue={randomName}/>
          </div>
          <button className="btn w-full" type="submit" >Crea una sala nova</button>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    newProjectId: state.project.newProjectId,
    isAuthenticated: state.project.isAuthenticated,
    broadcasts: state.project.broadcasts
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project) => dispatch(createProject(project)),
    authenticate: () => dispatch(authenticate()),
    broadcastList: () => dispatch(broadcastList()),
    messagesList: () => dispatch(messagesList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreate)