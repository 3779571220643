import { useState, useEffect } from 'react'
import NotesEditor from './NotesEditor'

import { connect } from 'react-redux'
import { setNotes } from './../actions/projectActions'
import { serialize } from './../utils/'

const defaultContent = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ],
  },
]

function NotesCreate({ setNotes, notes }) {
  const [content, setContent] = useState(defaultContent)
  const [removed, setIsRemoved] = useState(false)

  useEffect(() => {
    setNotes({
      html: content.map(node => serialize(node)).join(''),
      json: content
    })
  }, [content])


  useEffect(() => {
    if (notes === null) {
      setIsRemoved(true)
    } else {
      setIsRemoved(false)
    }
  }, [notes])





  return (<>
    <div>
      <div className="border-2 dark:border-gray-700">
        <NotesEditor key="notesEditor" contentValue={content} setContentValue={setContent} />
      </div>
      {removed && <div className='text-red-500 text-sm pt-1'>Aquesta nota ha sigut eliminada pel presentador. Edita-la o fes-ne una de nova perquè la visualitzi...</div>}
    </div>
  </>)
  
}

export default connect((state, ownProps) => {

  const { projects } = state.firestore.data
  const projectId = ownProps.projectId
  if (projects) {
    const project = projects[projectId]
    return {
      notes: project?.notes?.json
    }
  }
  return {}

}, (dispatch, ownProps) => {

  const projectId = ownProps.projectId
  return {
    setNotes: (notes) => dispatch(setNotes(notes, projectId))
  }

})(NotesCreate)