import { Text } from 'slate'
import escapeHtml from 'escape-html'
import { jsx } from 'slate-hyperscript';

export function serialize(node) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<i>${string}</i>`
    }
    if (node.underline) {
      string = `<u>${string}</u>`
    }
    return string
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'heading-one':
      return `<h1 class="bg-green-500 text-gray-50 px-1">${children}</h1>`
    case 'heading-two':
      return `<h2 class="bg-red-500 text-gray-50 px-1">${children}</h2>`
    case 'heading-three':
      return `<h1 class="text-green-500">${children}</h1>`
    case 'heading-four':
      return `<h2 class="text-red-500">${children}</h2>`
    case 'heading-five':
      return `<p class="text-gray-50">${children}</p>`
    case 'heading-six':
      return `<p style="color: #f54d45">${children}</p>`
    case 'heading-seven':
      return `<p class="line-small">${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    default:
      return children
  }
}



const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  let children = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children = [{ text: '' }];
  }


  switch (el.nodeName) {
    // Elements:
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':

      var htmlElement = el.outerHTML;

      if (htmlElement.includes("bg-green-500 text-gray-50 px-1")) 
      { return jsx('element', { type: 'heading-one' }, children); } 

      else if (htmlElement.includes("bg-red-500 text-gray-50 px-1")) 
      { return jsx('element', { type: 'heading-two' }, children); } 

      else if (htmlElement.includes("text-green-500")) 
      { return jsx('element', { type: 'heading-three' }, children); } 

      else if (htmlElement.includes("text-red-500")) 
      { return jsx('element', { type: 'heading-four' }, children); } 

      else if (htmlElement.includes("text-gray-50")) 
      { return jsx('element', { type: 'heading-five' }, children); } 

      else if (htmlElement.includes('color: #f54d45')) 
      { return jsx('element', { type: 'heading-six' }, children); }

      else if (htmlElement.includes('line-small')) 
      { return jsx('element', { type: 'heading-seven' }, children); }

      else { return jsx('element', { type: 'paragraph' }, children); }
      /* return jsx('element', { type: 'paragraph' }, children); */




    case 'H1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children);


    // Leafs:
    case 'STRONG':
      return { text: el.textContent, bold: true };
    case 'EM':
      return { text: el.textContent, italic: true };
    case 'U':
      return { text: el.textContent, underline: true };

    default:
      return el.textContent;
  }
};

export const deserializeFromHtml = html => {
  const document = new window.DOMParser().parseFromString(html, 'text/html');
  return deserialize(document.body);
};














export function getTimeCodeFromNum(num) {
  let seconds = parseInt(num);
  let minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  const hours = parseInt(minutes / 60);
  minutes -= hours * 60;

  if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${minutes}:${String(
    seconds % 60
  ).padStart(2, 0)}`;
}

export function getInitials(fullName) {
  const splitValue = fullName.split(' ')
  return splitValue.map((n, i) => i < 2 ? n[0] : null).join('').toUpperCase()
}