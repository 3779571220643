import { useState, useRef, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  deleteItem,
  toggleItemIsReady,
  setEditedMessage,
  setIsEmitted,
  setItemPosition,
  setIsEditing,
} from "../actions/projectActions";
import { addOverlay, removeOverlay } from "../actions/overlayActions";
import { clamp, distance } from "popmotion";
import move from "array-move";
import Spinner from "./Spinner";
import Message from "./Message";
import ReactTooltip from "react-tooltip";

import Cayron from "./Cayron";


const buffer = 80;

export const findIndex = (i, yOffset, positions) => {
  let target = i;
  const { top, height } = positions[i];
  const bottom = top + height;

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[i + 1];
    if (nextItem === undefined) return i;

    const swapOffset =
      distance(bottom, nextItem.top + nextItem.height / 2) + buffer;
    if (yOffset > swapOffset) target = i + 1;

    // If moving up
  } else if (yOffset < 0) {
    const prevItem = positions[i - 1];
    if (prevItem === undefined) return i;

    const prevBottom = prevItem.top + prevItem.height;
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer;
    if (yOffset < -swapOffset) target = i - 1;
  }
  return clamp(0, positions.length, target);
};

function OverlayNotifications(props) {

  const {
    deleteItem,
    addOverlay,
    removeOverlay,
    project,
    role,
    title,
    setIsEmitted,
    setItemPosition,
    darkMode,
    size
  } = props;

  const [overlay, setOverlay] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [grabLinksVisible, setGrabLinksVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [dragCompleted, setDragCompleted] = useState(false);
  const [settingDarkMode, setSettingDarkMode] = useState(false);
  const [initItemsLength, setInitItemsLength] = useState(null);

  const [numberOfMssges, setNumberOfMssges] = useState(0);
  const [hour, setHour] = useState(new Date());

  var firstime = true;


  useEffect(() => {
    if (firstime) {
      firstime = false;
      setInterval(() => setHour(new Date()), 500);
    }
  }, []);



  function checkNotas() {
    var regex = project?.notes?.html?.replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<h2 class="text-red-500">', '').replaceAll('<h2 class="text-green-500">', '').replaceAll('</h2>', '').replaceAll('<i>', '').replaceAll('</i>', '').replaceAll('<u>', '').replaceAll('</u>', '').replaceAll('<strong>', '').replaceAll('</strong>', '');

    if (project?.notes?.html && regex != 'undefinedundefined' && regex != '') {
      return true
    } else {
      return false
    }
  }


  useEffect( async () => {
    var num = 0;
    await project?.items?.map(element => {
      if (element.type != 'cayron' && element.isReady) {
        num++;
      }
    });
    setNumberOfMssges(num);
  }, [project]);


  useEffect(() => {
    if (project) {
      if (!initItemsLength || initItemsLength >= project.itemsLength) {
        setInitItemsLength(project.items.length);
      }
      setItems(_.sortBy(project.items, ["position"]));
    }
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        setInitItemsLength(project.items.length);
      }
    };
    // eslint-disable-next-line
  }, [project]);


  useEffect(() => {
    if (darkMode) { document.documentElement.classList.toggle("dark"); }

    if (settingDarkMode) {
      document.documentElement.classList.toggle("dark");
    } else {
      document.documentElement.classList.toggle("dark");
    }

  }, [settingDarkMode, darkMode]);


  useEffect(() => {
    setSettingDarkMode(darkMode);
  }, [darkMode]);

  
  const positions = useRef([]).current;
  const setPositions = (i, offset) => (positions[i] = offset);


  const submitOverlay = (messageId) => {
    if (project.overlay) {
      removeOverlay();
      setSelectedItem(null);
      setOverlay(null);
      if (messageId) setIsEmitted(true, messageId);
    } else {
      addOverlay(overlay);
    }
  };


  const selectCard = (card, index) => {
    if (index !== selectedItem) {
      setOverlay(card);
      setSelectedItem(index);
    } else {
      setOverlay(null);
      setSelectedItem(null);
    }
  };




  if (!project)
    return (
      <Spinner className="fixed top-1/2 left-1/2 transform -translate-1/2" />
    );

  return (
    <div
      className={`relative flex flex-col mb-2 overlayList--${role}`}
      style={
        role === "admin"
          ? { height: "calc(100vh - 3rem)" }
          : role === "editor"
          ? { height: "calc(100vh - 15rem)" }
          : role === "subscriber"
          ? { maxHeight: "100%" }
          : role === "audioEditor"
          ? { maxHeight: "calc(100vh - 7rem)" }
          : role === "presenter"
          ? { maxHeight: "calc(100vh - 11rem)" }
          : null
      }
    >
      
      <div className="sticky top-0 px-3 flex justify-between items-center bg-gray-50 space-x-2 border-2 dark:border-gray-700 py-2 z-10">
        <h2 className="text-sm font-semibold">{title ?? "Overlays"}</h2>
        {role !== "editor" && (
          <>
            <button onClick={() => setGrabLinksVisible(true)} className="p-1 flex" /* data-tip="Enlaces de escena para tu OBS" */ >
              Enllaços d'escena (OBS)
              {"\u00A0"}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </svg>
            </button>
            <ReactTooltip />
          </>
        )}
      </div>
      <div
        className={`flex-1 overflow-auto border-l-2 border-r-2 border-b-2 dark:border-gray-700 overflow-x-hidden select-none ${
          project.overlay && role === "admin" && `pointer-events-none`
        }`}
      >
        <div className={`fixed top-5 right-5 flex items-center space-x-3 z-20 text-${size}`}>
          {(role === "subscriber" || role === "presenter") && (
            <button onClick={() => setSettingDarkMode(!settingDarkMode)}>
              {settingDarkMode ? "🌚" : "🌞"}
            </button>
          )}
          {role === "subscriber" && (
            <button onClick={() => setGrabLinksVisible(true)} className="">
              🔗
            </button>
          )}
        </div>
        {role === "subscriber" && (
          <h1 className="mt-4 mb-8 text-3xl font-bold">Emissor de missatges</h1>
        )}

        
        
        <div style={{ paddingBottom: '85px', display: 'flex', alignItems: 'stretch', flexWrap: 'wrap' }} className="w-screen h-screen fixed pr-10">

          <div className={`w-full relative border-4 rounded p-2 text-center mb-2 font-semibold ${!settingDarkMode? 'border-gray-900 text-gray-900' : 'border-gray-400 text-gray-50'}`}>
            {/* <div style={{ paddingRight: '15px' }} className="absolute w-full">
              <h1 className="w-full font-semibol">HORA</h1>
              <hr className={`mt-1 border rounded-full ${!settingDarkMode? 'border-gray-900 bg-gray-900' : 'border-gray-400 bg-gray-300'}`}></hr>
            </div> */}
            <div className="w-full h-full flex items-center">
              <div className="font-semibold w-full"> {hour.getHours()+':'+(hour.getMinutes() < 10? '0'+hour.getMinutes() : hour.getMinutes())+':'+(hour.getSeconds() < 10? '0'+hour.getSeconds() : hour.getSeconds())} </div>
            </div>
          </div>

          <div className="w-full flex space-x-2">

              <div className={`w-full border-4 rounded p-2 text-center font-semibold flex items-center ${!settingDarkMode? 'border-gray-900 text-gray-900' : 'border-gray-400 text-gray-50'} ${checkNotas()? `` : `opacity-30` }`}>
                {checkNotas()? <h1 className="w-full font-semibol">CON NOTAS</h1> : <h1 className="w-full font-semibold">SIN NOTAS</h1>}
              </div>

              <div className={`w-full relative border-4 rounded p-2 text-center font-semibold ${!settingDarkMode? 'border-gray-900 text-gray-900' : 'border-gray-400 text-gray-50'} ${numberOfMssges? `` : `flex items-center opacity-30` }`}>
                <div style={{ paddingRight: '15px' }} className="absolute w-full">
                  {numberOfMssges? <h1 className="w-full font-semibol">MENSAJES</h1> : <h1 className="w-full font-semibold">SIN MENSAJES</h1>}
                  {numberOfMssges? <hr className={`mt-1 border rounded-full ${!settingDarkMode? 'border-gray-900 bg-gray-900' : 'border-gray-400 bg-gray-300'}`}></hr> : null}
                </div>
                <div className="w-full h-full flex items-center">
                  {numberOfMssges? <div className="font-semibold w-full"> {numberOfMssges} </div> : null}
                </div>
              </div>

          </div>

        </div>

      </div>
    </div>
  );
}




export default compose(
  firestoreConnect((props) => {
    const paramsId = props.match?.params.id ?? null;
    if (!paramsId) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [
          { collection: "items" }, //  orderBy: ['position', 'desc']
        ],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [
          {
            collection: "overlays",
          },
        ],
        storeAs: "overlays",
      },
    ];
  }),
  connect(
    (state, ownProps) => {
      const { projects } = state.firestore.data;
      const { items, overlays } = state.firestore.ordered;
      const { users } = state.firestore.data;
      const projectId = ownProps.project?.id ?? null;
      if (projectId) return {};
      if (projects && items && overlays) {
        const project = projects[ownProps.match.params.id];
        const user = users[state.firebase.auth.uid];
        return {
          roles: {
            suscriber: true,
          },
          project: {
            id: ownProps.match.params.id,
            ...project,
            items,
            itemsLength: items.length,
            overlay: overlays?.length ?? 0,
          },
          darkMode: user?.settings?.darkMode,
        };
      }
      return {};
    },
    (dispatch, ownProps) => {
      const projectId = ownProps.project?.id ?? ownProps.match.params.id;
      return {
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        addOverlay: (message) => dispatch(addOverlay(message, projectId)),
        removeOverlay: () => dispatch(removeOverlay(projectId)),
        toggleItemIsReady: (id) => dispatch(toggleItemIsReady(id, projectId)),
        setEditedMessage: (value, messageId) =>
          dispatch(setEditedMessage(value, messageId, projectId)),
        setIsEmitted: (value, messageId) =>
          dispatch(setIsEmitted(value, messageId, projectId)),
        setItemPosition: (value, messageId) =>
          dispatch(setItemPosition(value, messageId, projectId)),
        setIsEditing: (value, messageId) =>
          dispatch(setIsEditing(value, messageId, projectId)),
      };
    }
  )
)(OverlayNotifications);
