import React, { useState, useRef, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import _ from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  deleteItem,
  toggleItemIsReady,
  setEditedMessage,
  setIsEmitted,
  setCategoria,
  setItemPosition,
  setIsEditing,
  setCategories,
} from "../actions/projectActions";
import { addOverlay, removeOverlay, updateVoting } from "../actions/overlayActions";
import { clamp, distance } from "popmotion";
import move from "array-move";
import Spinner from "./Spinner";
import Message from "./Message";
import Card from "./Card";

import ReactTooltip from "react-tooltip";

import Cayron from "./Cayron";
import Poll from "./Poll";
import { DragDropContext, Droppable } from "react-beautiful-dnd";


const buffer = 80;

export const findIndex = (i, yOffset, positions) => {

  let target = i;
  const { top, height } = positions[i];
  const bottom = top + height;

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[i + 1];
    if (nextItem === undefined) return i;
    const swapOffset = (distance(bottom, (nextItem.top + nextItem.height / 2))) + buffer;
    if (yOffset > swapOffset) target = i + 2;
  } 
  // If moving up
  else if (yOffset < 0) {
    const prevItem = positions[i - 1];
    if (prevItem === undefined) return i;
    const prevBottom = prevItem.top + prevItem.height;
    const swapOffset = (distance(top, (prevBottom - prevItem.height / 2))) + buffer;
    if (yOffset < -swapOffset) target = i - 2;
  }

  return clamp(0, positions.length, target);
};



function OverlayCreate(props) {

  const {
    deleteItem,
    addOverlay,
    removeOverlay,
    project,
    role,
    title,
    setIsEmitted,
    setCategoria,
    setItemPosition,
    darkMode,
    setCategories,
    hiddeGroups,
  } = props;

  const [overlay, setOverlay] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [grabLinksVisible, setGrabLinksVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [dragCompleted, setDragCompleted] = useState(false);
  const [settingDarkMode, setSettingDarkMode] = useState(false);
  const [initItemsLength, setInitItemsLength] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState('null')
  const [category, setCategory] = useState([{id: 'null', txt: 'Nuevos elementos'}])
  const [newCatName, setNewCatName] = useState('')
  const [addingCat, setAddingCat] = useState(false)


  var svg_back = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.708 15.833H5.854V14.083H11.708Q13 14.083 13.958 13.292Q14.917 12.5 14.917 11.271Q14.917 10.042 13.958 9.24Q13 8.438 11.708 8.438H6.667L8.792 10.562L7.562 11.792L3.333 7.562L7.562 3.333L8.792 4.562L6.667 6.688H11.708Q13.729 6.688 15.198 7.99Q16.667 9.292 16.667 11.25Q16.667 13.208 15.198 14.521Q13.729 15.833 11.708 15.833Z"/></svg>
  var svg_save = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>
  var svg_plus = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M9.125 15.833V10.875H4.167V9.125H9.125V4.167H10.875V9.125H15.833V10.875H10.875V15.833Z"/></svg>
  var svg_trash = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.896 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V5.125q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h3.375q0-.354.26-.615.261-.26.615-.26h3.187q.355 0 .615.26.26.261.26.615h3.375q.355 0 .615.26.26.261.26.615t-.26.615q-.26.26-.615.26V15.75q0 .729-.51 1.24-.511.51-1.24.51Zm1.542-4.375q0 .354.26.615.26.26.614.26.355 0 .615-.26.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.615-.26-.354 0-.614.26-.26.261-.26.615Zm3.333 0q0 .354.26.615.261.26.615.26t.614-.26q.261-.261.261-.615V7.75q0-.354-.261-.615-.26-.26-.614-.26t-.615.26q-.26.261-.26.615Z"/></svg>

  var svg_down = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/></svg>;
  var svg_up = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5.625 12.188q-.25-.25-.25-.615t.25-.615l3.75-3.75q.146-.146.302-.208T10 6.938q.167 0 .323.062t.302.208l3.771 3.771q.25.25.26.594.011.344-.26.615-.25.25-.615.25-.364 0-.614-.25L10 9.042l-3.167 3.166q-.25.25-.593.25-.344 0-.615-.27Z"/></svg>;


  useEffect(() => {
    if (project && !project?.categories) {
      setCategories(category)
    }
  }, [project, category]);





  var svg_link = <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/></svg>;
  var svg_copy = <svg xmlns="http://www.w3.org/2000/svg" data-tip="Copiar URL" className="h-5 w-5 dark:hover:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/></svg>


  useEffect(() => {
    if (project) {
      if (!initItemsLength || initItemsLength >= project.itemsLength) {
        setInitItemsLength(project.items.length);
      }
      setItems(_.sortBy(project.items, ["position"]));
    }
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        setInitItemsLength(project.items.length);
      }
    };
    // eslint-disable-next-line
  }, [project]);


  useEffect(() => {
    if (darkMode) { document.documentElement.classList.toggle("dark"); }

    if (settingDarkMode) {
      document.documentElement.classList.toggle("dark");
    } else {
      document.documentElement.classList.toggle("dark");
    }
  }, [settingDarkMode, darkMode]);

  useEffect(() => {
    setSettingDarkMode(darkMode);
  }, [darkMode]);


  const positions = useRef([]).current;
  const setPositions = (i, offset) => (positions[i] = offset);







  const submitOverlay = (messageId) => {
    if (project.overlay) {
      removeOverlay();
      setSelectedItem(null);
      setOverlay(null);
      if (messageId) setIsEmitted(true, messageId);
    } else {
      addOverlay(overlay);
    }
  };


  const selectCard = (card, index) => {
    if (index !== selectedItem) {
      setOverlay(card);
      setSelectedItem(index);
    } else {
      setOverlay(null);
      setSelectedItem(null);
    }
  };


  


  const scrollToTopFunc = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    document.getElementById('elements-div').scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }



  const handleDrop = (droppedItem) => {

    if (!droppedItem.destination) return;
    var updatedList = [...items];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    console.log(items)

    updatedList?.map((element, key) => {
      setItemPosition(key, element.id)
    });
  };










  const addCategoryFunc = () => {

    var id = newCatName.toLocaleLowerCase().replaceAll(' ', '-');
    var newCatTMP = {id: id, txt: newCatName};
    var catsTMP = [...project.categories, newCatTMP];
    setCategories(catsTMP)

    setAddingCat(false);
    setNewCatName('');
  };


  const hasObjects = (id) => {
    var itemsTMP = items.filter((item) => (!Object.hasOwn(item, 'category') && id === 'null') || (item.category === id) ).filter((item) => { if (role === "presenter") { return item.isReady && !item.isEmitted; } else { return item; } });
    if (itemsTMP && itemsTMP.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  
  const removeCategory = (id) => {
    var itemsTMP = items.map(element => {
      if (!Object.hasOwn(element, 'category') || element?.category === id) {        
        setCategoria('null', element.id);
        return {...element, category: 'null'};
      } else {
        return element;
      }
    });

    var categoriesTMP = project?.categories?.filter(function(element) { return element.id !== id });

    setItems(itemsTMP);
    setCategories(categoriesTMP);
  };



  if (!project)
    return (
      <Spinner className="fixed top-1/2 left-1/2 transform -translate-1/2" />
    );

  return (
    <div
      className={`relative flex flex-col mb-2 overlayList--${role}`}
      style={
        role === "admin"
          ? { height: "calc(100vh - 3rem)" }
          : role === "editor"
          ? { height: "calc(100vh - 15rem)" }
          : role === "subscriber"
          ? { maxHeight: "100%" }
          : role === "audioEditor"
          ? { maxHeight: "calc(100vh - 7rem)" }
          : role === "presenter"
          ? { maxHeight: "calc(100vh - 11rem)" }
          : null
      }
    >
      <div className="sticky top-0 px-3 flex justify-between items-center space-x-2 border-2 dark:border-gray-700 py-2 z-10 bg-gray-50">
        {/* <h2 className="text-sm font-semibold">{title ?? "Overlays"}</h2> */}


        {(addingCat)?
          <div className="flex space-x-2">
            <input type="text" placeholder="Nom de la categoria" autoComplete="off" onChange={ (event) => setNewCatName(event.target.value) } className="w-full"/>


            <button type="button" className="btn--tool--blue" style={{ paddingBlock: 2, paddingInline: 8 }} onClick={() => {addCategoryFunc()}} disabled={!newCatName || newCatName.replaceAll(' ', '') === ''}> 
              {svg_save}
              <span>Desar categoria</span>
            </button>

            <button type="button" className="btn--tool--gray" style={{ paddingBlock: 2, paddingInline: 5 }} onClick={() => {setAddingCat(false); setNewCatName('')}}> 
              {svg_back}
            </button>
          </div>
        :
          <div className="flex space-x-2">
            <button type="button" className="btn--tool--blue" style={{ paddingBlock: 6, paddingInline: 8 }} onClick={() => {setAddingCat(true)}}> 
              {svg_plus}
              <span>Nova categoria</span>
            </button>
          </div>
        }







        {role !== "editor" && (
          <>
            <button
              onClick={() => setGrabLinksVisible(true)}
              className="p-1 flex"
              data-tip="Enlaces de escena para tu OBS"
            >
              Enllaços d'escena (OBS)
              {"\u00A0"}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                />
              </svg>
            </button>
            <ReactTooltip />
          </>
        )}
      </div>


      <div
        id={'elements-div'}
        className={`flex-1 space-y-2 overflow-auto overflow-x-hidden ${
          project.overlay && role === "admin" && ``
        }`}
      >


        <div className="fixed top-5 right-5 flex items-center space-x-3 z-20 select-none cursor-pointer" style={{ marginTop: 6 }}>


          {role === "subscriber" && <div className="flex justify-center items-center relative">
            <button
              onClick={() => submitOverlay()}
              disabled={!project.overlay}
              style={{ opacity: !project.overlay? '0.2' : '1' }}
              className="flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-3xl"
            >
              DEIXAR D'EMETRE
            </button>
          </div>}


          {(role === "subscriber" || role === "presenter") && (
            <button onClick={() => setSettingDarkMode(!settingDarkMode)}>
              {settingDarkMode ? "🌚" : "🌞"}
            </button>
          )}
          {role === "subscriber" && (
            <button onClick={() => setGrabLinksVisible(true)} className="">
              🔗
            </button>
          )}


        </div>


        {role === "subscriber" && (
          <h1 className="text-3xl font-bold" style={{ marginBottom: 40, marginTop: 30 }}>Emissor de missatges</h1>
        )}

        
        {project?.categories?.map((element) => {

          return <div className={`w-full rounded-md overflow-hidden ${(hasObjects(element.id))? '' : 'opacity-50'}`} style={{ border: (!hiddeGroups)? '2px rgb(200,200,200) solid' : 'transparent', marginTop: (!hiddeGroups)? 10 : 0 }}>
            {(!hiddeGroups)? 
              <div className="pl-3 pr-1 py-1 bg-gray-200 flex justify-between items-center">
                <p className="font-bold" id={element.txt}>{(element.txt === 'Sense categoria')? 'Nous elements' : element.txt}</p>

                <div className="flex justify-between space-x-1 items-center">
                  {( role != "subscriber" && element.id != 'null') && <button type="button" className="btn--tool--red" style={{ paddingBlock: 6, paddingInline: 5 }} onClick={() => { if (window.confirm('Segur que vols eliminar aquesta categoria?')) { removeCategory(element.id) }}}> 
                    {svg_trash}
                  </button>}

                  {element.id != selectedCategory? 
                    <button type="button" className="btn--tool--blue" style={{ paddingBlock: 6, paddingInline: 5 }} onClick={() => { setSelectedCategory(element.id); scrollToTopFunc(); }} disabled={!hasObjects(element.id)}> 
                      {svg_down}
                    </button>
                  :
                    <button type="button" className="btn--tool--blue" style={{ paddingBlock: 6, paddingInline: 5 }} onClick={() => { setSelectedCategory(null) }} disabled={!hasObjects(element.id)}> 
                      {svg_up}
                    </button>
                  }
                </div>
              </div>
            : null }
















            {((element.id === selectedCategory) || hiddeGroups) && 
              <div>
                <DragDropContext onDragEnd={handleDrop}>
                  <Droppable droppableId={"list-container-"+element.id}> 

                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >


                
                
                      {items.filter((item) => ((item.category === element.id) || (!Object.hasOwn(item, 'category') && element.id === 'null'))).filter((item) => { if (role === "presenter") { return item.isReady && !item.isEmitted; } else { return item; } }).map((item, index) => {

                        if ( /chatMessage/.test(item.type) || /audioMessage/.test(item.type) ) {
                          return (
                            <Message
                              key={item.id}
                              {...props}
                              setDragCompleted={setDragCompleted}
                              dragCompleted={dragCompleted}
                              message={item}
                              index={index}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              overlay={overlay}
                              setOverlay={setOverlay}
                              setPositions={setPositions}
                              setItemPosition={setItemPosition}
                              projectStyles={project?.projectStyles? project.projectStyles : null}
                            />
                          );
                        }

                        if ( /cayron/.test(item.type) ) {
                          return (
                            <Cayron
                              key={item.id}
                              {...props}
                              setDragCompleted={setDragCompleted}
                              dragCompleted={dragCompleted}
                              message={item}
                              index={index}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              overlay={overlay}
                              setOverlay={setOverlay}
                              setPositions={setPositions}
                              setItemPosition={setItemPosition}
                              projectStyles={project?.projectStyles? project.projectStyles : null}
                            />
                          );
                        }

                        if ( /poll/.test(item.type) ) {
                          return (
                            <Poll
                              key={item.id}
                              {...props}
                              setDragCompleted={setDragCompleted}
                              dragCompleted={dragCompleted}
                              message={item}
                              index={index}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              overlay={overlay}
                              setOverlay={setOverlay}
                              setPositions={setPositions}
                              setItemPosition={setItemPosition}
                              projectStyles={project?.projectStyles? project.projectStyles : null}
                            />
                          );
                        }

                        if (/card/.test(item.type)) {
                          return (
                            <Card
                              key={item.id}
                              {...props}
                              setDragCompleted={setDragCompleted}
                              dragCompleted={dragCompleted}
                              message={item}
                              index={index}
                              selectedItem={selectedItem}
                              setSelectedItem={setSelectedItem}
                              overlay={overlay}
                              setOverlay={setOverlay}
                              setPositions={setPositions}
                              handleDrop={handleDrop}
                              setItemPosition={setItemPosition}
                            />
                          );
                        }

                      })}



                      {provided.placeholder}

                    </div>)}
                    {/* </AnimatePresence> */}
                  </Droppable>
                </DragDropContext>
              </div>
            }
          </div>

        
        })}
      </div>

      {role === "admin" && (
        <div className="flex justify-center items-center relative px-3 py-4 border-2 dark:border-gray-700">
          <span
            className={`absolute left-8 rounded px-2 py-1 bg-gray-900 text-gray-100 transition-all text-sm font-semibold pointer-events-none ${
              !project.overlay ? `opacity-0` : `animate-pulse`
            }`}
          >
            A L'AIRE
          </span>
          <button
            onClick={() => submitOverlay()}
            disabled={!project.overlay && !overlay}
            className=" flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-3xl"
          >
            {!project.overlay ? "EMETRE" : "DEIXAR D'EMETRE"}
          </button>
        </div>
      )}

      <AnimatePresence>
        {role === "subscriber" &&
          initItemsLength &&
          project.itemsLength - initItemsLength > 0 && (
            <motion.div
              key="alertNewMessage"
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              exit={{ opacity: 0 }}
              className="fixed bottom-4 right-3 bg-yellow-300 dark:bg-yellow-500 p-3 rounded flex items-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 animate-bounce"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="font-semibold">
                {project.itemsLength - initItemsLength}
              </span>
              <span className="font-semibold">{`Nuevo${
                project.itemsLength - initItemsLength > 1 ? `s` : ``
              } mensaje${
                project.itemsLength - initItemsLength > 1 ? `s` : ``
              }`}</span>
            </motion.div>
          )}
        {grabLinksVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="modal"
            className="fixed w-screen h-screen top-0 left-0 z-10"
          >
            <div className="absolute z-20 top-1/4 left-1/2 transform -translate-x-1/2 px-10 pt-5 pb-10 bg-gray-50 dark:bg-gray-800 rounded text-center">
              <h2 className="font-semibold mb-4 text-xl">
                Enllaços d'escena per al teu OBS
              </h2>
              <div className="space-y-3 text-gray-400">


                {project?.widgets?.includes("Messages") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista de mensajes</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.PUBLIC_URL}/${project.id}/messages-overlay`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}${project.id}/messages-overlay`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


                {project?.widgets?.includes("Messages") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista de missatges dàudio</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.REACT_APP_BASE}${project.id}/audio-messages-overlay`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}${project.id}/audio-messages-overlay`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


                {project?.widgets?.includes("Cayrons") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista de cayrons</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.REACT_APP_BASE}${project.id}/cayrons-overlay`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}${project.id}/cayrons-overlay`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


                {project?.widgets?.includes("Polls") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista d'enquestes</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.REACT_APP_BASE}${project.id}/polls-overlay`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}${project.id}/polls-overlay`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


                {project?.widgets?.includes("Subtitles") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista de subtítols</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.REACT_APP_BASE}subtitles/${project.publicURL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}subtitles/${project.publicURL}`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


                
                {project?.widgets?.includes("Cards") && <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                  <span>Vista de targetes</span>
                  <div className="flex items-center space-x-2">
                    <a
                      href={`${process.env.REACT_APP_BASE}${project.id}/cards-overlay`}
                      target="_blank"
                      rel="noreferrer"
                      className="dark:hover:text-gray-200"
                    >
                      {svg_link}
                    </a>
                    <CopyToClipboard
                      text={`${process.env.REACT_APP_BASE}${project.id}/cards-overlay`}
                    >
                      <motion.button
                        whileHover={{ scale: 1 }}
                        whileTap={{ scale: 0.8 }}
                      >
                        {svg_copy}
                      </motion.button>
                    </CopyToClipboard>
                  </div>
                </div>}


              </div>
            </div>
            <div
              onClick={() => setGrabLinksVisible(false)}
              className="absolute top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-80"
            />
            <ReactTooltip place="bottom" effect="solid" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default compose(
  firestoreConnect((props) => {
    const paramsId = props.match?.params.id ?? null;
    if (!paramsId) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [
          { collection: "items" }, //  orderBy: ['position', 'desc']
        ],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: paramsId,
        subcollections: [
          {
            collection: "overlays",
          },
        ],
        storeAs: "overlays",
      },
    ];
  }),
  connect(
    (state, ownProps) => {
      const { projects } = state.firestore.data;
      const { items, overlays } = state.firestore.ordered;
      const { users } = state.firestore.data;
      const projectId = ownProps.project?.id ?? null;
      
      if (projectId) return {};

      if (projects && items && overlays) {
        const project = projects[ownProps.match.params.id];
        const user = users[state.firebase.auth.uid];
        return {
          roles: {
            suscriber: true,
          },
          project: {
            id: ownProps.match.params.id,
            ...project,
            items,
            itemsLength: items.length,
            overlay: overlays?.length ?? 0,
          },
          darkMode: user?.settings?.darkMode,
        };
      }
      return {};
    },
    (dispatch, ownProps) => {
      const projectId = ownProps.project?.id ?? ownProps.match.params.id;
      return {
        deleteItem: (id) => dispatch(deleteItem(id, projectId)),
        addOverlay: (message) => dispatch(addOverlay(message, projectId)),
        removeOverlay: () => dispatch(removeOverlay(projectId)),
        toggleItemIsReady: (id) => dispatch(toggleItemIsReady(id, projectId)),
        setEditedMessage: (value, messageId) => dispatch(setEditedMessage(value, messageId, projectId)),
        updateVoting: (value, pollId) => dispatch(updateVoting(value, pollId, projectId)),
        setIsEmitted: (value, messageId) => dispatch(setIsEmitted(value, messageId, projectId)),
        setCategoria: (value, messageId) => dispatch(setCategoria(value, messageId, projectId)),
        setItemPosition: (value, messageId) => dispatch(setItemPosition(value, messageId, projectId)),
        setIsEditing: (value, messageId) => dispatch(setIsEditing(value, messageId, projectId)),
        setCategories: (value) => dispatch(setCategories(value, projectId)),
      };
    }
  )
)(OverlayCreate);
