import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";



var startAnimations = {
  'preset-1': [{ scale: 0, opacity: 0, x: "8rem", y: 30 }, { x: "-100%" }, { height: 0 }],
  'preset-IMMB': [{ y: "-100%" }, { x: '-101%' }, { y: '100%' }],
  'slide-up': [{ y: 500 }, { y: 500 }, { y: 500 }],
  'slide-right': [{x: -1500 }, { x: -1500 }, { x: -1500 }],
  'slide-down': [{ y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-left': [{x: 2000 }, { x: 2000 }, { x: 2000 }],
}

var endAnimations = {
  'preset-1': [{ scale: 0, opacity: 0, x: "8rem", y: 30 }, { x: "-100%" }, { height: 0 }],
  'preset-IMMB': [{ y: "-100%" }, { x: '-101%' }, { y: '100%' }],
  'slide-up': [{ y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-right': [{x: 2000 }, { x: 2000 }, { x: 2000 }],
  'slide-down': [{ y: 500 }, { y: 500 }, { y: 500 }],
  'slide-left': [{x: -2000 }, { x: -2000 }, { x: -2000 }],
}


function MessageOverlay(props) {
  const { message, defaultMessage, dummy, project, show } = props;
  const [overlay, setOverlay] = useState(null);
  const [projectStyles, setProjectStyles] = useState(null);


  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect(() => {
    if (dummy) {
      setOverlay(defaultMessage);
    } else {
      setOverlay(message?.snippet);
    }
    // eslint-disable-next-line
  }, [message, dummy]);

  
  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);


  function checkFunc() {
    return show != null && overlay && ( 
      overlay.profileImageUrl || 
      overlay.authorInitials || 
      (overlay.displayName && overlay.displayName.split(' ').join('')) || 
      (overlay.businessName && overlay.businessName.split(' ').join('')) || 
      (overlay.currentMessage && overlay.currentMessage.split(' ').join(''))
    )
  }





  function IMMBelement() {
    {/* EXTRA COMPONENTS FOR IMMB-messages */}
    var text = '';

    const variants = {
      open: { x: ['0%', '-100%'] },
      closed: { x: "0%" },
    }


    return <div className="relative" style={{ zIndex: 100 }}>
      <div className="fixed bottom-0 left-0 right-0 flex w-screen overflow-hidden items-center">
        <motion.div
          initial={projectStyles && startAnimations[projectStyles?.messageStartAnimation][2]? startAnimations[projectStyles.messageStartAnimation][2] : startAnimations['preset-1'][2]}
          animate={{ height: "auto", width: "100%", scale: 1, opacity: 0.8, x: 0, y: 0 }}
          exit={projectStyles && endAnimations[projectStyles?.messageEndAnimation][2]? endAnimations[projectStyles.messageEndAnimation][2] : endAnimations['preset-1'][2]}
          transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationBody)? projectStyles.messageAnimationDurationBody : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayBody)? projectStyles.messageAnimationDelayBody : 0.0 }}
          className={`overflow-hidden w-screen ${projectStyles?.messageClasesBodyBox? projectStyles.messageClasesBodyBox : ''}`}
          style={{ 
            backgroundColor: projectStyles?.messageColorBody? projectStyles.messageColorBody : '#F4F4F5', 
            color: projectStyles?.messageColorBodyText? projectStyles.messageColorBodyText : '#7C7C83',
          }}
        >

          {/* FAKE */}
          <motion.p
            initial={{ x: '100%' }}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: '-250%', y: 0 }}
            exit={{ x: '100%' }}
            transition={{ ease: "linear", duration: 35, delay: 1 }}
            className={`invisible whitespace-nowrap font-bold leading-tight opacity-100 ${projectStyles?.messageClasesBodyText? projectStyles.messageClasesBodyText : ''}`} 
          >
            <motion.p
              initial={{ opacity: 0}}
              animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0}}
              transition={{ type: "easeInOut", duration: 0.2, delay: 0 }}
            >
              {overlay.currentMessage.includes("·")? 
                overlay.currentMessage.split('·').map((element, key) => {
                  if (key === 0) {
                    text = '<div class="whitespace-nowrap flex justify-center items-center"><p class="pr-10">'+element+'</p><p class="pr-10">·</p>'
                  } else if ((key+1) === overlay.currentMessage.split('·').length) {
                    text = text+'<p>'+element+'</p></div>'

                    return <div dangerouslySetInnerHTML={{__html: text}}></div>
                  } else {
                    text = text+'<p class="pr-10">'+element+'</p><p class="pr-10">·</p>'
                  }
                })
              : overlay.currentMessage}
            </motion.p>
          </motion.p>


        
          {/* REAL */}
          <motion.p
            animate={(!isEmpty(message))? "open" : "closed" }
            variants={variants}
            /* initial={{ x: '100vw'}}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: '-100%', y: 0 }}
            exit={{ x: '100vw'}} */
            style={{ paddingLeft: '105vw' }}
            transition={{ ease: "linear", duration: (10+(overlay.currentMessage.split('·').length*3)), delay: 1 }}
            className={`fixed top-0 whitespace-nowrap font-bold leading-tight opacity-100 ${projectStyles?.messageClasesBodyText? projectStyles.messageClasesBodyText : ''} ${project?.messageColorBodyText? project.messageColorBodyText : 'text-gray-50'}`} 
          >
            <motion.p
              initial={{ opacity: 0}}
              animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={{ opacity: 0}}
              transition={{ type: "easeInOut", duration: 0.2, delay: 0 }}
            >
              {overlay.currentMessage.includes("·")? 
                overlay.currentMessage.split('·').map((element, key) => {
                  if (key === 0) {
                    text = '<div class="whitespace-nowrap flex justify-center items-center"><p class="pr-10">'+element+'</p><p class="pr-10">·</p>'
                  } else if ((key+1) === overlay.currentMessage.split('·').length) {
                    text = text+'<p>'+element+'</p></div>'

                    return <div dangerouslySetInnerHTML={{__html: text}}></div>
                  } else {
                    text = text+'<p class="pr-10">'+element+'</p><p class="pr-10">·</p>'
                  }
                })
              : overlay.currentMessage}
            </motion.p>
          </motion.p>


        </motion.div>
      </div>
    </div>
  }

  function IMMBelementTitle() {
    {/* EXTRA COMPONENTS FOR IMMB-messages */}

    return <div className="relative" style={{ zIndex: 101 }}>
      <motion.div
        initial={{ x: '51%' }}
        animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
        exit={{ x: '51%' }}
        transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationName)? projectStyles.messageAnimationDurationName : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayName)? projectStyles.messageAnimationDelayName : 0.0 }}
        className={`inline-block overflow-hidden`}
      >
        <motion.div
          initial={projectStyles && startAnimations[projectStyles?.messageStartAnimation][1]? startAnimations[projectStyles.messageStartAnimation][1] : startAnimations['preset-1'][1]}
          animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
          exit={projectStyles && endAnimations[projectStyles?.messageEndAnimation][1]? endAnimations[projectStyles.messageEndAnimation][1] : endAnimations['preset-1'][1]}
          transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationName)? projectStyles.messageAnimationDurationName : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayName)? projectStyles.messageAnimationDelayName : 0.0 }}
          className={` overflow-hidden${projectStyles?.messageClasesNameBox? projectStyles.messageClasesNameBox : ''}`}
          style={{ 
            backgroundColor: projectStyles?.messageColorName? projectStyles.messageColorName : '#18181B', 
            color: projectStyles?.messageColorNameText? projectStyles.messageColorNameText : '#F4F4F5', 
          }}
        >

          <motion.span
            initial={{ height: '0%' }}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
            exit={{ height: '0%' }}
            transition={{ type: "easeInOut", duration: 0.5, delay: 1 }}
            className={`invisible`} 
          >
            <div className={`${projectStyles?.messageClasesNameText? projectStyles.messageClasesNameText : 'text-4xl pl-3 pr-5 py-2 '} font-bold whitespace-nowrap`}>
              <motion.span
                initial={{ opacity: 0}}
                animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0}}
                transition={{ type: "easeInOut", duration: 0.2, delay: 0 }}
              >
                {overlay.displayName} 
              </motion.span>
            </div>
          </motion.span>

          <motion.span
            initial={{ y: '100%' }}
            animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
            exit={{ y: '100%' }}
            transition={{ type: "easeInOut", duration: 0.5, delay: 0.9 }}
            className={`fixed top-0 overflow-hidden`} 
            style={{ 
              /* color: projectStyles?.messageColorNameText? projectStyles.messageColorNameText : '#F4F4F5',  */
            }}
          >
            <div className={`${projectStyles?.messageClasesNameText? projectStyles.messageClasesNameText : 'text-4xl pl-3 pr-5 py-2 '} font-bold whitespace-nowrap`}>
              <motion.span
                initial={{ opacity: 0}}
                animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                exit={{ opacity: 0}}
                transition={{ type: "easeInOut", duration: 0.2, delay: 0 }}
              >
                {overlay.displayName} 
              </motion.span>
            </div>
          </motion.span>

        </motion.div> 
      </motion.div> 


    </div>
  }



  return (
    show || show === null? <div className={`z-50 fixed top-0 left-0 w-screen h-screen 
        ${checkFunc()? 'flex justify-center items-center bg-gray-300 bg-opacity-50 cursor-default overflow-hidden' : 'pointer-events-none'}
        ${(show === false && show != null)? 'hidden ' : ''}
      `}
    >
      
      <div className={`fixed z-50 w-screen h-screen ${
        checkFunc()? 'bg-gray-300 border border-gray-600 rounded-sm overflow-hidden' : 'pointer-events-none top-0 left-0' } `}
        style={{ 
          transform: checkFunc()? 'scale(0.45) translate(-111%, -111%)' : null,
          minWidth: checkFunc()? '1920px' : null,
          minHeight: checkFunc()? '1080px' : null,
          maxWidth: checkFunc()? '1920px' : null,
          maxHeight: checkFunc()? '1080px' : null,
          top: checkFunc()? '50%' : null,
          left: checkFunc()? '50%' : null,    
        }}
      >
        
        {checkFunc() && <div onClick={() => message.setShowMessagePreview(!message.show)} className="text-right top-0 right-0 fixed font-bold text-gray-500 z-50 hover:text-gray-900 transform transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-16 w-16" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </div>}


        <AnimatePresence>
          {(show || show === null) && overlay && (
            <div
              key={overlay.id}
              className={`absolute bottom-16 left-10 w-full max-w-6xl pl-28 pointer-events-none`}
            >
              {(overlay.profileImageUrl || overlay.authorInitials) && (overlay.showInitials || overlay.showInitials === undefined)? 
                <motion.div
                  initial={projectStyles && startAnimations[projectStyles?.messageStartAnimation]? startAnimations[projectStyles.messageStartAnimation][0] : startAnimations['preset-1'][0]}
                  animate={{ height: "128px",  width: "128px", scale: 1, opacity: 1, x: 0, y: 0 }}
                  exit={projectStyles && endAnimations[projectStyles?.messageEndAnimation]? endAnimations[projectStyles.messageEndAnimation][0] : endAnimations['preset-1'][0]}
                  transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationProfile)? projectStyles.messageAnimationDurationProfile : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayProfile)? projectStyles.messageAnimationDelayProfile : 0.0 }}
                  className={`absolute left-0 w-32 h-32 overflow-hidden grid place-items-center ${projectStyles?.messageClasesProfileBox? projectStyles.messageClasesProfileBox : '-mt-5'}`}
                  style={{
                    zIndex: -1,
                    backgroundColor: overlay.profileImageUrl? "transparent" : overlay.authorInitialsColor? overlay.authorInitialsColor : projectStyles?.messageColorProfile? projectStyles.messageColorProfile : null,
                    backgroundImage: `url(${overlay.profileImageUrl})`,
                    backgroundSize: "cover",
                    color: projectStyles?.messageColorProfileText? projectStyles.messageColorProfileText : '#F4F4F5', 
                    boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
                  }}
                >
                  {!overlay.profileImageUrl && (
                    <p className={`font-medium ${projectStyles?.messageClasesProfileText? projectStyles.messageClasesProfileText : 'text-5xl'}`}>
                      {overlay.authorInitials}
                    </p>
                  )}
                </motion.div> 
              : null }

              <div className={`${projectStyles?.messageStartAnimation && projectStyles.messageStartAnimation === 'preset-1'? 'overflow-hidden' : ''}`}>

                {(projectStyles?.messagePreset != 'IMMB-messages-verd' && projectStyles?.messagePreset != 'IMMB-messages-vermell') && 
                (overlay.businessName || overlay.displayName && (overlay.displayName.split(' ').join('') || overlay.businessName.split(' ').join('')))? 
                  <motion.div
                    initial={projectStyles && startAnimations[projectStyles?.messageStartAnimation][1]? startAnimations[projectStyles.messageStartAnimation][1] : startAnimations['preset-1'][1]}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={projectStyles && endAnimations[projectStyles?.messageEndAnimation][1]? endAnimations[projectStyles.messageEndAnimation][1] : endAnimations['preset-1'][1]}
                    transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationName)? projectStyles.messageAnimationDurationName : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayName)? projectStyles.messageAnimationDelayName : 0.0 }}
                    className={` z-20 flex items-center flex-0 space-x-1 overflow-hidden ${projectStyles?.messageClasesNameBox? projectStyles.messageClasesNameBox : ''}`}
                  >
                    <span 
                      className={`font-bold whitespace-nowrap ${projectStyles?.messageClasesNameText? projectStyles.messageClasesNameText : 'text-4xl pl-3 pr-5 py-2 '}`} 
                      style={{ 
                        backgroundColor: projectStyles?.messageColorName? projectStyles.messageColorName : '#18181B', 
                        color: projectStyles?.messageColorNameText? projectStyles.messageColorNameText : '#F4F4F5', 
                        boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
                      }}
                    >
                      {overlay.displayName}
                      {overlay.businessName && overlay.businessName.split(' ').join('')? <span className="w-full text-left flex items-end text-2xl flex-0 font-light whitespace-nowrap pl-3 pr-5 py-2" style={{ padding: '0px', paddingLeft: '0px' }}>{overlay.businessName}</span> : ''}
                    </span>
                  </motion.div> 
                : null }
                
                {/* EXTRA COMPONENTS FOR IMMB-messages */}
                {(projectStyles?.messagePreset === 'IMMB-messages-verd' || projectStyles?.messagePreset === 'IMMB-messages-vermell') && <>{IMMBelementTitle()}</>}



                {((projectStyles?.messagePreset != 'IMMB-messages-verd' && projectStyles?.messagePreset != 'IMMB-messages-vermell') && overlay.currentMessage && overlay.currentMessage.split(' ').join(''))? 
                  <motion.div
                    initial={projectStyles && startAnimations[projectStyles?.messageStartAnimation][2]? startAnimations[projectStyles.messageStartAnimation][2] : startAnimations['preset-1'][2]}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={projectStyles && endAnimations[projectStyles?.messageEndAnimation][2]? endAnimations[projectStyles.messageEndAnimation][2] : endAnimations['preset-1'][2]}
                    transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.messageAnimationDurationBody)? projectStyles.messageAnimationDurationBody : 0.5, delay: !isNaN(projectStyles?.messageAnimationDelayBody)? projectStyles.messageAnimationDelayBody : 0.0 }}
                    className={`overflow-hidden ${projectStyles?.messageClasesBodyBox? projectStyles.messageClasesBodyBox : ''}`}
                    style={{ 
                      backgroundColor: projectStyles?.messageColorBody? projectStyles.messageColorBody : '#F4F4F5', 
                      color: projectStyles?.messageColorBodyText? projectStyles.messageColorBodyText : '#7C7C83', 
                      boxShadow: '0px 4px 16px rgba(17,17,26,0.1), 0px 8px 24px rgba(17,17,26,0.1), 0px 16px 56px rgba(17,17,26,0.1)'
                    }}
                  >
                    <p className={`w-full whitespace-pre-wrap font-medium px-4 pt-2 pb-2.5 leading-tight ${projectStyles?.messageClasesBodyText? projectStyles.messageClasesBodyText : ''} ${ overlay.fontSize? (overlay.fontSize === '50'? 'text-xl' : overlay.fontSize === '100'? 'text-2xl' : 'text-4xl') : 'text-4xl' }`}>
                      {overlay.currentMessage}
                    </p>
                  </motion.div> 
                : null }

                {/* EXTRA COMPONENTS FOR IMMB-messages */}
                {(projectStyles?.messagePreset === 'IMMB-messages-verd' || projectStyles?.messagePreset === 'IMMB-messages-vermell') && <>{IMMBelement()}</>}


              </div>


            </div>
          )}
        </AnimatePresence>
      </div>
    </div> : null 
  );
}

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const messageToShowTMP = ownProps.messageToShowTMP ?? null;
  const messages = state.firestore.ordered.overlays;

  if (!projectId && !messageToShowTMP ) return {};

  if (projectId) {
    const projects = state.firestore.data.projects;
    const users = state.firestore.data.users;
    const message = messages ? messages[0] : null;
    const project = projects ? projects[projectId] : null;
    const user = users && project ? users[project.authorId] : null;
  
    return {
      message: {
        ...message,
      },
      project,
      show: null,
    };
  } else if (messageToShowTMP.show) {
    const user = null;
    const message = messageToShowTMP;

    return {
      message: {
        ...message,
      },
      show: messageToShowTMP.show,
    };
  } else {
    return {}
  }

};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "chatMessage"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  })
)(MessageOverlay);

MessageOverlay.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

MessageOverlay.defaultProps = {
  defaultMessage: null,
  dummy: false,
};
