import { Helmet } from 'react-helmet'
import AudioMessageCreate from './AudioMessageCreate'
import OverlayCreate from './OverlayCreate'

function Messenger(props) {
  const { match } = props
  return (
    <>
    <Helmet title="Àudio Moderador" />
    <div className="grid gap-5 p-5">
      <AudioMessageCreate projectId={match.params.id} />
      <OverlayCreate {...props} title="Llista de missatges" role="audioEditor" />
    </div>
    </>
  )
}

export default Messenger