import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";
import ReactAudioPlayer from "react-audio-player";
import ReactTooltip from "react-tooltip";

import CayronOverlay from "./Overlays/cayron";
import { Draggable } from "react-beautiful-dnd";


function Cayron(props) {
  const {
    deleteItem,
    message,
    project,
    role,
    toggleItemIsReady,
    setEditedMessage,
    setIsEmitted,
    index,
    selectedItem,
    setSelectedItem,
    overlay,
    setOverlay,
    setPositions,
    addOverlay,
    removeOverlay,
    setItemPosition,
    projectStyles,
    setDragCompleted,
    dragCompleted,
    setIsEditing,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [editor, setEditor] = useState(null);
  const ref = useRef(null);

  const [showMessagePreview, setShowMessagePreview] = useState(false)



  useEffect(() => {
    setPositions(index, {
      height: ref.current.offsetHeight,
      top: ref.current.offsetTop,
    });
  });

  const submitOverlay = (messageId) => {
    if (project.overlay) {
      removeOverlay();
      setSelectedItem(null);
      setOverlay(null);
      setIsEmitted(true, messageId);
    } else {
      addOverlay(overlay);
    }
  };

  const handleSelected = () => {
    const isSelected = index !== selectedItem;
    setSelectedItem(isSelected ? index : null);
    setOverlay(isSelected ? message : null);
    // aquesta funció te un problema de responsabilitat compartida ja que els condicionals serveixen perque a l'hora selecciona i deselecciona
  };

  const edit = (messageId) => {
    setEditor(editor === index ? null : index);
    setIsEditing(true, messageId);
  };

  const cancelEdit = (messageId) => {
    setEditor(null);
    setIsEditing(false, messageId);
  };

  const saveEdit = (event, index, id) => {
    
    var currentMessageTMP = document.getElementById(`currentMessage${index}`)?.value;
    var displayNameTMP = document.getElementById(`displayName${index}`)?.value;
    var authorRoleTMP = document.getElementById(`authorRole${index}`)?.value;
    var audioNotesTMP = document.getElementById(`audioNotes${index}`)?.value;

    setEditedMessage(
      {
        currentMessage: currentMessageTMP ?? null,
        displayName: displayNameTMP ?? null,
        authorRole: authorRoleTMP ?? null,
        audioNotes: audioNotesTMP ?? null, // Modified
      },
      id
    );
    setEditor(null);
    setIsEditing(false, id);
  };

  const _deleteItem = (id) => {
    const confirm = window.confirm("Si us plau, confirmeu aquesta acció");
    if (confirm) {
      deleteItem(id);
    }
    // TODO: borrar imatge
  };

  const onTop = { zIndex: 100 };
  const flat = {
    zIndex: 5,
    transition: { delay: 0.3 },
  };

  const {
    id,
    position,

    isChatOwner,
    displayName,
    originalMessage,
    authorInitials,
    isReady,
    currentMessage,
    lastUpdate,
    audioMessageUrl,
    authorRole,
    audioNotes,
    isEmitted,
    savedAt,
    emittedAt,
    isEditing,
  } = props.message;


  /* useEffect(() => {
    if (dragCompleted) setItemPosition(index, id);
    // eslint-disable-next-line
  }, [dragCompleted, setItemPosition]); */

  const time = lastUpdate?.toDate() ?? savedAt.toDate();
  const emittedTime = emittedAt?.toDate() ?? null;

  const displayTimeHour = (time) =>
    new Intl.DateTimeFormat("es", {
      hour: "numeric",
      minute: "numeric",
    }).format(time);

  const displayFullTime = (time) =>
    new Intl.DateTimeFormat("es", {
      dateStyle: "full",
      timeStyle: "long",
    }).format(time);



  if (/cayron/.test(message.type)) {
    return <Draggable isDragDisabled={role === "subscriber"} draggableId={'message-' + message.id} index={position}>
        
        
      {(provided, snapshot) => (

        <div 
          className={"message-" + message.id}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <motion.form
            ref={ref}
            layout={role !== "presenter" ? true : false}
            drag={role !== "presenter" ? "y" : false}
            whileHover={{ cursor: "grab" }}
            whileTap={
              role === "subscriber"
                ? { scale: 1.01, cursor: "grabbing" }
                : { cursor: "grabbing" }
            }
            transition={{ type: "easeInOut" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onSubmit={(event) => event.preventDefault()}
            exit={
              role === "subscriber" ? { scale: 0.9, opacity: 0 } : { opacity: 0 }
            }
            style={{ maxWidth: '999999px', margin: role === "subscriber"? 10 : 0, borderTop: (snapshot.isDragging)? '2px rgb(200,200,200) solid' : '', borderLeft: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderRight: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderBottom: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '' }}
            className={`selected-message relative flex items-start justify-between z-10 p-[1em] pl-2 border-b-2 ${
              role !== "presenter" && `dark:border-gray-600`
            } ${selectedItem === index && `active`} ${
              isReady && !isEmitted && role !== "presenter"
                ? `bg-green-50 dark:bg-green-900`
                : isEmitted
                ? `bg-red-50 dark:bg-red-900`
                : role === "presenter"
                ? "bg-[#fff] dark:bg-[#000] border-[#000] dark:border-[#fff]"
                : "bg-gray-50 dark:bg-gray-700"
            }`}
          >

            <div className="flex items-center space-x-2">
              {role !== "presenter" && (
                <button
                  className="text-gray-400 hover:text-gray-500"
                  style={{ cursor: "grab" }}
                  type="button"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm-8-8a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM8 2a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z"
                    />
                  </svg>
                </button>
              )}
            </div>
            <div className="flex-1 flex flex-col">
              <div className="space-x-1 cursor-default">
                {editor === index ? (
                  <input
                    id={`displayName${index}`}
                    className="ml-2 mb-1 w-1/2 text-xs font-semibold border-2 border-green-500"
                    type="text"
                    defaultValue={displayName}
                  />
                ) : (
                  <span
                    className={`font-semibold whitespace-nowrap pl-2 py-1 ${
                      isChatOwner && `bg-yellow-400 text-gray-900 px-2`
                    }`}
                  >
                    {displayName}
                  </span>
                )}
                {role !== "presenter" && (
                  <p className="inline text-xs">
                    {lastUpdate ? `Actualitzat` : `Creat`} a las{" "}
                    <span data-tip={displayFullTime(time)}>
                      {displayTimeHour(time)}
                    </span>
                  </p>
                )}
              </div>
              {editor === index ? (
                <TextareaAutosize
                  id={`currentMessage${index}`}
                  type="textarea"
                  spellCheck="false"
                  className={`flex-1 ml-2 px-0 py-1 h-20 resize-none border-2 border-green-500 ${
                    role === "subscriber" && ``
                  }`}
                  defaultValue={currentMessage}
                />
              ) : role !== "presenter" ? (
                <TextareaAutosize
                  id={`currentMessage${index}`}
                  className={`neutre px-2 resize-none mt-1 ${
                    role === "subscriber" && `mr-5`
                  } ${role === "presenter" && `dark:text-[#fff] text-[#000]`}`}
                  disabled
                  value={currentMessage}
                />
              ) : (
                <p className="px-2">{currentMessage}</p>
              )}
              {role !== "presenter" &&
                (editor !== index ? (
                  <div className="flex items-center px-2 py-1 mt-2">
                    {/* {isEditing ? (
                      <span className="text-sm text-gray-400 py-1.5">
                        Editando...
                      </span>
                    ) : ( */}
                      <>


                        <button type="button" onClick={() => _deleteItem(id)} className="btn--tool--gray" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>Esborrar</span>
                        </button>

                        
                        <button type="button" onClick={() => edit(id)} className="ml-2 btn--tool--gray" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fillRule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>Editar</span>
                        </button>


                        <button type="button" onClick={() => setShowMessagePreview(!showMessagePreview)} className={`ml-2 ${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`}>
                          {!showMessagePreview?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                          : 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                          }
                          <span>Vista prèvia</span>
                        </button>


                        {/* <button type="button" onClick={() => toggleItemIsReady(id)} className={`ml-2 ${isReady? 'btn--tool--blue' : 'btn--tool--gray'}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>{isReady ? "Confirmat" : "Confirmar"}</span>
                        </button>
  */}

                      </>
                    {/* )} */}
                  </div>
                ) : (
                  <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 text-xs">

                    <button type="button" onClick={() => cancelEdit(id)} className="btn--tool--gray"> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                      </svg>
                      <span>Cancel·lar</span>
                    </button>

                    <button type="submit" className="btn--tool--blue" onClick={(event) => saveEdit(event, index, id)}> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                      </svg>
                      <span>Desa</span>
                    </button>

                  </div>
                ))}
            </div>

            <div className="flex flex-wrap justify-end items-start">

              {isEmitted && role !== "presenter" && (
                <div className="flex space-x-1 text-sm items-center text-gray-500 dark:text-gray-300 cursor-default whitespace-nowrap w-full justify-end" style={{ margin: '2px', marginRight: '10px' }}>
                  <span className="text-xs uppercase font-semibold px-1">
                    Emès a les{" "}
                    <span data-tip={displayFullTime(emittedTime)} data-place="bottom"> {displayTimeHour(emittedTime)} </span>
                  </span>
                  <button onClick={() => setIsEmitted(false, id)}>X</button>
                </div>
              )}
              
              {selectedItem != index && <div className="bg-gray-200 mx-2 rounded px-2 opacity-50" style={{ paddingBlock: '3px' }}>
                <h1  className={`font-bold whitespace-nowrap ${role !== "presenter"? 'text-gray-600 text-sm' : ''}`}>Cayron</h1>
              </div>}

              {role === "subscriber" && (
                <div className="transform -translate-y-1/2 flex space-x-2 text-sm items-center" style={{ marginTop: '20px' }}>

                  {/* {Boolean(!project.overlay) && ( */}
                    <button
                      onClick={handleSelected}
                      className={`disabled:opacity-20 disabled:cursor-not-allowed z-10 text-gray-500 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-100 uppercase font-semibold flex px-3 py-2 border-2 rounded-sm text-xs ${
                        selectedItem === index && "border-none"
                      }`}
                      type="button"
                      disabled={!Boolean(!project.overlay)}
                    >
                      {selectedItem === index ? "Cancel·lar" : "Seleccionar"}
                    </button>
                  {/* )} */}

                  {(overlay?.originalMessage ?? null) === originalMessage && (
                    <button
                      onClick={() => submitOverlay(id)}
                      type="button"
                      className=" flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-md"
                    >
                      {!project.overlay ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                          <span>EMETRE</span>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                            />
                          </svg>
                          <span>DEIXAR D'EMETRE</span>
                        </>
                      )}
                    </button>
                  )}
                </div>
              )}

              {role === "admin" && (
                <div className="w-6 h-6 rounded-sm border-2 dark:border-gray-600  hover:text-green-400">
                  <motion.button
                    onClick={handleSelected}
                    whileHover={{ scale: 1.5 }}
                    whileTap={{ scale: 0.9 }}
                    animate={{ scale: selectedItem === index ? 1.4 : 1 }}
                    transition={{ type: "linear" }}
                    className="selected-message__tool selected-message__tool--checkbox"
                  >
                    {selectedItem !== index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </motion.button>
                </div>
              )}
            </div>


          </motion.form>
          <ReactTooltip place="top" effect="solid" delayShow={500} />

          <CayronOverlay 
            messageToShowTMP={{ 
              id: '',
              snippet: {
                displayName: displayName,
                authorInitials: authorInitials,
                currentMessage: currentMessage,
                originalMessage: originalMessage,
                isReady: false
              },
              type: 'cayron',
              show: showMessagePreview,
              projectStyles: projectStyles,
              setShowMessagePreview: setShowMessagePreview,
            }} 
          />
        </div>
      )}
    </Draggable>
  }


}

export default Cayron;
