import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";
import AudioTimeline from "./../AudioTimeline";



var startAnimations = {
  'preset-1': [{ opacity: 0 }, { scale: 0, opacity: 0 }, { width: 0 }, { width: 0 }, { width: 0 }],
  'slide-up': [{ y: 500 }, { y: 500 }, { y: 500 }, { y: 500 }, { y: 500 }],
  'slide-right': [{x: -1500 }, { x: -1500 }, { x: -1500 }, { x: -1500 }, { x: -1500 }],
  'slide-down': [{ y: -1500 }, { y: -1500 }, { y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-left': [{x: 2000 }, { x: 2000 }, { x: 2000 }, { x: 2000 }, { x: 2000 }],
}

var endAnimations = {
  'preset-1': [{ opacity: 0 }, { scale: 0, opacity: 0 }, { width: 0 }, { width: 0 }, { width: 0 }],
  'slide-up': [{ y: -1500 }, { y: -1500 }, { y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-right': [{x: 2000 }, { x: 2000 }, { x: 2000 }, { x: 2000 }, { x: 2000 }],
  'slide-down': [{ y: 500 }, { y: 500 }, { y: 500 }, { y: 500 }, { y: 500 }],
  'slide-left': [{x: -2000 }, { x: -2000 }, { x: -2000 }, { x: -2000 }, { x: -2000 }],
}



function AudioMessageOverlay(props) {
  const { message, defaultMessage, dummy, project, show } = props;
  const [overlay, setOverlay] = useState(null);
  const [projectStyles, setProjectStyles] = useState(null);


  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect(() => {
    if (dummy) {
      setOverlay(defaultMessage);
    } else {
      setOverlay(message?.snippet);
    }
  }, [message, dummy]);


  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);


  function checkFunc() {
    return show != null && overlay && ( 
      overlay.profileImageUrl || 
      (overlay.displayName && overlay.displayName.split(' ').join('')) || 
      (overlay.audioMessageUrl && overlay.audioMessageUrl.split(' ').join(''))
    )
  }


  function unMuteMe(elem) {
    elem.muted = false;
  }


  function muteMe(elem) {
    elem.muted = true;
  }


  useEffect(() => {
    if ((show || show === null) && overlay) {
      var elems = document.querySelectorAll("video, audio");
      [].forEach.call(elems, function(elem) { unMuteMe(elem); });
    } else {
      var elems = document.querySelectorAll("video, audio");
      [].forEach.call(elems, function(elem) { muteMe(elem); });
    }
  }, [overlay, show]);


  return (
    show || show === null? <div className={`fixed top-0 left-0 z-50 w-screen h-screen 
      ${checkFunc()? 'bg-gray-300 bg-opacity-50 cursor-default' : 'pointer-events-none'}
      ${(show === false && show != null)? 'hidden ' : ''}
    `}>
      
      <div className={`fixed z-50 w-screen h-screen ${
        checkFunc()? 'bg-gray-300 border border-gray-600 rounded-sm' : 'pointer-events-none top-0 left-0' } `}
        style={{ 
          transform: checkFunc()? 'scale(0.45) translate(-111%, -111%)' : null,
          minWidth: checkFunc()? '1920px' : null,
          minHeight: checkFunc()? '1080px' : null,
          maxWidth: checkFunc()? '1920px' : null,
          maxHeight: checkFunc()? '1080px' : null,
          top: checkFunc()? '50%' : null,
          left: checkFunc()? '50%' : null,    
        }}
      >
        
        {checkFunc() && <div onClick={() => message.setShowMessagePreview(!message.show)} className="text-right top-0 right-0 fixed font-bold text-gray-500 z-50 hover:text-gray-900 transform transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-16 w-16" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </div>}


        <AnimatePresence>
          {(show || show === null) && overlay && (
            <motion.div
              key={overlay.id}
              initial={projectStyles && startAnimations[projectStyles?.audioMessageStartAnimation][0]? startAnimations[projectStyles.audioMessageStartAnimation][0] : startAnimations['preset-1'][0]}
              animate={{ height: "auto", width: "100%", scale: 1, opacity: 1, x: 0, y: 0 }}
              exit={projectStyles && endAnimations[projectStyles?.audioMessageEndAnimation][0]? endAnimations[projectStyles.audioMessageEndAnimation][0] : endAnimations['preset-1'][0]}
              transition={{ type: "easeInOut", duration: projectStyles?.audioMessageAnimationDurationBody? projectStyles.audioMessageAnimationDurationBody : 0.5, delay: projectStyles?.audioMessageAnimationDelayBody? projectStyles.audioMessageAnimationDelayBody : 0.0 }}
              className={`absolute bottom-16 left-10 w-full max-w-4xl text-2xl p-3 flex items-center ${projectStyles?.audioMessageRoundedStyleBody? projectStyles.audioMessageRoundedStyleBody : ''}`}
              style={{
                backgroundColor: projectStyles?.audioMessageColorBody? projectStyles.audioMessageColorBody : '#F4F4F5',
              }}
            >

              <motion.div
                initial={projectStyles && startAnimations[projectStyles?.audioMessageStartAnimation][1]? startAnimations[projectStyles.audioMessageStartAnimation][1] : startAnimations['preset-1'][1]}
                animate={{ height: "96px", width: "96px", scale: 1, opacity: 1, x: 0, y: 0 }}
                exit={projectStyles && endAnimations[projectStyles?.audioMessageEndAnimation][1]? endAnimations[projectStyles.audioMessageEndAnimation][1] : endAnimations['preset-1'][1]}
                transition={{  duration: projectStyles?.audioMessageAnimationDurationProfile? projectStyles.audioMessageAnimationDurationProfile : 0.5, delay: projectStyles?.audioMessageAnimationDelayProfile? projectStyles.audioMessageAnimationDelayProfile : 0.0 }}
                className={`w-24 h-24 overflow-hidden text-gray-50 grid place-items-center ${projectStyles?.audioMessageRoundedStyleProfile? projectStyles.audioMessageRoundedStyleProfile : ''}`}
                style={{ 
                  backgroundImage: `url("${overlay.profileImageUrl}")`, backgroundSize: "cover",
                  backgroundColor: '#DFE5E7'
                }}
              >
                {!overlay.profileImageUrl && (
                  <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" >
                    <path fill="#DFE5E7" d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z" ></path>
                    <path fill="#FFF" d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z" ></path>
                  </svg>
                )}
              </motion.div>

              <div className="relative flex-1 z-10">
                <motion.div
                  initial={projectStyles && startAnimations[projectStyles?.audioMessageStartAnimation][2]? startAnimations[projectStyles.audioMessageStartAnimation][2] : startAnimations['preset-1'][2]}
                  animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                  exit={projectStyles && endAnimations[projectStyles?.audioMessageEndAnimation][2]? endAnimations[projectStyles.audioMessageEndAnimation][2] : endAnimations['preset-1'][2]}
                  transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.audioMessageAnimationDurationName)? projectStyles.audioMessageAnimationDurationName : 0.5, delay: !isNaN(projectStyles?.audioMessageAnimationDelayName)? projectStyles.audioMessageAnimationDelayName : 0.0 }}
                  className={`flex items-center space-x-1 overflow-hidden`}
                  style={{
                    color: projectStyles?.audioMessageColorNameText? projectStyles.audioMessageColorNameText : '#19191B',
                  }}
                >
                  {overlay.displayName !== "" && (
                    <p className={`text-4xl font-bold whitespace-nowrap px-3 mb-1`} >
                      {overlay.displayName}
                    </p>
                  )}
                </motion.div>
                
                <motion.div
                  initial={projectStyles && startAnimations[projectStyles?.audioMessageStartAnimation][3]? startAnimations[projectStyles.audioMessageStartAnimation][3] : startAnimations['preset-1'][3]}
                  animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                  exit={projectStyles && endAnimations[projectStyles?.audioMessageEndAnimation][3]? endAnimations[projectStyles.audioMessageEndAnimation][3] : endAnimations['preset-1'][3]}
                  transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.audioMessageAnimationDurationInfo)? projectStyles.audioMessageAnimationDurationInfo : 0.5, delay: !isNaN(projectStyles?.audioMessageAnimationDelayInfo)? projectStyles.audioMessageAnimationDelayInfo : 0.0 }}
                  className={`flex items-center space-x-1 overflow-hidden`}
                  style={{
                    color: projectStyles?.audioMessageColorInfoText? projectStyles.audioMessageColorInfoText : '#19191B',
                  }}
                >
                  {overlay.displayName !== "" && (
                    <p className={`text-2xl font-bold whitespace-nowrap px-3 mb-1`} >
                    {overlay.authorRole}
                    </p>
                  )}
                </motion.div>

                <motion.div
                  initial={projectStyles && startAnimations[projectStyles?.audioMessageStartAnimation][4]? startAnimations[projectStyles.audioMessageStartAnimation][4] : startAnimations['preset-1'][4]}
                  animate={{ height: "24px", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                  exit={projectStyles && endAnimations[projectStyles?.audioMessageEndAnimation][4]? endAnimations[projectStyles.audioMessageEndAnimation][4] : endAnimations['preset-1'][4]}
                  transition={{ type: "easeInOut", duration: !isNaN(projectStyles?.audioMessageAnimationDurationControls)? projectStyles.audioMessageAnimationDurationControls : 0.5, delay: !isNaN(projectStyles?.audioMessageAnimationDelayControls)? projectStyles.audioMessageAnimationDelayControls : 0.0 }}
                  className="flex overflow-hidden"
                >
                  <AudioTimeline 
                    src={overlay.audioMessageUrl} 
                    barColor={projectStyles?.audioMessageColorBar? projectStyles.audioMessageColorBar : '#52525B'} 
                    dotColor={projectStyles?.audioMessageColorDot? projectStyles.audioMessageColorDot : '#18181B'} 
                    timerColor={projectStyles?.audioMessageColorTimer? projectStyles.audioMessageColorTimer : '#A1A1AA'} 
                  />
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div> : null 
  );
}

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const messageToShowTMP = ownProps.messageToShowTMP ?? null;
  const messages = state.firestore.ordered.overlays;

  if (!projectId && !messageToShowTMP ) return {};

  if (projectId) {
    const projects = state.firestore.data.projects;
    const users = state.firestore.data.users;
    const message = messages ? messages[0] : null;
    const project = projects ? projects[projectId] : null;
    const user = users && project ? users[project.authorId] : null;

    return {
      message: {
        ...message,
      },
      project,
      show: null,
    };
  } else if (messageToShowTMP.show) {
    const user = null;
    const message = messageToShowTMP;

    return {
      message: {
        ...message,
      },
      show: messageToShowTMP.show,
    };
  } else {
    return {}
  }


};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "audioMessage"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  })
)(AudioMessageOverlay);

AudioMessageOverlay.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

AudioMessageOverlay.defaultProps = {
  defaultMessage: null,
  dummy: false,
};
