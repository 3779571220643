import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { saveItem } from '../actions/projectActions'
import { getInitials } from '../utils'
import Spinner from './Spinner'
import PollOverlay from "./Overlays/poll";
import TextareaAutosize from "react-textarea-autosize";


function PollCreate(props) {

  const { saveItem, firebase, simple, projectStyles } = props

  const [visible, setVisible] = useState(simple? simple : false)
  const [showMessagePreview, setShowMessagePreview] = useState(false)
  const [disablePrevButton, setDisablePrevButton] = useState(true)

  
  const [pollQuestion, setPollQuestion] = useState('')
  const [pollOptions, setPollOptions] = useState([{ text: '', votes: [] }])
  const [showQrCode, setShowQrCode] = useState(true)
  
  const [newPollOptionsAction, setNewPollOptionsAction] = useState(null)




  useEffect(() => {
    if ( (pollOptions) ||
      (pollQuestion && pollQuestion.split(' ').join('') != '')
    ) {
      setDisablePrevButton(false)
    } else {
      setDisablePrevButton(true)
    }
  }, [pollOptions, pollQuestion])


  useEffect(() => {
    if (newPollOptionsAction != null) {
      var tmpPollOptions = pollOptions;

      if (newPollOptionsAction) {
        tmpPollOptions.pop();
        setPollOptions(tmpPollOptions);
      } else {
        tmpPollOptions.push({ text: '', votes: [] });
        setPollOptions(tmpPollOptions);
      }

      setNewPollOptionsAction(null);
    }
  }, [newPollOptionsAction])


  const handleOnSubmit = (event) => {
    event.preventDefault()
    saveItem(
      'poll',
      { 
        pollQuestion: pollQuestion,
        pollOptions: pollOptions,
        showQrCode: showQrCode,
        isOpen: false,
      }
    );
    setVisible(false);

    setPollQuestion('');
    setPollOptions([{ text: '', votes: [] }]);
    setShowQrCode(true);
    setShowMessagePreview(false)
  }


  const keyUp = (event) => {
    if (event.keyCode === 27) {
      setVisible(false)
    }
  }


  const setQuestionFunc = (text, id) => {
    var tmpPollOptions = pollOptions;
    tmpPollOptions[id].text = text;
    setPollOptions(tmpPollOptions);
  }


  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700`}>
      {simple != true && <button onClick={() => setVisible(!visible)} onKeyUp={keyUp} className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Crear nova enquesta</h2>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} className="hover:text-gray-200">
          {!visible ?
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" />
            </svg>
          } 
        </motion.div>
      </button>}
      <AnimatePresence>
      {visible &&
        <motion.form
          key="cayronForm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onSubmit={handleOnSubmit}
          className={`p-4 ${simple != true? 'border-t-2' : ''} dark:border-gray-700`}
        >
          <div className="flex space-x-2 mb-3">
            <div className="flex-1">
              <div className='flex items-center mb-2'>
                <input
                  id="question"
                  onKeyUp={keyUp}
                  autoComplete="off"
                  type="text"
                  className="w-full"
                  onChange={(event) => {setPollQuestion(event.target.value)} }
                  placeholder="Pregunta"
                  required
                />

                <label className="whitespace-nowrap cursor-pointer" style={{ userSelect: 'none' }}>
                  <input type="checkbox" id={`show-qr-code`} style={{ marginRight: '5px', marginLeft: '10px' }} checked={showQrCode} onClick={(event) => { setShowQrCode(!showQrCode); }}/> 
                  QR Code
                </label>
              </div>

              <div className='flex justify-center items-center space-x-2 mb-2 mt-3'>
                <p className='whitespace-nowrap text-sm text-gray-400'>Possibles respostes</p>
                <div className='w-full h-0 border-b-2 dark:border-gray-700'></div>
              </div>

              {pollOptions.map((element, key) => {
                return <div>
                  <input key={key} id={"answer"+key} onKeyUp={keyUp} autoComplete="off" type="text" className="w-full mb-2" onChange={(event) => {setQuestionFunc(event.target.value, key)} } placeholder={'Resposta n'+(key+1)} required/>
                </div>
              })}


              <div className='w-full flex justify-between items-center'>
                <button type="button" className='btn--tool--gray' onClick={() => {setNewPollOptionsAction(true);}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M5 10.75v-1.5h10v1.5Z"/></svg>
                  <span>Eliminar opció</span>
                </button>

                <button type="button" className='btn--tool--gray' onClick={() => {setNewPollOptionsAction(false);}}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor"><path d="M10 15.833q-.354 0-.615-.26-.26-.261-.26-.615v-4.083H5.042q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h4.083V5.042q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v4.083h4.083q.354 0 .615.26.26.261.26.615t-.26.615q-.261.26-.615.26h-4.083v4.083q0 .354-.26.615-.261.26-.615.26Z"/></svg>
                  <span>Afegir opció</span>
                </button>
              </div>
              
            </div>
          </div>


          <div className="space-x-2 flex justify-between text-sm lg:flex-row-reverse lg:-ml-2 pt-2">
            <div className='flex space-x-2'>
              <button type="button" className={`${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`} onClick={() => setShowMessagePreview(!showMessagePreview)} disabled={disablePrevButton}>
                {!showMessagePreview?
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                }
                <span>Vista prèvia</span>
              </button>
              
              {simple != true && <button type="submit" className="btn--tool--blue" disabled={disablePrevButton}> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                </svg>
                <span>Desa</span>
              </button>}
            </div>

            {simple != true && <button type="button" onClick={() => setVisible(false)} className="btn--tool--gray"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
              <span>Cancel·lar</span>
            </button>}
          </div>


          <PollOverlay 
            messageToShowTMP={{ 
              id: '',
              snippet: {
                pollQuestion: pollQuestion,
                pollOptions: pollOptions,
                showQrCode: showQrCode,
                isOpen: false,
              },
              type: 'poll',
              show: showMessagePreview,
              projectStyles: projectStyles,
              setShowMessagePreview: setShowMessagePreview,
            }} 
          /> 


        </motion.form>
      }
      </AnimatePresence>
    </div>
  )
}





const mapStateToProps = (state, ownProps) => {
  return { };
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(PollCreate)