import { useState, useRef, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty } from "react-redux-firebase";
import { setProjectStyles, setProjectStylePresets } from "../actions/projectActions";

import ColorPicker from './ColorPicker'

import MessageCreate from './MessageCreate'
import AudioMessageCreate from './AudioMessageCreate'
import CayronCreate from './CayronCreate'
import SubtitlesCreateTest from './SubtitlesCreateTest'
import CardCreate from './CardCreate'



var anuimationOptions = [ 
  ['preset-1', 'Preset Inicial'],
  ['slide-up', 'Slide up'],
  ['slide-right', 'Slide right'],
  ['slide-down', 'Slide down'],
  ['slide-left', 'Slide left'],
]

var roundedOptions = [ 
  ['', 'Rounded None'],
  ['rounded-sm', 'Rounded Small'],
  ['rounded', 'Rounded Normal'],
  ['rounded-md', 'Rounded Medium'],
  ['rounded-lg', 'Rounded Large'],
  ['rounded-xl', 'Rounded x1 Large'],
  ['rounded-2xl', 'Rounded x2 Large'],
  ['rounded-3xl', 'Rounded x3 Large'],
  ['rounded-full', 'Rounded Full'],
]

var fonts = [ 
  ['', 'Default'],
  ['anton-font', 'Anton'],
  ['source-serif-pro-semibold', 'Source Serif Pro (semibold)'],
  ['barlow thin', 'Barlow (thin)'],
  ['barlow-semibold', 'Barlow (semibold)'],
  ['ag-book-rounded', 'AG Book Rounded'],
]

var fontSizes = [ 
  ['text-xs', 'Super petit'],
  ['text-sm', 'Petit'],
  ['', 'Default'],
  ['text-lg', 'Gran'],
  ['text-xl', 'Extra Gran'],
  ['text-2xl', 'Extra x2 Gran'],
  ['text-3xl', 'Extra x3 Gran'],
  ['text-4xl', 'Extra x4 Gran'],
  ['text-5xl', 'Extra x5 Gran'],
  ['text-6xl', 'Extra x6 Gran'],
  ['text-7xl', 'Extra x7 Gran'],
  ['text-8xl', 'Extra x8 Gran'],
  ['text-9xl', 'Extra x9 Gran'],
]

var paddings = [ 
  ['', 'Default'],
  ['-0', 'Sense paddings'],
  ['-1', 'Padding 1'],
  ['-2', 'Padding 2'],
  ['-3', 'Padding 3'],
  ['-4', 'Padding 4'],
  ['-5', 'Padding 5'],
  ['-6', 'Padding 6'],
  ['-7', 'Padding 7'],
  ['-8', 'Padding 8'],
  ['-9', 'Padding 9'],
  ['-10', 'Padding 10'],
  ['-11', 'Padding 11'],
  ['-12', 'Padding 12'],
  ['-14', 'Padding 14'],
  ['-16', 'Padding 16'],
  ['-20', 'Padding 20'],
]

var margins = [ 
  ['', 'Default'],
  ['-0', 'Sense margin'],
  ['-1', 'Margin 1'],
  ['-2', 'Margin 2'],
  ['-3', 'Margin 3'],
  ['-4', 'Margin 4'],
  ['-5', 'Margin 5'],
  ['-6', 'Margin 6'],
  ['-7', 'Margin 7'],
  ['-8', 'Margin 8'],
  ['-9', 'Margin 9'],
  ['-10', 'Margin 10'],
  ['-11', 'Margin 11'],
  ['-12', 'Margin 12'],
  ['-14', 'Margin 14'],
  ['-16', 'Margin 16'],
  ['-20', 'Margin 20'],
  ['-24', 'Margin 24'],
  ['-28', 'Margin 28'],
  ['-32', 'Margin 32'],
]

var backgroundOpacityes = [ 
  ['', 'Default'],
  ['bg-opacity-0', 'Invisible'],
  ['bg-opacity-5', 'Opacitat al 5%'],
  ['bg-opacity-10', 'Opacitat al 10%'],
  ['bg-opacity-20', 'Opacitat al 20%'],
  ['bg-opacity-25', 'Opacitat al 25%'],
  ['bg-opacity-30', 'Opacitat al 30%'],
  ['bg-opacity-40', 'Opacitat al 40%'],
  ['bg-opacity-50', 'Opacitat al 50%'],
  ['bg-opacity-60', 'Opacitat al 60%'],
  ['bg-opacity-70', 'Opacitat al 70%'],
  ['bg-opacity-75', 'Opacitat al 75%'],
  ['bg-opacity-80', 'Opacitat al 80%'],
  ['bg-opacity-90', 'Opacitat al 90%'],
  ['bg-opacity-95', 'Opacitat al 95%'],
  ['bg-opacity-100', 'Sense opacitat'],
]

var delayOptions = [ 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3 ]
var durationOptions = [ 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3 ]



function Configurador(props) {

  const { match, stylePresets, projectStyles, setProjectStyles, setProjectStylePresets } = props
  const [disableButton, setDisableButton] = useState(true)

  var svg_back = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M11.708 15.833H5.854V14.083H11.708Q13 14.083 13.958 13.292Q14.917 12.5 14.917 11.271Q14.917 10.042 13.958 9.24Q13 8.438 11.708 8.438H6.667L8.792 10.562L7.562 11.792L3.333 7.562L7.562 3.333L8.792 4.562L6.667 6.688H11.708Q13.729 6.688 15.198 7.99Q16.667 9.292 16.667 11.25Q16.667 13.208 15.198 14.521Q13.729 15.833 11.708 15.833Z"/></svg>
  var svg_save = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>
  var svg_plus = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M9.125 15.833V10.875H4.167V9.125H9.125V4.167H10.875V9.125H15.833V10.875H10.875V15.833Z"/></svg>
  var svg_minus = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="currentColor"><path d="M4.167 10.833V9.083H15.833V10.833Z"/></svg>

  const [projectStylesTMP, setProjectStylesTMP] = useState(null)



  /* Messages */
  const [showMessageConfig, setShowMessageConfig] = useState(false)
  const [messagePreset, setMessagePreset] = useState('default')
  
  const [messageStartAnimation, setMessageStartAnimation] = useState('preset-1')
  const [messageEndAnimation, setMessageEndAnimation] = useState('preset-1')

  const [messageAnimationDelayProfile, setMessageAnimationDelayProfile] = useState(0.0)
  const [messageAnimationDelayName, setMessageAnimationDelayName] = useState(0.0)
  const [messageAnimationDelayBody, setMessageAnimationDelayBody] = useState(0.0)

  const [messageAnimationDurationProfile, setMessageAnimationDurationProfile] = useState(0.5)
  const [messageAnimationDurationName, setMessageAnimationDurationName] = useState(0.5)
  const [messageAnimationDurationBody, setMessageAnimationDurationBody] = useState(0.5)

  const [messageShowPickerProfile, setMessageShowPickerProfile] = useState(false)
  const [messageColorProfile, setMessageColorProfile] = useState('#10B981')
  const [messageShowPickerName, setMessageShowPickerName] = useState(false)
  const [messageColorName, setMessageColorName] = useState('#18181B')
  const [messageShowPickerBody, setMessageShowPickerBody] = useState(false)
  const [messageColorBody, setMessageColorBody] = useState('#F4F4F5')

  const [messageShowPickerProfileText, setMessageShowPickerProfileText] = useState(false)
  const [messageColorProfileText, setMessageColorProfileText] = useState('#F4F4F5')
  const [messageShowPickerNameText, setMessageShowPickerNameText] = useState(false)
  const [messageColorNameText, setMessageColorNameText] = useState('#F4F4F5')
  const [messageShowPickerBodyText, setMessageShowPickerBodyText] = useState(false)
  const [messageColorBodyText, setMessageColorBodyText] = useState('#52525B')

  /* classes */

  const [messageClasesProfileText, setMessageClasesProfileText] = useState('')
  const [messageClasesProfileBox, setMessageClasesProfileBox] = useState('')
  const [messageClasesNameText, setMessageClasesNameText] = useState('')
  const [messageClasesNameBox, setMessageClasesNameBox] = useState('')
  const [messageClasesBodyText, setMessageClasesBodyText] = useState('')
  const [messageClasesBodyBox, setMessageClasesBodyBox] = useState('')

  const [messageRoundedStyleProfile, setMessageRoundedStyleProfile] = useState('')
  const [messageRoundedStyleName, setMessageRoundedStyleName] = useState('')
  const [messageRoundedStyleBody, setMessageRoundedStyleBody] = useState('')

  const [messageFontProfileText, setMessageFontProfileText] = useState('')
  const [messageFontSizeProfileText, setMessageFontSizeProfileText] = useState('text-5xl')
  const [messageFontPaddingTopProfileText, setMessageFontPaddingTopProfileText] = useState('pt-0')
  const [messageFontPaddingRightProfileText, setMessageFontPaddingRightProfileText] = useState('pr-0')
  const [messageFontPaddingBotProfileText, setMessageFontPaddingBotProfileText] = useState('pb-0')
  const [messageFontPaddingLeftProfileText, setMessageFontPaddingLeftProfileText] = useState('pl-0')
  const [messageFontMarginTopProfileBox, setMessageFontMarginTopProfileBox] = useState('-mt-5')
  const [messageFontMarginRightProfileBox, setMessageFontMarginRightProfileBox] = useState('mr-0')
  const [messageFontMarginBotProfileBox, setMessageFontMarginBotProfileBox] = useState('mb-0')
  const [messageFontMarginLeftProfileBox, setMessageFontMarginLeftProfileBox] = useState('ml-0')

  const [messageFontNameText, setMessageFontNameText] = useState('')
  const [messageFontSizeNameText, setMessageFontSizeNameText] = useState('text-4xl')
  const [messageFontPaddingTopNameText, setMessageFontPaddingTopNameText] = useState('pt-2')
  const [messageFontPaddingRightNameText, setMessageFontPaddingRightNameText] = useState('pr-5')
  const [messageFontPaddingBotNameText, setMessageFontPaddingBotNameText] = useState('pb-2')
  const [messageFontPaddingLeftNameText, setMessageFontPaddingLeftNameText] = useState('pl-3')
  const [messageFontMarginTopNameBox, setMessageFontMarginTopNameBox] = useState('mt-0')
  const [messageFontMarginRightNameBox, setMessageFontMarginRightNameBox] = useState('mr-0')
  const [messageFontMarginBotNameBox, setMessageFontMarginBotNameBox] = useState('mb-0')
  const [messageFontMarginLeftNameBox, setMessageFontMarginLeftNameBox] = useState('ml-0')

  const [messageFontBodyText, setMessageFontBodyText] = useState('')
  const [messageFontSizeBodyText, setMessageFontSizeBodyText] = useState('text-4xl')
  const [messageFontPaddingTopBodyText, setMessageFontPaddingTopBodyText] = useState('pt-2')
  const [messageFontPaddingRightBodyText, setMessageFontPaddingRightBodyText] = useState('pr-4')
  const [messageFontPaddingBotBodyText, setMessageFontPaddingBotBodyText] = useState('pb-2') /* 2.5? */
  const [messageFontPaddingLeftBodyText, setMessageFontPaddingLeftBodyText] = useState('pl-4')
  const [messageFontMarginTopBodyBox, setMessageFontMarginTopBodyBox] = useState('mt-0')
  const [messageFontMarginRightBodyBox, setMessageFontMarginRightBodyBox] = useState('mr-0')
  const [messageFontMarginBotBodyBox, setMessageFontMarginBotBodyBox] = useState('mb-0')
  const [messageFontMarginLeftBodyBox, setMessageFontMarginLeftBodyBox] = useState('ml-0')

  const [messageOpacityProfileBox, setMessageOpacityProfileBox] = useState('opacity-100')
  const [messageOpacityNameBox, setMessageOpacityNameBox] = useState('opacity-100')
  const [messageOpacityBodyBox, setMessageOpacityBodyBox] = useState('opacity-100')


  useEffect(() => { 
    setMessageClasesProfileText(
      ' '+messageFontProfileText
      +' '+messageFontSizeProfileText
      +' '+messageFontPaddingTopProfileText
      +' '+messageFontPaddingRightProfileText
      +' '+messageFontPaddingBotProfileText
      +' '+messageFontPaddingLeftProfileText
      +' '
    )
  }, [
    messageFontProfileText, 
    messageFontSizeProfileText,
    messageFontPaddingTopProfileText,
    messageFontPaddingRightProfileText,
    messageFontPaddingBotProfileText,
    messageFontPaddingLeftProfileText
  ]);

  useEffect(() => { 
    setMessageClasesProfileBox(
      ' '+messageRoundedStyleProfile
      +' '+messageFontMarginTopProfileBox
      +' '+messageFontMarginRightProfileBox
      +' '+messageFontMarginBotProfileBox
      +' '+messageFontMarginLeftProfileBox
      +' '+messageOpacityProfileBox
      +' '
    )
  }, [
    messageRoundedStyleProfile,
    messageFontMarginTopProfileBox,
    messageFontMarginRightProfileBox,
    messageFontMarginBotProfileBox,
    messageFontMarginLeftProfileBox,
    messageOpacityProfileBox
  ]);

  useEffect(() => { 
    setMessageClasesNameText(
      ' '+messageFontNameText
      +' '+messageFontSizeNameText
      +' '+messageFontPaddingTopNameText
      +' '+messageFontPaddingRightNameText
      +' '+messageFontPaddingBotNameText
      +' '+messageFontPaddingLeftNameText
      +' '
    )
  }, [
    messageFontNameText, 
    messageFontSizeNameText,
    messageFontPaddingTopNameText,
    messageFontPaddingRightNameText,
    messageFontPaddingBotNameText,
    messageFontPaddingLeftNameText
  ]);

  useEffect(() => { 
    setMessageClasesNameBox(
      ' '+messageRoundedStyleName
      +' '+messageFontMarginTopNameBox
      +' '+messageFontMarginRightNameBox
      +' '+messageFontMarginBotNameBox
      +' '+messageFontMarginLeftNameBox
      +' '+messageOpacityNameBox
      +' '
    )
  }, [
    messageRoundedStyleName,
    messageFontMarginTopNameBox,
    messageFontMarginRightNameBox,
    messageFontMarginBotNameBox,
    messageFontMarginLeftNameBox,
    messageOpacityNameBox
  ]);

  useEffect(() => { 
    setMessageClasesBodyText(
      ' '+messageFontBodyText
      +' '+messageFontSizeBodyText
      +' '+messageFontPaddingTopBodyText
      +' '+messageFontPaddingRightBodyText
      +' '+messageFontPaddingBotBodyText
      +' '+messageFontPaddingLeftBodyText
      +' '
    )
  }, [
    messageFontBodyText, 
    messageFontSizeBodyText,
    messageFontPaddingTopBodyText,
    messageFontPaddingRightBodyText,
    messageFontPaddingBotBodyText,
    messageFontPaddingLeftBodyText
  ]);

  useEffect(() => { 
    setMessageClasesBodyBox(
      ' '+messageRoundedStyleBody
      +' '+messageFontMarginTopBodyBox
      +' '+messageFontMarginRightBodyBox
      +' '+messageFontMarginBotBodyBox
      +' '+messageFontMarginLeftBodyBox
      +' '+messageOpacityBodyBox
      +' '
    )
  }, [
    messageRoundedStyleBody,
    messageFontMarginTopBodyBox,
    messageFontMarginRightBodyBox,
    messageFontMarginBotBodyBox,
    messageFontMarginLeftBodyBox,
    messageOpacityBodyBox
  ]);



  /* Audio Messages */
  const [showAudioMessageConfig, setShowAudioMessageConfig] = useState(false)

  const [audioMessageStartAnimation, setAudioMessageStartAnimation] = useState('preset-1')
  const [audioMessageEndAnimation, setAudioMessageEndAnimation] = useState('preset-1')

  const [audioMessageAnimationDelayProfile, setAudioMessageAnimationDelayProfile] = useState(0.0)
  const [audioMessageAnimationDelayName, setAudioMessageAnimationDelayName] = useState(0.3)
  const [audioMessageAnimationDelayInfo, setAudioMessageAnimationDelayInfo] = useState(0.5)
  const [audioMessageAnimationDelayControls, setAudioMessageAnimationDelayControls] = useState(0.7)
  const [audioMessageAnimationDelayBody, setAudioMessageAnimationDelayBody] = useState(0.0)

  const [audioMessageAnimationDurationProfile, setAudioMessageAnimationDurationProfile] = useState(0.5)
  const [audioMessageAnimationDurationName, setAudioMessageAnimationDurationName] = useState(0.5)
  const [audioMessageAnimationDurationInfo, setAudioMessageAnimationDurationInfo] = useState(0.5)
  const [audioMessageAnimationDurationControls, setAudioMessageAnimationDurationControls] = useState(0.5)
  const [audioMessageAnimationDurationBody, setAudioMessageAnimationDurationBody] = useState(0.5)

  const [audioMessageRoundedStyleProfile, setAudioMessageRoundedStyleProfile] = useState('rounded-full')
  const [audioMessageRoundedStyleBody, setAudioMessageRoundedStyleBody] = useState('rounded-3xl')

  const [audioMessageShowPickerBody, setAudioMessageShowPickerBody] = useState(false)
  const [audioMessageColorBody, setAudioMessageColorBody] = useState('#F4F4F5')
  const [audioMessageShowPickerBar, setAudioMessageShowPickerBar] = useState(false)
  const [audioMessageColorBar, setAudioMessageColorBar] = useState('#52525B')
  const [audioMessageShowPickerDot, setAudioMessageShowPickerDot] = useState(false)
  const [audioMessageColorDot, setAudioMessageColorDot] = useState('#18181B')
  const [audioMessageShowPickerTimer, setAudioMessageShowPickerTimer] = useState(false)
  const [audioMessageColorTimer, setAudioMessageColorTimer] = useState('#A1A1AA')
  
  const [audioMessageShowPickerNameText, setAudioMessageShowPickerNameText] = useState(false)
  const [audioMessageColorNameText, setAudioMessageColorNameText] = useState('#19191B')
  const [audioMessageShowPickerInfoText, setAudioMessageShowPickerInfoText] = useState(false)
  const [audioMessageColorInfoText, setAudioMessageColorInfoText] = useState('#19191B')



  /* Cayrons */
  const [showCayronConfig, setShowCayronConfig] = useState(false)
  const [cayronPreset, setCayronPreset] = useState('default')

  const [cayronStartAnimation, setCayronStartAnimation] = useState('preset-1')
  const [cayronEndAnimation, setCayronEndAnimation] = useState('preset-1')

  const [cayronAnimationDelayNameBox, setCayronAnimationDelayNameBox] = useState(0.0)
  const [cayronAnimationDelayName, setCayronAnimationDelayName] = useState(0.2)
  const [cayronAnimationDelayBusiness, setCayronAnimationDelayBusiness] = useState(0.4)

  const [cayronAnimationDurationNameBox, setCayronAnimationDurationNameBox] = useState(0.5)
  const [cayronAnimationDurationName, setCayronAnimationDurationName] = useState(0.5)
  const [cayronAnimationDurationBusiness, setCayronAnimationDurationBusiness] = useState(0.5)

  const [cayronShowPickerName, setCayronShowPickerName] = useState(false)
  const [cayronColorName, setCayronColorName] = useState('#19191B')
  const [cayronShowPickerBody, setCayronShowPickerBody] = useState(false)
  const [cayronColorBody, setCayronColorBody] = useState('#F4F4F5')

  const [cayronShowPickerNameText, setCayronShowPickerNameText] = useState(false)
  const [cayronColorNameText, setCayronColorNameText] = useState('#F4F4F5')
  const [cayronShowPickerBodyText, setCayronShowPickerBodyText] = useState(false)
  const [cayronColorBodyText, setCayronColorBodyText] = useState('#52525B')

  /* classes */

  const [cayronClasesNameText, setCayronClasesNameText] = useState('')
  const [cayronClasesBodyText, setCayronClasesBodyText] = useState('')
  const [cayronClasesNameBox, setCayronClasesNameBox] = useState('')
  const [cayronClasesBodyBox, setCayronClasesBodyBox] = useState('')

  const [cayronRoundedStyleName, setCayronRoundedStyleName] = useState('')
  const [cayronRoundedStyleBusiness, setCayronRoundedStyleBusiness] = useState('')

  const [cayronFontNameText, setCayronFontNameText] = useState('anton-font')
  const [cayronFontSizeNameText, setCayronFontSizeNameText] = useState('text-5xl')
  const [cayronFontPaddingTopNameText, setCayronFontPaddingTopNameText] = useState('pt-5')
  const [cayronFontPaddingRightNameText, setCayronFontPaddingRightNameText] = useState('pr-5')
  const [cayronFontPaddingBotNameText, setCayronFontPaddingBotNameText] = useState('pb-5')
  const [cayronFontPaddingLeftNameText, setCayronFontPaddingLeftNameText] = useState('pl-5')
  const [cayronFontMarginTopNameBox, setCayronFontMarginTopNameBox] = useState('mt-0')
  const [cayronFontMarginRightNameBox, setCayronFontMarginRightNameBox] = useState('mr-0')
  const [cayronFontMarginBotNameBox, setCayronFontMarginBotNameBox] = useState('mb-0')
  const [cayronFontMarginLeftNameBox, setCayronFontMarginLeftNameBox] = useState('ml-0')

  const [cayronFontBodyText, setCayronFontBodyText] = useState('')
  const [cayronFontSizeBodyText, setCayronFontSizeBodyText] = useState('text-2xl')
  const [cayronFontPaddingTopBodyText, setCayronFontPaddingTopBodyText] = useState('pt-3')
  const [cayronFontPaddingRightBodyText, setCayronFontPaddingRightBodyText] = useState('pr-5')
  const [cayronFontPaddingBotBodyText, setCayronFontPaddingBotBodyText] = useState('pb-3')
  const [cayronFontPaddingLeftBodyText, setCayronFontPaddingLeftBodyText] = useState('pl-5')
  const [cayronFontMarginTopBodyBox, setCayronFontMarginTopBodyBox] = useState('mt-0')
  const [cayronFontMarginRightBodyBox, setCayronFontMarginRightBodyBox] = useState('mr-0')
  const [cayronFontMarginBotBodyBox, setCayronFontMarginBotBodyBox] = useState('mb-0')
  const [cayronFontMarginLeftBodyBox, setCayronFontMarginLeftBodyBox] = useState('ml-0')

  useEffect(() => { 
    setCayronClasesNameText(
      ' '+cayronFontNameText
      +' '+cayronFontSizeNameText
      +' '+cayronFontPaddingTopNameText
      +' '+cayronFontPaddingRightNameText
      +' '+cayronFontPaddingBotNameText
      +' '+cayronFontPaddingLeftNameText
      +' '
    )
  }, [
    cayronFontNameText, 
    cayronFontSizeNameText,
    cayronFontPaddingTopNameText,
    cayronFontPaddingRightNameText,
    cayronFontPaddingBotNameText,
    cayronFontPaddingLeftNameText
  ]);

  useEffect(() => { 
    setCayronClasesNameBox(
      ' '+cayronRoundedStyleName
      +' '+cayronFontMarginTopNameBox
      +' '+cayronFontMarginRightNameBox
      +' '+cayronFontMarginBotNameBox
      +' '+cayronFontMarginLeftNameBox
      +' '
    )
  }, [
    cayronRoundedStyleName,
    cayronFontMarginTopNameBox,
    cayronFontMarginRightNameBox,
    cayronFontMarginBotNameBox,
    cayronFontMarginLeftNameBox
  ]);

  useEffect(() => { 
    setCayronClasesBodyText(
      ' '+cayronFontBodyText
      +' '+cayronFontSizeBodyText
      +' '+cayronFontPaddingTopBodyText
      +' '+cayronFontPaddingRightBodyText
      +' '+cayronFontPaddingBotBodyText
      +' '+cayronFontPaddingLeftBodyText
      +' '
    )
  }, [
    cayronFontBodyText, 
    cayronFontSizeBodyText,
    cayronFontPaddingTopBodyText,
    cayronFontPaddingRightBodyText,
    cayronFontPaddingBotBodyText,
    cayronFontPaddingLeftBodyText,
  ]);

  useEffect(() => { 
    setCayronClasesBodyBox(
      ' '+cayronRoundedStyleBusiness
      +' '+cayronFontMarginTopBodyBox
      +' '+cayronFontMarginRightBodyBox
      +' '+cayronFontMarginBotBodyBox
      +' '+cayronFontMarginLeftBodyBox
      +' '
    )
  }, [
    cayronRoundedStyleBusiness,
    cayronFontMarginTopBodyBox,
    cayronFontMarginRightBodyBox,
    cayronFontMarginBotBodyBox,
    cayronFontMarginLeftBodyBox
  ]);


  

  /* Subtitles */
  const [showSubtitleConfig, setShowSubtitleConfig] = useState(false)

  const [subtitleStartAnimation, setSubtitleStartAnimation] = useState('preset-1')
  const [subtitleEndAnimation, setSubtitleEndAnimation] = useState('preset-1')

  const [subtitleAnimationDelayBox, setSubtitleAnimationDelayBox] = useState(0.0)
  const [subtitleAnimationDelayText, setSubtitleAnimationDelayText] = useState(0.0)

  const [subtitleAnimationDurationBox, setSubtitleAnimationDurationBox] = useState(0.5)
  const [subtitleAnimationDurationText, setSubtitleAnimationDurationText] = useState(0.5)

  const [subtitleRoundedStyleBox, setSubtitleRoundedStyleBox] = useState('')

  const [subtitleShowPickerBox, setSubtitleShowPickerBox] = useState(false)
  const [subtitleColorBox, setSubtitleColorBox] = useState('#18181B')
  const [subtitleShowPickerText, setSubtitleShowPickerText] = useState(false)
  const [subtitleColorText, setSubtitleColorText] = useState('#F4F4F5')




  /* Cards */
  const [showCardConfig, setShowCardConfig] = useState(false)
  const [cardPreset, setCardPreset] = useState('default')
  
  const [cardStartAnimation, setCardStartAnimation] = useState('preset-1')
  const [cardEndAnimation, setCardEndAnimation] = useState('preset-1')

  const [cardAnimationDelayImage, setCardAnimationDelayImage] = useState(0.0)
  const [cardAnimationDelayInitialBox, setCardAnimationDelayInitialBox] = useState(0.0)
  const [cardAnimationDelaySecondaryBox, setCardAnimationDelaySecondaryBox] = useState(0.0)

  const [cardAnimationDurationImage, setCardAnimationDurationImage] = useState(0.5)
  const [cardAnimationDurationInitialBox, setCardAnimationDurationInitialBox] = useState(0.5)
  const [cardAnimationDurationSecondaryBox, setCardAnimationDurationSecondaryBox] = useState(0.5)

  const [cardShowPickerInitialBox, setCardShowPickerInitialBox] = useState(false)
  const [cardColorInitialBox, setCardColorInitialBox] = useState('#F4F4F5')
  const [cardShowPickerSecondaryBox, setCardShowPickerSecondaryBox] = useState(false)
  const [cardColorSecondaryBox, setCardColorSecondaryBox] = useState('#F4F4F5')
  const [cardShowPickerTitle, setCardShowPickerTitle] = useState(false)
  const [cardColorTitle, setCardColorTitle] = useState('#3F3F46')
  const [cardShowPickerSubtitle, setCardShowPickerSubtitle] = useState(false)
  const [cardColorSubtitle, setCardColorSubtitle] = useState('#3F3F46')
  const [cardShowPickerInitialText, setCardShowPickerInitialText] = useState(false)
  const [cardColorInitialText, setCardColorInitialText] = useState('#3F3F46')
  const [cardShowPickerSecondaryText, setCardShowPickerSecondaryText] = useState(false)
  const [cardColorSecondaryText, setCardColorSecondaryText] = useState('#3F3F46')

  /* classes */

  const [cardClasesImage, setCardClasesImage] = useState('')
  const [cardClasesInitialBox, setCardClasesInitialBox] = useState('')
  const [cardClasesSecondaryBox, setCardClasesSecondaryBox] = useState('')
  const [cardClasesTitle, setCardClasesTitle] = useState('')
  const [cardClasesSubtitle, setCardClasesSubtitle] = useState('')
  const [cardClasesInitialText, setCardClasesInitialText] = useState('')
  const [cardClasesSecondaryText, setCardClasesSecondaryText] = useState('')

  const [cardRoundedStyleImage, setCardRoundedStyleImage] = useState('')
  const [cardRoundedStyleInitialBox, setCardRoundedStyleInitialBox] = useState('')
  const [cardRoundedStyleSecondaryBox, setCardRoundedStyleSecondaryBox] = useState('')

  const [cardFontTitle, setCardFontTitle] = useState('')
  const [cardFontSizeTitle, setCardFontSizeTitle] = useState('text-4xl')

  const [cardFontSubtitle, setCardFontSubtitle] = useState('')
  const [cardFontSizeSubtitle, setCardFontSizeSubtitle] = useState('text-3xl')

  const [cardFontInitialText, setCardFontInitialText] = useState('')

  const [cardFontSecondaryText, setCardFontSecondaryText] = useState('')


  useEffect(() => { 
    setCardClasesImage(
      ' '+cardRoundedStyleImage
      +' '
    )
  }, [
    cardRoundedStyleImage, 
  ]);

  useEffect(() => { 
    setCardClasesInitialBox(
      ' '+cardRoundedStyleInitialBox
      +' '
    )
  }, [
    cardRoundedStyleInitialBox, 
  ]);

  useEffect(() => { 
    setCardClasesSecondaryBox(
      ' '+cardRoundedStyleSecondaryBox
      +' '
    )
  }, [
    cardRoundedStyleSecondaryBox, 
  ]);

  useEffect(() => { 
    setCardClasesTitle(
      ' '+cardFontTitle
      +' '+cardFontSizeTitle
      +' '
    )
  }, [
    cardFontTitle, 
    cardFontSizeTitle,
  ]);

  useEffect(() => { 
    setCardClasesSubtitle(
      ' '+cardFontSubtitle
      +' '+cardFontSizeSubtitle
      +' '
    )
  }, [
    cardFontSubtitle, 
    cardFontSizeSubtitle,
  ]);

  useEffect(() => { 
    setCardClasesInitialText(
      ' '+cardFontInitialText
      +' '
    )
  }, [
    cardFontInitialText, 
  ]);

  useEffect(() => { 
    setCardClasesSecondaryText(
      ' '+cardFontSecondaryText
      +' '
    )
  }, [
    cardFontSecondaryText, 
  ]);





  useEffect(() => {
    setDisableButton(true)
    if (projectStyles) {
      
      if (projectStyles.messagePreset) { setMessagePreset(projectStyles.messagePreset); }
      if (projectStyles.messageStartAnimation) { setMessageStartAnimation(projectStyles.messageStartAnimation); }
      if (projectStyles.messageEndAnimation) { setMessageEndAnimation(projectStyles.messageEndAnimation); }
      if (projectStyles.messageAnimationDelayProfile) { setMessageAnimationDelayProfile(projectStyles.messageAnimationDelayProfile); }
      if (projectStyles.messageAnimationDelayName) { setMessageAnimationDelayName(projectStyles.messageAnimationDelayName); }
      if (projectStyles.messageAnimationDelayBody) { setMessageAnimationDelayBody(projectStyles.messageAnimationDelayBody); }
      if (projectStyles.messageAnimationDurationProfile) { setMessageAnimationDurationProfile(projectStyles.messageAnimationDurationProfile); }
      if (projectStyles.messageAnimationDurationName) { setMessageAnimationDurationName(projectStyles.messageAnimationDurationName); }
      if (projectStyles.messageAnimationDurationBody) { setMessageAnimationDurationBody(projectStyles.messageAnimationDurationBody); }
      if (projectStyles.messageRoundedStyleProfile) { setMessageRoundedStyleProfile(projectStyles.messageRoundedStyleProfile); }
      if (projectStyles.messageRoundedStyleName) { setMessageRoundedStyleName(projectStyles.messageRoundedStyleName); }
      if (projectStyles.messageRoundedStyleBody) { setMessageRoundedStyleBody(projectStyles.messageRoundedStyleBody); }
      if (projectStyles.messageColorProfile) { setMessageColorProfile(projectStyles.messageColorProfile); }
      if (projectStyles.messageColorName) { setMessageColorName(projectStyles.messageColorName); }
      if (projectStyles.messageColorBody) { setMessageColorBody(projectStyles.messageColorBody); }
      if (projectStyles.messageColorProfileText) { setMessageColorProfileText(projectStyles.messageColorProfileText); }
      if (projectStyles.messageColorNameText) { setMessageColorNameText(projectStyles.messageColorNameText); }
      if (projectStyles.messageFontProfileText) { setMessageFontProfileText(projectStyles.messageFontProfileText); }
      if (projectStyles.messageFontSizeProfileText) { setMessageFontSizeProfileText(projectStyles.messageFontSizeProfileText); }
      if (projectStyles.messageFontPaddingTopProfileText) { setMessageFontPaddingTopProfileText(projectStyles.messageFontPaddingTopProfileText); }
      if (projectStyles.messageFontPaddingRightProfileText) { setMessageFontPaddingRightProfileText(projectStyles.messageFontPaddingRightProfileText); }
      if (projectStyles.messageFontPaddingBotProfileText) { setMessageFontPaddingBotProfileText(projectStyles.messageFontPaddingBotProfileText); }
      if (projectStyles.messageFontPaddingLeftProfileText) { setMessageFontPaddingLeftProfileText(projectStyles.messageFontPaddingLeftProfileText); }
      if (projectStyles.messageFontMarginTopProfileBox) { setMessageFontMarginTopProfileBox(projectStyles.messageFontMarginTopProfileBox); }
      if (projectStyles.messageFontMarginRightProfileBox) { setMessageFontMarginRightProfileBox(projectStyles.messageFontMarginRightProfileBox); }
      if (projectStyles.messageFontMarginBotProfileBox) { setMessageFontMarginBotProfileBox(projectStyles.messageFontMarginBotProfileBox); }
      if (projectStyles.messageFontMarginLeftProfileBox) { setMessageFontMarginLeftProfileBox(projectStyles.messageFontMarginLeftProfileBox); }
      if (projectStyles.messageFontNameText) { setMessageFontNameText(projectStyles.messageFontNameText); }
      if (projectStyles.messageFontSizeNameText) { setMessageFontSizeNameText(projectStyles.messageFontSizeNameText); }
      if (projectStyles.messageFontPaddingTopNameText) { setMessageFontPaddingTopNameText(projectStyles.messageFontPaddingTopNameText); }
      if (projectStyles.messageFontPaddingRightNameText) { setMessageFontPaddingRightNameText(projectStyles.messageFontPaddingRightNameText); }
      if (projectStyles.messageFontPaddingBotNameText) { setMessageFontPaddingBotNameText(projectStyles.messageFontPaddingBotNameText); }
      if (projectStyles.messageFontPaddingLeftNameText) { setMessageFontPaddingLeftNameText(projectStyles.messageFontPaddingLeftNameText); }
      if (projectStyles.messageFontMarginTopNameBox) { setMessageFontMarginTopNameBox(projectStyles.messageFontMarginTopNameBox); }
      if (projectStyles.messageFontMarginRightNameBox) { setMessageFontMarginRightNameBox(projectStyles.messageFontMarginRightNameBox); }
      if (projectStyles.messageFontMarginBotNameBox) { setMessageFontMarginBotNameBox(projectStyles.messageFontMarginBotNameBox); }
      if (projectStyles.messageFontMarginLeftNameBox) { setMessageFontMarginLeftNameBox(projectStyles.messageFontMarginLeftNameBox); }
      if (projectStyles.messageFontBodyText) { setMessageFontBodyText(projectStyles.messageFontBodyText); }
      if (projectStyles.messageFontSizeBodyText) { setMessageFontSizeBodyText(projectStyles.messageFontSizeBodyText); }
      if (projectStyles.messageFontPaddingTopBodyText) { setMessageFontPaddingTopBodyText(projectStyles.messageFontPaddingTopBodyText); }
      if (projectStyles.messageFontPaddingRightBodyText) { setMessageFontPaddingRightBodyText(projectStyles.messageFontPaddingRightBodyText); }
      if (projectStyles.messageFontPaddingBotBodyText) { setMessageFontPaddingBotBodyText(projectStyles.messageFontPaddingBotBodyText); }
      if (projectStyles.messageFontPaddingLeftBodyText) { setMessageFontPaddingLeftBodyText(projectStyles.messageFontPaddingLeftBodyText); }
      if (projectStyles.messageFontMarginTopBodyBox) { setMessageFontMarginTopBodyBox(projectStyles.messageFontMarginTopBodyBox); }
      if (projectStyles.messageFontMarginRightBodyBox) { setMessageFontMarginRightBodyBox(projectStyles.messageFontMarginRightBodyBox); }
      if (projectStyles.messageFontMarginBotBodyBox) { setMessageFontMarginBotBodyBox(projectStyles.messageFontMarginBotBodyBox); }
      if (projectStyles.messageFontMarginLeftBodyBox) { setMessageFontMarginLeftBodyBox(projectStyles.messageFontMarginLeftBodyBox); }
      if (projectStyles.messageColorBodyText) { setMessageColorBodyText(projectStyles.messageColorBodyText); }
      if (projectStyles.messageClasesProfileText) { setMessageClasesProfileText(projectStyles.messageClasesProfileText); }
      if (projectStyles.messageClasesProfileBox) { setMessageClasesProfileBox(projectStyles.messageClasesProfileBox); }
      if (projectStyles.messageClasesNameText) { setMessageClasesNameText(projectStyles.messageClasesNameText); }
      if (projectStyles.messageClasesNameBox) { setMessageClasesNameBox(projectStyles.messageClasesNameBox); }
      if (projectStyles.messageClasesBodyText) { setMessageClasesBodyText(projectStyles.messageClasesBodyText); }
      if (projectStyles.messageClasesBodyBox) { setMessageClasesBodyBox(projectStyles.messageClasesBodyBox); }
      if (projectStyles.messageOpacityProfileBox) { setMessageOpacityProfileBox(projectStyles.messageOpacityProfileBox); }
      if (projectStyles.messageOpacityNameBox) { setMessageOpacityNameBox(projectStyles.messageOpacityNameBox); }
      if (projectStyles.messageOpacityBodyBox) { setMessageOpacityBodyBox(projectStyles.messageOpacityBodyBox); }


      if (projectStyles.audioMessageStartAnimation) { setAudioMessageStartAnimation(projectStyles.audioMessageStartAnimation); }
      if (projectStyles.audioMessageEndAnimation) { setAudioMessageEndAnimation(projectStyles.audioMessageEndAnimation); }
      if (projectStyles.audioMessageAnimationDelayProfile) { setAudioMessageAnimationDelayProfile(projectStyles.audioMessageAnimationDelayProfile); }
      if (projectStyles.audioMessageAnimationDelayName) { setAudioMessageAnimationDelayName(projectStyles.audioMessageAnimationDelayName); }
      if (projectStyles.audioMessageAnimationDelayControls) { setAudioMessageAnimationDelayControls(projectStyles.audioMessageAnimationDelayControls); }
      if (projectStyles.audioMessageAnimationDelayInfo) { setAudioMessageAnimationDelayInfo(projectStyles.audioMessageAnimationDelayInfo); }
      if (projectStyles.audioMessageAnimationDelayBody) { setAudioMessageAnimationDelayBody(projectStyles.audioMessageAnimationDelayBody); }
      if (projectStyles.audioMessageAnimationDurationProfile) { setAudioMessageAnimationDurationProfile(projectStyles.audioMessageAnimationDurationProfile); }
      if (projectStyles.audioMessageAnimationDurationName) { setAudioMessageAnimationDurationName(projectStyles.audioMessageAnimationDurationName); }
      if (projectStyles.audioMessageAnimationDurationControls) { setAudioMessageAnimationDurationControls(projectStyles.audioMessageAnimationDurationControls); }
      if (projectStyles.audioMessageAnimationDurationInfo) { setAudioMessageAnimationDurationInfo(projectStyles.audioMessageAnimationDurationInfo); }
      if (projectStyles.audioMessageAnimationDurationBody) { setAudioMessageAnimationDurationBody(projectStyles.audioMessageAnimationDurationBody); }
      if (projectStyles.audioMessageRoundedStyleProfile) { setAudioMessageRoundedStyleProfile(projectStyles.audioMessageRoundedStyleProfile); }
      if (projectStyles.audioMessageRoundedStyleBody) { setAudioMessageRoundedStyleBody(projectStyles.audioMessageRoundedStyleBody); }
      if (projectStyles.audioMessageColorBody) { setAudioMessageColorBody(projectStyles.audioMessageColorBody); }
      if (projectStyles.audioMessageColorNameText) { setAudioMessageColorNameText(projectStyles.audioMessageColorNameText); }
      if (projectStyles.audioMessageColorInfoText) { setAudioMessageColorInfoText(projectStyles.audioMessageColorInfoText); }


      if (projectStyles.cayronPreset) { setCayronPreset(projectStyles.cayronPreset); }
      if (projectStyles.cayronStartAnimation) { setCayronStartAnimation(projectStyles.cayronStartAnimation); }
      if (projectStyles.cayronEndAnimation) { setCayronEndAnimation(projectStyles.cayronEndAnimation); }
      if (projectStyles.cayronAnimationDelayNameBox) { setCayronAnimationDelayNameBox(projectStyles.cayronAnimationDelayNameBox); }
      if (projectStyles.cayronAnimationDelayName) { setCayronAnimationDelayName(projectStyles.cayronAnimationDelayName); }
      if (projectStyles.cayronAnimationDelayBusiness) { setCayronAnimationDelayBusiness(projectStyles.cayronAnimationDelayBusiness); }
      if (projectStyles.cayronAnimationDurationNameBox) { setCayronAnimationDurationNameBox(projectStyles.cayronAnimationDurationNameBox); }
      if (projectStyles.cayronAnimationDurationName) { setCayronAnimationDurationName(projectStyles.cayronAnimationDurationName); }
      if (projectStyles.cayronAnimationDurationBusiness) { setCayronAnimationDurationBusiness(projectStyles.cayronAnimationDurationBusiness); }
      if (projectStyles.cayronRoundedStyleName) { setCayronRoundedStyleName(projectStyles.cayronRoundedStyleName); }
      if (projectStyles.cayronRoundedStyleBusiness) { setCayronRoundedStyleBusiness(projectStyles.cayronRoundedStyleBusiness); }
      if (projectStyles.cayronColorName) { setCayronColorName(projectStyles.cayronColorName); }
      if (projectStyles.cayronColorBody) { setCayronColorBody(projectStyles.cayronColorBody); }
      if (projectStyles.cayronColorNameText) { setCayronColorNameText(projectStyles.cayronColorNameText); }
      if (projectStyles.cayronColorBodyText) { setCayronColorBodyText(projectStyles.cayronColorBodyText); }
      if (projectStyles.cayronFontNameText) { setCayronFontNameText(projectStyles.cayronFontNameText); }
      if (projectStyles.cayronFontSizeNameText) { setCayronFontSizeNameText(projectStyles.cayronFontSizeNameText); }
      if (projectStyles.cayronFontPaddingTopNameText) { setCayronFontPaddingTopNameText(projectStyles.cayronFontPaddingTopNameText); }
      if (projectStyles.cayronFontPaddingRightNameText) { setCayronFontPaddingRightNameText(projectStyles.cayronFontPaddingRightNameText); }
      if (projectStyles.cayronFontPaddingBotNameText) { setCayronFontPaddingBotNameText(projectStyles.cayronFontPaddingBotNameText); }
      if (projectStyles.cayronFontPaddingLeftNameText) { setCayronFontPaddingLeftNameText(projectStyles.cayronFontPaddingLeftNameText); }
      if (projectStyles.cayronFontMarginTopNameBox) { setCayronFontMarginTopNameBox(projectStyles.cayronFontMarginTopNameBox); }
      if (projectStyles.cayronFontMarginRightNameBox) { setCayronFontMarginRightNameBox(projectStyles.cayronFontMarginRightNameBox); }
      if (projectStyles.cayronFontMarginBotNameBox) { setCayronFontMarginBotNameBox(projectStyles.cayronFontMarginBotNameBox); }
      if (projectStyles.cayronFontMarginLeftNameBox) { setCayronFontMarginLeftNameBox(projectStyles.cayronFontMarginLeftNameBox); }
      if (projectStyles.cayronFontBodyText) { setCayronFontBodyText(projectStyles.cayronFontBodyText); }
      if (projectStyles.cayronFontSizeBodyText) { setCayronFontSizeBodyText(projectStyles.cayronFontSizeBodyText); }
      if (projectStyles.cayronFontPaddingTopBodyText) { setCayronFontPaddingTopBodyText(projectStyles.cayronFontPaddingTopBodyText); }
      if (projectStyles.cayronFontPaddingRightBodyText) { setCayronFontPaddingRightBodyText(projectStyles.cayronFontPaddingRightBodyText); }
      if (projectStyles.cayronFontPaddingBotBodyText) { setCayronFontPaddingBotBodyText(projectStyles.cayronFontPaddingBotBodyText); }
      if (projectStyles.cayronFontPaddingLeftBodyText) { setCayronFontPaddingLeftBodyText(projectStyles.cayronFontPaddingLeftBodyText); }
      if (projectStyles.cayronFontMarginTopBodyBox) { setCayronFontMarginTopBodyBox(projectStyles.cayronFontMarginTopBodyBox); }
      if (projectStyles.cayronFontMarginRightBodyBox) { setCayronFontMarginRightBodyBox(projectStyles.cayronFontMarginRightBodyBox); }
      if (projectStyles.cayronFontMarginBotBodyBox) { setCayronFontMarginBotBodyBox(projectStyles.cayronFontMarginBotBodyBox); }
      if (projectStyles.cayronFontMarginLeftBodyBox) { setCayronFontMarginLeftBodyBox(projectStyles.cayronFontMarginLeftBodyBox); }
      if (projectStyles.cayronClasesNameText) { setCayronClasesNameText(projectStyles.cayronClasesNameText); }
      if (projectStyles.cayronClasesBodyText) { setCayronClasesBodyText(projectStyles.cayronClasesBodyText); }
      if (projectStyles.cayronClasesNameBox) { setCayronClasesNameBox(projectStyles.cayronClasesNameBox); }
      if (projectStyles.cayronClasesBodyBox) { setCayronClasesBodyBox(projectStyles.cayronClasesBodyBox); }


      if (projectStyles.subtitleStartAnimation) { setSubtitleStartAnimation(projectStyles.subtitleStartAnimation); }
      if (projectStyles.subtitleEndAnimation) { setSubtitleEndAnimation(projectStyles.subtitleEndAnimation); }
      if (projectStyles.subtitleAnimationDelayBox) { setSubtitleAnimationDelayBox(projectStyles.subtitleAnimationDelayBox); }
      if (projectStyles.subtitleAnimationDelayText) { setSubtitleAnimationDelayText(projectStyles.subtitleAnimationDelayText); }
      if (projectStyles.subtitleAnimationDurationBox) { setSubtitleAnimationDurationBox(projectStyles.subtitleAnimationDurationBox); }
      if (projectStyles.subtitleAnimationDurationText) { setSubtitleAnimationDurationText(projectStyles.subtitleAnimationDurationText); }
      if (projectStyles.subtitleRoundedStyleBox) { setSubtitleRoundedStyleBox(projectStyles.subtitleRoundedStyleBox); }
      if (projectStyles.subtitleColorBox) { setSubtitleColorBox(projectStyles.subtitleColorBox); }
      if (projectStyles.subtitleColorText) { setSubtitleColorText(projectStyles.subtitleColorText); }


      if (projectStyles.cardPreset) { setCardPreset(projectStyles.cardPreset); }
      if (projectStyles.cardStartAnimation) { setCardStartAnimation(projectStyles.cardStartAnimation); }
      if (projectStyles.cardEndAnimation) { setCardEndAnimation(projectStyles.cardEndAnimation); }
      if (projectStyles.cardAnimationDelayImage) { setCardAnimationDelayImage(projectStyles.cardAnimationDelayImage); }
      if (projectStyles.cardAnimationDelayInitialBox) { setCardAnimationDelayInitialBox(projectStyles.cardAnimationDelayInitialBox); }
      if (projectStyles.cardAnimationDelaySecondaryBox) { setCardAnimationDelaySecondaryBox(projectStyles.cardAnimationDelaySecondaryBox); }
      if (projectStyles.cardAnimationDurationImage) { setCardAnimationDurationImage(projectStyles.cardAnimationDurationImage); }
      if (projectStyles.cardAnimationDurationInitialBox) { setCardAnimationDurationInitialBox(projectStyles.cardAnimationDurationInitialBox); }
      if (projectStyles.cardAnimationDurationSecondaryBox) { setCardAnimationDurationSecondaryBox(projectStyles.cardAnimationDurationSecondaryBox); }
      if (projectStyles.cardColorInitialBox) { setCardColorInitialBox(projectStyles.cardColorInitialBox); }
      if (projectStyles.cardColorSecondaryBox) { setCardColorSecondaryBox(projectStyles.cardColorSecondaryBox); }
      if (projectStyles.cardColorTitle) { setCardColorTitle(projectStyles.cardColorTitle); }
      if (projectStyles.cardColorSubtitle) { setCardColorSubtitle(projectStyles.cardColorSubtitle); }
      if (projectStyles.cardColorInitialText) { setCardColorInitialText(projectStyles.cardColorInitialText); }
      if (projectStyles.cardColorSecondaryText) { setCardColorSecondaryText(projectStyles.cardColorSecondaryText); }
      if (projectStyles.cardClasesImage) { setCardClasesImage(projectStyles.cardClasesImage); }
      if (projectStyles.cardClasesInitialBox) { setCardClasesInitialBox(projectStyles.cardClasesInitialBox); }
      if (projectStyles.cardClasesSecondaryBox) { setCardClasesSecondaryBox(projectStyles.cardClasesSecondaryBox); }
      if (projectStyles.cardClasesTitle) { setCardClasesTitle(projectStyles.cardClasesTitle); }
      if (projectStyles.cardClasesSubtitle) { setCardClasesSubtitle(projectStyles.cardClasesSubtitle); }
      if (projectStyles.cardClasesInitialText) { setCardClasesInitialText(projectStyles.cardClasesInitialText); }
      if (projectStyles.cardClasesSecondaryText) { setCardClasesSecondaryText(projectStyles.cardClasesSecondaryText); }
      if (projectStyles.cardRoundedStyleImage) { setCardRoundedStyleImage(projectStyles.cardRoundedStyleImage); }
      if (projectStyles.cardRoundedStyleInitialBox) { setCardRoundedStyleInitialBox(projectStyles.cardRoundedStyleInitialBox); }
      if (projectStyles.cardRoundedStyleSecondaryBox) { setCardRoundedStyleSecondaryBox(projectStyles.cardRoundedStyleSecondaryBox); }

      if (projectStyles.cardFontTitle) { setCardFontTitle(projectStyles.cardFontTitle); }
      if (projectStyles.cardFontSizeTitle) { setCardFontSizeTitle(projectStyles.cardFontSizeTitle); }

      if (projectStyles.cardFontSubtitle) { setCardFontSubtitle(projectStyles.cardFontSubtitle); }
      if (projectStyles.cardFontSizeSubtitle) { setCardFontSizeSubtitle(projectStyles.cardFontSizeSubtitle); }

      if (projectStyles.cardFontInitialText) { setCardFontInitialText(projectStyles.cardFontInitialText); }

      if (projectStyles.cardFontSecondaryText) { setCardFontSecondaryText(projectStyles.cardFontSecondaryText); }


    } else {
      setDisableButton(false)
    }
  }, [projectStyles])


  useEffect(() => {

    setProjectStylesTMP({
      projectStyles,

      messagePreset,
      messageStartAnimation,
      messageEndAnimation,
      messageAnimationDelayProfile: parseFloat(messageAnimationDelayProfile),
      messageAnimationDelayName: parseFloat(messageAnimationDelayName),
      messageAnimationDelayBody: parseFloat(messageAnimationDelayBody),
      messageAnimationDurationProfile: parseFloat(messageAnimationDurationProfile),
      messageAnimationDurationName: parseFloat(messageAnimationDurationName),
      messageAnimationDurationBody: parseFloat(messageAnimationDurationBody),
      messageRoundedStyleProfile,
      messageRoundedStyleName,
      messageRoundedStyleBody,
      messageColorProfile,
      messageColorName,
      messageColorBody,
      messageColorProfileText,
      messageColorNameText,
      messageColorBodyText,
      messageFontProfileText,
      messageFontSizeProfileText,
      messageFontPaddingTopProfileText,
      messageFontPaddingRightProfileText,
      messageFontPaddingBotProfileText,
      messageFontPaddingLeftProfileText,
      messageFontMarginTopProfileBox,
      messageFontMarginRightProfileBox,
      messageFontMarginBotProfileBox,
      messageFontMarginLeftProfileBox,
      messageFontNameText,
      messageFontSizeNameText,
      messageFontPaddingTopNameText,
      messageFontPaddingRightNameText,
      messageFontPaddingBotNameText,
      messageFontPaddingLeftNameText,
      messageFontMarginTopNameBox,
      messageFontMarginRightNameBox,
      messageFontMarginBotNameBox,
      messageFontMarginLeftNameBox,
      messageFontBodyText,
      messageFontSizeBodyText,
      messageFontPaddingTopBodyText,
      messageFontPaddingRightBodyText,
      messageFontPaddingBotBodyText,
      messageFontPaddingLeftBodyText,
      messageFontMarginTopBodyBox,
      messageFontMarginRightBodyBox,
      messageFontMarginBotBodyBox,
      messageFontMarginLeftBodyBox,
      messageOpacityProfileBox,
      messageOpacityNameBox,
      messageOpacityBodyBox,
      messageClasesProfileText,
      messageClasesProfileBox,
      messageClasesNameText,
      messageClasesNameBox,
      messageClasesBodyText,
      messageClasesBodyBox,


      audioMessageStartAnimation,
      audioMessageEndAnimation,
      audioMessageAnimationDelayProfile: parseFloat(audioMessageAnimationDelayProfile),
      audioMessageAnimationDelayName: parseFloat(audioMessageAnimationDelayName),
      audioMessageAnimationDelayControls: parseFloat(audioMessageAnimationDelayControls),
      audioMessageAnimationDelayInfo: parseFloat(audioMessageAnimationDelayInfo),
      audioMessageAnimationDelayBody: parseFloat(audioMessageAnimationDelayBody),
      audioMessageAnimationDurationProfile: parseFloat(audioMessageAnimationDurationProfile),
      audioMessageAnimationDurationName: parseFloat(audioMessageAnimationDurationName),
      audioMessageAnimationDurationControls: parseFloat(audioMessageAnimationDurationControls),
      audioMessageAnimationDurationInfo: parseFloat(audioMessageAnimationDurationInfo),
      audioMessageAnimationDurationBody: parseFloat(audioMessageAnimationDurationBody),
      audioMessageRoundedStyleProfile,
      audioMessageRoundedStyleBody,
      audioMessageColorBody,
      audioMessageColorNameText,
      audioMessageColorInfoText,
      audioMessageColorBar,
      audioMessageColorDot,
      audioMessageColorTimer,


      cayronPreset,
      cayronStartAnimation,
      cayronEndAnimation,
      cayronAnimationDelayNameBox: parseFloat(cayronAnimationDelayNameBox),
      cayronAnimationDelayName: parseFloat(cayronAnimationDelayName),
      cayronAnimationDelayBusiness: parseFloat(cayronAnimationDelayBusiness),
      cayronAnimationDurationNameBox: parseFloat(cayronAnimationDurationNameBox),
      cayronAnimationDurationName: parseFloat(cayronAnimationDurationName),
      cayronAnimationDurationBusiness: parseFloat(cayronAnimationDurationBusiness),
      cayronRoundedStyleName,
      cayronRoundedStyleBusiness,
      cayronColorName,
      cayronColorBody,
      cayronColorNameText,
      cayronColorBodyText,
      cayronFontNameText,
      cayronFontSizeNameText,
      cayronFontPaddingTopNameText,
      cayronFontPaddingRightNameText,
      cayronFontPaddingBotNameText,
      cayronFontPaddingLeftNameText,
      cayronFontMarginTopNameBox,
      cayronFontMarginRightNameBox,
      cayronFontMarginBotNameBox,
      cayronFontMarginLeftNameBox,
      cayronFontBodyText,
      cayronFontSizeBodyText,
      cayronFontPaddingTopBodyText,
      cayronFontPaddingRightBodyText,
      cayronFontPaddingBotBodyText,
      cayronFontPaddingLeftBodyText,
      cayronFontMarginTopBodyBox,
      cayronFontMarginRightBodyBox,
      cayronFontMarginBotBodyBox,
      cayronFontMarginLeftBodyBox,
      cayronClasesNameText,
      cayronClasesBodyText,
      cayronClasesNameBox,
      cayronClasesBodyBox,


      subtitleStartAnimation,
      subtitleEndAnimation,
      subtitleAnimationDelayBox: parseFloat(subtitleAnimationDelayBox),
      subtitleAnimationDelayText: parseFloat(subtitleAnimationDelayText),
      subtitleAnimationDurationBox: parseFloat(subtitleAnimationDurationBox),
      subtitleAnimationDurationText: parseFloat(subtitleAnimationDurationText),
      subtitleRoundedStyleBox,
      subtitleColorBox,
      subtitleColorText,


      cardPreset,
      cardStartAnimation,
      cardEndAnimation,
      cardAnimationDelayImage,
      cardAnimationDelayInitialBox,
      cardAnimationDelaySecondaryBox,
      cardAnimationDurationImage,
      cardAnimationDurationInitialBox,
      cardAnimationDurationSecondaryBox,
      cardColorInitialBox,
      cardColorSecondaryBox,
      cardColorTitle,
      cardColorSubtitle,
      cardColorInitialText,
      cardColorSecondaryText,
      cardClasesImage,
      cardClasesInitialBox,
      cardClasesSecondaryBox,
      cardClasesTitle,
      cardClasesSubtitle,
      cardClasesInitialText,
      cardClasesSecondaryText,
      cardRoundedStyleImage,
      cardRoundedStyleInitialBox,
      cardRoundedStyleSecondaryBox,

      cardFontTitle,
      cardFontSizeTitle,

      cardFontSubtitle,
      cardFontSizeSubtitle,

      cardFontInitialText,

      cardFontSecondaryText,

    })

    if (projectStyles) {

      if (
          projectStyles.messagePreset != messagePreset || 
          projectStyles.messageStartAnimation != messageStartAnimation || 
          projectStyles.messageEndAnimation != messageEndAnimation || 
          projectStyles.messageAnimationDelayProfile != messageAnimationDelayProfile || 
          projectStyles.messageAnimationDelayName != messageAnimationDelayName || 
          projectStyles.messageAnimationDelayBody != messageAnimationDelayBody || 
          projectStyles.messageAnimationDurationProfile != messageAnimationDurationProfile || 
          projectStyles.messageAnimationDurationName != messageAnimationDurationName || 
          projectStyles.messageAnimationDurationBody != messageAnimationDurationBody || 
          projectStyles.messageRoundedStyleProfile != messageRoundedStyleProfile || 
          projectStyles.messageRoundedStyleName != messageRoundedStyleName || 
          projectStyles.messageRoundedStyleBody != messageRoundedStyleBody || 
          projectStyles.messageColorProfile != messageColorProfile || 
          projectStyles.messageColorName != messageColorName || 
          projectStyles.messageColorBody != messageColorBody || 
          projectStyles.messageColorProfileText != messageColorProfileText ||
          projectStyles.messageColorNameText != messageColorNameText || 
          projectStyles.messageColorBodyText != messageColorBodyText || 
          projectStyles.messageFontProfileText != messageFontProfileText || 
          projectStyles.messageFontSizeProfileText != messageFontSizeProfileText || 
          projectStyles.messageFontPaddingTopProfileText != messageFontPaddingTopProfileText || 
          projectStyles.messageFontPaddingRightProfileText != messageFontPaddingRightProfileText || 
          projectStyles.messageFontPaddingBotProfileText != messageFontPaddingBotProfileText || 
          projectStyles.messageFontPaddingLeftProfileText != messageFontPaddingLeftProfileText || 
          projectStyles.messageFontMarginTopProfileBox != messageFontMarginTopProfileBox || 
          projectStyles.messageFontMarginRightProfileBox != messageFontMarginRightProfileBox || 
          projectStyles.messageFontMarginBotProfileBox != messageFontMarginBotProfileBox || 
          projectStyles.messageFontMarginLeftProfileBox != messageFontMarginLeftProfileBox || 
          projectStyles.messageFontNameText != messageFontNameText || 
          projectStyles.messageFontSizeNameText != messageFontSizeNameText || 
          projectStyles.messageFontPaddingTopNameText != messageFontPaddingTopNameText || 
          projectStyles.messageFontPaddingRightNameText != messageFontPaddingRightNameText || 
          projectStyles.messageFontPaddingBotNameText != messageFontPaddingBotNameText || 
          projectStyles.messageFontPaddingLeftNameText != messageFontPaddingLeftNameText || 
          projectStyles.messageFontMarginTopNameBox != messageFontMarginTopNameBox || 
          projectStyles.messageFontMarginRightNameBox != messageFontMarginRightNameBox || 
          projectStyles.messageFontMarginBotNameBox != messageFontMarginBotNameBox || 
          projectStyles.messageFontMarginLeftNameBox != messageFontMarginLeftNameBox || 
          projectStyles.messageFontBodyText != messageFontBodyText || 
          projectStyles.messageFontSizeBodyText != messageFontSizeBodyText || 
          projectStyles.messageFontPaddingTopBodyText != messageFontPaddingTopBodyText || 
          projectStyles.messageFontPaddingRightBodyText != messageFontPaddingRightBodyText || 
          projectStyles.messageFontPaddingBotBodyText != messageFontPaddingBotBodyText || 
          projectStyles.messageFontPaddingLeftBodyText != messageFontPaddingLeftBodyText || 
          projectStyles.messageFontMarginTopBodyBox != messageFontMarginTopBodyBox || 
          projectStyles.messageFontMarginRightBodyBox != messageFontMarginRightBodyBox || 
          projectStyles.messageFontMarginBotBodyBox != messageFontMarginBotBodyBox || 
          projectStyles.messageFontMarginBotBodyBox != messageFontMarginBotBodyBox || 
          projectStyles.messageFontMarginLeftBodyBox != messageFontMarginLeftBodyBox || 
          projectStyles.messageOpacityProfileBox != messageOpacityProfileBox || 
          projectStyles.messageOpacityNameBox != messageOpacityNameBox || 
          projectStyles.messageOpacityBodyBox != messageOpacityBodyBox || 
          projectStyles.messageClasesProfileText != messageClasesProfileText || 
          projectStyles.messageClasesProfileBox != messageClasesProfileBox || 
          projectStyles.messageClasesNameText != messageClasesNameText || 
          projectStyles.messageClasesNameBox != messageClasesNameBox || 
          projectStyles.messageClasesBodyText != messageClasesBodyText || 
          projectStyles.messageClasesBodyBox != messageClasesBodyBox || 


          projectStyles.audioMessageStartAnimation != audioMessageStartAnimation ||
          projectStyles.audioMessageEndAnimation != audioMessageEndAnimation || 
          projectStyles.audioMessageAnimationDelayProfile != audioMessageAnimationDelayProfile || 
          projectStyles.audioMessageAnimationDelayName != audioMessageAnimationDelayName ||
          projectStyles.audioMessageAnimationDelayControls != audioMessageAnimationDelayControls || 
          projectStyles.audioMessageAnimationDelayInfo != audioMessageAnimationDelayInfo || 
          projectStyles.audioMessageAnimationDelayBody != audioMessageAnimationDelayBody || 
          projectStyles.audioMessageAnimationDurationProfile != audioMessageAnimationDurationProfile || 
          projectStyles.audioMessageAnimationDurationName != audioMessageAnimationDurationName || 
          projectStyles.audioMessageAnimationDurationControls != audioMessageAnimationDurationControls || 
          projectStyles.audioMessageAnimationDurationInfo != audioMessageAnimationDurationInfo || 
          projectStyles.audioMessageAnimationDurationBody != audioMessageAnimationDurationBody || 
          projectStyles.audioMessageRoundedStyleProfile != audioMessageRoundedStyleProfile || 
          projectStyles.audioMessageRoundedStyleBody != audioMessageRoundedStyleBody || 
          projectStyles.audioMessageColorBody != audioMessageColorBody ||
          projectStyles.audioMessageColorNameText != audioMessageColorNameText || 
          projectStyles.audioMessageColorInfoText != audioMessageColorInfoText || 
          projectStyles.audioMessageColorBar != audioMessageColorBar ||
          projectStyles.audioMessageColorDot != audioMessageColorDot || 
          projectStyles.audioMessageColorTimer != audioMessageColorTimer || 


          projectStyles.cayronPreset != cayronPreset || 
          projectStyles.cayronStartAnimation != cayronStartAnimation || 
          projectStyles.cayronEndAnimation != cayronEndAnimation || 
          projectStyles.cayronAnimationDelayNameBox != cayronAnimationDelayNameBox || 
          projectStyles.cayronAnimationDelayName != cayronAnimationDelayName || 
          projectStyles.cayronAnimationDelayBusiness != cayronAnimationDelayBusiness || 
          projectStyles.cayronAnimationDurationNameBox != cayronAnimationDurationNameBox || 
          projectStyles.cayronAnimationDurationName != cayronAnimationDurationName || 
          projectStyles.cayronAnimationDurationBusiness != cayronAnimationDurationBusiness || 
          projectStyles.cayronRoundedStyleName != cayronRoundedStyleName || 
          projectStyles.cayronRoundedStyleBusiness != cayronRoundedStyleBusiness || 
          projectStyles.cayronColorName != cayronColorName || 
          projectStyles.cayronColorBody != cayronColorBody || 
          projectStyles.cayronColorNameText != cayronColorNameText || 
          projectStyles.cayronColorBodyText != cayronColorBodyText || 
          projectStyles.cayronFontNameText != cayronFontNameText || 
          projectStyles.cayronFontSizeNameText != cayronFontSizeNameText || 
          projectStyles.cayronFontPaddingTopNameText != cayronFontPaddingTopNameText || 
          projectStyles.cayronFontPaddingRightNameText != cayronFontPaddingRightNameText || 
          projectStyles.cayronFontPaddingBotNameText != cayronFontPaddingBotNameText || 
          projectStyles.cayronFontPaddingLeftNameText != cayronFontPaddingLeftNameText || 
          projectStyles.cayronFontMarginTopNameBox != cayronFontMarginTopNameBox || 
          projectStyles.cayronFontMarginRightNameBox != cayronFontMarginRightNameBox || 
          projectStyles.cayronFontMarginBotNameBox != cayronFontMarginBotNameBox || 
          projectStyles.cayronFontMarginLeftNameBox != cayronFontMarginLeftNameBox || 
          projectStyles.cayronFontBodyText != cayronFontBodyText || 
          projectStyles.cayronFontSizeBodyText != cayronFontSizeBodyText || 
          projectStyles.cayronFontPaddingTopBodyText != cayronFontPaddingTopBodyText || 
          projectStyles.cayronFontPaddingRightBodyText != cayronFontPaddingRightBodyText || 
          projectStyles.cayronFontPaddingBotBodyText != cayronFontPaddingBotBodyText || 
          projectStyles.cayronFontPaddingLeftBodyText != cayronFontPaddingLeftBodyText || 
          projectStyles.cayronFontMarginTopBodyBox != cayronFontMarginTopBodyBox || 
          projectStyles.cayronFontMarginRightBodyBox != cayronFontMarginRightBodyBox || 
          projectStyles.cayronFontMarginBotBodyBox != cayronFontMarginBotBodyBox || 
          projectStyles.cayronFontMarginLeftBodyBox != cayronFontMarginLeftBodyBox || 
          projectStyles.cayronClasesNameText != cayronClasesNameText || 
          projectStyles.cayronClasesBodyText != cayronClasesBodyText || 
          projectStyles.cayronClasesNameBox != cayronClasesNameBox || 
          projectStyles.cayronClasesBodyBox != cayronClasesBodyBox || 


          projectStyles.subtitleStartAnimation != subtitleStartAnimation || 
          projectStyles.subtitleEndAnimation != subtitleEndAnimation ||
          projectStyles.subtitleAnimationDelayBox != subtitleAnimationDelayBox ||
          projectStyles.subtitleAnimationDelayText != subtitleAnimationDelayText ||
          projectStyles.subtitleAnimationDurationBox != subtitleAnimationDurationBox ||
          projectStyles.subtitleAnimationDurationText != subtitleAnimationDurationText ||
          projectStyles.subtitleRoundedStyleBox != subtitleRoundedStyleBox ||
          projectStyles.subtitleColorBox != subtitleColorBox || 
          projectStyles.subtitleColorText != subtitleColorText ||


          projectStyles.cardPreset != cardPreset ||
          projectStyles.cardStartAnimation != cardStartAnimation ||
          projectStyles.cardEndAnimation != cardEndAnimation ||
          projectStyles.cardAnimationDelayImage != cardAnimationDelayImage ||
          projectStyles.cardAnimationDelayInitialBox != cardAnimationDelayInitialBox ||
          projectStyles.cardAnimationDelaySecondaryBox != cardAnimationDelaySecondaryBox ||
          projectStyles.cardAnimationDurationImage != cardAnimationDurationImage ||
          projectStyles.cardAnimationDurationInitialBox != cardAnimationDurationInitialBox ||
          projectStyles.cardAnimationDurationSecondaryBox != cardAnimationDurationSecondaryBox ||
          projectStyles.cardColorInitialBox != cardColorInitialBox ||
          projectStyles.cardColorSecondaryBox != cardColorSecondaryBox ||
          projectStyles.cardColorTitle != cardColorTitle ||
          projectStyles.cardColorSubtitle != cardColorSubtitle ||
          projectStyles.cardColorInitialText != cardColorInitialText ||
          projectStyles.cardColorSecondaryText != cardColorSecondaryText ||
          projectStyles.cardClasesImage != cardClasesImage || 
          projectStyles.cardClasesInitialBox != cardClasesInitialBox || 
          projectStyles.cardClasesSecondaryBox != cardClasesSecondaryBox || 
          projectStyles.cardClasesTitle != cardClasesTitle || 
          projectStyles.cardClasesSubtitle != cardClasesSubtitle || 
          projectStyles.cardClasesInitialText != cardClasesInitialText || 
          projectStyles.cardClasesSecondaryText != cardClasesSecondaryText || 
          projectStyles.cardRoundedStyleImage != cardRoundedStyleImage || 
          projectStyles.cardRoundedStyleInitialBox != cardRoundedStyleInitialBox || 
          projectStyles.cardRoundedStyleSecondaryBox != cardRoundedStyleSecondaryBox ||

          projectStyles.cardFontTitle != cardFontTitle ||
          projectStyles.cardFontSizeTitle != cardFontSizeTitle ||

          projectStyles.cardFontSubtitle != cardFontSubtitle ||
          projectStyles.cardFontSizeSubtitle != cardFontSizeSubtitle ||

          projectStyles.cardFontInitialText != cardFontInitialText ||

          projectStyles.cardFontSecondaryText != cardFontSecondaryText


        ) { 
        setDisableButton(false) 
      } else {
        setDisableButton(true) 
      }
    }
  }, [
    projectStyles, 

    messagePreset,
    messageStartAnimation,
    messageEndAnimation,
    messageAnimationDelayProfile,
    messageAnimationDelayName,
    messageAnimationDelayBody,
    messageAnimationDurationProfile,
    messageAnimationDurationName,
    messageAnimationDurationBody,
    messageRoundedStyleProfile,
    messageRoundedStyleName,
    messageRoundedStyleBody,
    messageColorProfile,
    messageColorName,
    messageColorBody,
    messageColorProfileText,
    messageColorNameText,
    messageColorBodyText,
    messageFontProfileText,
    messageFontSizeProfileText,
    messageFontPaddingTopProfileText,
    messageFontPaddingRightProfileText,
    messageFontPaddingBotProfileText,
    messageFontPaddingLeftProfileText,
    messageFontMarginTopProfileBox,
    messageFontMarginRightProfileBox,
    messageFontMarginBotProfileBox,
    messageFontMarginLeftProfileBox,
    messageFontNameText,
    messageFontSizeNameText,
    messageFontPaddingTopNameText,
    messageFontPaddingRightNameText,
    messageFontPaddingBotNameText,
    messageFontPaddingLeftNameText,
    messageFontMarginTopNameBox,
    messageFontMarginRightNameBox,
    messageFontMarginBotNameBox,
    messageFontMarginLeftNameBox,
    messageFontBodyText,
    messageFontSizeBodyText,
    messageFontPaddingTopBodyText,
    messageFontPaddingRightBodyText,
    messageFontPaddingBotBodyText,
    messageFontPaddingLeftBodyText,
    messageFontMarginTopBodyBox,
    messageFontMarginRightBodyBox,
    messageFontMarginBotBodyBox,
    messageOpacityProfileBox,
    messageOpacityNameBox,
    messageOpacityBodyBox,
    messageFontMarginLeftBodyBox,
    messageClasesProfileText,
    messageClasesProfileBox,
    messageClasesNameText,
    messageClasesNameBox,
    messageClasesBodyText,
    messageClasesBodyBox,


    audioMessageStartAnimation,
    audioMessageEndAnimation,
    audioMessageAnimationDelayProfile,
    audioMessageAnimationDelayName,
    audioMessageAnimationDelayControls,
    audioMessageAnimationDelayInfo,
    audioMessageAnimationDelayBody,
    audioMessageAnimationDurationProfile,
    audioMessageAnimationDurationName,
    audioMessageAnimationDurationControls,
    audioMessageAnimationDurationInfo,
    audioMessageAnimationDurationBody,
    audioMessageRoundedStyleProfile,
    audioMessageRoundedStyleBody,
    audioMessageColorBody,
    audioMessageColorNameText,
    audioMessageColorInfoText,
    audioMessageColorBar,
    audioMessageColorDot,
    audioMessageColorTimer,


    cayronPreset,
    cayronStartAnimation,
    cayronEndAnimation,
    cayronAnimationDelayNameBox,
    cayronAnimationDelayName,
    cayronAnimationDelayBusiness,
    cayronAnimationDurationNameBox,
    cayronAnimationDurationName,
    cayronAnimationDurationBusiness,
    cayronRoundedStyleName,
    cayronRoundedStyleBusiness,
    cayronColorName,
    cayronColorBody,
    cayronColorNameText,
    cayronColorBodyText,
    cayronFontNameText,
    cayronFontSizeNameText,
    cayronFontPaddingTopNameText,
    cayronFontPaddingRightNameText,
    cayronFontPaddingBotNameText,
    cayronFontPaddingLeftNameText,
    cayronFontMarginTopNameBox,
    cayronFontMarginRightNameBox,
    cayronFontMarginBotNameBox,
    cayronFontMarginLeftNameBox,
    cayronFontBodyText,
    cayronFontSizeBodyText,
    cayronFontPaddingTopBodyText,
    cayronFontPaddingRightBodyText,
    cayronFontPaddingBotBodyText,
    cayronFontPaddingLeftBodyText,
    cayronFontMarginTopBodyBox,
    cayronFontMarginRightBodyBox,
    cayronFontMarginBotBodyBox,
    cayronFontMarginLeftBodyBox,
    cayronClasesNameText,
    cayronClasesBodyText,
    cayronClasesNameBox,
    cayronClasesBodyBox,


    subtitleStartAnimation,
    subtitleEndAnimation,
    subtitleAnimationDelayBox,
    subtitleAnimationDelayText,
    subtitleAnimationDurationBox,
    subtitleAnimationDurationText,
    subtitleRoundedStyleBox,
    subtitleColorBox,
    subtitleColorText,


    cardPreset,
    cardStartAnimation,
    cardEndAnimation,
    cardAnimationDelayImage,
    cardAnimationDelayInitialBox,
    cardAnimationDelaySecondaryBox,
    cardAnimationDurationImage,
    cardAnimationDurationInitialBox,
    cardAnimationDurationSecondaryBox,
    cardColorInitialBox,
    cardColorSecondaryBox,
    cardColorTitle,
    cardColorSubtitle,
    cardColorInitialText,
    cardColorSecondaryText,
    cardClasesImage,
    cardClasesInitialBox,
    cardClasesSecondaryBox,
    cardClasesTitle,
    cardClasesSubtitle,
    cardClasesInitialText,
    cardClasesSecondaryText,
    cardRoundedStyleImage,
    cardRoundedStyleInitialBox,
    cardRoundedStyleSecondaryBox,

    cardFontTitle,
    cardFontSizeTitle,

    cardFontSubtitle,
    cardFontSizeSubtitle,

    cardFontInitialText,

    cardFontSecondaryText,


  ])


  function saveConfig() {
    setDisableButton(true)

    var tmp = {
      messagePreset,
      messageStartAnimation,
      messageEndAnimation,
      messageAnimationDelayProfile: parseFloat(messageAnimationDelayProfile),
      messageAnimationDelayName: parseFloat(messageAnimationDelayName),
      messageAnimationDelayBody: parseFloat(messageAnimationDelayBody),
      messageAnimationDurationProfile: parseFloat(messageAnimationDurationProfile),
      messageAnimationDurationName: parseFloat(messageAnimationDurationName),
      messageAnimationDurationBody: parseFloat(messageAnimationDurationBody),
      messageRoundedStyleProfile,
      messageRoundedStyleName,
      messageRoundedStyleBody,
      messageColorProfile,
      messageColorName,
      messageColorBody,
      messageColorProfileText,
      messageColorNameText,
      messageColorBodyText,
      messageFontProfileText,
      messageFontNameText,
      messageFontSizeNameText,
      messageFontPaddingTopNameText,
      messageFontPaddingRightNameText,
      messageFontPaddingBotNameText,
      messageFontPaddingLeftNameText,
      messageFontMarginTopNameBox,
      messageFontMarginRightNameBox,
      messageFontMarginBotNameBox,
      messageFontMarginLeftNameBox,
      messageFontSizeProfileText,
      messageFontPaddingTopProfileText,
      messageFontPaddingRightProfileText,
      messageFontPaddingBotProfileText,
      messageFontPaddingLeftProfileText,
      messageFontMarginTopProfileBox,
      messageFontMarginRightProfileBox,
      messageFontMarginBotProfileBox,
      messageFontMarginLeftProfileBox,
      messageFontBodyText,
      messageFontSizeBodyText,
      messageFontPaddingTopBodyText,
      messageFontPaddingRightBodyText,
      messageFontPaddingBotBodyText,
      messageFontPaddingLeftBodyText,
      messageFontMarginTopBodyBox,
      messageFontMarginRightBodyBox,
      messageFontMarginBotBodyBox,
      messageFontMarginLeftBodyBox,
      messageOpacityProfileBox,
      messageOpacityNameBox,
      messageOpacityBodyBox,
      messageClasesProfileText,
      messageClasesProfileBox,
      messageClasesNameText,
      messageClasesNameBox,
      messageClasesBodyText,
      messageClasesBodyBox,


      audioMessageStartAnimation,
      audioMessageEndAnimation,
      audioMessageAnimationDelayProfile: parseFloat(audioMessageAnimationDelayProfile),
      audioMessageAnimationDelayName: parseFloat(audioMessageAnimationDelayName),
      audioMessageAnimationDelayControls: parseFloat(audioMessageAnimationDelayControls),
      audioMessageAnimationDelayInfo: parseFloat(audioMessageAnimationDelayInfo),
      audioMessageAnimationDelayBody: parseFloat(audioMessageAnimationDelayBody),
      audioMessageAnimationDurationProfile: parseFloat(audioMessageAnimationDurationProfile),
      audioMessageAnimationDurationName: parseFloat(audioMessageAnimationDurationName),
      audioMessageAnimationDurationControls: parseFloat(audioMessageAnimationDurationControls),
      audioMessageAnimationDurationInfo: parseFloat(audioMessageAnimationDurationInfo),
      audioMessageAnimationDurationBody: parseFloat(audioMessageAnimationDurationBody),
      audioMessageRoundedStyleProfile,
      audioMessageRoundedStyleBody,
      audioMessageColorBody,
      audioMessageColorNameText,
      audioMessageColorInfoText,
      audioMessageColorBar,
      audioMessageColorDot,
      audioMessageColorTimer,


      cayronPreset,
      cayronStartAnimation,
      cayronEndAnimation,
      cayronAnimationDelayNameBox: parseFloat(cayronAnimationDelayNameBox),
      cayronAnimationDelayName: parseFloat(cayronAnimationDelayName),
      cayronAnimationDelayBusiness: parseFloat(cayronAnimationDelayBusiness),
      cayronAnimationDurationNameBox: parseFloat(cayronAnimationDurationNameBox),
      cayronAnimationDurationName: parseFloat(cayronAnimationDurationName),
      cayronAnimationDurationBusiness: parseFloat(cayronAnimationDurationBusiness),
      cayronRoundedStyleName,
      cayronRoundedStyleBusiness,
      cayronColorName,
      cayronColorBody,
      cayronColorNameText,
      cayronColorBodyText,
      cayronFontNameText,
      cayronFontSizeNameText,
      cayronFontPaddingTopNameText,
      cayronFontPaddingRightNameText,
      cayronFontPaddingBotNameText,
      cayronFontPaddingLeftNameText,
      cayronFontMarginTopNameBox,
      cayronFontMarginRightNameBox,
      cayronFontMarginBotNameBox,
      cayronFontMarginLeftNameBox,
      cayronFontBodyText,
      cayronFontSizeBodyText,
      cayronFontPaddingTopBodyText,
      cayronFontPaddingRightBodyText,
      cayronFontPaddingBotBodyText,
      cayronFontPaddingLeftBodyText,
      cayronFontMarginTopBodyBox,
      cayronFontMarginRightBodyBox,
      cayronFontMarginBotBodyBox,
      cayronFontMarginLeftBodyBox,
      cayronClasesNameText,
      cayronClasesBodyText,
      cayronClasesNameBox,
      cayronClasesBodyBox,


      subtitleStartAnimation,
      subtitleEndAnimation,
      subtitleAnimationDelayBox: parseFloat(subtitleAnimationDelayBox),
      subtitleAnimationDelayText: parseFloat(subtitleAnimationDelayText),
      subtitleAnimationDurationBox: parseFloat(subtitleAnimationDurationBox),
      subtitleAnimationDurationText: parseFloat(subtitleAnimationDurationText),
      subtitleRoundedStyleBox,
      subtitleColorBox,
      subtitleColorText,


      cardPreset,
      cardStartAnimation,
      cardEndAnimation,
      cardAnimationDelayImage: parseFloat(cardAnimationDelayImage),
      cardAnimationDelayInitialBox: parseFloat(cardAnimationDelayInitialBox),
      cardAnimationDelaySecondaryBox: parseFloat(cardAnimationDelaySecondaryBox),
      cardAnimationDurationImage: parseFloat(cardAnimationDurationImage),
      cardAnimationDurationInitialBox: parseFloat(cardAnimationDurationInitialBox),
      cardAnimationDurationSecondaryBox: parseFloat(cardAnimationDurationSecondaryBox),
      cardColorInitialBox,
      cardColorSecondaryBox,
      cardColorTitle,
      cardColorSubtitle,
      cardColorInitialText,
      cardColorSecondaryText,
      cardClasesImage,
      cardClasesInitialBox,
      cardClasesSecondaryBox,
      cardClasesTitle,
      cardClasesSubtitle,
      cardClasesInitialText,
      cardClasesSecondaryText,
      cardRoundedStyleImage,
      cardRoundedStyleInitialBox,
      cardRoundedStyleSecondaryBox,

      cardFontTitle,
      cardFontSizeTitle,

      cardFontSubtitle,
      cardFontSizeSubtitle,

      cardFontInitialText,

      cardFontSecondaryText,


    }


    setProjectStyles(tmp);
    
    setProjectStylePresets({
      messageStartAnimation,
      messageEndAnimation,
      messageAnimationDelayProfile: parseFloat(messageAnimationDelayProfile),
      messageAnimationDelayName: parseFloat(messageAnimationDelayName),
      messageAnimationDelayBody: parseFloat(messageAnimationDelayBody),
      messageAnimationDurationProfile: parseFloat(messageAnimationDurationProfile),
      messageAnimationDurationName: parseFloat(messageAnimationDurationName),
      messageAnimationDurationBody: parseFloat(messageAnimationDurationBody),
      messageRoundedStyleProfile,
      messageRoundedStyleName,
      messageRoundedStyleBody,
      messageColorProfile,
      messageColorName,
      messageColorBody,
      messageColorProfileText,
      messageColorNameText,
      messageColorBodyText,
      messageFontProfileText,
      messageFontSizeProfileText,
      messageFontPaddingTopProfileText,
      messageFontPaddingRightProfileText,
      messageFontPaddingBotProfileText,
      messageFontPaddingLeftProfileText,
      messageFontMarginTopProfileBox,
      messageFontMarginRightProfileBox,
      messageFontMarginBotProfileBox,
      messageFontMarginLeftProfileBox,
      messageFontNameText,
      messageFontSizeNameText,
      messageFontPaddingTopNameText,
      messageFontPaddingRightNameText,
      messageFontPaddingBotNameText,
      messageFontPaddingLeftNameText,
      messageFontMarginTopNameBox,
      messageFontMarginRightNameBox,
      messageFontMarginBotNameBox,
      messageFontMarginLeftNameBox,
      messageFontBodyText,
      messageFontSizeBodyText,
      messageFontPaddingTopBodyText,
      messageFontPaddingRightBodyText,
      messageFontPaddingBotBodyText,
      messageFontPaddingLeftBodyText,
      messageFontMarginTopBodyBox,
      messageFontMarginRightBodyBox,
      messageFontMarginBotBodyBox,
      messageFontMarginLeftBodyBox,
      messageOpacityProfileBox,
      messageOpacityNameBox,
      messageOpacityBodyBox,
      messageClasesProfileText,
      messageClasesProfileBox,
      messageClasesNameText,
      messageClasesNameBox,
      messageClasesBodyText,
      messageClasesBodyBox,
    }, messagePreset);

    setProjectStylePresets({
      cayronStartAnimation,
      cayronEndAnimation,
      cayronAnimationDelayNameBox: parseFloat(cayronAnimationDelayNameBox),
      cayronAnimationDelayName: parseFloat(cayronAnimationDelayName),
      cayronAnimationDelayBusiness: parseFloat(cayronAnimationDelayBusiness),
      cayronAnimationDurationNameBox: parseFloat(cayronAnimationDurationNameBox),
      cayronAnimationDurationName: parseFloat(cayronAnimationDurationName),
      cayronAnimationDurationBusiness: parseFloat(cayronAnimationDurationBusiness),
      cayronRoundedStyleName,
      cayronRoundedStyleBusiness,
      cayronColorName,
      cayronColorBody,
      cayronColorNameText,
      cayronColorBodyText,
      cayronFontNameText,
      cayronFontSizeNameText,
      cayronFontPaddingTopNameText,
      cayronFontPaddingRightNameText,
      cayronFontPaddingBotNameText,
      cayronFontPaddingLeftNameText,
      cayronFontMarginTopNameBox,
      cayronFontMarginRightNameBox,
      cayronFontMarginBotNameBox,
      cayronFontMarginLeftNameBox,
      cayronFontBodyText,
      cayronFontSizeBodyText,
      cayronFontPaddingTopBodyText,
      cayronFontPaddingRightBodyText,
      cayronFontPaddingBotBodyText,
      cayronFontPaddingLeftBodyText,
      cayronFontMarginTopBodyBox,
      cayronFontMarginRightBodyBox,
      cayronFontMarginBotBodyBox,
      cayronFontMarginLeftBodyBox,
      cayronClasesNameText,
      cayronClasesBodyText,
      cayronClasesNameBox,
      cayronClasesBodyBox,
    }, cayronPreset);


    setProjectStylePresets({
      cardStartAnimation,
      cardEndAnimation,
      cardAnimationDelayImage: parseFloat(cardAnimationDelayImage),
      cardAnimationDelayInitialBox: parseFloat(cardAnimationDelayInitialBox),
      cardAnimationDelaySecondaryBox: parseFloat(cardAnimationDelaySecondaryBox),
      cardAnimationDurationImage: parseFloat(cardAnimationDurationImage),
      cardAnimationDurationInitialBox: parseFloat(cardAnimationDurationInitialBox),
      cardAnimationDurationSecondaryBox: parseFloat(cardAnimationDurationSecondaryBox),
      cardColorInitialBox,
      cardColorSecondaryBox,
      cardColorTitle,
      cardColorSubtitle,
      cardColorInitialText,
      cardColorSecondaryText,
      cardClasesImage,
      cardClasesInitialBox,
      cardClasesSecondaryBox,
      cardClasesTitle,
      cardClasesSubtitle,
      cardClasesInitialText,
      cardClasesSecondaryText,
      cardRoundedStyleImage,
      cardRoundedStyleInitialBox,
      cardRoundedStyleSecondaryBox,

      cardFontTitle,
      cardFontSizeTitle,

      cardFontSubtitle,
      cardFontSizeSubtitle,

      cardFontInitialText,

      cardFontSecondaryText,
    }, cardPreset);

  }


  useEffect(() => {
    if (!(projectStyles === null) && (isEmpty(projectStyles) || projectStyles === undefined)) {
      saveConfig()
    }
  }, [projectStyles]);


  function restoreAllConfigs() {
    restoreMessageConfig()
    restoreAudioMessageConfig()
    restoreCayronConfig()
    restoreSubtitleConfig()
    restoreCardConfig()
  }


  /* Message Config */
  function restoreMessageConfig() {
    /* console.log(stylePresets) */

    if (stylePresets && messagePreset && stylePresets[messagePreset] && stylePresets[messagePreset] && stylePresets[messagePreset].projectStyles) {
      var stylesTMP = stylePresets[messagePreset].projectStyles;

      if (stylesTMP.messagePreset) {setMessagePreset(stylesTMP.messagePreset);}

      if (stylesTMP.messageStartAnimation) {setMessageStartAnimation(stylesTMP.messageStartAnimation);}
      if (stylesTMP.messageEndAnimation) {setMessageEndAnimation(stylesTMP.messageEndAnimation);}
  
      if (stylesTMP.messageRoundedStyleProfile) {setMessageRoundedStyleProfile(stylesTMP.messageRoundedStyleProfile);}
      if (stylesTMP.messageRoundedStyleName) {setMessageRoundedStyleName(stylesTMP.messageRoundedStyleName);}
      if (stylesTMP.messageRoundedStyleBody) {setMessageRoundedStyleBody(stylesTMP.messageRoundedStyleBody);}

      if (!isNaN(stylesTMP.messageAnimationDelayProfile)) {setMessageAnimationDelayProfile(stylesTMP.messageAnimationDelayProfile);}
      if (!isNaN(stylesTMP.messageAnimationDelayName)) {setMessageAnimationDelayName(stylesTMP.messageAnimationDelayName);}
      if (!isNaN(stylesTMP.messageAnimationDelayBody)) {setMessageAnimationDelayBody(stylesTMP.messageAnimationDelayBody);}
  
      if (!isNaN(stylesTMP.messageAnimationDurationProfile)) {setMessageAnimationDurationProfile(stylesTMP.messageAnimationDurationProfile);}
      if (!isNaN(stylesTMP.messageAnimationDurationName)) {setMessageAnimationDurationName(stylesTMP.messageAnimationDurationName);}
      if (!isNaN(stylesTMP.messageAnimationDurationBody)) {setMessageAnimationDurationBody(stylesTMP.messageAnimationDurationBody);}
  
      if (stylesTMP.messageColorProfile) {setMessageColorProfile(stylesTMP.messageColorProfile);}
      if (stylesTMP.messageColorName) {setMessageColorName(stylesTMP.messageColorName);}
      if (stylesTMP.messageColorBody) {setMessageColorBody(stylesTMP.messageColorBody);}
      if (stylesTMP.messageColorProfileText) {setMessageColorProfileText(stylesTMP.messageColorProfileText);}
      if (stylesTMP.messageColorNameText) {setMessageColorNameText(stylesTMP.messageColorNameText);}
      if (stylesTMP.messageColorBodyText) {setMessageColorBodyText(stylesTMP.messageColorBodyText);}

      if (stylesTMP.messageFontProfileText) {setMessageFontProfileText(stylesTMP.messageFontProfileText);}
      if (stylesTMP.messageFontSizeProfileText) {setMessageFontSizeProfileText(stylesTMP.messageFontSizeProfileText);}
      if (stylesTMP.messageFontPaddingTopProfileText) {setMessageFontPaddingTopProfileText(stylesTMP.messageFontPaddingTopProfileText);}
      if (stylesTMP.messageFontPaddingRightProfileText) {setMessageFontPaddingRightProfileText(stylesTMP.messageFontPaddingRightProfileText);}
      if (stylesTMP.messageFontPaddingBotProfileText) {setMessageFontPaddingBotProfileText(stylesTMP.messageFontPaddingBotProfileText);}
      if (stylesTMP.messageFontPaddingLeftProfileText) {setMessageFontPaddingLeftProfileText(stylesTMP.messageFontPaddingLeftProfileText);}

      if (stylesTMP.messageFontMarginTopProfileBox) {setMessageFontMarginTopProfileBox(stylesTMP.messageFontMarginTopProfileBox);}
      if (stylesTMP.messageFontMarginRightProfileBox) {setMessageFontMarginRightProfileBox(stylesTMP.messageFontMarginRightProfileBox);}
      if (stylesTMP.messageFontMarginBotProfileBox) {setMessageFontMarginBotProfileBox(stylesTMP.messageFontMarginBotProfileBox);}
      if (stylesTMP.messageFontMarginLeftProfileBox) {setMessageFontMarginLeftProfileBox(stylesTMP.messageFontMarginLeftProfileBox);}

      if (stylesTMP.messageFontNameText) {setMessageFontNameText(stylesTMP.messageFontNameText);}
      if (stylesTMP.messageFontSizeNameText) {setMessageFontSizeNameText(stylesTMP.messageFontSizeNameText);}
      if (stylesTMP.messageFontPaddingTopNameText) {setMessageFontPaddingTopNameText(stylesTMP.messageFontPaddingTopNameText);}
      if (stylesTMP.messageFontPaddingRightNameText) {setMessageFontPaddingRightNameText(stylesTMP.messageFontPaddingRightNameText);}
      if (stylesTMP.messageFontPaddingBotNameText) {setMessageFontPaddingBotNameText(stylesTMP.messageFontPaddingBotNameText);}
      if (stylesTMP.messageFontPaddingLeftNameText) {setMessageFontPaddingLeftNameText(stylesTMP.messageFontPaddingLeftNameText);}
      if (stylesTMP.messageFontMarginTopNameBox) {setMessageFontMarginTopNameBox(stylesTMP.messageFontMarginTopNameBox);}
      if (stylesTMP.messageFontMarginTopNameBox) {setMessageFontMarginRightNameBox(stylesTMP.messageFontMarginTopNameBox);}
      if (stylesTMP.messageFontMarginBotNameBox) {setMessageFontMarginBotNameBox(stylesTMP.messageFontMarginBotNameBox);}
      if (stylesTMP.messageFontMarginLeftNameBox) {setMessageFontMarginLeftNameBox(stylesTMP.messageFontMarginLeftNameBox);}

      if (stylesTMP.messageFontBodyText) {setMessageFontBodyText(stylesTMP.messageFontBodyText);}
      if (stylesTMP.messageFontSizeBodyText) {setMessageFontSizeBodyText(stylesTMP.messageFontSizeBodyText);}
      if (stylesTMP.messageFontPaddingTopBodyText) {setMessageFontPaddingTopBodyText(stylesTMP.messageFontPaddingTopBodyText);}
      if (stylesTMP.messageFontPaddingRightBodyText) {setMessageFontPaddingRightBodyText(stylesTMP.messageFontPaddingRightBodyText);}
      if (stylesTMP.messageFontPaddingBotBodyText) {setMessageFontPaddingBotBodyText(stylesTMP.messageFontPaddingBotBodyText);}
      if (stylesTMP.messageFontPaddingLeftBodyText) {setMessageFontPaddingLeftBodyText(stylesTMP.messageFontPaddingLeftBodyText);}
      if (stylesTMP.messageFontMarginTopBodyBox) {setMessageFontMarginTopBodyBox(stylesTMP.messageFontMarginTopBodyBox);}
      if (stylesTMP.messageFontMarginRightBodyBox) {setMessageFontMarginRightBodyBox(stylesTMP.messageFontMarginRightBodyBox);}
      if (stylesTMP.messageFontMarginBotBodyBox) {setMessageFontMarginBotBodyBox(stylesTMP.messageFontMarginBotBodyBox);}
      if (stylesTMP.messageFontMarginLeftBodyBox) {setMessageFontMarginLeftBodyBox(stylesTMP.messageFontMarginLeftBodyBox);}

      if (stylesTMP.messageOpacityProfileBox) {setMessageOpacityProfileBox(stylesTMP.messageOpacityProfileBox);}
      if (stylesTMP.messageOpacityNameBox) {setMessageOpacityNameBox(stylesTMP.messageOpacityNameBox);}
      if (stylesTMP.messageOpacityBodyBox) {setMessageOpacityBodyBox(stylesTMP.messageOpacityBodyBox);}

      if (stylesTMP.messageClasesProfileText) {setMessageClasesProfileText(stylesTMP.messageClasesProfileText);}
      if (stylesTMP.messageClasesProfileBox) {setMessageClasesProfileBox(stylesTMP.messageClasesProfileBox);}
      if (stylesTMP.messageClasesNameText) {setMessageClasesNameText(stylesTMP.messageClasesNameText);}
      if (stylesTMP.messageClasesNameBox) {setMessageClasesNameBox(stylesTMP.messageClasesNameBox);}
      if (stylesTMP.messageClasesBodyText) {setMessageClasesBodyText(stylesTMP.messageClasesBodyText);}
      if (stylesTMP.messageClasesBodyBox) {setMessageClasesBodyBox(stylesTMP.messageClasesBodyBox);}

    } else {

      /* setMessagePreset('default'); */
      setMessageStartAnimation('preset-1');
      setMessageEndAnimation('preset-1');

      setMessageRoundedStyleProfile('');
      setMessageRoundedStyleName('');
      setMessageRoundedStyleBody('');

      setMessageAnimationDelayProfile(0.0);
      setMessageAnimationDelayName(0.0);
      setMessageAnimationDelayBody(0.0);
      
      setMessageAnimationDurationProfile(0.5);
      setMessageAnimationDurationName(0.5);
      setMessageAnimationDurationBody(0.5);

      setMessageColorProfile('#10B981');
      setMessageColorName('#18181B');
      setMessageColorBody('#F4F4F5');
      setMessageColorProfileText('#F4F4F5');
      setMessageColorNameText('#F4F4F5');
      setMessageColorBodyText('#52525B');

      setMessageFontProfileText('');
      setMessageFontSizeProfileText('text-5xl');
      setMessageFontPaddingTopProfileText('pt-0');
      setMessageFontPaddingRightProfileText('pr-0');
      setMessageFontPaddingBotProfileText('pb-0');
      setMessageFontPaddingLeftProfileText('pl-0');

      setMessageFontMarginTopProfileBox('-mt-5');
      setMessageFontMarginRightProfileBox('mr-0');
      setMessageFontMarginBotProfileBox('mb-0');
      setMessageFontMarginLeftProfileBox('ml-0');

      setMessageFontNameText('');
      setMessageFontSizeNameText('text-4xl');
      setMessageFontPaddingTopNameText('pt-2');
      setMessageFontPaddingRightNameText('pr-5');
      setMessageFontPaddingBotNameText('pb-2');
      setMessageFontPaddingLeftNameText('pl-3');
      setMessageFontMarginTopNameBox('mt-0');
      setMessageFontMarginRightNameBox('mr-0');
      setMessageFontMarginBotNameBox('mb-0');
      setMessageFontMarginLeftNameBox('ml-0');

      setMessageFontBodyText('');
      setMessageFontSizeBodyText('text-4xl');
      setMessageFontPaddingTopBodyText('pt-2');
      setMessageFontPaddingRightBodyText('pr-4');
      setMessageFontPaddingBotBodyText('pb-2'); /* 2.5? */
      setMessageFontPaddingLeftBodyText('pl-4');
      setMessageFontMarginTopBodyBox('mt-0');
      setMessageFontMarginRightBodyBox('mr-0');
      setMessageFontMarginBotBodyBox('mb-0');
      setMessageFontMarginLeftBodyBox('ml-0');

      setMessageOpacityProfileBox('opacity-100');
      setMessageOpacityNameBox('opacity-100');
      setMessageOpacityBodyBox('opacity-100');
      
    }
  }


  useEffect(() => {
    restoreMessageConfig()
  }, [messagePreset]);

  
  /* Audio Message Config */
  function restoreAudioMessageConfig() {
    setAudioMessageStartAnimation('preset-1');
    setAudioMessageEndAnimation('preset-1');

    setAudioMessageAnimationDelayProfile(0.0);
    setAudioMessageAnimationDelayName(0.3);
    setAudioMessageAnimationDelayControls(0.7);
    setAudioMessageAnimationDelayInfo(0.5);
    setAudioMessageAnimationDelayBody(0.0);

    setAudioMessageAnimationDurationProfile(0.5);
    setAudioMessageAnimationDurationName(0.5);
    setAudioMessageAnimationDurationControls(0.5);
    setAudioMessageAnimationDurationInfo(0.5);
    setAudioMessageAnimationDurationBody(0.5);

    setAudioMessageRoundedStyleProfile('rounded-full');
    setAudioMessageRoundedStyleBody('rounded-3xl');

    setAudioMessageColorBody('#F4F4F5');
    setAudioMessageColorNameText('#19191B');
    setAudioMessageColorInfoText('#19191B');
    setAudioMessageColorBar('#52525B');
    setAudioMessageColorDot('#18181B');
    setAudioMessageColorTimer('#A1A1AA');
  }


  /* Cayron Config */
  function restoreCayronConfig() {
    if (stylePresets && cayronPreset && stylePresets[cayronPreset] && stylePresets[cayronPreset] && stylePresets[cayronPreset].projectStyles) {
      var stylesTMP = stylePresets[cayronPreset].projectStyles;
      
      if (stylesTMP.cayronPreset) {setCayronPreset(stylesTMP.cayronPreset);}

      if (stylesTMP.cayronStartAnimation) {setCayronStartAnimation(stylesTMP.cayronStartAnimation);}
      if (stylesTMP.cayronEndAnimation) {setCayronEndAnimation(stylesTMP.cayronEndAnimation);}
  
      if (!isNaN(stylesTMP.cayronAnimationDelayNameBox)) {setCayronAnimationDelayNameBox(stylesTMP.cayronAnimationDelayNameBox);}
      if (!isNaN(stylesTMP.cayronAnimationDelayName)) {setCayronAnimationDelayName(stylesTMP.cayronAnimationDelayName);}
      if (!isNaN(stylesTMP.cayronAnimationDelayBusiness)) {setCayronAnimationDelayBusiness(stylesTMP.cayronAnimationDelayBusiness);}
  
      if (!isNaN(stylesTMP.cayronAnimationDurationNameBox)) {setCayronAnimationDurationNameBox(stylesTMP.cayronAnimationDurationNameBox);}
      if (!isNaN(stylesTMP.cayronAnimationDurationName)) {setCayronAnimationDurationName(stylesTMP.cayronAnimationDurationName);}
      if (!isNaN(stylesTMP.cayronAnimationDurationBusiness)) {setCayronAnimationDurationBusiness(stylesTMP.cayronAnimationDurationBusiness);}
  
      if (stylesTMP.cayronRoundedStyleName) {setCayronRoundedStyleName(stylesTMP.cayronRoundedStyleName);}
      if (stylesTMP.cayronRoundedStyleBusiness) {setCayronRoundedStyleBusiness(stylesTMP.cayronRoundedStyleBusiness);}
  
      if (stylesTMP.cayronColorName) {setCayronColorName(stylesTMP.cayronColorName);}
      if (stylesTMP.cayronColorBody) {setCayronColorBody(stylesTMP.cayronColorBody);}
      if (stylesTMP.cayronColorNameText) {setCayronColorNameText(stylesTMP.cayronColorNameText);}
      if (stylesTMP.cayronColorBodyText) {setCayronColorBodyText(stylesTMP.cayronColorBodyText);}

      if (stylesTMP.cayronFontNameText) {setCayronFontNameText(stylesTMP.cayronFontNameText);}
      if (stylesTMP.cayronFontSizeNameText) {setCayronFontSizeNameText(stylesTMP.cayronFontSizeNameText);}
      if (stylesTMP.cayronFontPaddingTopNameText) {setCayronFontPaddingTopNameText(stylesTMP.cayronFontPaddingTopNameText);}
      if (stylesTMP.cayronFontPaddingRightNameText) {setCayronFontPaddingRightNameText(stylesTMP.cayronFontPaddingRightNameText);}
      if (stylesTMP.cayronFontPaddingBotNameText) {setCayronFontPaddingBotNameText(stylesTMP.cayronFontPaddingBotNameText);}
      if (stylesTMP.cayronFontPaddingLeftNameText) {setCayronFontPaddingLeftNameText(stylesTMP.cayronFontPaddingLeftNameText);}

      if (stylesTMP.cayronFontMarginTopNameBox) {setCayronFontMarginTopNameBox(stylesTMP.cayronFontMarginTopNameBox);}
      if (stylesTMP.cayronFontMarginRightNameBox) {setCayronFontMarginRightNameBox(stylesTMP.cayronFontMarginRightNameBox);}
      if (stylesTMP.cayronFontMarginBotNameBox) {setCayronFontMarginBotNameBox(stylesTMP.cayronFontMarginBotNameBox);}
      if (stylesTMP.cayronFontMarginLeftNameBox) {setCayronFontMarginLeftNameBox(stylesTMP.cayronFontMarginLeftNameBox);}

      if (stylesTMP.cayronFontBodyText) {setCayronFontBodyText(stylesTMP.cayronFontBodyText);}
      if (stylesTMP.cayronFontSizeBodyText) {setCayronFontSizeBodyText(stylesTMP.cayronFontSizeBodyText);}
      if (stylesTMP.cayronFontPaddingTopBodyText) {setCayronFontPaddingTopBodyText(stylesTMP.cayronFontPaddingTopBodyText);}
      if (stylesTMP.cayronFontPaddingRightBodyText) {setCayronFontPaddingRightBodyText(stylesTMP.cayronFontPaddingRightBodyText);}
      if (stylesTMP.cayronFontPaddingBotBodyText) {setCayronFontPaddingBotBodyText(stylesTMP.cayronFontPaddingBotBodyText);}
      if (stylesTMP.cayronFontPaddingLeftBodyText) {setCayronFontPaddingLeftBodyText(stylesTMP.cayronFontPaddingLeftBodyText);}

      if (stylesTMP.cayronFontMarginTopBodyBox) {setCayronFontMarginTopBodyBox(stylesTMP.cayronFontMarginTopBodyBox);}
      if (stylesTMP.cayronFontMarginRightBodyBox) {setCayronFontMarginRightBodyBox(stylesTMP.cayronFontMarginRightBodyBox);}
      if (stylesTMP.cayronFontMarginBotBodyBox) {setCayronFontMarginBotBodyBox(stylesTMP.cayronFontMarginBotBodyBox);}
      if (stylesTMP.cayronFontMarginLeftBodyBox) {setCayronFontMarginLeftBodyBox(stylesTMP.cayronFontMarginLeftBodyBox);}

      if (stylesTMP.cayronClasesNameText) {setCayronClasesNameText(stylesTMP.cayronClasesNameText);}
      if (stylesTMP.cayronClasesBodyText) {setCayronClasesBodyText(stylesTMP.cayronClasesBodyText);}
      if (stylesTMP.cayronClasesNameBox) {setCayronClasesNameBox(stylesTMP.cayronClasesNameBox);}
      if (stylesTMP.cayronClasesBodyBox) {setCayronClasesBodyBox(stylesTMP.cayronClasesBodyBox);}

    } else {
      /* setCayronPreset('default'); */

      setCayronStartAnimation('preset-1');
      setCayronEndAnimation('preset-1');
  
      setCayronAnimationDelayNameBox(0.0);
      setCayronAnimationDelayName(0.2);
      setCayronAnimationDelayBusiness(0.4);
  
      setCayronAnimationDurationNameBox(0.5);
      setCayronAnimationDurationName(0.5);
      setCayronAnimationDurationBusiness(0.5);
  
      setCayronRoundedStyleName('');
      setCayronRoundedStyleBusiness('');
  
      setCayronColorName('#19191B');
      setCayronColorBody('#F4F4F5');
      setCayronColorNameText('#F4F4F5');
      setCayronColorBodyText('#52525B');
    
      setCayronFontNameText('anton-font');
      setCayronFontSizeNameText('text-5xl');
      setCayronFontPaddingTopNameText('pt-5');
      setCayronFontPaddingRightNameText('pr-5');
      setCayronFontPaddingBotNameText('pb-5');
      setCayronFontPaddingLeftNameText('pl-5');
  
      setCayronFontMarginTopNameBox('mt-0');
      setCayronFontMarginRightNameBox('mt-0');
      setCayronFontMarginBotNameBox('mt-0');
      setCayronFontMarginLeftNameBox('mt-0');
  
      setCayronFontBodyText('');
      setCayronFontSizeBodyText('text-2xl');
      setCayronFontPaddingTopBodyText('pt-3');
      setCayronFontPaddingRightBodyText('pr-5');
      setCayronFontPaddingBotBodyText('pb-3');
      setCayronFontPaddingLeftBodyText('pl-5');
  
      setCayronFontMarginTopBodyBox('mt-0');
      setCayronFontMarginRightBodyBox('mt-0');
      setCayronFontMarginBotBodyBox('mt-0');
      setCayronFontMarginLeftBodyBox('mt-0');
    }
  }

  
  useEffect(() => {
    restoreCayronConfig()
  }, [cayronPreset]);


  /* Subtitle Config */
  function restoreSubtitleConfig() {
    setSubtitleStartAnimation('preset-1');
    setSubtitleEndAnimation('preset-1');

    setSubtitleAnimationDelayBox(0.0);
    setSubtitleAnimationDelayText(0.0);

    setSubtitleAnimationDurationBox(0.5);
    setSubtitleAnimationDurationText(0.5);

    setSubtitleRoundedStyleBox('');

    setSubtitleColorBox('#18181B');
    setSubtitleColorText('#F4F4F5');
  }


  /* Cayron Config */
  function restoreCardConfig() {

    if (stylePresets && cardPreset && stylePresets[cardPreset] && stylePresets[cardPreset] && stylePresets[cardPreset].projectStyles) {
      var stylesTMP = stylePresets[cardPreset].projectStyles;

      if (stylesTMP.cardPreset) {setCardPreset(stylesTMP.cardPreset);}

      if (stylesTMP.cardStartAnimation) {setCardStartAnimation(stylesTMP.cardStartAnimation);}
      if (stylesTMP.cardEndAnimation) {setCardEndAnimation(stylesTMP.cardEndAnimation);}

      if (!isNaN(stylesTMP.cardAnimationDelayImage)) {setCardAnimationDelayImage(stylesTMP.cardAnimationDelayImage);}
      if (!isNaN(stylesTMP.cardAnimationDelayInitialBox)) {setCardAnimationDelayInitialBox(stylesTMP.cardAnimationDelayInitialBox);}
      if (!isNaN(stylesTMP.cardAnimationDelaySecondaryBox)) {setCardAnimationDelaySecondaryBox(stylesTMP.cardAnimationDelaySecondaryBox);}
  
      if (!isNaN(stylesTMP.cardAnimationDurationImage)) {setCardAnimationDurationImage(stylesTMP.cardAnimationDurationImage);}
      if (!isNaN(stylesTMP.cardAnimationDurationInitialBox)) {setCardAnimationDurationInitialBox(stylesTMP.cardAnimationDurationInitialBox);}
      if (!isNaN(stylesTMP.cardAnimationDurationSecondaryBox)) {setCardAnimationDurationSecondaryBox(stylesTMP.cardAnimationDurationSecondaryBox);}

      if (stylesTMP.cardColorInitialBox) {setCardColorInitialBox(stylesTMP.cardColorInitialBox);}
      if (stylesTMP.cardColorSecondaryBox) {setCardColorSecondaryBox(stylesTMP.cardColorSecondaryBox);}
      if (stylesTMP.cardColorTitle) {setCardColorTitle(stylesTMP.cardColorTitle);}
      if (stylesTMP.cardColorSubtitle) {setCardColorSubtitle(stylesTMP.cardColorSubtitle);}
      if (stylesTMP.cardColorInitialText) {setCardColorInitialText(stylesTMP.cardColorInitialText);}
      if (stylesTMP.cardColorSecondaryText) {setCardColorSecondaryText(stylesTMP.cardColorSecondaryText);}

      if (stylesTMP.cardClasesImage) {setCardClasesImage(stylesTMP.cardClasesImage);}
      if (stylesTMP.cardClasesInitialBox) {setCardClasesInitialBox(stylesTMP.cardClasesInitialBox);}
      if (stylesTMP.cardClasesSecondaryBox) {setCardClasesSecondaryBox(stylesTMP.cardClasesSecondaryBox);}
      if (stylesTMP.cardClasesTitle) {setCardClasesTitle(stylesTMP.cardClasesTitle);}
      if (stylesTMP.cardClasesSubtitle) {setCardClasesSubtitle(stylesTMP.cardClasesSubtitle);}
      if (stylesTMP.cardClasesInitialText) {setCardClasesInitialText(stylesTMP.cardClasesInitialText);}
      if (stylesTMP.cardClasesSecondaryText) {setCardClasesSecondaryText(stylesTMP.cardClasesSecondaryText);}

      if (stylesTMP.cardRoundedStyleImage) {setCardRoundedStyleImage(stylesTMP.cardRoundedStyleImage);}
      if (stylesTMP.cardRoundedStyleInitialBox) {setCardRoundedStyleInitialBox(stylesTMP.cardRoundedStyleInitialBox);}
      if (stylesTMP.cardRoundedStyleSecondaryBox) {setCardRoundedStyleSecondaryBox(stylesTMP.cardRoundedStyleSecondaryBox);}

      if (stylesTMP.cardFontTitle) {setCardFontTitle(stylesTMP.cardFontTitle);}
      if (stylesTMP.cardFontSizeTitle) {setCardFontSizeTitle(stylesTMP.cardFontSizeTitle);}

      if (stylesTMP.cardFontSubtitle) {setCardFontSubtitle(stylesTMP.cardFontSubtitle);}
      if (stylesTMP.cardFontSizeSubtitle) {setCardFontSizeSubtitle(stylesTMP.cardFontSizeSubtitle);}

      if (stylesTMP.cardFontInitialText) {setCardFontInitialText(stylesTMP.cardFontInitialText);}

      if (stylesTMP.cardFontSecondaryText) {setCardFontSecondaryText(stylesTMP.cardFontSecondaryText);}


    } else {

      setCardStartAnimation('preset-1');
      setCardEndAnimation('preset-1');

      setCardAnimationDelayImage(0.0);
      setCardAnimationDelayInitialBox(0.0);
      setCardAnimationDelaySecondaryBox(0.0);
  
      setCardAnimationDurationImage(0.5);
      setCardAnimationDurationInitialBox(0.5);
      setCardAnimationDurationSecondaryBox(0.5);

      setCardColorInitialBox('#F4F4F5');
      setCardColorSecondaryBox('#F4F4F5');
      setCardColorTitle('#3F3F46');
      setCardColorSubtitle('#3F3F46');
      setCardColorInitialText('#3F3F46');
      setCardColorSecondaryText('#3F3F46');

      setCardRoundedStyleImage('');
      setCardRoundedStyleInitialBox('');
      setCardRoundedStyleSecondaryBox('');

      setCardFontTitle('');
      setCardFontSizeTitle('text-4xl');

      setCardFontSubtitle('');
      setCardFontSizeSubtitle('text-3xl');

      setCardFontInitialText('');

      setCardFontSecondaryText('');
      
    }
  }

  useEffect(() => {
    restoreCardConfig()
  }, [cardPreset]);




  return <>
    <div className="p-5">
    {/* animate-bounce */}
      <div className="flex justify-between">
        <button type="button" onClick={restoreAllConfigs} className="btn--tool--gray disabled:cursor-not-allowed"> 
          {svg_back}
          <span>Restaurar configuracions</span>
        </button>
        <button type="button" onClick={saveConfig} className={`btn--tool--blue disabled:cursor-not-allowed  ${!disableButton? 'animate-bounce' : ''}`} disabled={disableButton}> 
          {svg_save}
          <span>Desar la configuració</span>
        </button>
      </div>


      {/* MESSAGES */}
      <div className="border-2 mt-4 overflow-hidden">
        <div className="p-4">
          <div className="flex flex-wrap items-center justify-between">

            <h1 className="text-lg font-bold">Configuració dels missatges</h1>

            <div className="flex space-x-2">
              <select className="w-full" style={{ paddingBlock: '7px' }} value={messagePreset} onChange={ (event) => setMessagePreset(event.target.value)}>
                <option value='default-messages'>Per defecte</option>
                <option value='IMMB-messages-verd'>IMMB Verd</option>
                <option value='IMMB-messages-vermell'>IMMB Vermell</option>
              </select>

              <button type="button" onClick={restoreMessageConfig} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {svg_back}
                <span>Restaurar</span>
              </button>
              
              <button type="button" onClick={ () => { setShowMessageConfig(!showMessageConfig); }} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {showMessageConfig? svg_minus : svg_plus}
              </button>
            </div>

          </div>

          {showMessageConfig && <>

            <div className="border border-gray-200 mt-4"></div>
                        
            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3 mt-3">
              <div className="flex w-full flex-wrap space-x-0 space-y-3">


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Animacións</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació d'entrada</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageStartAnimation} onChange={ (event) => setMessageStartAnimation(event.target.value)}>
                        <option value={'preset-IMMB'}>Animació d'entrada Preset IMMB</option>
                        {anuimationOptions.map((animationOption, key) => <option key={key} value={animationOption[0]}>Animació d'entrada {animationOption[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació de sortida</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageEndAnimation} onChange={ (event) => setMessageEndAnimation(event.target.value)}>
                        <option value={'preset-IMMB'}>Animació de sortida Preset IMMB</option>
                        {anuimationOptions.map((animationOption, key) => <option key={key} value={animationOption[0]}>Animació de sortida {animationOption[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Temps d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDurationProfile} onChange={ (event) => setMessageAnimationDurationProfile(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa del perfil</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del nom / extra info</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDurationName} onChange={ (event) => setMessageAnimationDurationName(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa del nom / extra info</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del missatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDurationBody} onChange={ (event) => setMessageAnimationDurationBody(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa del missatge</option> )}
                      </select>
                    </div>
                  </div>
                </div>


              </div>


              <div className="flex w-full flex-wrap space-x-0 space-y-3 2xl:mt-0 mt-3">
                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Retard d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDelayProfile} onChange={ (event) => setMessageAnimationDelayProfile(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa del perfil</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del nom / extra info</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDelayName} onChange={ (event) => setMessageAnimationDelayName(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa del nom / extra info</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del missatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageAnimationDelayBody} onChange={ (event) => setMessageAnimationDelayBody(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa del missatge</option> )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Estil de redondeig</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageRoundedStyleProfile} onChange={ (event) => setMessageRoundedStyleProfile(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del nom / extra info</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageRoundedStyleName} onChange={ (event) => setMessageRoundedStyleName(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del missatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={messageRoundedStyleBody} onChange={ (event) => setMessageRoundedStyleBody(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings de la caixa del perfil</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopProfileText} onChange={ (event) => setMessageFontPaddingTopProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightProfileText} onChange={ (event) => setMessageFontPaddingRightProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotProfileText} onChange={ (event) => setMessageFontPaddingBotProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftProfileText} onChange={ (event) => setMessageFontPaddingLeftProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>


              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings del nom / extra info</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopNameText} onChange={ (event) => setMessageFontPaddingTopNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightNameText} onChange={ (event) => setMessageFontPaddingRightNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotNameText} onChange={ (event) => setMessageFontPaddingBotNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftNameText} onChange={ (event) => setMessageFontPaddingLeftNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>


              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings del missatge</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopBodyText} onChange={ (event) => setMessageFontPaddingTopBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightBodyText} onChange={ (event) => setMessageFontPaddingRightBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotBodyText} onChange={ (event) => setMessageFontPaddingBotBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftBodyText} onChange={ (event) => setMessageFontPaddingLeftBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del perfil</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopProfileBox} onChange={ (event) => setMessageFontMarginTopProfileBox(event.target.value)}>
                      <option value={'-mt-5'}>Margin -1</option>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightProfileBox} onChange={ (event) => setMessageFontMarginRightProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotProfileBox} onChange={ (event) => setMessageFontMarginBotProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftProfileBox} onChange={ (event) => setMessageFontMarginLeftProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del nom / extra info</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopNameBox} onChange={ (event) => setMessageFontMarginTopNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightNameBox} onChange={ (event) => setMessageFontMarginRightNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotNameBox} onChange={ (event) => setMessageFontMarginBotNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftNameBox} onChange={ (event) => setMessageFontMarginLeftNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del missatge</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopBodyBox} onChange={ (event) => setMessageFontMarginTopBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightBodyBox} onChange={ (event) => setMessageFontMarginRightBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotBodyBox} onChange={ (event) => setMessageFontMarginBotBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftBodyBox} onChange={ (event) => setMessageFontMarginLeftBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div>


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Estil del text</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del perfil</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontProfileText} onChange={ (event) => setMessageFontProfileText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del perfil</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontSizeProfileText} onChange={ (event) => setMessageFontSizeProfileText(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del nom / extra info</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontNameText} onChange={ (event) => setMessageFontNameText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del nom / extra info</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontSizeNameText} onChange={ (event) => setMessageFontSizeNameText(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>


                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del missatge</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontBodyText} onChange={ (event) => setMessageFontBodyText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del missatge</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontSizeBodyText} onChange={ (event) => setMessageFontSizeBodyText(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>
              </div>
            </div>
            
            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Opacitat del fons</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons del perfil</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageOpacityProfileBox} onChange={ (event) => setMessageOpacityProfileBox(event.target.value)}>
                    {backgroundOpacityes.map((opacity, key) => <option key={key} value={opacity[0]}>{opacity[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons del nom / extra info</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageOpacityNameBox} onChange={ (event) => setMessageOpacityNameBox(event.target.value)}>
                    {backgroundOpacityes.map((opacity, key) => <option key={key} value={opacity[0]}>{opacity[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons del missatge</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={messageOpacityBodyBox} onChange={ (event) => setMessageOpacityBodyBox(event.target.value)}>
                    {backgroundOpacityes.map((opacity, key) => <option key={key} value={opacity[0]}>{opacity[1]}</option> )}
                  </select>
                </div>
              </div>
            </div>


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Colors</h1>
              <hr className="mb-1"></hr>

              <div className="flex flex-wrap w-full lg:flex-nowrap lg:space-x-3">
                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons del perfil</p>
                    <div style={{ backgroundColor: messageColorProfile }} onClick={ () => setMessageShowPickerProfile(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerProfile && <ColorPicker color={messageColorProfile} setColor={setMessageColorProfile} setClose={setMessageShowPickerProfile} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons del nom / extra info</p>
                    <div style={{ backgroundColor: messageColorName }} onClick={ () => setMessageShowPickerName(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerName && <ColorPicker color={messageColorName} setColor={setMessageColorName} setClose={setMessageShowPickerName} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons del missatge</p>
                    <div style={{ backgroundColor: messageColorBody }} onClick={ () => setMessageShowPickerBody(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerBody && <ColorPicker color={messageColorBody} setColor={setMessageColorBody} setClose={setMessageShowPickerBody} />}
                  </div>
                </div>

                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3 mt-3 lg:mt-0">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del perfil</p>
                    <div style={{ backgroundColor: messageColorProfileText }} onClick={ () => setMessageShowPickerProfileText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerProfileText && <ColorPicker color={messageColorProfileText} setColor={setMessageColorProfileText} setClose={setMessageShowPickerProfileText} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del nom / extra info</p>
                    <div style={{ backgroundColor: messageColorNameText }} onClick={ () => setMessageShowPickerNameText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerNameText && <ColorPicker color={messageColorNameText} setColor={setMessageColorNameText} setClose={setMessageShowPickerNameText} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del missatge</p>
                    <div style={{ backgroundColor: messageColorBodyText }} onClick={ () => setMessageShowPickerBodyText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {messageShowPickerBodyText && <ColorPicker color={messageColorBodyText} setColor={setMessageColorBodyText} setClose={setMessageShowPickerBodyText} />}
                  </div>
                </div>
              </div>
            </div>

          </>}

        </div>


        {showMessageConfig && <>
          <div className="mx-4 border border-gray-200"></div>
          <MessageCreate projectId={match.params.id} simple={true} projectStyles={projectStylesTMP} />
        </>}
        <div id="messageConfig" className="absolute mt-10 "></div>

      </div>



      {/* Audio Messages */}
      <div className="border-2 mt-4 overflow-hidden">
        <div className="p-4">
          <div className="flex flex-wrap items-center justify-between">

            <h1 className="text-lg font-bold">Configuració dels missatges d'àudio</h1>

            <div className="flex space-x-2">
              <button type="button" onClick={restoreAudioMessageConfig} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {svg_back}
                <span>Restaurar</span>
              </button>
              
              <button type="button" onClick={ () => { setShowAudioMessageConfig(!showAudioMessageConfig); }} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {showAudioMessageConfig? svg_minus : svg_plus}
              </button>
            </div>

          </div>

          {showAudioMessageConfig && <>
            <div className="border border-gray-200 mt-4"></div>
            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3 mt-3">

              <div className="flex w-full flex-wrap space-x-0 space-y-3">


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Animacións</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació d'entrada</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageStartAnimation} onChange={ (event) => setAudioMessageStartAnimation(event.target.value)}>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació d'entrada {anuimationOption[1]}</option> )}
                      </select>
                    </div>

                    <div className="text-sm w-full">
                      <p className="whitespace-nowrap p-1">Animació de sortida</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageEndAnimation} onChange={ (event) => setAudioMessageEndAnimation(event.target.value)}>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació de sortida {anuimationOption[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border mt-3">                  
                  <h1 className="text-xl w-full px-1 pb-1">Temps d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps de la caixa general</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDurationBody} onChange={ (event) => setAudioMessageAnimationDurationBody(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa general</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDurationProfile} onChange={ (event) => setAudioMessageAnimationDurationProfile(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en el perfil</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDurationName} onChange={ (event) => setAudioMessageAnimationDurationName(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en el nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps l'informació</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDurationInfo} onChange={ (event) => setAudioMessageAnimationDurationInfo(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en l'informació</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del reproductor</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDurationControls} onChange={ (event) => setAudioMessageAnimationDurationControls(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en el reproductor</option> )}
                      </select>
                    </div>
                  </div>
                </div>

              </div>


              <div className="flex w-full flex-wrap space-x-0 space-y-3 mt-3 2xl:mt-0">

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Retard d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de la caixa general</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDelayBody} onChange={ (event) => setAudioMessageAnimationDelayBody(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa general</option> )}
                      </select>
                    </div>
                    
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDelayProfile} onChange={ (event) => setAudioMessageAnimationDelayProfile(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en el perfil</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDelayName} onChange={ (event) => setAudioMessageAnimationDelayName(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en el nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de l'informació</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDelayInfo} onChange={ (event) => setAudioMessageAnimationDelayInfo(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en l'informació</option> )}
                      </select>
                    </div>
                    
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del reproductor</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageAnimationDelayControls} onChange={ (event) => setAudioMessageAnimationDelayControls(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en le reproductor</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border mt-3">                  
                  <h1 className="text-xl w-full px-1 pb-1">Estil de redondeig</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeigde la caixa</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageRoundedStyleBody} onChange={ (event) => setAudioMessageRoundedStyleBody(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del perfil</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={audioMessageRoundedStyleProfile} onChange={ (event) => setAudioMessageRoundedStyleProfile(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Colors</h1>
              <hr className="mb-1"></hr>

              <div className="flex flex-wrap lg:flex-nowrap lg:space-x-3">

                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons del reproductor</p>
                    <div style={{ backgroundColor: audioMessageColorBody }} onClick={ () => setAudioMessageShowPickerBody(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerBody && <ColorPicker color={audioMessageColorBody} setColor={setAudioMessageColorBody} setClose={setAudioMessageShowPickerBody} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del nom</p>
                    <div style={{ backgroundColor: audioMessageColorNameText }} onClick={ () => setAudioMessageShowPickerNameText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerNameText && <ColorPicker color={audioMessageColorNameText} setColor={setAudioMessageColorNameText} setClose={setAudioMessageShowPickerNameText} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text d'informació addicional</p> 
                    <div style={{ backgroundColor: audioMessageColorInfoText }} onClick={ () => setAudioMessageShowPickerInfoText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerInfoText && <ColorPicker color={audioMessageColorInfoText} setColor={setAudioMessageColorInfoText} setClose={setAudioMessageShowPickerInfoText} />}
                  </div>
                </div>

                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap sm:space-x-3 mt-3 lg:mt-0">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Barra del reproductor</p>
                    <div style={{ backgroundColor: audioMessageColorBar }} onClick={ () => setAudioMessageShowPickerBar(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerBar && <ColorPicker color={audioMessageColorBar} setColor={setAudioMessageColorBar} setClose={setAudioMessageShowPickerBar} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Punt del reproductor</p>
                    <div style={{ backgroundColor: audioMessageColorDot }} onClick={ () => setAudioMessageShowPickerDot(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerDot && <ColorPicker color={audioMessageColorDot} setColor={setAudioMessageColorDot} setClose={setAudioMessageShowPickerDot} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text de duració de l'audio</p> 
                    <div style={{ backgroundColor: audioMessageColorTimer }} onClick={ () => setAudioMessageShowPickerTimer(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {audioMessageShowPickerTimer && <ColorPicker color={audioMessageColorTimer} setColor={setAudioMessageColorTimer} setClose={setAudioMessageShowPickerTimer} />}
                  </div>
                </div>

              </div>
            </div>

          </>}
        </div>

        {showAudioMessageConfig && <>
          <div className="mx-4 border border-gray-200"></div>
          <AudioMessageCreate projectId={match.params.id} simple={true} projectStyles={projectStylesTMP} />
        </>}
        <div id="audioMessageConfig" className="absolute mt-10 "></div>

      </div>



      {/* Cayrons */}
      <div className="border-2 mt-4 overflow-hidden">
        <div className="p-4">
          <div className="flex flex-wrap items-center justify-between">

            <h1 className="text-lg font-bold">Configuració dels cayrons</h1>
            
            <div className="flex space-x-2">
              <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronPreset} onChange={ (event) => setCayronPreset(event.target.value)}>
                <option value='default-cayrons'>Per defecte</option>
                <option value='IMMB-cayrons'>IMMB</option>
              </select>

              <button type="button" onClick={restoreCayronConfig} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {svg_back}
                <span>Restaurar preset</span>
              </button>
              
              <button type="button" onClick={ () => { setShowCayronConfig(!showCayronConfig); }} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {showCayronConfig? svg_minus : svg_plus}
              </button>
            </div>

          </div>

          {showCayronConfig && <>
            <div className="border border-gray-200 mt-4"></div>
            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3 mt-3">

              <div className="flex w-full flex-wrap space-x-0 space-y-3">

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Animacións</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació d'entrada</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronStartAnimation} onChange={ (event) => setCayronStartAnimation(event.target.value)}>
                        <option value={'preset-IMMB'}>Animació d'entrada Preset IMMB</option>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació d'entrada {anuimationOption[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació de sortida</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronEndAnimation} onChange={ (event) => setCayronEndAnimation(event.target.value)}>
                        <option value={'preset-IMMB'}>Animació de sortida Preset IMMB</option>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació de sortida {anuimationOption[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Temps d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Duració de la caixa del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDurationNameBox} onChange={ (event) => setCayronAnimationDurationNameBox(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa del nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDurationName} onChange={ (event) => setCayronAnimationDurationName(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en el text del nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Duració de l'informació</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDurationBusiness} onChange={ (event) => setCayronAnimationDurationBusiness(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa de l'informació</option> )}
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              <div className="flex w-full flex-wrap space-x-0 space-y-3 2xl:mt-0 mt-3">

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Retard d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-2 sm:space-y-0 sm:space-x-2">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de la caixa del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDelayNameBox} onChange={ (event) => setCayronAnimationDelayNameBox(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa del nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDelayName} onChange={ (event) => setCayronAnimationDelayName(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en el text del nom</option> )}
                      </select>
                    </div>
                    
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de l'informació</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronAnimationDelayBusiness} onChange={ (event) => setCayronAnimationDelayBusiness(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa de l'informació</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Estil de redondeig</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronRoundedStyleName} onChange={ (event) => setCayronRoundedStyleName(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]} en la caixa del nom</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeigde l'informació</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronRoundedStyleBusiness} onChange={ (event) => setCayronRoundedStyleBusiness(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]} en la caixa de l'informació</option> )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings de la caixa del nom</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingTopNameText} onChange={ (event) => setCayronFontPaddingTopNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingRightNameText} onChange={ (event) => setCayronFontPaddingRightNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingBotNameText} onChange={ (event) => setCayronFontPaddingBotNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingLeftNameText} onChange={ (event) => setCayronFontPaddingLeftNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>


              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings de la caixa d'informació adicional</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingTopBodyText} onChange={ (event) => setCayronFontPaddingTopBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingRightBodyText} onChange={ (event) => setCayronFontPaddingRightBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingBotBodyText} onChange={ (event) => setCayronFontPaddingBotBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontPaddingLeftBodyText} onChange={ (event) => setCayronFontPaddingLeftBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del nom</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior del nom</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginTopNameBox} onChange={ (event) => setCayronFontMarginTopNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret del nom</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginRightNameBox} onChange={ (event) => setCayronFontMarginRightNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior del nom</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginBotNameBox} onChange={ (event) => setCayronFontMarginBotNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre del nom</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginLeftNameBox} onChange={ (event) => setCayronFontMarginLeftNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa d'informació adicional</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginTopBodyBox} onChange={ (event) => setCayronFontMarginTopBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginRightBodyBox} onChange={ (event) => setCayronFontMarginRightBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginBotBodyBox} onChange={ (event) => setCayronFontMarginBotBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontMarginLeftBodyBox} onChange={ (event) => setCayronFontMarginLeftBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div>


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Estil del text</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del nom</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontNameText} onChange={ (event) => setCayronFontNameText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del nom</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontSizeNameText} onChange={ (event) => setCayronFontSizeNameText(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font de l'informació adicional</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontBodyText} onChange={ (event) => setCayronFontBodyText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize de l'informació adicional</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cayronFontSizeBodyText} onChange={ (event) => setCayronFontSizeBodyText(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>
              </div>
            </div>


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Colors</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons del nom</p>
                  <div style={{ backgroundColor: cayronColorName }} onClick={ () => setCayronShowPickerName(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {cayronShowPickerName && <ColorPicker color={cayronColorName} setColor={setCayronColorName} setClose={setCayronShowPickerName} />}
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons de la professió</p>
                  <div style={{ backgroundColor: cayronColorBody }} onClick={ () => setCayronShowPickerBody(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {cayronShowPickerBody && <ColorPicker color={cayronColorBody} setColor={setCayronColorBody} setClose={setCayronShowPickerBody} />}
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Text del nom</p>
                  <div style={{ backgroundColor: cayronColorNameText }} onClick={ () => setCayronShowPickerNameText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {cayronShowPickerNameText && <ColorPicker color={cayronColorNameText} setColor={setCayronColorNameText} setClose={setCayronShowPickerNameText} />}
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Text de la professió</p>
                  <div style={{ backgroundColor: cayronColorBodyText }} onClick={ () => setCayronShowPickerBodyText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {cayronShowPickerBodyText && <ColorPicker color={cayronColorBodyText} setColor={setCayronColorBodyText} setClose={setCayronShowPickerBodyText} />}
                </div>
              </div>
            </div>


          </>}
        </div>

        {showCayronConfig && <>
          <div className="mx-4 border border-gray-200"></div>
          <CayronCreate projectId={match.params.id} simple={true} projectStyles={projectStylesTMP} />
        </>}
        <div id="cayronConfig" className="absolute mt-10 "></div>

      </div>






      {/* Subtitles */}
      <div className="border-2 mt-4 overflow-hidden">
        <div className="p-4">
          <div className="flex flex-wrap items-center justify-between">

            <h1 className="text-lg font-bold">Configuració dels subtítols</h1>
            
            <div className="flex space-x-2">
              <button type="button" onClick={restoreSubtitleConfig} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {svg_back}
                <span>Restaurar</span>
              </button>
              
              <button type="button" onClick={ () => {setShowSubtitleConfig(!showSubtitleConfig); }} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {showSubtitleConfig? svg_minus : svg_plus}
              </button>
            </div>

          </div>  

          {showSubtitleConfig && <>

            <div className="border border-gray-200 mt-4"></div>
            <div className="flex flex-wrap w-full md:flex-nowrap md:space-x-3 mt-3">

              <div className="flex w-full flex-wrap space-x-0 space-y-3">


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Animacións</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació d'entrada</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleStartAnimation} onChange={ (event) => setSubtitleStartAnimation(event.target.value)}>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació d'entrada {anuimationOption[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació de sortida</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleEndAnimation} onChange={ (event) => setSubtitleEndAnimation(event.target.value)}>
                        {anuimationOptions.map((anuimationOption, key) => <option key={key} value={anuimationOption[0]}>Animació de sortida {anuimationOption[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Temps d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps de la caixa general</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleAnimationDurationBox} onChange={ (event) => setSubtitleAnimationDurationBox(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa general</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps del text</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleAnimationDurationText} onChange={ (event) => setSubtitleAnimationDurationText(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en el text</option> )}
                      </select>
                    </div>
                  </div>
                </div>


              </div>

              <div className="flex w-full flex-wrap space-x-0 space-y-3">
                <div className="w-full bg-gray-100 p-2 border mt-3 md:mt-0">                  
                  <h1 className="text-xl w-full px-1 pb-1">Retard d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de la caixa general</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleAnimationDelayBox} onChange={ (event) => setSubtitleAnimationDelayBox(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa general</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard del text</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleAnimationDelayText} onChange={ (event) => setSubtitleAnimationDelayText(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>Retard de {delayOption}s en el text</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Estil de redondeig</h1>
                  <hr className="mb-1"></hr>
                  
                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig del nom</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={subtitleRoundedStyleBox} onChange={ (event) => setSubtitleRoundedStyleBox(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>

              </div>

            </div>


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Colors</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons del nom</p>
                  <div style={{ backgroundColor: subtitleColorBox }} onClick={ () => setSubtitleShowPickerBox(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {subtitleShowPickerBox && <ColorPicker color={subtitleColorBox} setColor={setSubtitleColorBox} setClose={setSubtitleShowPickerBox} />}
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fons de la professió</p>
                  <div style={{ backgroundColor: subtitleColorText }} onClick={ () => setSubtitleShowPickerText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                  {subtitleShowPickerText && <ColorPicker color={subtitleColorText} setColor={setSubtitleColorText} setClose={setSubtitleShowPickerText} />}
                </div>
              </div>
            </div>

          </>}
        </div>

        {showSubtitleConfig && <>
          <div className="mx-4 border border-gray-200"></div>
          <SubtitlesCreateTest projectId={match.params.id} simple={true} projectStyles={projectStylesTMP} />
        </>}

        <div id="subtitleConfig" className="absolute mt-10 "></div>

      </div>



      {/* Cards */}
      <div className="border-2 mt-4 overflow-hidden">
        <div className="p-4">
          <div className="flex flex-wrap items-center justify-between">

            <h1 className="text-lg font-bold">Configuració de les targetas</h1>

            <div className="flex space-x-2">
              <select className="w-full" style={{ paddingBlock: '7px' }} value={cardPreset} onChange={ (event) => setCardPreset(event.target.value)}>
                <option value='default-cards'>Per defecte</option>
                <option value='personalizated-cards'>Personalitzat</option>
              </select>

              <button type="button" onClick={restoreCardConfig} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {svg_back}
                <span>Restaurar</span>
              </button>
              
              <button type="button" onClick={ () => { setShowCardConfig(!showCardConfig); }} className="btn--tool--gray disabled:cursor-not-allowed"> 
                {showCardConfig? svg_minus : svg_plus}
              </button>
            </div>

          </div>

          {showCardConfig && <>

            <div className="border border-gray-200 mt-4"></div>
                        
            <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3 mt-3">
              <div className="flex w-full flex-wrap space-x-0 space-y-3">


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Animacións</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació d'entrada</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardStartAnimation} onChange={ (event) => setCardStartAnimation(event.target.value)}>
                        {anuimationOptions.map((animationOption, key) => <option key={key} value={animationOption[0]}>Animació d'entrada {animationOption[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Animació de sortida</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardEndAnimation} onChange={ (event) => setCardEndAnimation(event.target.value)}>
                        {anuimationOptions.map((animationOption, key) => <option key={key} value={animationOption[0]}>Animació de sortida {animationOption[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>


                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Temps d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps de l'imatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDurationImage} onChange={ (event) => setCardAnimationDurationImage(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en l'imatge</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps de la caixa principal</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDurationInitialBox} onChange={ (event) => setCardAnimationDurationInitialBox(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa principal</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Temps de la caixa inferior</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDurationSecondaryBox} onChange={ (event) => setCardAnimationDurationSecondaryBox(event.target.value)}>
                        {durationOptions.map((durationOption, key) => <option key={key} value={durationOption}>{durationOption}s en la caixa inferior</option> )}
                      </select>
                    </div>
                  </div>
                </div>

              </div>


              <div className="flex w-full flex-wrap space-x-0 space-y-3 2xl:mt-0 mt-3">
                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Retard d'animacions</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de l'imatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDelayImage} onChange={ (event) => setCardAnimationDelayImage(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en l'imatge</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard de la caixa principal</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDelayInitialBox} onChange={ (event) => setCardAnimationDelayInitialBox(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa principal</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Retard inferior</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardAnimationDelaySecondaryBox} onChange={ (event) => setCardAnimationDelaySecondaryBox(event.target.value)}>
                        {delayOptions.map((delayOption, key) => <option key={key} value={delayOption}>{delayOption}s en la caixa inferior</option> )}
                      </select>
                    </div>
                  </div>
                </div>
                

                <div className="w-full bg-gray-100 p-2 border">                  
                  <h1 className="text-xl w-full px-1 pb-1">Estil de redondeig</h1>
                  <hr className="mb-1"></hr>

                  <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig de l'imatge</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardRoundedStyleImage} onChange={ (event) => setCardRoundedStyleImage(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig de la caixa principal</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardRoundedStyleInitialBox} onChange={ (event) => setCardRoundedStyleInitialBox(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>

                    <div className="w-full">
                      <p className="whitespace-nowrap p-1">Redondeig de la caixa inferior</p>
                      <select className="w-full" style={{ paddingBlock: '7px' }} value={cardRoundedStyleSecondaryBox} onChange={ (event) => setCardRoundedStyleSecondaryBox(event.target.value)}>
                        {roundedOptions.map((roundedOptions, key) => <option key={key} value={roundedOptions[0]}>{roundedOptions[1]}</option> )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            {/* <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings de la caixa del perfil</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopProfileText} onChange={ (event) => setMessageFontPaddingTopProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightProfileText} onChange={ (event) => setMessageFontPaddingRightProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotProfileText} onChange={ (event) => setMessageFontPaddingBotProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftProfileText} onChange={ (event) => setMessageFontPaddingLeftProfileText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>


              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings del nom / extra info</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopNameText} onChange={ (event) => setMessageFontPaddingTopNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightNameText} onChange={ (event) => setMessageFontPaddingRightNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotNameText} onChange={ (event) => setMessageFontPaddingBotNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftNameText} onChange={ (event) => setMessageFontPaddingLeftNameText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>


              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Paddings del missatge</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingTopBodyText} onChange={ (event) => setMessageFontPaddingTopBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pt'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingRightBodyText} onChange={ (event) => setMessageFontPaddingRightBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pr'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingBotBodyText} onChange={ (event) => setMessageFontPaddingBotBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pb'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Padding esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontPaddingLeftBodyText} onChange={ (event) => setMessageFontPaddingLeftBodyText(event.target.value)}>
                      {paddings.map((padding, key) => <option key={key} value={'pl'+padding[0]}>{padding[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div> */}


            {/* <div className="flex flex-wrap w-full 2xl:flex-nowrap 2xl:space-x-3">
              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del perfil</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopProfileBox} onChange={ (event) => setMessageFontMarginTopProfileBox(event.target.value)}>
                      <option value={'-mt-5'}>Margin -1</option>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightProfileBox} onChange={ (event) => setMessageFontMarginRightProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotProfileBox} onChange={ (event) => setMessageFontMarginBotProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftProfileBox} onChange={ (event) => setMessageFontMarginLeftProfileBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del nom / extra info</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopNameBox} onChange={ (event) => setMessageFontMarginTopNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightNameBox} onChange={ (event) => setMessageFontMarginRightNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotNameBox} onChange={ (event) => setMessageFontMarginBotNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftNameBox} onChange={ (event) => setMessageFontMarginLeftNameBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full bg-gray-100 p-2 border mt-3">                  
                <h1 className="text-xl w-full px-1 pb-1">Margin de la caixa del missatge</h1>
                <hr className="mb-1"></hr>

                <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin superior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginTopBodyBox} onChange={ (event) => setMessageFontMarginTopBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mt'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin dret</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginRightBodyBox} onChange={ (event) => setMessageFontMarginRightBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mr'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin inferior</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginBotBodyBox} onChange={ (event) => setMessageFontMarginBotBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'mb'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Margin esquerre</p>
                    <select className="w-full" style={{ paddingBlock: '7px' }} value={messageFontMarginLeftBodyBox} onChange={ (event) => setMessageFontMarginLeftBodyBox(event.target.value)}>
                      {margins.map((margin, key) => <option key={key} value={'ml'+margin[0]}>{margin[1]}</option> )}
                    </select>
                  </div>
                </div>
              </div>
            </div> */}


            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Estil del text</h1>
              <hr className="mb-1"></hr>

              <div className="text-sm flex flex-wrap sm:flex-nowrap sm:space-x-3">
                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del títol</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontTitle} onChange={ (event) => setCardFontTitle(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del títol</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontSizeTitle} onChange={ (event) => setCardFontSizeTitle(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del subtítol</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontSubtitle} onChange={ (event) => setCardFontSubtitle(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Fontsize del subtítol</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontSizeSubtitle} onChange={ (event) => setCardFontSizeSubtitle(event.target.value)}>
                    {fontSizes.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>


                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del text principal</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontInitialText} onChange={ (event) => setCardFontInitialText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>

                <div className="w-full">
                  <p className="whitespace-nowrap p-1">Font del text inferior</p>
                  <select className="w-full" style={{ paddingBlock: '7px' }} value={cardFontSecondaryText} onChange={ (event) => setCardFontSecondaryText(event.target.value)}>
                    {fonts.map((font, key) => <option key={key} value={font[0]}>{font[1]}</option> )}
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full bg-gray-100 p-2 border mt-3">                  
              <h1 className="text-xl w-full px-1 pb-1">Colors</h1>
              <hr className="mb-1"></hr>

              <div className="flex flex-wrap w-full lg:flex-nowrap lg:space-x-3">
                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons de la caixa principal</p>
                    <div style={{ backgroundColor: cardColorInitialBox }} onClick={ () => setCardShowPickerInitialBox(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerInitialBox && <ColorPicker color={cardColorInitialBox} setColor={setCardColorInitialBox} setClose={setCardShowPickerInitialBox} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Fons de la caixa inferior</p>
                    <div style={{ backgroundColor: cardColorSecondaryBox }} onClick={ () => setCardShowPickerSecondaryBox(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerSecondaryBox && <ColorPicker color={cardColorSecondaryBox} setColor={setCardColorSecondaryBox} setClose={setCardShowPickerSecondaryBox} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del títol</p>
                    <div style={{ backgroundColor: cardColorTitle }} onClick={ () => setCardShowPickerTitle(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerTitle && <ColorPicker color={cardColorTitle} setColor={setCardColorTitle} setClose={setCardShowPickerTitle} />}
                  </div>
                </div>

                <div className="text-sm flex w-full flex-wrap sm:flex-nowrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3 mt-3 lg:mt-0">
                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text del subtítol</p>
                    <div style={{ backgroundColor: cardColorSubtitle }} onClick={ () => setCardShowPickerSubtitle(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerSubtitle && <ColorPicker color={cardColorSubtitle} setColor={setCardColorSubtitle} setClose={setCardShowPickerSubtitle} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text principal</p>
                    <div style={{ backgroundColor: cardColorInitialText }} onClick={ () => setCardShowPickerInitialText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerInitialText && <ColorPicker color={cardColorInitialText} setColor={setCardColorInitialText} setClose={setCardShowPickerInitialText} />}
                  </div>

                  <div className="w-full">
                    <p className="whitespace-nowrap p-1">Text inferior</p>
                    <div style={{ backgroundColor: cardColorSecondaryText }} onClick={ () => setCardShowPickerSecondaryText(true)} className="py-4 border-2 border-gray-300 rounded-sm select-none font-bold text-2xl text-gray-50 hover:opacity-50 cursor-pointer"></div>
                    {cardShowPickerSecondaryText && <ColorPicker color={cardColorSecondaryText} setColor={setCardColorSecondaryText} setClose={setCardShowPickerSecondaryText} />}
                  </div>
                </div>
              </div>
            </div>

          </>}

        </div>


        {showMessageConfig && <>
          <div className="mx-4 border border-gray-200"></div>
          <CardCreate projectId={match.params.id} simple={true} projectStyles={projectStylesTMP} />
        </>}
        <div id="messageConfig" className="absolute mt-10 "></div>

      </div>




    </div>
  </>

  
  
}





const mapStateToProps = (state, ownProps) => {
  const { projects } = state.firestore.data;
  const { stylePresets } = state.firestore.data;

  if (projects && ownProps?.match?.params?.id && projects[ownProps.match.params.id]) {
    return { 
      projectStyles: projects[ownProps.match.params.id].projectStyles,
      stylePresets: stylePresets,
    };
  } else {
    return { 
      projectStyles: null,
      stylePresets: {}
    };
  }
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project?.id ?? ownProps.match.params.id;
  return {
    setProjectStyles: (value) => dispatch(setProjectStyles(value, projectId)),
    setProjectStylePresets: (value, presetName) => dispatch(setProjectStylePresets(value, presetName)),
  };
};


export default compose(
  firestoreConnect((props) => {
    const paramsId = props.match?.params.id ?? null;
    if (!paramsId) return [];
    return [
      { collection: "projects" },
      { collection: "stylePresets" },
      { collection: "projects", doc: paramsId, subcollections: [ { collection: "items" } ], storeAs: "items" },
      { collection: "projects", doc: paramsId, subcollections: [ { collection: "overlays" } ], storeAs: "overlays" },
    ];
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Configurador);