import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";



var startAnimations = {
  'preset-1': [{ x: "-100%", y: "-100%" }, { y: "200%" }, { width: 0 }],
  'preset-IMMB': [{ x: "-100%" }, { x: "100%", y: "200%" }, { width: 0 }],
  'slide-up': [{ y: 500 }, { y: 500 }, { y: 500 }],
  'slide-right': [{x: -1500 }, { x: -1500 }, { x: -1500 }],
  'slide-down': [{ y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-left': [{x: 2000 }, { x: 2000 }, { x: 2000 }],
}

var endAnimations = {
  'preset-1': [{ x: "-105%" }, { y: "200%" }, { width: 0 }],
  'preset-IMMB': [{ x: "-100%" }, { x: "100%", y: "200%" }, { width: 0 }],
  'slide-up': [{ y: -1500 }, { y: -1500 }, { y: -1500 }],
  'slide-right': [{x: 2000 }, { x: 2000 }, { x: 2000 }],
  'slide-down': [{ y: 500 }, { y: 500 }, { y: 500 }],
  'slide-left': [{x: -2000 }, { x: -2000 }, { x: -2000 }],
}



function CayronOverlay(props) {
  const { message, defaultMessage, dummy, project, show } = props;
  const [overlay, setOverlay] = useState(null);
  const [projectStyles, setProjectStyles] = useState(null);



  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect(() => {
    if (dummy) {
      setOverlay(defaultMessage);
    } else {
      setOverlay(message?.snippet);
    }
  }, [message, dummy]);


  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);


  function checkFunc() {
    return show != null && overlay && ( 
      overlay.profileImageUrl || 
      overlay.authorInitials || 
      (overlay.displayName && overlay.displayName.split(' ').join('')) || 
      (overlay.currentMessage && overlay.currentMessage.split(' ').join(''))
    )
  }






  function IMMBelement() {
    {/* EXTRA COMPONENTS FOR IMMB-cayrons */}
    return <div className="flex w-full justify-between">
      <motion.div 
        initial={{ scaleX: 0, scaleY: 0, y: '-7px' }}
        animate={{ scaleX: 1, scaleY: 0.8, y: '-7px' }}
        exit={{ scaleX: 0, scaleY: 0, y: '-7px' }}
        transition={{ ease: "easeInOut", duration: 0.5, delay: 0.5 }}
        className={`py-9`} style={{ backgroundColor: '#0EBB3A' }}
      >
        <div className={`${projectStyles?.cayronFontMarginLeftBodyBox? projectStyles.cayronFontMarginLeftBodyBox : ''}`}></div>
      </motion.div>

      <motion.div 
        initial={{ scaleX: 0, scaleY: 0, y: '-6px' }}
        animate={{ scaleX: 1, scaleY: 0.8, y: '-6px' }}
        exit={{ scaleX: 0, scaleY: 0, y: '-6px' }}
        transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
        style={{ marginLeft: '-80px', marginTop: '-1px' }}
        >
        <motion.div 
          initial={{ scaleX: 0, scaleY: 0 }}
          animate={{ scaleX: 1, scaleY: 1 }}
          exit={{ scaleX: 0, scaleY: 0 }}
          transition={{ ease: "easeInOut", duration: 0.5, delay: 1.2 }}
          className="pl-10 pr-10 py-9" style={{ backgroundColor: '#EE502B' }}
        >
        </motion.div>
      </motion.div>
    </div>
  }






  
  return (
    show || show === null? <div className={`fixed top-0 left-0 z-50 w-screen h-screen 
      ${ checkFunc()? 'bg-gray-300 bg-opacity-50 cursor-default' : 'pointer-events-none' }
      ${(show === false && show != null)? 'hidden ' : ''} `}
    >
      
      <div className={`fixed z-50 w-screen h-screen ${
        checkFunc()? 'bg-gray-300 border border-gray-600 rounded-sm' : 'pointer-events-none top-0 left-0' } `}
        style={{ 
          transform: checkFunc()? 'scale(0.45) translate(-111%, -111%)' : null,
          minWidth: checkFunc()? '1920px' : null,
          minHeight: checkFunc()? '1080px' : null,
          maxWidth: checkFunc()? '1920px' : null,
          maxHeight: checkFunc()? '1080px' : null,
          top: checkFunc()? '50%' : null,
          left: checkFunc()? '50%' : null,    
        }}
      >
        
        {checkFunc() && <div onClick={() => message.setShowMessagePreview(!message.show)} className="text-right top-0 right-0 fixed font-bold text-gray-500 z-50 hover:text-gray-900 transform transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-16 w-16" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
        </div>}

        <AnimatePresence >

          {overlay && (
            <div
              key={overlay.id}
              className="absolute left-10 text-2xl pl-20"
              style={{ bottom: '120px', overflow: 'hidden', }}
            >

              <div className="flex flex-col overflow-hidden">
                {overlay.displayName && 
                  <motion.div
                    initial={projectStyles && startAnimations[projectStyles?.cayronStartAnimation][0]? startAnimations[projectStyles.cayronStartAnimation][0] : startAnimations['preset-1'][0]}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={projectStyles && endAnimations[projectStyles?.cayronEndAnimation][0]? endAnimations[projectStyles.cayronEndAnimation][0] : endAnimations['preset-1'][0]}
                    transition={{ ease: [1, 0.01, 0.61, 1], duration: !isNaN(projectStyles?.cayronAnimationDurationNameBox)? projectStyles.cayronAnimationDurationNameBox : 0.5, delay: !isNaN(projectStyles?.cayronAnimationDelayNameBox)? projectStyles.cayronAnimationDelayNameBox : 0.0 }}
                    className={`overflow-hidden ${projectStyles?.cayronClasesNameBox? projectStyles.cayronClasesNameBox : ''}`}
                    style={{
                      backgroundColor: projectStyles?.cayronColorName? projectStyles.cayronColorName : '#19191B',
                      color: projectStyles?.cayronColorNameText? projectStyles.cayronColorNameText : '#F4F4F5', 
                    }}
                  >

                    <motion.div
                      initial={projectStyles && startAnimations[projectStyles?.cayronStartAnimation][1]? startAnimations[projectStyles.cayronStartAnimation][1] : startAnimations['preset-1'][1]}
                      animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                      exit={projectStyles && endAnimations[projectStyles?.cayronEndAnimation][1]? endAnimations[projectStyles.cayronEndAnimation][1] : endAnimations['preset-1'][1]}
                      transition={{ ease: [1, 0.01, 0.61, 1], duration: !isNaN(projectStyles?.cayronAnimationDurationName)? projectStyles.cayronAnimationDurationName : 0.5, delay: !isNaN(projectStyles?.cayronAnimationDelayName)? projectStyles.cayronAnimationDelayName : 0.2 }}
                    >
                      <p className={`${projectStyles?.cayronClasesNameText? projectStyles.cayronClasesNameText : 'text-5xl anton-font p-5'} whitespace-nowrap`}>
                        {overlay.displayName} 
                      </p>
                    </motion.div>

                  </motion.div>
                }



                {overlay.currentMessage && 
                  <div className="flex">
                    <div className="flex flex-wrap">

                      <motion.div
                        initial={projectStyles && startAnimations[projectStyles?.cayronStartAnimation][2]? startAnimations[projectStyles.cayronStartAnimation][2] : startAnimations['preset-1'][2]}
                        animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                        exit={projectStyles && endAnimations[projectStyles?.cayronEndAnimation][2]? endAnimations[projectStyles.cayronEndAnimation][2] : endAnimations['preset-1'][2]}
                        transition={{ ease: "easeInOut", duration: !isNaN(projectStyles?.cayronAnimationDurationBusiness)? projectStyles.cayronAnimationDurationBusiness : 0.5, delay: !isNaN(projectStyles?.cayronAnimationDelayBusiness)? projectStyles.cayronAnimationDelayBusiness : 0.4 }}
                        className={`overflow-hidden ${projectStyles?.cayronClasesBodyBox? projectStyles.cayronClasesBodyBox : ''}`}
                        style={{
                          backgroundColor: projectStyles?.cayronColorBody? projectStyles.cayronColorBody : '#F4F4F5',
                          color: projectStyles?.cayronColorBodyText? projectStyles.cayronColorBodyText : '#52525B', 
                        }}
                      >
                        <p className={`${projectStyles?.cayronClasesBodyText? projectStyles.cayronClasesBodyText : 'text-2xl px-5 py-3'} whitespace-nowrap font-bold leading-tight`}>
                          {overlay.currentMessage.split('\n')[0]}
                        </p>
                      </motion.div>

                      {/* EXTRA COMPONENTS FOR IMMB-cayrons */}
                      {projectStyles?.cayronPreset === 'IMMB-cayrons' && !overlay.currentMessage.includes("\n") && <>{IMMBelement()}</>}

                    </div>
                  </div>
                }



                {overlay.currentMessage && overlay.currentMessage.includes("\n") && <>

                  {overlay.currentMessage.split('\n').map((element, key) =>
                    <div key={key} className="flex">
                      <div className="flex flex-wrap">

                        {element && key != 0 && <motion.div
                          initial={projectStyles && startAnimations[projectStyles?.cayronStartAnimation][2]? startAnimations[projectStyles.cayronStartAnimation][2] : startAnimations['preset-1'][2]}
                          animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                          exit={projectStyles && endAnimations[projectStyles?.cayronEndAnimation][2]? endAnimations[projectStyles.cayronEndAnimation][2] : endAnimations['preset-1'][2]}
                          transition={{ ease: "easeInOut", duration: !isNaN(projectStyles?.cayronAnimationDurationBusiness)? projectStyles.cayronAnimationDurationBusiness : 0.5, delay: !isNaN(projectStyles?.cayronAnimationDelayBusiness)? projectStyles.cayronAnimationDelayBusiness : 0.4 }}
                          className={`overflow-hidden ${projectStyles?.cayronClasesBodyBox? projectStyles.cayronClasesBodyBox : ''}`}
                          style={{
                            backgroundColor: projectStyles?.cayronColorBody? projectStyles.cayronColorBody : '#F4F4F5',
                            color: projectStyles?.cayronColorBodyText? projectStyles.cayronColorBodyText : '#52525B', 
                          }}
                        >
                          <p style={{ paddingTop: 0 }} className={`${projectStyles?.cayronClasesBodyText? projectStyles.cayronClasesBodyText : 'text-2xl px-5 py-3'} whitespace-nowrap font-bold leading-tight`}>
                            {element}
                          </p>
                        </motion.div>}


                        {/* EXTRA COMPONENTS FOR IMMB-cayrons */}
                        {projectStyles?.cayronPreset === 'IMMB-cayrons' && (key+1) ===  overlay.currentMessage.split('\n').length && <>{IMMBelement()}</>}
                      </div>
                    </div>
                  )}
                </>}









                
              </div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div> : null 
  );
}





const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const messageToShowTMP = ownProps.messageToShowTMP ?? null;
  const messages = state.firestore.ordered.overlays;

  if (!projectId && !messageToShowTMP ) return {};


  if (projectId) {
    const projects = state.firestore.data.projects;
    const users = state.firestore.data.users;
    const message = messages ? messages[0] : null;
    const project = projects ? projects[projectId] : null;
    const user = users && project ? users[project.authorId] : null;
    return {
      message: {
        ...message,
      },
      project,
      show: null,
    };
  } else if (messageToShowTMP.show) {
    const user = null;
    const message = messageToShowTMP;

    return {
      message: {
        ...message,
      },
      show: messageToShowTMP.show,
    };
  } else {
    return {}
  }


};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "cayron"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  })
)(CayronOverlay);

CayronOverlay.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

CayronOverlay.defaultProps = {
  defaultMessage: null,
  dummy: false,
};
