import { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { saveItem } from '../actions/projectActions'
import Spinner from './Spinner'
import SlateEditorCards from './SlateEditorCards'

import ReactQuill from 'react-quill';
import '../../node_modules/react-quill/dist/quill.snow.css';
import { serialize } from './../utils/'


const defaultContent = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ],
  },
]


function CardCreate(props) {

  const { saveItem, firebase, simple, categories } = props

  const [visible, setVisible] = useState(true)
  const [pictureIsLoading, setPictureIsLoading] = useState(false)




  const [showPrimaryBox, setShowPrimaryBox] = useState(true)
  const [showSecondaryBox, setShowSexondaryBox] = useState(true)
  const [showImageBox, setShowImageBox] = useState(true)

  const [ownCategory, setOwnCategory] = useState('null')
  const [hiddenTitle, setHiddenTitle] = useState('')

  const [title, setTitle] = useState(defaultContent)
  const [subtitle, setSubtitle] = useState(defaultContent)
  const [body, setBody] = useState(defaultContent)
  const [secondaryBody, setSecondaryBody] = useState(defaultContent)
  const [pictureURL, setPictureURL] = useState(null)





  const [fileName, setFileName] = useState(null)
  const [uploadInfo, setUploadInfo] = useState(null)

  const [showCardPreview, setShowCardPreview] = useState(false)
  const [disablePrevButton, setDisablePrevButton] = useState(true)

  var svg_image = <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="currentColor"><path d="M11.8 34.15H36.25L28.9 24.35L22.3 32.9L17.65 26.55ZM9 42Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H39Q40.2 6 41.1 6.9Q42 7.8 42 9V39Q42 40.2 41.1 41.1Q40.2 42 39 42ZM9 39H39Q39 39 39 39Q39 39 39 39V9Q39 9 39 9Q39 9 39 9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39ZM9 9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39Q9 39 9 39Q9 39 9 39V9Q9 9 9 9Q9 9 9 9Z"/></svg>



  useEffect(() => {
    if ( pictureURL || 
      (title && title.map(node => serialize(node)).join('') != '') ||
      (subtitle && subtitle.map(node => serialize(node)).join('') != '') ||
      (body && body.map(node => serialize(node)).join('') != '') ||
      (secondaryBody && secondaryBody.map(node => serialize(node)).join('') != '')
    ) {
      setDisablePrevButton(false)
    } else {
      setDisablePrevButton(true)
    }
  }, [pictureURL, title, subtitle])

  const handleOnSubmit = (event) => {
    event.preventDefault()

    var titleTMP = title.map(node => serialize(node)).join('').replaceAll('\n', '')
    var subtitleTMP = subtitle.map(node => serialize(node)).join('').replaceAll('\n', '')
    var bodyTMP = body.map(node => serialize(node)).join('').replaceAll('\n', '')
    var secondaryBodyTMP = secondaryBody.map(node => serialize(node)).join('').replaceAll('\n', '')
    
    var card = {
      showPrimaryBox,
      showSecondaryBox,
      showImageBox,
      category: ownCategory,
      hiddenTitle: hiddenTitle,
      title: titleTMP,
      subtitle: subtitleTMP,
      body: bodyTMP,
      secondaryBody: secondaryBodyTMP,
      pictureURL,      
    }

    saveItem('card', card)
    setVisible(false)


    setShowPrimaryBox(true)
    setShowSexondaryBox(true)
    setShowImageBox(true)
    setOwnCategory('null')
    setHiddenTitle('')
    setTitle(defaultContent)
    setSubtitle(defaultContent)
    setBody(defaultContent)
    setSecondaryBody(defaultContent)
    setPictureURL(null)
  }

  const preventPressEnter = event => { if (event.key === 'Enter') event.preventDefault() }

  const keyUp = (event) => {
    if (event.keyCode === 27) {
      setVisible(false)
    }
  }

  const handleFileOnChange = event => {
    const file = event.target.files[0]
    if (file?.size < 5000000) {
      setPictureIsLoading(true)

      const storageRef = firebase.storage().ref(`images/${file.name}`)
      const task = storageRef.put(file)

      task.on('state_changed', snapshoot => {}, error => setUploadInfo(error.message), () => {
        storageRef.getDownloadURL().then(url => {
          setPictureIsLoading(false)
          setPictureURL(url)
        })
      })
      
      setFileName(event.target.files[0].name)
    } else {
      setUploadInfo("L'arxiu ha de ser inferior a 5000KB")
    }
  }

  const deletePicture = () => {
    firebase.storage().ref(`images`).child(`${fileName}`).delete()
    setPictureURL(null)
    document.getElementById("uploadeFile1").value = null;
  }
  
  


  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700`}>
      {simple != true && <button onClick={() => setVisible(!visible)} onKeyUp={keyUp} className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Crear nova targeta</h2>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} className="hover:text-gray-200">
        {!visible ?
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" />
          </svg>
        } 
        </motion.div>
      </button>}
      <AnimatePresence>
        {visible &&
          <motion.form
            key="messageForm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleOnSubmit}
            onKeyPress={preventPressEnter}
            className={`p-4 ${simple != true? 'border-t-2' : ''} dark:border-gray-700`}
          >


            <div className='flex w-full mb-2'>
              {/* <select name='categoria' onChange={(event) => setOwnCategory(event.target.value)} defaultValue={null} className="w-full p-1">
                <option value={null}>Sense categoria</option>
              </select> */}

              <select name='categoria' onChange={(event) => setOwnCategory(event.target.value)} defaultValue={'null'} className="w-full p-1">
                {categories?.map(element => {
                  return <option key={element.id} value={element.id}>{element.txt}</option>
                })}
              </select>
            </div>

            <input type="text" placeholder="Títol intern" autoComplete="off" onChange={ (event) => setHiddenTitle(event.target.value) } className="w-full mb-2"/>

            
            <hr className='mb-3 mt-1'></hr>


            <div className='flex mb-1'>
              <label className="btn--tool whitespace-nowrap" style={{ userSelect: 'none', paddingTop: 0 }}>
                <input type="checkbox" style={{ marginRight: '5px' }} checked={showImageBox} onChange={(event) => { setShowImageBox(!showImageBox); }}/> 
                Imatge
              </label>
              <label className="btn--tool whitespace-nowrap" style={{ userSelect: 'none', paddingTop: 0 }}>
                <input type="checkbox" style={{ marginRight: '5px', marginLeft: '10px' }} checked={showPrimaryBox} onChange={(event) => { setShowPrimaryBox(!showPrimaryBox); }}/> 
                Títol & Subtítol
              </label>
              <label className="btn--tool whitespace-nowrap" style={{ userSelect: 'none', paddingTop: 0 }}>
                <input type="checkbox" style={{ marginRight: '5px', marginLeft: '10px' }} checked={showSecondaryBox} onChange={(event) => { setShowSexondaryBox(!showSecondaryBox); }}/> 
                Textos inferiors
              </label>
            </div>
            

            {showImageBox && <div className="relative w-full h-80 mb-2 overflow-hidden bg-gray-200 select-none flex items-center justify-center">
              {pictureURL?
                <img src={pictureURL} className="w-full h-80 object-cover" alt="profile" />
              : pictureIsLoading? 
                <Spinner className="w-20 h-20 grid place-items-center p-6"/> 
                :
                <div className='text-gray-300 w-full h-full flex items-center justify-center scale-125'>
                  {svg_image}
                </div>
              }
            </div>}



            {showImageBox && <div className="flex flex-wrap items-center space-x-2 my-2">
              
              {uploadInfo && <div className="bg-red-500 mb-2 w-full text-gray-50 px-2 py-1 rounded-sm text-sm flex items-center justify-between space-x-2">
                <div className='flex'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <span>{uploadInfo}</span>
                </div>
                <button onClick={() => setUploadInfo(null)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div> }

              <div className='flex flex-nowrap items-center space-x-2'>
                {pictureURL && <button onClick={deletePicture} className="btn--tool" type="button" style={{ userSelect: 'none' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                  <span>Esborrar</span>
                </button> }

                <label className="btn--tool" style={{ userSelect: 'none' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                  <span>Pujar Imatge</span>
                  <input type="file" id="uploadeFile1" onChange={handleFileOnChange} style={{ display: "none" }}/>
                </label>
              </div>

            </div>}


            
            {showPrimaryBox && <div className="flex-1">
              
              {/* <input type="text" placeholder="Títol de la caixa principal" autoComplete="off" onChange={ (event) => setTitle(event.target.value) } className="w-full mb-2"/> */}
              <div className='mb-2'>
                {/* <ReactQuill placeholder='Títol de la caixa principal' theme="snow" value={title} onChange={setTitle} className="bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:active:bg-gray-800 bg-opacity-40 focus:outline-none transition duration-150 ease-in-out"/> */}
                <SlateEditorCards key="title" contentValue={title} setContentValue={setTitle} placeholder='Títol de la caixa principal'/>
              </div>
              
              {/* <input type="text" placeholder="Subtítol de la caixa principal" autoComplete="off" onChange={ (event) => setSubtitle(event.target.value) } className="w-full py-2"/> */}
              <SlateEditorCards key="subtitle" contentValue={subtitle} setContentValue={setSubtitle} placeholder='Subtítol de la caixa principal'/>

            </div>}

            {showSecondaryBox && <div className="flex-1">
              <div className='mt-2'>
                {/* <ReactQuill placeholder='Text de la caixa principal' theme="snow" value={body} onChange={setBody} className="bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:active:bg-gray-800 bg-opacity-40 focus:outline-none transition duration-150 ease-in-out"/> */}
                <SlateEditorCards key="body" contentValue={body} setContentValue={setBody} placeholder='Text de la caixa principal'/>
              </div>
              <div className='mt-2'>
                {/* <ReactQuill placeholder='Text de la caixa inferior' theme="snow" value={secondaryBody} onChange={setSecondaryBody} className="bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:active:bg-gray-800 bg-opacity-40 focus:outline-none transition duration-150 ease-in-out"/> */}
                <SlateEditorCards key="secondaryBody" contentValue={secondaryBody} setContentValue={setSecondaryBody} placeholder='Text de la caixa inferior'/>
              </div>
            </div>}


            <div className="space-x-2 flex justify-between text-sm lg:flex-row-reverse lg:-ml-2 pt-2">
              <div className='flex space-x-2'>
                {/* <button type="button" className={`${showCardPreview? 'btn--tool--blue' : 'btn--tool--gray'}`} onClick={() => setShowCardPreview(!showCardPreview)} disabled={disablePrevButton}>
                  {!showCardPreview?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                    : 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                  }
                  <span>Vista prèvia</span>
                </button> */}
                
                {simple != true && <button type="submit" className="btn--tool--blue" disabled={disablePrevButton}> 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                  </svg>
                  <span>Desa</span>
                </button>}
              </div>

              {simple != true && <button type="button" onClick={() => setVisible(false)} className="btn--tool--gray"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
                <span>Cancel·lar</span>
              </button>}
            </div>



          </motion.form>
        }
      </AnimatePresence>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {};
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(CardCreate)
