import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { saveItem } from '../actions/projectActions'
import ReactAudioPlayer from 'react-audio-player'
import Spinner from './Spinner'

import AudioMessageOverlay from "./Overlays/audioMessage";


function AudioMessageCreate(props) {

  const { saveItem, firebase, simple, projectStyles } = props

  const [visible, setVisible] = useState(simple? simple : false)
  const [authorInitials, setAuthorInitials] = useState('')
  const [uploadInfo, setUploadInfo] = useState(null)
  const [pictureURL, setPictureURL] = useState(null)
  const [audioURL, setAudioURL] = useState(null)
  const [fileIsLoading, setFileIsLoading] = useState(false)
  const [pictureFileName, setPictureFileName] = useState(null)
  const [audioFileName, setAudioFileName] = useState(null)

  const [messageAuthorName, setMessageAuthorName] = useState('')
  const [authorRole, setAuthorRole] = useState('')
  const [messageBodyText, setMessageBodyText] = useState('')

  const [showMessagePreview, setShowMessagePreview] = useState(false)
  const [disablePrevButton, setDisablePrevButton] = useState(true)



  useEffect(() => {
    if ( pictureURL || audioURL || 
      (messageAuthorName && messageAuthorName.split(' ').join(''))
    ) {
      setDisablePrevButton(false)
    } else {
      setDisablePrevButton(true)
    }
  }, [pictureURL, audioURL, messageAuthorName])


  useEffect(() => {
    if (!visible) {
      setPictureURL(null)
      setAudioURL(null)
      setUploadInfo(null)
    }
  }, [visible])


  const handleOnSubmit = (event) => {
    event.preventDefault()
    saveItem( 'audioMessage', { 
      displayName: messageAuthorName,
      authorRole: authorRole,
      audioNotes: messageBodyText,
      authorInitials: authorInitials,
      profileImageUrl: pictureURL ?? null,
      audioMessageUrl: audioURL ?? null,
      isReady: false
    });

    setVisible(false);
    setAuthorInitials('');
    setMessageBodyText('');
    setDisablePrevButton(true);
    setShowMessagePreview(false);
  }


  const preventPressEnter = event => { if (event.key === 'Enter') event.preventDefault() }


  const keyUp = (event) => {
    if (event.keyCode === 27) {
      setVisible(false)
    }
  }



  const handleFileOnChange = event => {
    const file = event.target.files[0]

    if ((file.type.includes('image') && file.size < 550000) || !file.type.includes('image')) {
      const filePath = `${file.type.includes('image') ? `images` : (file.type.includes('audio') || file.type.includes('video')) ? `audios` : `files`}`
      const storageRef = firebase.storage().ref(`${filePath}/${file.name}`)
      const task = storageRef.put(file)
      setFileIsLoading(filePath)
      task.on('state_changed',
      snapshoot => {},
      error => setUploadInfo(error.message),
      () => {
        storageRef.getDownloadURL().then(url => {
          setFileIsLoading(false)
          if (filePath === 'images') setPictureURL(url) // fer switch?
          if (filePath === 'audios') setAudioURL(url)
        })
      })
      if (filePath === 'images') setPictureFileName(event.target.files[0].name)
      if (filePath === 'audios') setAudioFileName(event.target.files[0].name)
    } else {
      setUploadInfo("La imatge ha de ser inferior a 550KB")
    }
  }

  const deletePicture = () => {
    firebase.storage().ref(`images`).child(pictureFileName).delete()
    setPictureURL(null)
  }

  const deleteAudio = () => {
    firebase.storage().ref(`audios`).child(audioFileName).delete()
    setAudioURL(null)
    document.getElementById("uploadeFile3").value = null;
  }
  
  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700`}>
      {simple != true && <button onClick={() => setVisible(!visible)} onKeyUp={keyUp} className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Crear un missatge d'àudio nou</h2>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} className="hover:text-gray-200">
        {!visible ?
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" />
          </svg>
          } 
        </motion.div>
      </button>}
      <AnimatePresence>
      {visible &&
        <motion.form
          key="audioMessageForm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onSubmit={handleOnSubmit}
          onKeyPress={preventPressEnter}
          className={`p-4 ${simple != true? 'border-t-2' : ''} dark:border-gray-700`}
        >

          <div className="flex space-x-2 mb-3">

            <div className="relative w-20 h-20 rounded-full overflow-hidden">
              {pictureURL ?
                <img src={pictureURL} className="w-20 h-20 object-cover" alt="profile" />
                : 
                fileIsLoading === 'images' ? 
                  <Spinner className="w-full h-full grid place-items-center p-6"/>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212"><path fill="#DFE5E7" d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"></path><path fill="#FFF" d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"></path></svg>
              }
            </div>


            <div className="flex-1">
              <div className="flex">
                <input id="authorName" onKeyUp={keyUp} autoComplete="off" type="text" className="flex-1 mb-2" onChange={(event) => setMessageAuthorName(event.target.value) } placeholder="Nom"/>
                <input id="authorRole" onKeyUp={keyUp} autoComplete="off" type="text" className="flex-1 mb-2 ml-2" onChange={(event) => setAuthorRole(event.target.value) } placeholder="Informació addicional"/>
              </div>



              <input id="audioNotes" onKeyUp={keyUp} onChange={(event) => setMessageBodyText(event.target.value) } autoComplete="off" className="w-full py-2" type="text" placeholder="Notes de text" name=""/>
            
              <div className='flex w-full pt-2 space-x-2'>
                <div className="flex items-center space-x-2">
                  {uploadInfo &&
                    <div className="bg-red-500 text-gray-50 px-2 py-1 rounded-sm text-sm flex items-center space-x-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                      <span>{uploadInfo}</span>
                      <button onClick={() => setUploadInfo(null)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                  }

                  {pictureURL &&
                    <button onClick={deletePicture} className="btn--tool--gray" type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                      <span>Esborrar imatge</span>
                    </button>
                  }

                  <label className="btn--tool--gray">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                    </svg>
                    <span>Pujar Imatge</span>
                    <input id="uploadeFile3" style={{ display: "none" }} type="file" onChange={handleFileOnChange} />
                  </label>
                </div>

                <div className='space-x-2'>
                  {audioURL ?
                    <div className="flex items-center space-x-2">
                      <div className='bg-gray-200 rounded-sm flex items-center overflow-hidden' style={{ height: '36px' }}><ReactAudioPlayer src={audioURL} controls /></div>
                      <button onClick={deleteAudio} className="btn--tool--gray" type="button" style={{ height: '36px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        <span>Esborrar àudio</span>
                      </button>
                    </div>
                  :
                    fileIsLoading === 'audios' ?
                      <Spinner className="w-12 h-12 p-2"/>
                      :
                      <label className="btn--tool--gray flex-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
                          <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
                        </svg>
                        <span>Pujar àudio</span>
                        <input style={{ display: "none" }} type="file" onChange={handleFileOnChange} className="w-full" required />
                      </label>
                  }
                </div>
              </div>
            
            </div>
          </div>


          <div className="space-x-2 flex justify-between text-sm lg:flex-row-reverse lg:-ml-2 pt-2">
            <div className='flex space-x-2'>
              <button type="button" className={`${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`} onClick={() => setShowMessagePreview(!showMessagePreview)} disabled={disablePrevButton}>
                {!showMessagePreview?
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                  : 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                }
                <span>Vista prèvia</span>
              </button>
              
              {simple != true && <button type="submit" className="btn--tool--blue" disabled={disablePrevButton}> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                </svg>
                <span>Desa</span>
              </button>}
            </div>

            {simple != true && <button type="button" onClick={() => setVisible(false)} className="btn--tool--gray"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
              <span>Cancel·lar</span>
            </button>}
          </div>


          <AudioMessageOverlay 
            messageToShowTMP={{ 
              id: '',
              snippet: {
                displayName: messageAuthorName,
                authorRole: authorRole,
                profileImageUrl: pictureURL ?? null,
                audioMessageUrl: audioURL ?? null,
                isReady: false
              },
              type: 'chatMessage',
              show: showMessagePreview,
              projectStyles: projectStyles,
              setShowMessagePreview: setShowMessagePreview,
            }} 
          /> 




        </motion.form>
      }
      </AnimatePresence>
    </div>
  )
}




const mapStateToProps = (state, ownProps) => {
  return {};
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(AudioMessageCreate)