import { connect } from "react-redux";
import { motion } from "framer-motion";
import { setSubsBroadcastId, setPublicURL } from "../actions/projectActions";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SubtitlesCreate(props) {
  const { project, setSubsBroadcastId, setPublicURL } = props;
  return (
    <div className="border-2 dark:border-gray-700">
      <div className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Subtítols</h2>
      </div>
      <div className="p-3 border-t-2 dark:border-gray-600 space-y-3">
        <div className="relative flex items-center">
          <label
            htmlFor="broadcastID"
            className="inputStyle mb-0 bg-opacity-30 py-1 px-2 text-gray-500 font-light border-r border-solid dark:border-gray-700 border-gray-200 whitespace-nowrap"
          >
            YouTube ID
          </label>
          <input
            id="broadcastID"
            className="inputStyle w-full text-gray-400 focus:text-green-300 bg-opacity-30"
            type="text"
            onChange={(event) => setSubsBroadcastId(event.target.value)}
            defaultValue={project.subtitles?.broadcastId ?? ``}
          />
          <a
            href={`https://youtu.be/${project.subtitles?.broadcastId ?? ``}`}
            className="absolute right-1 text-gray-500 hover:text-gray-200"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
              />
            </svg>
          </a>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="publicURL"
            className="bg-gray-100 relative py-1 px-2 text-gray-500 font-light border-b dark:border-gray-700"
          >
            URL pública
            <CopyToClipboard
              text={`${process.env.REACT_APP_BASE}${project.publicURL}`}
            >
              <motion.button
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                className="absolute right-8 hover:text-gray-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                  />
                </svg>
              </motion.button>
            </CopyToClipboard>

            <a
              href={`${process.env.REACT_APP_BASE}${project?.publicURL}`}
              className="absolute right-1 hover:text-gray-200"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                />
              </svg>
            </a>
          </label>
          <div className="bg-gray-100 flex items-center p-2 overflow-hidden">
            <div className="pointer-events-none"> {process.env.REACT_APP_BASE} </div>
            <input
              id="publicURL"
              className="p-0 ml-2 w-full"
              type="text"
              placeholder="..."
              onChange={(event) => setPublicURL(event.target.value?.replaceAll('/', ''))}
              defaultValue={project.publicURL}
            />
          </div>
        </div>
        {/* <a
          href={`${process.env.REACT_APP_BASE}subtitulador/${project.id}`}
          target="_blank"
          rel="noreferrer"
          className="btn--icon space-x-2 uppercase font-semibold text-blue-500"
        >
          <span>Sala de subtítols</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            />
          </svg>
        </a> */}
      </div>
    </div>
  );
}

export default connect(null, (dispatch, ownProps) => {
  const projectId = ownProps.project.id;
  return {
    setSubsBroadcastId: (value) => dispatch(setSubsBroadcastId(value, projectId)),
    setPublicURL: (value) => dispatch(setPublicURL(value, projectId)),
  };
})(SubtitlesCreate);
