import { Redirect, useLocation } from "react-router-dom";

function UserSettings() {
  const location = useLocation();
  return (
    <Redirect
      to={
        location.pathname === "/settings"
          ? "/settings/general"
          : location.pathname
      }
    />
  );
}

export default UserSettings;
