import { useState } from 'react'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import OverlayCreate from './OverlayCreate'
import { setNotes } from './../actions/projectActions'
import { serialize } from './../utils/'

const sizes = ['md', 'lg', 'xl', '2xl', '3xl']
const defaultContent = [
  {
    type: 'paragraph',
    children: [
      { text: '' }
    ],
  },
]

function Presenter(props) {
  const { notes, setNotes } = props
  const [textSize, setTextSize] = useState(0)

  const increaseTextSize = () => {
    setTextSize(textSize+1)
  }

  const decreaseTextSize = () => {
    setTextSize(textSize-1)
  }

  const clearNotes = () => {
    setNotes({
      html: null,
      json: null
    })
  }




  return (
    <>
      <Helmet title="Presentador" />

      <div style={{ left: '20px' }} className="fixed z-20 top-5 space-x-4">
        <button onClick={clearNotes} className={`font-bold text-gray-500 select-none cursor-pointer text-${sizes[textSize]}`} disabled={(notes === null) || (notes === '<p></p>')}>
          🗑️
        </button>
      </div>

      <div className="fixed z-20 top-5 right-20 space-x-4">
        <button onClick={increaseTextSize} className={`font-bold text-gray-500 select-none cursor-pointer text-${sizes[textSize]}`} disabled={textSize === sizes.length-1}>
          A+
        </button>
        <button onClick={decreaseTextSize} className={`font-bold text-gray-500 select-none cursor-pointer text-${sizes[textSize]}`} disabled={textSize === sizes.length-sizes.length}>
          A-
        </button>
      </div>

      <div className={`h-screen bg-gray-50 text-[#000] dark:bg-[#000] dark:text-[#fff] text-${sizes[textSize]} overflow-hidden`}>
        
        <div style={{ marginTop: '60px' }} className='flex flex-wrap items-baseline justify-between fixed z-10 top-0 left-0 right-0 border-b-2 pb-4 px-4 border-[#000] dark:border-[#fff] bg-gray-50 text-[#000] dark:bg-[#000] dark:text-[#fff]'>
          <div style={{ maxHeight: '132px', borderRadius: 5 }} className={`w-full overflow-auto px-3 ${((notes === '<p></p>') || (notes === null))? '' : 'bg-gray-300 bg-opacity-50'}`} dangerouslySetInnerHTML={{ __html: (notes === '<p></p>') || (notes === null) ? '<h2>Notes</h2>' : notes }} />
        </div>          


        <div style={{ paddingTop: '120px' }} className="p-5 dark:border-gray-600">

          <div className='flex items-center justify-center w-full'>
            <div className='selected-message w-full py-4 max-w-7xl flex items-start justify-between border-b-2 border-[#000] dark:border-[#fff]'>
            </div>
          </div>

          <OverlayCreate {...props} title="Llista de missatges" role="presenter" hiddeGroups={true} />
        </div>
      </div>
    </>
  )
}

export default compose(
  firestoreConnect(),
  connect((state, ownProps) => {
    const projectId = ownProps.match.params.id
    const projects = state.firestore.data?.projects ?? null
    const project = projects ? projects[projectId] : null
    return { notes: project?.notes?.html }
  }, (dispatch, ownProps) => {
    const projectId = ownProps.match.params.id
    return {
      setNotes: (notes) => dispatch(setNotes(notes, projectId))
    }
  })
)(Presenter)