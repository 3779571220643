import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./components/Layout";
import ProjectCreate from "./components/ProjectCreate";
import Overlayer from "./components/Overlayer";
import Project from "./components/Project";
import Subtitulator from "./components/Subtitulator";
import Subtitulator2 from "./components/Subtitulator2";
import AudioMessenger from "./components/AudioMessenger";
import Messenger from "./components/Messenger";
import Configurador from "./components/Configurador";
import Dashboard from "./components/Dashboard";
import SignIn from "./components/SignIn";
import PageNotFound from "./components/PageNotFound";
import UserSettings from "./components/Settings";
import SettingsLayout from "./components/Settings/layout";
import SettingsApearance from "./components/Settings/appearance";
import SettingsGeneral from "./components/Settings/general";
import Presenter from "./components/Presenter";
import PresenterNotifications from "./components/PresenterNotifications";


import MessageOverlay from "./components/Overlays/message";
import AudioMessageOverlay from "./components/Overlays/audioMessage";
import CardOverlay from "./components/Overlays/card";
import Subtitles from "./components/Overlays/onlySubtitles";
import SubtitlesOverlay from "./components/Overlays/subtitles";
import SubtitlesOnlyOverlay from "./components/Overlays/subtitlesOnly";
import CayronOverlay from "./components/Overlays/cayron";
import PollOverlay from "./components/Overlays/poll";
/* import LocationOverlay from "./components/Overlays/location"; */

import PollVoting from "./components/PollVoting";


function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/c" component={ProjectCreate} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/subtitulador-old/:id" component={Subtitulator} />
          <Route exact path="/subtitulador/:id" component={Subtitulator2} />
          <Route exact path="/moderador/:id" component={Messenger} />
          <Route exact path="/configurador/:id" component={Configurador} />

          <Route exact path="/presentador/:id" component={Presenter} />
          <Route exact path="/presentador-notifications/:id" component={PresenterNotifications} />

          <Route exact path="/audioModerador/:id" component={AudioMessenger} />
          <Route exact path="/emisor/:id" component={Overlayer} />
          <Route exact path="/s/:id" component={Subtitulator} />
          <Route exact path="/:id/admin" component={Project} />
          <Route path="/subtitles/:id" component={Subtitles} />
          <Route path="/:id/so" component={SubtitlesOnlyOverlay} />

          <Route exact path="/:id/messages-overlay" component={MessageOverlay} />
          <Route exact path="/:id/audio-messages-overlay" component={AudioMessageOverlay} />
          <Route exact path="/:id/cayrons-overlay" component={CayronOverlay} />
          <Route exact path="/:id/cards-overlay" component={CardOverlay} />
          {/* <Route exact path="/:id/location-overlay" component={LocationOverlay} /> */}


          <Route exact path="/:id/polls-overlay" component={PollOverlay} />
          <Route exact path="/poll-voting/:id/:pollId" component={PollVoting} />


          <Route exact path="/settings" component={() => (
            <SettingsLayout>
              <UserSettings />
            </SettingsLayout>
          )} />

          <Route exact path="/settings/general" component={() => (
            <SettingsLayout>
              <SettingsGeneral />
            </SettingsLayout>
          )} />

          <Route exact path="/settings/appearance" component={() => (
            <SettingsLayout>
              <SettingsApearance />
            </SettingsLayout>
          )} />

          <Route exact path="/404" component={PageNotFound} />
          <Route path="/:id" component={SubtitlesOverlay} />
          {/* No se puede hacer 404 por defecto aqui porque al encontrar un /some lo llevara a /:id, se debería hacer una condición en la page de /:id y redireccionar a 404 */}
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
