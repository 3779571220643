import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { motion, AnimatePresence } from 'framer-motion'
import { addSubsOverlay, updateSubsOverlay, removeOverlay } from '../actions/overlayActions'
import { saveSubtitles, saveSubtitlesHistory } from '../actions/projectActions'
import YoutubeEmbed from './YoutubeEmbed'
import Spinner from './Spinner'

function Subtitulator(props) {
  const { 
    addSubsOverlay,
    updateSubsOverlay,
    removeOverlay,
    subtitle,
    saveSubtitles,
    saveSubtitlesHistory,
    history,
    broadcastId
  } = props
  const [characters, setCharacters] = useState(0)
  const [words, setWords] = useState(1)
  const [viewOverlay, setViewOverlay] = useState(null)
  const [timer, setTimer] = useState(null)
  useEffect(() => {
    if (subtitle) setViewOverlay(subtitle.snippet)
  }, [subtitle])
  const handleOnSubmit = (event) => {
    event.preventDefault()
    saveSubtitles({
      clipboard: event.target['inputSubtitles'].value
    })
    saveSubtitlesHistory(event.target['inputSubtitles'].value)
    updateSubsOverlay({
      displayText: event.target['inputSubtitles'].value
    })
    setTimer(setTimeout(() => {
      updateSubsOverlay({
        displayText: ''
      })
    }, 5000))
    event.target['inputSubtitles'].value = ''
    setCharacters(0)
  }

  const toggleOverlay = () => {
    if (!subtitle.id) {
      addSubsOverlay({
        displayText: ''
      })
    } else {
      removeOverlay()
    }
  }

  const keyUp = (event) => {
    if (event.keyCode === 32 || event.keyCode === 189 || event.keyCode === 190 || event.keyCode === 188) {
      setWords(words+1)
      updateSubsOverlay({
        displayText: event.target.value
      })
      if (words % 18 === 0 ) {
        saveSubtitles({
          clipboard: event.target.value
        })
        saveSubtitlesHistory(event.target.value)
        event.target.value = ''
      }
      setTimer(clearTimeout(timer))
    }
  }
  return (
    <div className="text-gray-900">
      <div className="max-w-lg mx-auto m-10 relative z-20">
        {broadcastId ? <YoutubeEmbed id={broadcastId} /> : <Spinner className="mx-auto mt-40" />}
      </div>
      <button className={`fixed z-20 top-5 right-5 p-5 border border-gray-900 bg-gradient-to-b ${subtitle.id ? `from-red-500 to-red-700 border-red-500` : `from-gray-300 to-gray-400`}`} onClick={() => toggleOverlay()}>
        {subtitle.id ?
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
          </svg>
        :
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
          </svg>
        }
      </button>
      {history &&
        <ul className="absolute pointer-events-none hidden z-20 left-1/2 bottom-1/2 pb-12 w-full max-w-md transform -translate-x-1/2 flex-col space-y-5 mx-auto text-lg">
          <AnimatePresence>
            {history.map((item) => (
              <motion.li key={item.id} layout initial={viewOverlay ? { y: 100, scale: 1.5 } : false} animate={{ y: 0, scale: 1 }} transition={{ type: 'spring', damping: 20 }} className="px-3 py-1 rounded leading-tight text-gray-400 bg-gray-900">
                <p>{item.displayText}</p>
              </motion.li>
            ))}
          </AnimatePresence>
        </ul>
      }
      {viewOverlay &&
        <div className="absolute z-20 bottom-1/3 left-1/2 transform -translate-x-1/2 w-full mt-20 px-12">
          <div className=" w-full max-w-6xl pb-5 h-36 mx-auto text-5xl font-regular leading-tight bg-gray-900 px-3 py-1 rounded">
            <p className="text-gray-100">{viewOverlay.displayText}</p>
          </div>
        </div>
      }
      <div className="absolute z-20 top-3/4 left-1/2 transform -translate-x-1/2 px-10 py-5 rounded text-center w-full max-w-7xl">
        <form onSubmit={handleOnSubmit}>
          <input id="inputSubtitles" type="text" onKeyDown={keyUp} onChange={() => setCharacters(characters+1)} autoComplete="off" className="text-left text-5xl px-5 py-3 w-full border-2 border-gray-900 bg-gray-100 rounded-none"/>
        </form>
        {/* <div className={`py-6 text-xl text-gray-600 font-semibold ${characters >= 65 && characters < 80 ? `text-yellow-500` : characters >= 80 ? `text-red-500` : null}`}>{characters}/90</div> */}
      </div>
      <span className="fixed w-screen h-screen top-0 left-0 bg-gray-100 block" style={{ zIndex: -1 }}/>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.id
  const subtitles = state.firestore.ordered.overlays ?? false
  const subtitle = subtitles ? subtitles[0] : null
  const projects = state.firestore.data.projects
  const project = projects ? projects[projectId] : null
  return {
    broadcastId: project?.subtitles.broadcastId ?? null,
    subtitle: {
      ...subtitle
    },
    history: project?.subtitles.history ?? null
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.match.params.id
  return {
    addSubsOverlay: (message) => dispatch(addSubsOverlay(message, projectId)),
    saveSubtitles: (subs) => dispatch(saveSubtitles(subs, projectId)),
    saveSubtitlesHistory: (subs) => dispatch(saveSubtitlesHistory(subs, projectId)),
    updateSubsOverlay: (message) => dispatch(updateSubsOverlay(message, projectId)),
    removeOverlay: () => dispatch(removeOverlay(projectId)),
  }
}

export default compose(
  firestoreConnect((props) => {
    const projectId = props.match.params.id
    if (!projectId) return []
    return [
      { collection: 'projects' },
      { 
        collection: 'projects',
        doc: projectId,
        subcollections: [
          {
            collection: 'overlays',
            where: [
              ['type', '==', 'subtitle']
            ],
          }
        ],
        storeAs: 'overlays'
      }
    ]
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Subtitulator)