import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";

import QRCode from "react-qr-code";



function PollOverlay(props) {
  const { message, defaultMessage, dummy, project, show } = props;
  const [overlay, setOverlay] = useState(null);
  const [projectStyles, setProjectStyles] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);
  const [isOpen, setIsOpen] = useState(false);





  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect( async () => {
    if (dummy) {
      setOverlay(defaultMessage);
    } else {
      setOverlay(message?.snippet);
    }
  }, [message, dummy]);


  useEffect( async () => {

    if (message && message.snippet && message.snippet.pollOptions && message.snippet.pollOptions.length > 0) {
      var total = 0;
      await message.snippet.pollOptions.map(element => {
        total = total + element.votes.length;
      });
      setTotalVotes(total);
      setIsOpen(message.snippet.isOpen);
    }

  }, [message]);


  useEffect(() => {
    setOverlay(defaultMessage);
  }, [defaultMessage]);



  function checkFunc() {
    return show != null/*  && overlay && ( 
      (overlay.pollQuestion && overlay.pollQuestion.split(' ').join('')) || 
      (overlay.currentMessage && overlay.currentMessage.split(' ').join(''))
    ) */
  }










  
  return (
    <AnimatePresence >

      {show || show === null? <div className={`fixed top-0 left-0 z-50 w-screen h-screen 
        ${ checkFunc()? 'bg-gray-300 bg-opacity-50 cursor-default' : 'pointer-events-none' }
        ${(show === false && show != null)? 'hidden ' : ''} `}
      >
        
        <div className={`fixed z-50 w-screen h-screen ${
          checkFunc()? 'bg-gray-300 border border-gray-600 rounded-sm' : 'pointer-events-none top-0 left-0' } `}
          style={{ 
            transform: checkFunc()? 'scale(0.45) translate(-111%, -111%)' : null,
            minWidth: checkFunc()? '1920px' : null,
            minHeight: checkFunc()? '1080px' : null,
            maxWidth: checkFunc()? '1920px' : null,
            maxHeight: checkFunc()? '1080px' : null,
            top: checkFunc()? '50%' : null,
            left: checkFunc()? '50%' : null,    
          }}
        >
          


          {checkFunc() && <div onClick={() => message.setShowMessagePreview(!message.show)} className="text-right top-0 right-0 fixed font-bold text-gray-500 z-50 hover:text-gray-900 transform transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-16 w-16" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
          </div>}


            {(overlay)? 
              <div
                key={overlay.id}
                className="absolute left-10 text-2xl pl-20"
                style={{ bottom: '120px', overflow: 'hidden' }}
              >                      
                <div className="flex">
                  <div 
                    className={`transition-all duration-700`}
                    style={{ width: (isOpen && overlay?.showQrCode)? '200px' : '0px', height: '191px', opacity: (isOpen && overlay?.showQrCode)? '1' : '0' }}
                  >
                    <div className="rounded overflow-hidden p-1 mr-2 bg-gray-900" style={{ backgroundColor: 'white' }}>
                      <div className="rounded overflow-hidden">
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={`${process.env.REACT_APP_BASE}poll-voting/${props?.location?.pathname?.split('/')[1]}/${overlay?.id}`}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="flex flex-col overflow-hidden">
                    <div className="flex" style={{ maxWidth: 800}}>
                      {(overlay.pollQuestion) && 
                        <div className={`overflow-hidden`} style={{ backgroundColor: '#19191B', color: '#F4F4F5' }}>
                          <div className={`w-full h-full px-3 py-2 relative`}>
                            <p className={`w-full`}>
                              {overlay.pollQuestion} 
                            </p>
                          </div> 

                        </div>
                      }
                    </div>

                    <div className="flex flex-col">

                      {(overlay.pollOptions) && 
                        overlay?.pollOptions.map((element, key) => {
                          return <>
                            <div className={`overflow-hidden mt-2 flex justify-items-stretc pr-20`} style={{ minWidth: '600px', maxWidth: 900 }}>



                              <div className="flex w-full" >
                                <div className="w-full flex relative ">

                                  <motion.div 
                                    key={'d'+key}
                                    initial={{ x: "-100%", height: "auto", width: "0px" }}
                                    animate={{  width: element.votes?  (totalVotes? ((element.votes.length / totalVotes) * 100).toFixed(0) : 0) + '%' : '0%', height: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                                    exit={{ x: "-100%", height: "auto", width: "0px" }}
                                    transition={{ ease: [1, 0.01, 0.61, 1], duration: 0.5, delay: ((key/3) + 0.7) }}
                                    className="absolute overflow-hidden flex z-10"
                                  /*  style={{ width: '9%' }} */
                                  >
                                    <div className={`w-full h-full px-3 py-1 flex justify-between items-center`} style={{ backgroundColor: 'rgba( 59, 130, 246, 0.4 )' }}>
                                      <p className={`w-full`} style={{ color: 'transparent', fontSize: 19, fontWeight: 500 }}>
                                        {element.text}
                                      </p>
                                    </div> 
                                  </motion.div>

                                  <div className={`w-full h-full px-3 py-1 flex justify-between items-center`} style={{ backgroundColor: 'lightgray' }}>
                                    <p className={`w-full text-gray-900 z-30`} style={{ fontSize: 19, fontWeight: 500 }}>
                                      {element.text} 
                                    </p>
                                  </div> 
                                
                                </div>



                                <div className="flex justify-center items-center px-2 ml-2" style={{ backgroundColor: 'lightgray', width: 130 }}>
                                  <p className="whitespace-nowrap text-sm"><strong> {(totalVotes? ((element.votes.length / totalVotes) * 100).toFixed(0) : 0)}%</strong> / {element.votes.length} vots </p>
                                </div>
                              </div>



                            </div>
                            
                          </>
                        })
                      }
                    </div>    
                  </div>
                </div>

                

              
                {(isOpen)?
                  <motion.p
                    key='a'
                    initial={ {y: "200%", height: "auto", width: "auto" }}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={{ y: "200%", height: "auto", width: "auto" }}
                    transition={{ ease: [1, 0.01, 0.61, 1], duration: 1, delay: 0.5 }}
                    className={`text-blue-500 whitespace-nowrap ${(overlay?.showQrCode)? 'px-1' : 'px-1'}`}
                    style={{ fontSize: 16 }}
                  >
                    Votacions obertes
                  </motion.p> 
                :
                  <motion.p
                    key='b'
                    initial={ {y: "200%", height: "auto", width: "auto" }}
                    animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                    exit={{ y: "200%", height: "auto", width: "auto" }}
                    transition={{ ease: [1, 0.01, 0.61, 1], duration: 1, delay: 0.5 }}
                    className={`text-red-600 whitespace-nowrap ${(overlay?.showQrCode)? 'px-2' : 'px-1'}`}
                    style={{ fontSize: 16 }}
                  >
                      Votacions tancades
                  </motion.p> 
                }
              </div>
            : null }
        </div>
      </div> : null} 
    </AnimatePresence>
    );
}





const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match?.params.id ?? null;
  const messageToShowTMP = ownProps.messageToShowTMP ?? null;
  const messages = state.firestore.ordered.overlays;


  if (!projectId && !messageToShowTMP ) return {};


  if (projectId) {
    const projects = state.firestore.data.projects;
    const users = state.firestore.data.users;
    const message = messages ? messages[0] : null;
    const project = projects ? projects[projectId] : null;
    const user = users && project ? users[project.authorId] : null;

    
    return {
      message: {
        ...message,
      },
      project,
      show: null,
    };
  } else if (messageToShowTMP.show) {
    const user = null;
    const message = messageToShowTMP;

    return {
      message: {
        ...message,
      },
      show: messageToShowTMP.show,
    };
  } else {
    return {}
  }


};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (!props.match) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "poll"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  })
)(PollOverlay);

PollOverlay.propTypes = {
  defaultMessage: PropTypes.object,
  dummy: PropTypes.bool,
};

PollOverlay.defaultProps = {
  defaultMessage: null,
  dummy: false,
};
