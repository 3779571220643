import { LOCALES } from '../locales'

const ca = {
  [LOCALES.ca]: {
    "hello": "Bon dia, com va tot?",
    "catala": 'Català',
    'castella': 'Castellà',
    'titol_configuracio': 'Configuració'
  }
}

export default ca