import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { setWidget, setProjectDarkMode } from "./../actions/projectActions";

const widgets = [
  "Overlay",
  "LiveChat",
  "Cards",
  "Messages",
  "Cayrons",
  "Polls",
  "Subtitles",
];



function ProjectConfig(props) {

  const { project, setWidget, setProjectDarkMode } = props;
  var svg_link = <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" ><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>


  return (
    <div className="text-gray-500 h-full flex flex-col justify-between">
      <div className="">
        <div className="flex flex-col">
          {widgets.map((widget, index) => (
            <button
              key={widget + index}
              onClick={() => setWidget(widget)}
              className="flex items-center justify-between hover:bg-gray-600 hover:bg-opacity-20 cursor-pointer"
            >
              <label
                htmlFor={`toggle${index}`}
                className="text-sm flex-1 cursor-pointer p-4 text-left"
              >
                {widget}
              </label>
              <div className="relative inline-block w-10 align-middle select-none transition duration-500 mr-4 ease-in">
                <input
                  type="checkbox"
                  name="toggle"
                  checked={project.widgets?.includes(widget) ?? null}
                  id={`toggle${index}`}
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-gray-500 dark:bg-gray-800 border-4 appearance-none cursor-pointer"
                />
                <label
                  htmlFor={`toggle${index}`}
                  className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-600 cursor-pointer"
                ></label>
              </div>
            </button>
          ))}
          {/* <button onClick={() => setProjectDarkMode(!project.darkMode)} className="flex items-center justify-between hover:bg-gray-600 hover:bg-opacity-20 cursor-pointer">
          <label htmlFor={`toggleDarkMode`} className="text-sm text-gray-500 flex-1 cursor-pointer p-4 text-left">Dark Mode</label>
          <div className="relative inline-block w-10 align-middle select-none transition duration-500 mr-4 ease-in">
            <input type="checkbox" name="toggle" defaultChecked={project.darkMode ?? null} id={`toggleDarkMode`} className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-gray-500 dark:bg-gray-800 border-4 border-gray-300 dark:border-gray-600 appearance-none cursor-pointer"/>
            <label htmlFor={`toggleDarkMode`} className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-600 cursor-pointer"></label>
          </div>
        </button> */}
        </div>

        <div className="px-4 py-8 space-y-2">

          <a href={`${process.env.PUBLIC_URL}/configurador/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Configurador d'estils</span>
            {svg_link}
          </a>

          <div className="w-full py-2"></div>

          <a href={`${process.env.PUBLIC_URL}/moderador/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar al moderador</span>
            {svg_link}
          </a>

          <a href={`${process.env.PUBLIC_URL}/emisor/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar a l'emissor</span>
            {svg_link}
          </a>

          <a href={`${process.env.PUBLIC_URL}/subtitulador/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar al Subtitulator</span>
            {svg_link}
          </a>

          <a href={`${process.env.PUBLIC_URL}/audioModerador/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar al moderador d'àudio</span>
            {svg_link}
          </a>
          
          <div className="w-full py-2"></div>

          <a href={`${process.env.PUBLIC_URL}/presentador/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar al presentador</span>
            {svg_link}
          </a>

          <a href={`${process.env.PUBLIC_URL}/presentador-notifications/${project.id}`} className="btn-link" target="_blank" rel="noreferrer">
            <span>Anar als avisos</span>
            {svg_link}
          </a>

        </div>
      </div>
      <div className="px-4">
        <h2 className="text-lg leading-tight mb-1">{project.title}</h2>
        <h4 className="text-xs">id: {project.id}</h4>
      </div>
    </div>
  );
}

export default compose(
  firestoreConnect(["projects"]),
  connect(
    (state, ownProps) => {
      const projectId = ownProps.projectId;
      const projects = state.firestore.data.projects;
      const project = projects ? projects[projectId] : null;
      return {
        project: {
          id: projectId,
          ...project,
        },
      };
    },
    (dispatch, ownProps) => {
      const projectId = ownProps.projectId;
      return {
        setWidget: (widget) => dispatch(setWidget(widget, projectId)),
        setProjectDarkMode: (value) =>
          dispatch(setProjectDarkMode(value, projectId)),
      };
    }
  )
)(ProjectConfig);
