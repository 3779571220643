import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import OverlayNotifications from './OverlayNotifications'

const sizes = ['lg', 'xl', '2xl', '3xl', '4xl']
const sizesX2 = ['xl', '2xl', '3xl', '4xl', '5xl']

function Presenter(props) {
  const { notes } = props
  const [textSize, setTextSize] = useState(0)

  const increaseTextSize = () => {
    setTextSize(textSize+1)
  }

  const decreaseTextSize = () => {
    setTextSize(textSize-1)
  }

  return (
    <>
      <Helmet title="Presentador" />
      <div className="fixed z-20 top-5 right-20 space-x-4">
        <button onClick={increaseTextSize} className={`font-bold text-gray-500 select-none cursor-pointer text-${sizes[textSize]}`} disabled={textSize === sizes.length-1}>
          A+
        </button>
        <button onClick={decreaseTextSize} className={`font-bold text-gray-500 select-none cursor-pointer text-${sizes[textSize]}`} disabled={textSize === sizes.length-sizes.length}>
          A-
        </button>
      </div>
      <div className={`h-screen bg-gray-50 text-[#000] dark:bg-[#000] dark:text-[#fff] text-${sizesX2[textSize]} overflow-hidden`}>
        <div className="p-5 dark:border-gray-600 pt-16">
          <OverlayNotifications {...props} title="Llista de missatges" role="presenter" size={sizes[textSize]} />
        </div>
      </div>
    </>
  )
}

export default compose(
  firestoreConnect(),
  connect((state, ownProps) => {
    const projectId = ownProps.match.params.id
    const projects = state.firestore.data?.projects ?? null
    const project = projects ? projects[projectId] : null
    return { notes: project?.notes?.html }
  })
)(Presenter)