import { connect } from "react-redux";
import { setUserDarkMode, setLocale } from "../../actions/settingActions";
import Spinner from "../Spinner";
import translate from "../../i18n/translate";

function SettingsGeneral(props) {
  const { user, setUserDarkMode, setLocale } = props;

  if (!user) return <Spinner className="mx-auto mt-40" />;

  const { darkMode } = user.settings;

  const _setLocale = (lang) => {
    setLocale(lang, user.id);
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({ ...settings, lang: lang })
    );
  };

  const _setDarkMode = (value) => {
    setUserDarkMode(value, user.id);
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({ ...settings, darkMode: value })
    );
  };

  return (
    <>
      <header>
        <h2 className="text-3xl font-black">General</h2>
      </header>
      <div className="py-5">
        <button
          onClick={() => _setDarkMode(!darkMode)}
          className="flex items-center justify-between cursor-pointer py-4 border-b w-full"
        >
          <label htmlFor={`toggleDarkMode`} className="flex-1 text-left">
            Dark Mode
          </label>
          <div className="relative inline-block w-10 align-middle select-none transition duration-500 mr-4 ease-in">
            <input
              type="checkbox"
              name="toggle"
              defaultChecked={darkMode ?? null}
              id={`toggleDarkMode`}
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-gray-500 dark:bg-gray-800 border-4 appearance-none cursor-pointer"
            />
            <label
              htmlFor={`toggleDarkMode`}
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 dark:bg-gray-600 cursor-pointer"
            ></label>
          </div>
        </button>
        <div className="flex items-center justify-between cursor-pointer py-4 border-b w-full">
          <p>Idioma</p>
          <div className="border rounded-sm ">
            <button
              onClick={() => _setLocale("ca")}
              className={`px-4 py-2 border-r`}
            >
              {translate("catala")}
            </button>
            <button onClick={() => _setLocale("es")} className="px-4 py-2">
              {translate("castella")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => {
    const { users } = state.firestore.data;
    if (users) {
      const user = users[state.firebase.auth.uid];
      return {
        auth: state.firebase.auth,
        user: {
          id: state.firebase.auth.uid,
          displayName: user?.displayName,
          settings: user?.settings ?? {},
        },
      };
    }
    return {};
  },
  (dispatch) => {
    return {
      setUserDarkMode: (value, userId) =>
        dispatch(setUserDarkMode(value, userId)),
      setLocale: (value, userId) => dispatch(setLocale(value, userId)),
    };
  }
)(SettingsGeneral);
