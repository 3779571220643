import { nanoid } from "nanoid";

export const createProject = (project) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;
    firestore.collection("projects").add({
      ...project,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      authorId: authorId,
      authorEmail: profile.email,
      widgets: [],
    }).then((response) => {
      dispatch({
        type: "CREATE_PROJECT",
        id: response.id,
      });
    }).catch((error) => {
      dispatch({ type: "CREATE_PROJECT_ERROR", error });
    });
  };
};

export const deleteProject = (projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .delete()
      .then((response) => {
        dispatch({
          type: "DELETE_PROJECT",
        });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_PROJECT_ERROR", error });
      });
  };
};

export const connectLiveChat = (liveChat, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .update({
        ...liveChat,
      })
      .then(() => {
        dispatch({
          type: "CONNECT_LIVECHAT_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({ type: "CONNECT_LIVECHAT_ERROR", error });
      });
  };
};

export const saveSubtitles = (subs, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .update({
        "subtitles.clipboard": subs.clipboard,
      })
      .then(() => {
        dispatch({
          type: "SAVE_SUBTITLES_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SUBTITLES_ERROR", error });
      });
  };
};

export const saveSubtitlesHistory = (subs, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const subtitlesHistory =
      getState().firestore.data.projects[projectId].subtitles.history;
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .update({
        "subtitles.history": subtitlesHistory.concat({
          id: nanoid(),
          createdAt: new Date(),
          displayText: subs,
        }),
      })
      .then(() => {
        dispatch({
          type: "SAVE_SUBTITLES_HISTORY_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SUBTITLES_HISTORY_ERROR", error });
      });
  };
};

export const saveSubtitlesBackup = (subs, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const subtitlesBackups = getState().firestore.data.projects[projectId]
      .subtitles.backups
      ? getState().firestore.data.projects[projectId].subtitles.backups
      : [];
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .update({
        "subtitles.backups": subtitlesBackups.concat({
          id: nanoid(),
          createdAt: new Date(),
          displayText: subs,
        }),
      })
      .then(() => {
        console.log("New Backup");
        dispatch({
          type: "SAVE_SUBTITLES_BACKUP_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SUBTITLES_BACKUP_ERROR", error });
      });
  };
};

export const setWidget = (widget, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const widgets = getState().firestore.data.projects[projectId].widgets;
    const firestore = getFirestore();
    const firebase = getFirebase();
    firestore
      .collection("projects")
      .doc(projectId)
      .update({
        widgets: widgets.includes(widget)
          ? firebase.firestore.FieldValue.arrayRemove(widget)
          : firebase.firestore.FieldValue.arrayUnion(widget),
      })
      .then(() => {
        dispatch({
          type: "SET_WIDGET_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch({ type: "SET_WIDGET_ERROR", error });
      });
  };
};

export const setSubsBroadcastId = (value, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      "subtitles.broadcastId": value,
    });
  };
};

export const setPublicURL = (value, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      publicURL: value.replaceAll('/', ''),
    });
  };
};

export const setProjectStyles = (value, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      projectStyles: value,
    });
  };
};

export const setProjectStylePresets = (value, presetName) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection("stylePresets").doc(presetName).set({
      projectStyles: value,
    })
  };
};

export const toggleItemIsReady = (id, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const isReady = getState().firestore.data.items[id].isReady;
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(id)
      .update({
        isReady: !isReady,
      })
      .then((response) => {
        dispatch({
          type: "TOGGLE_ITEMS_IS_READY_SUCCESS",
        });
      });
  };
};

export const setProjectDarkMode = (value, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      darkMode: value,
    });
  };
};

export const setNotes = (notes, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      notes,
    });
  };
};

export const setCategories = (categories, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).update({
      categories,
    });
  };
};





// Guardar y eliminar cualquier elemento

export const saveItem = (type, item, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection("projects").doc(projectId).collection("items").add({ type, ...item, position: getState().firestore.ordered.items.length, savedAt: new Date() }).then(() => {
      console.log("item saved");
      dispatch({
        type: "SAVE_ITEM",
      });
    }).catch((error) => {
      dispatch({ type: "SAVE_ITEM", error });
    });
  };
};

export const deleteItem = (id, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(id)
      .delete()
      .then((response) => {
        dispatch({
          type: "DELETE_ITEM",
        });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_ITEM_ERROR", error });
      });
  };
};



// Message Actions?

export const setEditedMessage = (snippet, messageId, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    if (snippet?.displayName) {
      firestore.collection("projects").doc(projectId).collection("items").doc(messageId).update({
        ...snippet,
        authorInitials: snippet.displayName.split(" ").slice(0, 2).map((n) => n[0]).join("").toUpperCase(),
        lastUpdate: new Date(),
      }).then((response) => {
        dispatch({
          type: "SET_EDITED_MESSAGE_SUCCESS",
        });
      });
    } else {
      firestore.collection("projects").doc(projectId).collection("items").doc(messageId).update({
        ...snippet,
        lastUpdate: new Date(),
      }).then((response) => {
        dispatch({
          type: "SET_EDITED_MESSAGE_SUCCESS",
        });
      });
    }



  };
};

export const setIsEmitted = (value, messageId, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(messageId)
      .update({
        isEmitted: value,
        emittedAt: new Date(),
      });
  };
};

export const setCategoria = (value, messageId, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(messageId)
      .update({
        category: value,
      });
  };
};


export const setItemPosition = (value, messageId, projectId) => {
  console.log('ENTRED - ' + value + ' / ' + messageId)
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(messageId)
      .update({
        position: value,
      });
  };
};

export const setIsEditing = (value, messageId, projectId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("items")
      .doc(messageId)
      .update({
        isEditing: value,
      });
  };
};






