// Auth actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
// Project actions
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const SAVE_ITEM = 'SAVE_MESSAGE'
export const SAVE_ITEM_ERROR = 'SAVE_MESSAGE_ERROR'
export const DELETE_ITEM = 'DELETE_MESSAGE'
export const DELETE_ITEM_ERROR = 'DELETE_MESSAGE_ERROR'
// Youtube actions
export const YTAUTH_SUCCESS = 'YTAUTH_SUCCESS'
export const YTAUTH_ERROR = 'YTAUTH_ERROR'
export const BROADCASTSLIST_SUCCESS = 'BROADCASTSLIST_SUCCESS'
export const BROADCASTSLIST_ERROR = 'BROADCASTSLIST_ERROR'
export const BROADCASTINFO_SUCCESS = 'BROADCASTINFO_SUCCESS'
export const BROADCASTINFO_ERROR = 'BROADCASTINFO_ERROR'
export const LIVECHATMESSAGESLIST_SUCCESS = 'LIVECHATMESSAGESLIST_SUCCESS'
export const LIVECHATMESSAGESLIST_ERROR = 'LIVECHATMESSAGESLIST_ERROR'
