import { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { AnimatePresence, motion } from 'framer-motion'
import { saveItem } from '../actions/projectActions'
import { getInitials } from '../utils'
import Spinner from './Spinner'
import MessageOverlay from "./Overlays/message";
/* import { SketchPicker } from 'react-color'; */
import ColorPicker from './ColorPicker'

function MessageCreate(props) {

  const { saveItem, firebase, simple, projectStyles } = props

  const [visible, setVisible] = useState((simple)? simple : true)
  const [authorInitials, setAuthorInitials] = useState('')
  const [color, setColor] = useState(projectStyles?.messageColorProfile? projectStyles.messageColorProfile : '#10B981')
  const [colorText, setColorText] = useState(projectStyles?.messageColorProfileText? projectStyles.messageColorProfileText : '#F4F4F5')
  const [uploadInfo, setUploadInfo] = useState(null)
  const [pictureURL, setPictureURL] = useState(null)
  const [pictureIsLoading, setPictureIsLoading] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [showPicker, setShowPicker] = useState(false)

  const [messageAuthorName, setMessageAuthorName] = useState('')
  const [messageBussinesName, setMessageBussinesName] = useState('')
  const [messageBodyText, setMessageBodyText] = useState('')
  const [messageFontSize, setMessageFontSize] = useState(150)
  const [showInitials, setShowInitials] = useState(true)

  const [showMessagePreview, setShowMessagePreview] = useState(false)
  const [disablePrevButton, setDisablePrevButton] = useState(true)



  useEffect(() => {
    if (projectStyles && projectStyles.messageColorProfile) {
      setColor(projectStyles.messageColorProfile)
    }
    if (projectStyles && projectStyles.messageColorProfileText) {
      setColorText(projectStyles.messageColorProfileText)
    }
  }, [projectStyles])


  useEffect(() => {
    if ( pictureURL || 
      (messageBodyText && messageBodyText.split(' ').join('') != '') ||
      (messageBussinesName && messageBussinesName.split(' ').join('') != '') ||
      (messageAuthorName && messageAuthorName.split(' ').join('') != '')
    ) {
      setDisablePrevButton(false)
    } else {
      setDisablePrevButton(true)
    }
  }, [pictureURL, messageBodyText, messageBussinesName, messageAuthorName])


  useEffect(() => {
    if (!visible) {
      setPictureURL(null);
      setUploadInfo(null);
      setColor('#10B981')
    }
  }, [visible])

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    saveItem( 'chatMessage', { 
        displayName: messageAuthorName,
        authorInitials: authorInitials,
        authorInitialsColor: color,
        currentMessage: messageBodyText,
        originalMessage: messageBodyText,
        fontSize: messageFontSize+'',
        businessName: messageBussinesName,
        profileImageUrl: pictureURL ?? null,
        showInitials: showInitials,
        isReady: false
    });

    setTimeout(() => {

      setMessageFontSize(150);
      setMessageAuthorName('');
      setAuthorInitials('');
      setMessageBodyText('');
      setMessageBussinesName('');
      setVisible(false);
      setDisablePrevButton(true)
      setShowMessagePreview(false)
      setColor('#10B981')

    }, 200)

  }

  const preventPressEnter = event => { if (event.key === 'Enter') event.preventDefault() }

  const keyUp = (event) => {
    if (event.keyCode === 27) {
      setVisible(false)
    }
  }

  const handleFileOnChange = event => {
    const file = event.target.files[0]
    if (file?.size < 5000000) {
      setPictureIsLoading(true)

      const storageRef = firebase.storage().ref(`images/${file.name}`)
      const task = storageRef.put(file)

      task.on('state_changed', snapshoot => {}, error => setUploadInfo(error.message), () => {
        storageRef.getDownloadURL().then(url => {
          setPictureIsLoading(false)
          setPictureURL(url)
        })
      })

      setFileName(event.target.files[0].name)
    } else {
      setUploadInfo("L'arxiu ha de ser inferior a 5000KB")
    }
  }

  const deletePicture = () => {
    firebase.storage().ref(`images`).child(fileName).delete()
    setPictureURL(null)
    document.getElementById("uploadeFile2").value = null;
  }
  
  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700`}>
      {simple != true && <button onClick={() => setVisible(!visible)} onKeyUp={keyUp} className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Crear nou missatge</h2>
        <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} className="hover:text-gray-200">
        {!visible ?
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          :
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M18 12H6" />
          </svg>
        } 
        </motion.div>
      </button>}
      <AnimatePresence>
        {visible &&
          <motion.form
            key="messageForm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onSubmit={handleOnSubmit}
            onKeyPress={preventPressEnter}
            className={`p-4 ${simple != true? 'border-t-2' : ''} dark:border-gray-700`}
          >
            <div className="flex space-x-2">
              {/* bg-green-500 */}
              {showInitials && <div className="relative w-20 h-20 overflow-hidden" style={{ backgroundColor: color? color : '#10B981', color: colorText? colorText : '#F4F4F5' }}>
                {pictureURL ?
                  <img src={pictureURL} className="w-20 h-20 object-cover" alt="profile" />
                : pictureIsLoading ? 
                  <Spinner className="w-full h-full grid place-items-center p-6"/> 
                  :
                  <div className="w-full h-full grid place-items-center font-regular text-2xl cursor-pointer" onClick={ () => setShowPicker(true)}>
                    <p>{authorInitials}</p>
                  </div>
                }
              </div>}
              <div className="flex-1">
                <input id="authorName" onKeyUp={keyUp} autoComplete="off" type="text" className="w-full mb-2" onChange={(event) => { setAuthorInitials(getInitials(event.target.value)); setMessageAuthorName(event.target.value) }} placeholder="Nom" required/>
                <input id="businessName" onChange={(event) => setMessageBussinesName(event.target.value)} onKeyUp={keyUp} autoComplete="off" className="w-full py-2" type="text" placeholder="Nom de l'empresa" name=""/>
              </div>
            </div>

            <div className="flex items-center space-x-2 my-2">
              
              {uploadInfo && <div className="bg-red-500 text-gray-50 px-2 py-1 rounded-sm text-sm flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <span>{uploadInfo}</span>
                <button onClick={() => setUploadInfo(null)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </div> }

              {pictureURL && showInitials && <button onClick={deletePicture} className="btn--tool" type="button" style={{ userSelect: 'none' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <span>Esborrar</span>
              </button> }

              {/* <input type="color" id="color" value="color" /> */} 
              {showInitials && showPicker && 
                <div className='fixed pt-24 z-40 w-screen h-screen top-0 left-0 flex justify-center items-center' style={{ backgroundColor: 'rgba(200, 200, 200, 0.5)' }}>
                  
                  <div className='' style={{ width: '220px' }}>
                    <div className='w-full flex justify-end'>
                      <div onClick={ () => setShowPicker(false)} className="w-8 h-8 cursor-pointer bg-gray-50 hover:bg-gray-200 py-1 px-2 rounded mb-1 flex items-center" style={{ border: '1px solid silver' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                          <line x1="18" y1="6" x2="6" y2="18"/>
                          <line x1="6" y1="6" x2="18" y2="18"/>
                        </svg>
                      </div>
                    </div>

                    {/* <SketchPicker color={color} onChangeComplete={ (pickedColor) => { setColor(pickedColor.hex) } }/> */}
                    <ColorPicker color={color} setColor={setColor} setClose={setShowPicker} />

                  </div>
                </div>
              }

              {showInitials && <label className="btn--tool" style={{ userSelect: 'none' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
                <span>Pujar Imatge</span>
                <input type="file" id="uploadeFile2" onChange={handleFileOnChange} style={{ display: "none" }}/>
              </label>}
              
              
              <label className="btn--tool" style={{ userSelect: 'none' }}>
                <input type="checkbox" id="showImage" style={{ marginRight: '5px', marginLeft: '10px' }} checked={showInitials} onClick={(event) => { setShowInitials(!showInitials); }}/> 
                Mostrar retrat
              </label>

            </div>




            <div>
              <div className='flex w-full mb-1'>
                <select name='fontSize' onChange={(event) => setMessageFontSize(event.target.value)} defaultValue={150} className="p-1">
                  <option value={50}>Text petit</option>
                  <option value={100}>Text normal</option>
                  <option value={150}>Text gran</option>
                </select>
              </div>

              <textarea placeholder="Missatge de text" id={"messageBodyText"} value={messageBodyText} onChange={(event) => setMessageBodyText(event.target.value)} className="w-full py-1 h-32"></textarea>
            </div>





            <div className="space-x-2 flex justify-between text-sm lg:flex-row-reverse lg:-ml-2 pt-2">
              <div className='flex space-x-2'>
                <button type="button" className={`${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`} onClick={() => setShowMessagePreview(!showMessagePreview)} disabled={disablePrevButton}>
                  {!showMessagePreview?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                    : 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                  }
                  <span>Vista prèvia</span>
                </button>
                
                {simple != true && <button type="submit" className="btn--tool--blue" disabled={disablePrevButton}> 
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                  </svg>
                  <span>Desa</span>
                </button>}
              </div>

              {simple != true && <button type="button" onClick={() => setVisible(false)} className="btn--tool--gray"> 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                </svg>
                <span>Cancel·lar</span>
              </button>}
            </div>


            <MessageOverlay 
              messageToShowTMP={{ 
                id: '',
                snippet: {
                  displayName: messageAuthorName,
                  authorInitialsColor: color,
                  currentMessage: messageBodyText,
                  originalMessage: messageBodyText,
                  fontSize: messageFontSize+'',
                  businessName: messageBussinesName,
                  profileImageUrl: pictureURL ?? null,
                  isReady: false,
                  showInitials: showInitials,
                  authorInitials: authorInitials,
                },
                type: 'chatMessage',
                show: showMessagePreview,
                projectStyles: projectStyles,
                setShowMessagePreview: setShowMessagePreview,
              }} 
            /> 

          </motion.form>
        }
      </AnimatePresence>
    </div>
  )
}




const mapStateToProps = (state, ownProps) => {
  return {};
};


const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}


export default compose(firebaseConnect(),connect(mapStateToProps, mapDispatchToProps))(MessageCreate)