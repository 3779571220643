import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'
import DayJS from 'react-dayjs'
/* import { messagesList } from './../actions/twitchActions' */
import { saveItem } from './../actions/projectActions'
import { getInitials } from '../utils'

import tmi from 'tmi.js'


function LiveChatTwitch(props) {
  

  var { project, saveItem } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const [textChannels, setTextChannels] = useState('') /* loltyler1 */
  
  const [actualMessageTMP, setActualMessageTMP] = useState(null)
  const [chatListTMP, setChatListTMP] = useState([])
  const [autoScroll, setAutoScroll] = useState(true)
  const [client, setClient] = useState(new tmi.Client({ options: { skipMembership: true } }))
  var repeat = true;



  /* Show & Hidde */
  const connect = () => {
    setIsPlaying(true)

    if (repeat) {
      repeat = false;
      client.on('message', async (channel, tags, message, self) => {

        setActualMessageTMP({
          userId: tags['user-id'],
          timedate: tags['tmi-sent-ts'],
          username: tags['display-name'],
          usernameColor: tags['color'],
          message: message,
        });
        
      });
    }

    var clientTMP = client;
    clientTMP.channels = [textChannels.replaceAll(' ', '')]
    setClient(clientTMP)
    /* var z = document.createElement('div');
    z.innerHTML = `<div class='flex items-center justify-start w-full text-sm px-3 bg-gray-100 font-bold text-gray-500'><p>Joined ${textChannels.replaceAll(' ', '')}</p></div>`;
    document.getElementById('smallChat').appendChild(z); */

    client.connect();
  }
  const disconnect = () => {
    setIsPlaying(false)
    client.disconnect();

    var clientTMP = client;
    clientTMP.channels = []
    setClient(clientTMP)
  }


  /* Put messages */
  useEffect(() => {
    if (actualMessageTMP && isPlaying) {
      var chatListTMP2 = chatListTMP;
      if (chatListTMP2 && chatListTMP2.length > 49) {chatListTMP2.shift()}
      chatListTMP2.push(actualMessageTMP)
      setChatListTMP( chatListTMP2.filter( (value, index, self) => 
        index === self.findIndex((t) => ( t.username === value.username && t.message === value.message ))
      ) );

      var chatBox = document.getElementById('smallChat');
      if(autoScroll) {
        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      }
    }
  }, [actualMessageTMP])



  return (
    <div className="border-2 dark:border-gray-700 mb-2">
      <div className="flex items-center justify-between py-2 px-3">
        <div className="flex items-center space-x-3">
          <h2 className="text-sm font-semibold">Xat de Twitch</h2>
          <span className={`w-2 h-2 rounded-full block ${isPlaying? `bg-red-700 animate-pulse` : `bg-gray-700`}`}/>
        </div>

        <div className='flex items-center space-x-3'>

          <label className="whitespace-nowrap flex items-center" style={{ userSelect: 'none' }}>
            <input type="checkbox" style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={autoScroll} onChange={ (event) => setAutoScroll(!autoScroll)}/> 
            Scroll
          </label>

          {isPlaying? 
            <motion.button whileHover={{ scale: 1.2 }} className="hover:text-gray-400" onClick={disconnect}>
              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'>
                <path d="M11.583 15.833V4.167H15V15.833ZM5 15.833V4.167H8.417V15.833Z"/>
              </svg>
            </motion.button>
            :
            <motion.button whileHover={{ scale: 1.2 }} className="hover:text-gray-400" onClick={connect}>
              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill='currentColor'>
                <path d="M6.688 15.812V4.146L15.854 9.979Z"/>
              </svg>
            </motion.button>
          }

        </div>

      </div>

      <div className='w-full'>
        <input type="text" placeholder='Canal...' value={textChannels} onChange={ (event) => setTextChannels(event.target.value)} disabled={isPlaying} className="w-full dark:border-gray-700 dark:text-gray-50 disabled:dark:text-white text-gray-900 text-xs font-semibold border-t-2 disabled:opacity-50 disabled:cursor-not-allowed"/>
      </div>
      
      {/* (client && client.channels && client.channels.length > 0) || */ true &&
        <div id="smallChat" className={`${chatListTMP && chatListTMP.length > 0? 'border-t-2' : '' } dark:border-gray-700 max-h-96 overflow-y-auto overflow-x-hidden`} /* style={{backgroundColor: '#18181B'}} */>
          {chatListTMP?.map((fullMessage, key) => {
            const {userId, timedate, username, usernameColor, message}= fullMessage

            return (
              <div 
                key={key} 
                onClick={() => saveItem('chatMessage', { 
                  
                  twitchUserId: userId,
                  publishedAt: timedate,
                  authorInitials: getInitials(username),
                  authorInitialsColor: usernameColor? usernameColor : '#5B90E9',
                  currentMessage: message, originalMessage: message,
                  displayName: username,
                  isChatOwner: false,
                  profileImageUrl: null,
                  businessName: '',
                  fontSize: 150,
                  showInitials: true,
                  isReady: false 

                }, project.id )}
                className='flex items-center justify-start px-2 py-2 text-sm dark:hover:bg-gray-700 hover:bg-gray-200 hover:cursor-pointer'
              >
                <p className='text-gray-900 dark:text-gray-200'><strong style={{ color: usernameColor? usernameColor : '#5B90E9' }} className="brightness-75 font-bold">{username}: </strong> {message}</p>
              </div>
            )




          })}
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {} 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project.id
  return {
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatTwitch)