import { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink as Link } from 'react-router-dom'
import { signOut } from './../actions/authActions'

function UserActions(props) {
  const { className, auth, user, signOut } = props
  const [visible, setVisible] = useState(false)
  if (!user) return null
  return (
    <>
      <button onClick={() => setVisible(!visible)} className={`flex items-center p-2 pr-3 mr-8 space-x-2 rounded-3xl bg-gray-100 dark:bg-gray-900 dark:text-gray-500 ${className}`}>
        <img src={auth.photoURL} className="w-6 h-6 rounded-full" alt="User Avatar" />
        <svg xmlns="http://www.w3.org/2000/svg" className={`h-3 w-3 transform ${visible && `rotate-180`}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {visible &&
        <div onMouseOver={() => setVisible(true)} onMouseLeave={() => setVisible(false)} className="fixed top-11 right-8 w-56 bg-gray-100 dark:bg-gray-900 rounded p-2 flex flex-col font-semibold">
          {/* <span className="font-semibold px-3 py-4">{user.displayName}</span> */}
          <Link to="/settings" className="btn-nav hover:bg-gray-500 hover:bg-opacity-10">Configuració</Link>
          <span className="block border-b-2 w-full my-2 dark:border-gray-800" />
          <button onClick={signOut} className="btn-nav hover:bg-gray-500 hover:bg-opacity-10">
            <span>Sortir</span>
          </button>
        </div>
      }
    </>
  )
}

export default connect(state => (
  {
    auth: state.firebase.auth,
    user: state.firestore.data.users?.[state.firebase.auth.uid] ?? null
  }
), dispatch => {
  return {
    signOut: () => dispatch(signOut())
  }
})(UserActions)