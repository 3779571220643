import { useState, useEffect } from "react";
import { connect } from "react-redux";
import SubtitlesOverlay from "./Overlays/onlySubtitles";


function SubtitlesCreate(props) {
  const { simple, projectStyles } = props;
  const [showSubtitlePreview, setSubtitlePreview] = useState(false)
  const [textTMP, setTextTMP] = useState('Subtitles de test...')


  return (
    <div className={`${simple != true? 'border-2' : ''} dark:border-gray-700`}>
      {simple != true && <div className="w-full flex items-center justify-between py-2 px-3">
        <h2 className="text-sm font-semibold">Subtítols</h2>
      </div>}
      <div className={`p-3 ${simple != true? 'border-t-2' : ''} dark:border-gray-600 space-y-3`}>
        <input placeholder="Subtítols..." value={textTMP} onChange={ (event) => setTextTMP(event.target.value) } className="w-full"/>
        <SubtitlesOverlay 
            messageToShowTMP={{ 
              show: showSubtitlePreview,
              textTMP: textTMP,
              projectStyles: projectStyles,
              setSubtitlePreview: setSubtitlePreview,
            }} 
          /> 
        </div>
    </div>
  );
}

export default connect(null, (dispatch, ownProps) => {
  const projectId = ownProps.projectId
  return {
  };
})(SubtitlesCreate);
