import { LOCALES } from '../locales'

const es = {
  [LOCALES.es]: {
    "hello": "Hola buenos días. ¿cómo va todo?",
    'catala': 'Catalan',
    'castella': 'Español',
    'titol_configuracio': 'Configuración'
  }
}

export default es