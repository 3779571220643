import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";
import ReactAudioPlayer from "react-audio-player";
import ReactTooltip from "react-tooltip";
import MessageOverlay from "./Overlays/message";
import AudioMessageOverlay from "./Overlays/audioMessage";

import { Draggable } from "react-beautiful-dnd";



function Message(props) {
  const {
    deleteItem,
    message,
    project,
    role,
    toggleItemIsReady,
    setEditedMessage,
    setIsEmitted,
    index,
    selectedItem,
    setSelectedItem,
    overlay,
    setOverlay,
    setPositions,
    addOverlay,
    removeOverlay,
    setItemPosition,
    projectStyles,
    setDragCompleted,
    dragCompleted,
    setIsEditing,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [editor, setEditor] = useState(null);
  const ref = useRef(null);
  const [showMessagePreview, setShowMessagePreview] = useState(false)



  useEffect(() => {
    setPositions(index, {
      height: ref.current.offsetHeight,
      top: ref.current.offsetTop,
    });
  });

  const submitOverlay = (messageId) => {
    if (project.overlay) {
      removeOverlay();
      setSelectedItem(null);
      setOverlay(null);
      setIsEmitted(true, messageId);
    } else {
      addOverlay(overlay);
    }
  };

  const handleSelected = () => {
    const isSelected = index !== selectedItem;
    setSelectedItem(isSelected ? index : null);
    setOverlay(isSelected ? message : null);
    // aquesta funció te un problema de responsabilitat compartida ja que els condicionals serveixen perque a l'hora selecciona i deselecciona
  };

  const edit = (messageId) => {
    setEditor(editor === index ? null : index);
    setIsEditing(true, messageId);
  };

  const cancelEdit = (messageId) => {
    setEditor(null);
    setIsEditing(false, messageId);
  };

  const saveEdit = (event, index, id) => {



    var currentMessageTMP = document.getElementById(`currentMessage${index}`)?.value ?? null;
    var displayNameTMP = document.getElementById(`displayName${index}`)?.value;
    var businessNameTMP = document.getElementById(`businessName${index}`)?.value ?? null;
    var fontSizeTMP = document.getElementById(`fontSize${index}`)?.value ?? null;
    var authorRoleTMP = document.getElementById(`authorRole${index}`)?.value ?? null;
    var audioNotesTMP = document.getElementById(`audioNotes${index}`)?.value ?? null;
    var showInitialsTMP = document.getElementById(`showInitials${index}`)?.checked? true : false;
    var initialsColorTMP = document.getElementById(`initialsColor${index}`)?.value ?? '#32a852';

    setEditedMessage(
      {
        currentMessage: currentMessageTMP,
        displayName: displayNameTMP,
        businessName: businessNameTMP,
        fontSize: fontSizeTMP,
        authorRole: authorRoleTMP,
        audioNotes: audioNotesTMP,
        showInitials: showInitialsTMP,
        authorInitialsColor: initialsColorTMP,
      },
      id
    );
    setEditor(null);
    setIsEditing(false, id);
  };

  const _deleteItem = (id) => {
    const confirm = window.confirm("Porfavor, confirme esta acción");
    if (confirm) {
      deleteItem(id);
    }
    // TODO: borrar imatge
  };

  const onTop = { zIndex: 100 };
  const flat = {
    zIndex: 5,
    transition: { delay: 0.3 },
  };

  const {
    id,
    position,

    profileImageUrl,
    authorInitials,
    displayName,
    businessName,
    fontSize,
    originalMessage,
    isChatOwner,
    isReady,
    currentMessage,
    lastUpdate,
    audioMessageUrl,
    authorRole,
    audioNotes,
    isEmitted,
    savedAt,
    emittedAt,
    isEditing,
    showInitials,
    authorInitialsColor,
  } = props.message;



  

  const [picture, setPicture] = useState(profileImageUrl);

  /* useEffect(() => {
    if (dragCompleted) setItemPosition(index, id);
    // eslint-disable-next-line
  }, [dragCompleted, setItemPosition]); */

  const time = lastUpdate?.toDate() ?? savedAt.toDate();
  const emittedTime = emittedAt?.toDate() ?? null;

  const displayTimeHour = (time) =>
    new Intl.DateTimeFormat("es", {
      hour: "numeric",
      minute: "numeric",
    }).format(time);
  const displayFullTime = (time) =>
    new Intl.DateTimeFormat("es", {
      dateStyle: "full",
      timeStyle: "long",
    }).format(time);

  if (/chatMessage/.test(message.type)) {
    return <Draggable isDragDisabled={role === "subscriber"} draggableId={'message-' + message.id} index={position}>
      
      
      {(provided, snapshot) => (

        <div 
          className={"message-" + message.id}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <motion.form
            ref={ref}
            layout={role !== "presenter" ? true : false}
            drag={role !== "presenter" ? "y" : false}
            whileHover={{ cursor: "grab" }}
            whileTap={
              role === "subscriber"
                ? { scale: 1.01, cursor: "grabbing" }
                : { cursor: "grabbing" }
            }
            transition={{ type: "easeInOut" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            onSubmit={(event) => event.preventDefault()}
            exit={
              role === "subscriber" ? { scale: 0.9, opacity: 0 } : { opacity: 0 }
            }
            style={{ maxWidth: '999999px', margin: (role === "subscriber")? 10 : 0, borderTop: (snapshot.isDragging)? '2px rgb(200,200,200) solid' : '', borderLeft: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderRight: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderBottom: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '' }}
            className={`selected-message flex items-start justify-between p-[1em] pl-2 border-b-2 ${
              role !== "presenter" && `dark:border-gray-600`
            } ${selectedItem === index && `active`} ${
              isReady && !isEmitted && role !== "presenter"
                ? `bg-green-50 dark:bg-green-900`
                : isEmitted
                ? `bg-red-50 dark:bg-red-900`
                : role === "presenter"
                ? "bg-[#fff] dark:bg-[#000] border-[#000] dark:border-[#fff]"
                : "bg-gray-50 dark:bg-gray-700"
            }`}
          >

            {role !== "presenter" && (
              <div className="flex items-center space-x-2 h-full">
                {role !== "presenter" && (
                  <button
                    className="text-gray-400 hover:text-gray-500"
                    style={{ cursor: "grab" }}
                    type="button"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm-8-8a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM8 2a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z"
                      />
                    </svg>
                  </button>
                )}
                {profileImageUrl && picture && (showInitials || showInitials === undefined) ? (
                  <div className="w-20 h-20">
                    <img 
                      className={ role === "presenter" ? `w-[1.5em] h-[1.5em] filter grayscale opacity-60` : `w-20 h-20 rounded-sm` }
                      src={profileImageUrl}
                      onError={() => setPicture(false)}
                      alt=""
                    />
                      {editor != index ? <div className="w-full text-center bg-gray-200 dark:bg-gray-600 py-1 text-xs mt-1">
                      {fontSize === '50'? 'Text petit' : fontSize === '100'? 'Text normal' : fontSize === '150'? 'Text gran' : 'Text gran'}
                    </div> : null }
                  </div>
                ) : (
                  role !== "presenter" && (
                    <div>
                      <div 
                        className={`grid place-items-center font-regular text-gray-50 rounded-sm ${ (role !== "presenter")? `w-20 h-20 bg-green-500 text-3xl` : `hidden` /* `w-[1.5em] h-[1.5em] bg-gray-500 overflow-hidden` */ } `}
                        style={{ opacity: !(showInitials || showInitials === undefined) && '0.3', backgroundColor: authorInitialsColor, color: projectStyles?.messageColorProfileText? projectStyles.messageColorProfileText : 'white' }}
                      >
                        {(showInitials || showInitials === undefined) && authorInitials}
                      </div>

                      {editor != index ? <div className="w-full text-center bg-gray-200 dark:bg-gray-600 py-1 text-xs mt-1">
                        {fontSize === '50'? 'Text petit' : fontSize === '100'? 'Text normal' : fontSize === '150'? 'Text gran' : 'Text normal'}
                      </div> : null }
                    </div>
                  )
                )}
              </div>
              )}


            <div className="flex-1 flex flex-col">
              <div className="space-x-1 cursor-default">
                {editor === index ? (
                  <div className="flex pr-5">
                    <input
                      id={`displayName${index}`}
                      className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500"
                      type="text"
                      defaultValue={displayName}
                    />
                    <input
                      id={`businessName${index}`}
                      className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500"
                      type="text"
                      defaultValue={businessName}
                    />
                    <select id={`fontSize${index}`} className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500" defaultValue={fontSize != '50' && fontSize != '150'? 150 : parseInt(fontSize)}>
                      <option value={50}>Text petit</option>
                      <option value={100}>Text normal</option>
                      <option value={150}>Text gran</option>
                    </select>

                    <input
                      id={`initialsColor${index}`}
                      className="ml-2 mb-1 w-full text-xs font-semibold border-2 border-green-500"
                      type="color"
                      defaultValue={authorInitialsColor? authorInitialsColor : '#10B981'}
                    />

                    <label className="whitespace-nowrap" style={{ userSelect: 'none' }}>
                      <input type="checkbox" id={`showInitials${index}`} style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={showInitials}/> 
                      Mostrar retrat
                    </label>
                  </div>
                ) : (
                  <span
                    className={`font-semibold whitespace-nowrap pl-2 py-1 `}
                  >
                    {displayName} {businessName && <span className="font-normal">de {businessName}</span>}
                  </span>
                )}
                {role !== "presenter" && (
                  <p className="inline text-xs ml-2" style={{ paddingTop: '10px' }}>
                    {lastUpdate ? `Actualitzat` : `Creat`} a las{" "}
                    <span data-tip={displayFullTime(time)}>
                      {displayTimeHour(time)}
                    </span>
                  </p>
                )}
              </div>
              {editor === index ? (
                <TextareaAutosize
                  id={`currentMessage${index}`}
                  type="textarea"
                  spellCheck="false"
                  className={`flex-1 ml-2 px-0 py-1 h-20 resize-none border-2 border-green-500 ${
                    role === "subscriber" && ``
                  }`}
                  defaultValue={currentMessage}
                />
              ) : role !== "presenter" ? (
                <TextareaAutosize
                  id={`currentMessage${index}`}
                  className={`neutre px-2 resize-none mt-1 ${
                    role === "subscriber" && `mr-5`
                  } ${role === "presenter" && `dark:text-[#fff] text-[#000]`}`}
                  disabled
                  value={currentMessage}
                />
              ) : (
                <p className="px-2">{currentMessage}</p>
              )}
              {role !== "presenter" &&
                (editor !== index ? (
                  <div className="flex items-center px-2 py-1 mt-2">
                    {/* {isEditing ? (
                      <span className="text-sm text-gray-400 py-1.5">
                        Editant...
                      </span>
                    ) : ( */}
                      <>


                        <button type="button" onClick={() => _deleteItem(id)} className="btn--tool--gray" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>Esborrar</span>
                        </button>

                        
                        <button type="button" onClick={() => edit(id)} className="ml-2 btn--tool--gray" >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path
                              fillRule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>Editar</span>
                        </button>


                        <button type="button" onClick={() => setShowMessagePreview(!showMessagePreview)} className={`ml-2 ${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`} >
                          {!showMessagePreview?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                          : 
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                          }
                          <span>Vista prèvia</span>
                        </button>


                        <button type="button" onClick={() => toggleItemIsReady(id)} className={`ml-2 ${isReady? 'btn--tool--blue' : 'btn--tool--gray'}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>{isReady ? "Confirmat" : "Confirmar"}</span>
                        </button>


                      </>
                    {/* )} */}
                  </div>
                ) : (
                  <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 text-xs">

                    <button type="button" onClick={() => cancelEdit(id)} className="btn--tool--gray"> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                      </svg>
                      <span>Cancel·lar</span>
                    </button>

                    <button type="submit" className="btn--tool--blue" onClick={(event) => saveEdit(event, index, id)}> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                      </svg>
                      <span>Desa</span>
                    </button>
                    
                  </div>
                ))}
            </div>

            <div className="flex flex-wrap justify-end items-start">

              {isEmitted && role !== "presenter" && (
                <div className="flex space-x-1 text-sm items-center text-gray-500 dark:text-gray-300 cursor-default whitespace-nowrap w-full justify-end" style={{ margin: '2px', marginRight: '10px' }}>
                  <span className="text-xs uppercase font-semibold px-1">
                    Emès a les{" "}
                    <span data-tip={displayFullTime(emittedTime)} data-place="bottom"> {displayTimeHour(emittedTime)} </span>
                  </span>
                  <button onClick={() => setIsEmitted(false, id)}>X</button>
                </div>
              )}

              {(selectedItem != index && role !== "presenter") && <div className="bg-gray-200 mx-2 rounded px-2 opacity-50" style={{ paddingBlock: '3px' }}>
                <h1 className={`font-bold whitespace-nowrap ${role !== "presenter"? 'text-gray-600 text-sm' : ''}`}>Missatge de text</h1>
              </div>}

              {role === "subscriber" && (
                <div className="transform -translate-y-1/2 flex space-x-2 text-sm items-center" style={{ marginTop: '20px' }} >

                  {/* {Boolean(!project.overlay) && ( */}
                    <button
                      onClick={handleSelected}
                      className={`disabled:opacity-20 disabled:cursor-not-allowed z-10 text-gray-500 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-100 uppercase font-semibold flex px-3 py-2 border-2 rounded-sm text-xs ${
                        selectedItem === index && "border-none"
                      }`}
                      type="button"
                      disabled={!Boolean(!project.overlay)}
                    >
                      {selectedItem === index ? "Cancel·lar" : "Seleccionar"}
                    </button>
                  {/* )} */}

                  {(overlay?.originalMessage ?? null) === originalMessage && (
                    <button
                      onClick={() => submitOverlay(id)}
                      type="button"
                      className=" flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-md"
                    >
                      {!project.overlay ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                          <span>EMETRE</span>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                            />
                          </svg>
                          <span>DEIXAR D'EMETRE</span>
                        </>
                      )}
                    </button>
                  )}
                </div>
              )}
              
              {role === "admin" && (
                <div className="w-6 h-6 rounded-sm border-2 dark:border-gray-600  hover:text-green-400">
                  <motion.button
                    onClick={handleSelected}
                    whileHover={{ scale: 1.5 }}
                    whileTap={{ scale: 0.9 }}
                    animate={{ scale: selectedItem === index ? 1.4 : 1 }}
                    transition={{ type: "linear" }}
                    className="selected-message__tool selected-message__tool--checkbox"
                  >
                    {selectedItem !== index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </motion.button>
                </div>
              )}
            </div>


          </motion.form>
          <ReactTooltip place="top" effect="solid" delayShow={500} />

          <MessageOverlay 
            messageToShowTMP={{ 
              id: id,
              snippet: {
                displayName: displayName,
                authorInitials: authorInitials,
                authorInitialsColor: authorInitialsColor,
                showInitials: showInitials,
                currentMessage: currentMessage,
                originalMessage: originalMessage,
                fontSize: fontSize+'',
                businessName: businessName,
                profileImageUrl: profileImageUrl ?? null,
                isReady: false
              },
              type: 'chatMessage',
              show: showMessagePreview,
              projectStyles: projectStyles,
              setShowMessagePreview: setShowMessagePreview,
            }} 
          />

        </div>
      )}
    </Draggable>
  }

  if (/audioMessage/.test(message.type)) {
    return <Draggable isDragDisabled={role === "subscriber"} draggableId={'audio-message-' + message.id} index={position}>
      {(provided, snapshot) => (

      <div 
        className={"audio-message-"+ message.id}
        ref={provided.innerRef}
        {...provided.dragHandleProps}
        {...provided.draggableProps}
      >
      
        <motion.form
          ref={ref}
          layout={role !== "presenter" ? true : false}
          drag={role !== "presenter" ? "y" : false}
          whileHover={{ cursor: "grab" }}
          whileTap={
            role === "subscriber"
              ? { scale: 1.01, cursor: "grabbing" }
              : { cursor: "grabbing" }
          }
          transition={{ type: "easeInOut" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onSubmit={(event) => event.preventDefault()}
          exit={
            role === "subscriber" ? { scale: 0.9, opacity: 0 } : { opacity: 0 }
          }
          style={{ maxWidth: '999999px', margin: (role === "subscriber")? 10 : 0, borderTop: (snapshot.isDragging)? '2px rgb(200,200,200) solid' : '', borderLeft: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderRight: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '', borderBottom: snapshot.isDragging?  '2px rgb(200,200,200) solid' : '' }}
          className={`selected-message flex items-start justify-between p-3 pl-2 border-b-2 ${
            role !== "presenter" && `dark:border-gray-600`
          } ${selectedItem === index && `active`} ${
            isReady && !isEmitted && role !== "presenter"
              ? `bg-green-50 dark:bg-green-900`
              : isEmitted
              ? `bg-red-50 dark:bg-red-900`
              : role === "presenter"
              ? "bg-[#fff] dark:bg-[#000] border-[#000] dark:border-[#fff]"
              : "bg-yellow-50 dark:bg-yellow-900"
          }`}
        >
          <div className="flex items-center space-x-2">
            {role !== "presenter" && (
              <button
                className="text-gray-400 hover:text-gray-500"
                style={{ cursor: "grab" }}
                type="button"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M8 18a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm-8-8a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zM8 2a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z"
                  />
                </svg>
              </button>
            )}
            {profileImageUrl && picture ? (
              <img
                className={
                  role === "presenter"
                    ? `hidden`
                    : `w-20 h-20 rounded-full`
                }
                src={profileImageUrl}
                onError={() => setPicture(false)}
                alt=""
              />
            ) : (
              <svg
                className={
                  role !== "presenter" ? `w-20 h-20` : `hidden`
                }
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 212 212"
              >
                <path
                  fill="#DFE5E7"
                  d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z"
                ></path>
                <path
                  fill="#FFF"
                  d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z"
                ></path>
              </svg>
            )}
          </div>
          <div className="flex-1 flex flex-col">
            {/* {role === "presenter" && (
              <div className="flex items-center ml-1">
                <svg
                  className="w-[1.5em] h-[1.5em]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1280 720"
                >
                  <path
                    fill="currentColor"
                    d="M626 36a323 323 0 00-265 178 315 315 0 00-30 174c4 37 18 82 37 119l5 10-12 37-28 82-15 46c0 1 1 3 3 3 2 1 3 2 2 5 0 3 0 3 2 2a49977 49977 0 00169-53l10 4a324 324 0 00183 31 313 313 0 00267-242A317 317 0 00626 35zm39 68a261 261 0 01199 117 230 230 0 0137 165 257 257 0 01-398 180 1214 1214 0 00-92 29 1262 1262 0 0128-89l-4-9a258 258 0 01-20-252 262 262 0 01250-141z"
                  />
                  <path
                    fill="currentColor"
                    d="M532 196l-7 4c-5 3-20 19-25 26-14 23-18 53-9 83a379 379 0 0081 128c30 31 55 48 93 64 28 12 49 18 60 20 9 0 26-2 37-6 14-5 31-17 37-26 6-10 13-32 14-46v-10l-3-3c-2-3-11-8-29-18-43-23-49-25-56-22l-14 15c-18 20-21 24-24 24a194 194 0 01-74-49c-13-13-27-33-35-48l-2-4 7-8c9-10 13-16 17-26 4-11 4-15-2-29l-16-44c-6-16-10-22-16-24s-29-2-34-1z"
                  />
                </svg>
                <p className="text-[.8em]">Nota de veu de WhatsApp</p>
              </div>
            )} */}
            <div className="flex items-baseline justify-start cursor-default">
              {editor === index ? (
                <input
                  id={`displayName${index}`}
                  className="ml-2 mb-1 w-1/2 text-xs font-semibold border-2 border-green-500"
                  type="text"
                  defaultValue={displayName}
                />
              ) : displayName !== "" ? (
                <span className={`dark:text-gray-400 font-semibold whitespace-nowrap px-2 py-1 ${ isChatOwner && `bg-yellow-400 text-gray-900 px-2` }`} >
                  {displayName}
                </span>
              ) : (
                <span className="pl-2">Nom desconegut</span>
              )}


              {editor === index ? (
                <input id={`authorRole${index}`} type="text" placeholder="Informació addicional" defaultValue={authorRole} className="ml-2 mb-1 w-1/2 text-xs font-semibold border-2 border-green-500" />
              ) : (
                <span className="text-sm font-semibold whitespace-nowrap">{authorRole}</span>
              )}

              {role !== "presenter" && (
                <p className="inline text-xs ml-2 whitespace-nowrap">
                  {lastUpdate ? `Actualitzat` : `Creat`} a las{" "}
                  <span data-tip={displayFullTime(time)}>
                    {displayTimeHour(time)}
                  </span>
                </p>
              )}
            </div>


            <div className={`flex mt-1 pl-2 ${role === "subscriber" && `pr-64`}`} >
              {role !== "presenter" && (
                <ReactAudioPlayer
                  src={audioMessageUrl}
                  controls
                  className="audioMessage"
                />
              )}
            </div>
            
            {editor === index ? (
              <TextareaAutosize
                id={`audioNotes${index}`}
                type="textarea"
                spellCheck="false"
                className={`flex-1 ml-2 px-0 py-1 h-20 resize-none border-2 border-green-500 ${
                  role === "subscriber" && ``
                }`}
                defaultValue={audioNotes}
              />
            ) : role !== "presenter" ? (
              <TextareaAutosize
                id={`audioNotes${index}`}
                className={`neutre px-2 resize-none mt-1 dark:opacity-1 ${
                  role === "presenter" && `dark:text-[#fff] text-[#000]`
                } ${role === "subscriber" && `mr-5`}`}
                disabled
                value={audioNotes !== "" ? audioNotes : "Sense notes"}
              />
            ) : (
              <p className="px-2">
                {audioNotes !== "" ? audioNotes : "Sense notes"}
              </p>
            )}
            {role !== "presenter" &&
              (editor !== index ? (
                <div className="flex items-center px-2 py-1 mt-2">
                  {/* {isEditing ? (
                    <span className="text-sm text-gray-400 py-1.5">
                      Editant...
                    </span>
                  ) : ( */}
                    <>


                      <button type="button" onClick={() => _deleteItem(id)} className="btn--tool--gray" >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Esborrar</span>
                      </button>


                      <button type="button" onClick={() => edit(id)} className="ml-2 btn--tool--gray">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                          <path
                            fillRule="evenodd"
                            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span>Editar</span>
                      </button>


                      <button type="button" onClick={() => setShowMessagePreview(!showMessagePreview)} className={`ml-2 ${showMessagePreview? 'btn--tool--blue' : 'btn--tool--gray'}`} >
                        {!showMessagePreview?
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                        : 
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-4 w-4" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
                        }
                        <span>Vista prèvia</span>
                      </button>


                      <button type="button" onClick={() => toggleItemIsReady(id)} className={`ml-2 ${isReady? 'btn--tool--blue' : 'btn--tool--gray'}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                          <span>{isReady ? "Confirmat" : "Confirmar"}</span>
                        </button>


                      </>
                    {/* )} */}
                  </div>
                ) : (
                  <div className="flex items-center px-2 py-2 space-x-2 text-gray-500 text-xs">

                    <button type="button" onClick={() => cancelEdit(id)} className="btn--tool--gray"> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                      </svg>
                      <span>Cancel·lar</span>
                    </button>

                    <button type="submit" className="btn--tool--blue" onClick={(event) => saveEdit(event, index, id)}> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                      </svg>
                      <span>Desa</span>
                    </button>

                  </div>
                ))}
            </div>

            <div className="flex flex-wrap justify-end items-start">
            
              {isEmitted && role !== "presenter" && (
                <div className="flex space-x-1 text-sm items-center text-gray-500 dark:text-gray-300 cursor-default whitespace-nowrap w-full justify-end" style={{ margin: '2px', marginRight: '10px', maxWidth: '50px' }}>
                  <span className="text-xs uppercase font-semibold px-1">
                    Emès a les{" "}
                    <span data-tip={displayFullTime(emittedTime)} data-place="bottom"> {displayTimeHour(emittedTime)} </span>
                  </span>
                  <button onClick={() => setIsEmitted(false, id)}>X</button>
                </div>
              )}

              
              {selectedItem != index && <div className="bg-gray-200 mx-2 rounded px-2 opacity-50" style={{ paddingBlock: '3px' }}>
                <h1 className={`font-bold whitespace-nowrap ${role !== "presenter"? 'text-gray-600 text-sm' : ''}`}>Missatge d'àudio</h1>
              </div>}

              {role === "subscriber" && (
                <div className="transform -translate-y-1/2 flex space-x-2 text-sm items-center" style={{ marginTop: '20px' }}>

                  {/* {Boolean(!project.overlay) && ( */}
                    <button
                      onClick={handleSelected}
                      className={`disabled:opacity-20 disabled:cursor-not-allowed z-10 text-gray-500 hover:text-gray-400 dark:text-gray-300 dark:hover:text-gray-100 uppercase font-semibold flex px-3 py-2 border-2 rounded-sm text-xs ${
                        selectedItem === index && "border-none"
                      }`}
                      type="button"
                      disabled={!Boolean(!project.overlay)}
                    >
                      {selectedItem === index ? "Cancel·lar" : "Seleccionar"}
                    </button>
                  {/* )} */}
                  
                  {(overlay?.audioMessageUrl ?? null) === audioMessageUrl && (
                    <button
                      onClick={() => submitOverlay(id)}
                      type="button"
                      className=" flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-md"
                    >
                      {!project.overlay ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                          <span>EMETRE</span>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                            />
                          </svg>
                          <span>DEIXAR D'EMETRE</span>
                        </>
                      )}
                    </button>
                  )}


                </div>
              )}

              {role === "admin" && (
                <div className="w-6 h-6 rounded-sm border-2 dark:border-gray-600  hover:text-green-400">
                  <motion.button
                    onClick={handleSelected}
                    whileHover={{ scale: 1.5 }}
                    whileTap={{ scale: 0.9 }}
                    animate={{ scale: selectedItem === index ? 1.4 : 1 }}
                    transition={{ type: "linear" }}
                    className="selected-message__tool selected-message__tool--checkbox"
                  >
                    {selectedItem !== index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    )}
                  </motion.button>
                </div>
              )}
            </div>

          </motion.form>

          <ReactTooltip place="top" effect="solid" delayShow={500} />

          <AudioMessageOverlay 
            messageToShowTMP={{ 
              id: id,
              snippet: {
                displayName: displayName,
                authorRole: authorRole,
                profileImageUrl: profileImageUrl ?? null,
                audioMessageUrl: audioMessageUrl,
                isReady: false
              },
              type: 'chatMessage',
              show: showMessagePreview,
              projectStyles: projectStyles,
              setShowMessagePreview: setShowMessagePreview,
            }} 
          /> 

        </div>
      )}
    </Draggable>
  }
}

export default Message;
