import { useEffect } from 'react'
import { getTimeCodeFromNum } from './../utils/'

function AudioTimeline({ src, barColor, dotColor, timerColor }) {
  useEffect(() => {
    const audio = document.querySelector('#audio')
    const progress = document.querySelector('.progress')
    const doubleCheck = document.querySelector('.doubleCheck')
    const playButton = document.querySelector('.play-button')
    
    audio.addEventListener('loadeddata', () => {
      audio.play()
      const duration = document.querySelector('.time')
      duration.innerHTML = getTimeCodeFromNum(audio.duration)
      progress.style.animationDuration = audio.duration + 's'
      playButton.classList.add('--pause')
      setTimeout(() => {
        doubleCheck.style.color = 'skyblue'
      }, 200)
    })
    audio.addEventListener('ended', () => {
      playButton.classList.remove('--pause')
    })
  })

  return (
    <div className="flex items-center space-x-4">
      <audio id="audio" src={src} />
      <button className='play-button' style={{ color: barColor }}></button>

      <div className="relative w-[600px] h-1" style={{ backgroundColor: barColor }}>
        <span className="progress animate-progress absolute w-5 h-5 mt-0.5 transform -translate-y-1/2 -translate-x-1/2 rounded-full"  style={{ backgroundColor: dotColor }}/>
      </div>

      <div className="doubleCheck flex -space-x-4 transition">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transition relative z-10 duration-1000" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transition delay-100 duration-1000" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 13l4 4L19 7" />
        </svg>
      </div>

      <time className="time" style={{ color: timerColor }}/>
    </div>
  )
}

export default AudioTimeline