import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { settingApearance } from "../../actions/settingActions";
import MessageOverlay from "../Overlays/message";
import AudioMessageOverlay from "../Overlays/audioMessage";
import { AnimatePresence } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";
import { getInitials } from "../../utils";

const defaultMessageStyles = {
  authorBgColor: "#19191b",
  authorTextColor: "#f4f4f5",
  bodyMessageBgColor: "#f4f4f5",
  bodyMessageTextColor: "#19191b",
  imageProfileBgColor: "#13b981",
  imageProfileTextColor: "#ffffff",
};

const initWidgetStates = { message: false, audioMessage: false };

function SettingsApearance({ user, settingApearance }) {
  const [edit, setEdit] = useState(initWidgetStates);
  const [preview, setPreview] = useState(initWidgetStates);
  const [messageStyles, setMessageStyles] = useState(null);
  const [message, setMessage] = useState({
    id: "defaultMessage",
    authorInitials: "LI",
    displayName: "Lorem Ipsum",
    currentMessage:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip?",
    audioMessageUrl:
      "https://upload.wikimedia.org/wikipedia/commons/2/2e/Xabier_paya.wav",
  });

  useEffect(() => {
    if (edit.message && !user.settings.message) {
      settingApearance(
        {
          message: defaultMessageStyles,
        },
        user.id
      );
    }
    // eslint-disable-next-line
  }, [edit]);

  useEffect(() => {
    setMessageStyles(user?.settings.message ?? defaultMessageStyles);
  }, [user]);

  const handleOnChange = (event) => {
    setMessage({
      ...message,
      authorInitials: getInitials(event.target.form["name"].value),
      displayName: event.target.form["name"].value,
      currentMessage: event.target.form["message"].value,
    });
    setMessageStyles({
      ...messageStyles,
      authorBgColor: event.target.form["authorBgColor"].value,
      authorTextColor: event.target.form["authorTextColor"].value,
      bodyMessageBgColor: event.target.form["bodyMessageBgColor"].value,
      bodyMessageTextColor: event.target.form["bodyMessageTextColor"].value,
      imageProfileBgColor: event.target.form["imageProfileBgColor"].value,
      imageProfileTextColor: event.target.form["imageProfileTextColor"].value,
    });
    settingApearance(
      {
        message: messageStyles,
      },
      user.id
    );
  };

  return (
    <>
      <header>
        <h2 className="text-3xl font-black">Aparença</h2>
      </header>
      <div className="py-5 -ml-5 space-y-4">
        <div className="py-4 w-full bg-gray-100 dark:bg-gray-900 rounded px-5 bg-opacity-60">
          <header className="flex items-center justify-between">
            <h3 className="text-lg font-bold">Missatges</h3>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setPreview({ message: !preview.message })}
                className="btn btn--icon btn--transparent text-sm"
              >
                {preview.message ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                      clipRule="evenodd"
                    />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fillRule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                <span>Previsualitzar</span>
              </button>
              <button
                onClick={() => setEdit({ message: !edit.message })}
                className="btn btn--icon btn--transparent text-sm"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <span>Editar</span>
              </button>
            </div>
          </header>
          {edit.message && (
            <form
              onChange={handleOnChange}
              className="border-t-2 dark:border-gray-800 pt-5 mt-4"
            >
              <div className="mb-3 space-y-4">
                <div className="flex space-x-4">
                  <div className="flex items-center justify-between space-x-4 flex-1">
                    <input
                      id="name"
                      className="neutre dark:bg-gray-800 px-3 w-full py-4 rounded"
                      autoComplete="off"
                      spellCheck="off"
                      type="text"
                      defaultValue={message.displayName}
                    />
                  </div>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="authorTextColor"
                      className="w-16 h-16"
                      type="color"
                      defaultValue={messageStyles.authorTextColor}
                    />
                  </label>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="authorBgColor"
                      name="authorBgColor"
                      className="w-16 h-16"
                      type="color"
                      defaultValue={messageStyles.authorBgColor}
                    />
                  </label>
                </div>
                <div className="flex space-x-4">
                  <div className="flex items-center justify-between space-x-4 flex-1">
                    <TextareaAutosize
                      id="message"
                      name="message"
                      className="neutre dark:bg-gray-800 px-3 w-full py-4 resize-none rounded"
                      spellCheck={false}
                      type="text"
                      defaultValue={message.currentMessage}
                    />
                  </div>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="bodyMessageTextColor"
                      className="w-16 h-full"
                      type="color"
                      defaultValue={messageStyles.bodyMessageTextColor}
                    />
                  </label>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="bodyMessageBgColor"
                      className="w-16 h-full"
                      type="color"
                      defaultValue={messageStyles.bodyMessageBgColor}
                    />
                  </label>
                </div>
                <div className="flex items-center space-x-4">
                  <h4 className="flex-1 px-3 py-4 bg-gray-50 dark:bg-gray-800 dark:text-gray-500 rounded pointer-events-none">
                    Imatge
                  </h4>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="imageProfileTextColor"
                      className="w-16 h-16"
                      type="color"
                      defaultValue={messageStyles.imageProfileTextColor}
                    />
                  </label>
                  <label className="relative flex items-center justify-between">
                    <input
                      id="imageProfileBgColor"
                      className="w-16 h-16"
                      type="color"
                      defaultValue={messageStyles.imageProfileBgColor}
                    />
                  </label>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className="py-4 w-full bg-gray-100 dark:bg-gray-900 rounded px-5 bg-opacity-60">
          <header className="flex items-center justify-between">
            <h3 className="text-lg font-bold">Missatges d'àudio</h3>
            <div className="flex items-center space-x-4">
              <button
                onClick={() =>
                  setPreview({ audioMessage: !preview.audioMessage })
                }
                className="btn btn--icon btn--transparent text-sm"
              >
                {preview.audioMessage ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                      clipRule="evenodd"
                    />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fillRule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                <span>Previsualitzar</span>
              </button>
              {/* <button onClick={() => setEdit({ audioMessage: !edit.audioMessage })} className="btn btn--icon btn--transparent text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
                <span>Editar</span>
              </button> */}
            </div>
          </header>
          {edit.audioMessage && <span>Hola</span>}
        </div>
        <AnimatePresence>
          <MessageOverlay
            key="message"
            dummy={preview.message}
            defaultMessage={preview.message ? message : null}
            customStyles={messageStyles}
          />
          <AudioMessageOverlay
            key="audioMessage"
            dummy={preview.audioMessage}
            defaultMessage={preview.audioMessage ? message : null}
            customStyles={messageStyles}
          />
        </AnimatePresence>
      </div>
    </>
  );
}

export default connect(
  (state) => {
    const { users } = state.firestore.data;
    if (users) {
      const user = users[state.firebase.auth.uid];
      return {
        user: {
          id: state.firebase.auth.uid,
          displayName: user?.displayName,
          settings: user?.settings?.appearance ?? {},
        },
      };
    }
    return {};
  },
  (dispatch) => {
    return {
      settingApearance: (overlay, userId) =>
        dispatch(settingApearance(overlay, userId)),
    };
  }
)(SettingsApearance);
