import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";

import YoutubeEmbed from "../YoutubeEmbed";
import Spinner from "../Spinner";


var startAnimations = {
  'preset-1': [{}, {}],
  'slide-up': [{ y: 500 }, { y: 500 }],
  'slide-right': [{x: -2000 }, {x: -2000 }],
  'slide-down': [{ y: -2000 }, { y: -2000 }],
  'slide-left': [{x: 2000 }, {x: 2000 }],
}

var endAnimations = {
  'preset-1': [{}, {}],
  'slide-up': [{ y: -1500 }, { y: -1500 }],
  'slide-right': [{x: 2000 }, {x: 2000 }],
  'slide-down': [{ y: 500 }, { y: 500 }],
  'slide-left': [{x: -2000 }, {x: -2000 }],
}


function SubtitlesOverlay(props) {
  const { message, subtitle, broadcastId, lastBackup, project, show } = props;
  const [overlay, setOverlay] = useState(null);
  const [inputText, setInputText] = useState("");
  const [projectStyles, setProjectStyles] = useState(null);



  useEffect(() => {
    if (project && project.projectStyles) {
      setProjectStyles(project.projectStyles)
    } else if (message && message.projectStyles) {
      setProjectStyles(message.projectStyles)
    }
  }, [props]);


  useEffect(() => {
    if (message && message.textTMP) {
      setInputText(message.textTMP)
    }
  }, [message, show]);


  useEffect(() => {
    setOverlay(subtitle.snippet);
    if (show === null) {
      setInputText(subtitle?.snippet?.displayText);
    }
  }, [subtitle]);


  function checkFunc() {
    return message && message.textTMP
  }


  return (
      <div className={`z-30 ffff ${checkFunc()? 'w-full' : 'container_1920 h-screen'} flex items-end`}>
        <div className={`${checkFunc()? 'w-full' : 'container_1920'}`}>

          <AnimatePresence>
            {inputText && (
              <motion.div
                initial={projectStyles && startAnimations[projectStyles?.subtitleStartAnimation][0]? startAnimations[projectStyles.subtitleStartAnimation][0] : startAnimations['preset-1'][0]}
                animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                exit={projectStyles && endAnimations[projectStyles?.subtitleEndAnimation][0]? endAnimations[projectStyles.subtitleEndAnimation][0] : endAnimations['preset-1'][0]}
                transition={{ type: "easeInOut", duration: projectStyles?.subtitleAnimationDurationBox? projectStyles.subtitleAnimationDurationBox : 0.5, delay: projectStyles?.subtitleAnimationDelayBox? projectStyles.subtitleAnimationDelayBox : 0.0 }}
                className={`px-4 pt-4 pb-2 overflow-hidden ${checkFunc()? '' : 'only-subtitles'} ${projectStyles?.subtitleRoundedStyleBox? projectStyles.subtitleRoundedStyleBox : ''}`}
                style={{
                  backgroundColor: projectStyles?.subtitleColorBox? projectStyles.subtitleColorBox : '#18181B', 
                  color: projectStyles?.subtitleColorText? projectStyles.subtitleColorText : '#F4F4F5', 
                }}
              >
                <motion.div 
                  initial={projectStyles && startAnimations[projectStyles?.subtitleStartAnimation][1]? startAnimations[projectStyles.subtitleStartAnimation][1] : startAnimations['preset-1'][1]}
                  animate={{ height: "auto", width: "auto", scale: 1, opacity: 1, x: 0, y: 0 }}
                  exit={projectStyles && endAnimations[projectStyles?.subtitleEndAnimation][1]? endAnimations[projectStyles.subtitleEndAnimation][1] : endAnimations['preset-1'][1]}
                  transition={{ type: "easeInOut", duration: projectStyles?.subtitleAnimationDurationText? projectStyles.subtitleAnimationDurationText : 0.5, delay: projectStyles?.subtitleAnimationDelayText? projectStyles.subtitleAnimationDelayText : 0.0 }}
                  className={`${checkFunc()? 'text-5xl' : 'text-5xl'} font-regular overflow-hidden`}
                >
                  {/* {overlay && ( */}
                    <TextareaAutosize
                      className="neutre overflow-hidden w-full px-4 appearance-none resize-none pointer-events-none"
                      style={{ lineHeight: "1.15em", textAlign: "left" }}
                      value={inputText}
                    />
                  {/* )} */}
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

        </div>
      </div>
  );
}

const mapStateToProps = ({ firestore: { ordered } }, ownProps) => {
  const subtitles = ordered.overlays;
  const subtitle = subtitles ? subtitles[0] : null;
  const projects = ordered.projects;
  const project = projects ? projects[0] : null;
  const backups = projects ? projects[0]?.subtitles?.backups : null;
  const lastBackup = backups && backups.length ? backups[backups.length - 1] : null;
  const messageToShowTMP = ownProps?.messageToShowTMP ?? null;
  const showTMP = ownProps?.messageToShowTMP?.show ?? null;

  return {
    message: messageToShowTMP,
    show: showTMP,
    broadcastId: project?.subtitles?.broadcastId ?? null,
    projectId: project?.id ?? null,
    lastBackup,
    project,
    subtitle: { ...subtitle },
  };

};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {

    const projectURL = props.match?.params?.id;
    const projectId = props.projectId ?? null;

    if (!projectURL) return [];

    if (projectId) {
      return [
        { collection: "projects", where: [["publicURL", "==", projectURL]] },
        {
          collection: "projects",
          doc: projectId,
          subcollections: [
            {
              collection: "overlays",
              where: [["type", "==", "subtitle"]],
            },
          ],
          storeAs: "overlays",
        },
      ];
    }

    return [ { collection: "projects", where: [["publicURL", "==", projectURL]] } ];

  })
)(SubtitlesOverlay);
