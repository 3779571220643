import { useState, useEffect } from "react";
import { Helmet } from 'react-helmet'
import MessageCreate from './MessageCreate'
import OverlayCreate from './OverlayCreate'
import NotesCreate from './NotesCreate'
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

function Messenger(props) {
  const { match, projectStyles } = props

  

  
  return (
    <>
    <Helmet title="Moderador" />
    <div className="grid gap-5 p-5">
      <MessageCreate projectId={match.params.id} projectStyles={projectStyles} />
      <NotesCreate projectId={match.params.id} />
      <OverlayCreate {...props} title="Llista de missatges" role="editor" />
    </div>
    </>
  )
}




const mapStateToProps = (state, ownProps) => {
  const { projects } = state.firestore.data;
  if (projects && ownProps?.match?.params?.id && projects[ownProps.match.params.id] && projects[ownProps.match.params.id].projectStyles) {
    return { projectStyles: projects[ownProps.match.params.id].projectStyles };
  } else {
    return { projectStyles: {} };
  }
};


const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};


export default compose(
  firestoreConnect((props) => {
    const paramsId = props.match?.params.id ?? null;
    if (!paramsId) return [];
    return [
      { collection: "projects" },
    ];
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(Messenger);



