import { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { broadcastList, broadcastInfo } from "./../actions/youtubeActions";
import { saveItem, deleteItem } from "../actions/projectActions";
import LiveChatYouTube from "./LiveChatYouTube";
import LiveChatTwitch from "./LiveChatTwitch";
import ProjectConfig from "./ProjectConfig";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import NotesCreate from './NotesCreate'

import OverlayCreate from "./OverlayCreate";
import MessageCreate from "./MessageCreate";
import CardCreate from "./CardCreate";
import CayronCreate from "./CayronCreate";
import SubtitlesCreate from "./SubtitlesCreate";
import PollCreate from "./PollCreate";
import PollParticipants from "./PollParticipants";


function Project(props) {
  const { project, match, auth, isAuthenticated, broadcastInfo } = props;

  const [configVisible, setConfigVisible] = useState(false);
  const [backButtonVisible, setBackButtonVisible] = useState(false);
  const [displayScene, setDisplayScene] = useState(false);


  useEffect(() => {
    if (isAuthenticated) {
      broadcastInfo(project.broadcastId);
    }
  }, [isAuthenticated]);

  

  const hasWidget = (widget) => project.widgets?.includes(widget) ?? null;
  const displayLinksScene = () => {
    setDisplayScene((prev) => !prev);
  };


  if (!auth.uid) return <Redirect to="/signin" />;


  if (project) {
    return (
      <>
        <Helmet title="Admin" />
        <div
          className="grid gap-10 pr-10"
          style={{ gridTemplateColumns: "auto auto 1fr" }}
        >
          <div className="flex pr-10">
            <div
              className="fixed top-0 z-40 w-10 h-screen flex flex-col justify-end items-center pb-3 pt-6 bg-gray-200 dark:text-gray-600 dark:bg-gray-900"
              onMouseOver={() => setBackButtonVisible(true)}
              onMouseLeave={() => setBackButtonVisible(false)}
            >
              <AnimatePresence>
                {backButtonVisible && (
                  <motion.div
                    key="backButton"
                    initial={{ x: -60 }}
                    animate={{ x: 0 }}
                    exit={{ x: -60 }}
                    className="absolute top-7 dark:hover:text-gray-200"
                  >
                    <Link to="/">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        />
                      </svg>
                    </Link>
                  </motion.div>
                )}
              </AnimatePresence>
              <motion.button
                whileHover={{ scale: 1.2 }}
                whileTap={{ rotate: 200 }}
                className="hover:text-gray-500 dark:hover:text-gray-200"
                onClick={() => setConfigVisible(!configVisible)}
              >
                <svg
                  data-tip="Opcións"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
                {/* <ReactTooltip  place="top" effect="solid" delayShow={500} /> */}
              </motion.button>
            </div>
            <AnimatePresence>
              {configVisible && (
                <>
                  <motion.div
                    key="configPanel"
                    initial={{ x: "-100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "-100%" }}
                    transition={{ type: "spring", damping: "20" }}
                    className="z-30 w-80 bg-gray-200 dark:bg-gray-900 py-4 fixed left-10 bottom-0 top-0"
                  >
                    <ProjectConfig projectId={project.id} />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setConfigVisible(false)}
                    className="fixed z-20 top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-40"
                  />
                </>
              )}
            </AnimatePresence>
          </div>

          {/* Page */}
          <div className="space-y-3 py-5" style={{ width: '100vw', minWidth: '430px', maxWidth: '550px' }}>
            <h1 className="text-3xl font-bold">Admin</h1>

            <NotesCreate projectId={match.params.id} />

            {hasWidget("LiveChat") && <LiveChatYouTube project={project} />}
            {hasWidget("LiveChat") && <LiveChatTwitch project={project} />}
            {hasWidget("Cards") && ( <CardCreate projectId={project.id} categories={project.categories} /> )}
            {hasWidget("Messages") && <MessageCreate projectId={project.id} projectStyles={project?.projectStyles? project.projectStyles : null} />}
            {hasWidget("Cayrons") && <CayronCreate projectId={project.id} projectStyles={project?.projectStyles? project.projectStyles : null} />}
            {hasWidget("Polls") && <div>
              <PollCreate projectId={project.id} projectStyles={project?.projectStyles? project.projectStyles : null} />
              <PollParticipants projectId={project.id} polls={project.items.filter(element => element.type === 'poll')}/>
            </div>}
            {hasWidget("Subtitles") && <SubtitlesCreate project={project} />}
            {hasWidget("Subtitles") && (
              <div>
                {displayScene ? (
                  <div>
                    <div className="flex items-center justify-between w-full py-2 border-b border-gray-600 text-gray-600">
                      <span>Enllaç OBS subtítols</span>
                      <div className="flex items-center space-x-2">
                        <a
                          href={`${process.env.REACT_APP_BASE}subtitles/${project.publicURL}`}
                          target="_blank"
                          rel="noreferrer"
                          className="dark:hover:text-gray-200"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={1}
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                        </a>
                        <CopyToClipboard
                          text={`${process.env.REACT_APP_BASE}subtitles/${project.publicURL}`}
                        >
                          <motion.button
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.8 }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              data-tip="Copiar URL"
                              className="h-5 w-5 dark:hover:text-gray-200"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1}
                                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                              />
                            </svg>
                          </motion.button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {project.widgets && project.widgets.length <= 0 && (
              <button
                className="w-full h-96 bg-gray-900 text-gray-600 bg-opacity-50"
                onClick={() => setConfigVisible(!configVisible)}
              >
                Add widgets
              </button>
            )}
          </div>
          <div className="py-5">
            {hasWidget("Overlay") && (
              <OverlayCreate project={project} role="admin" />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <span>carregant...</span>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.projects;
  const items = state.firestore.ordered.items ?? [];
  const messages = state.firestore.ordered.chatMessages ?? [];
  const overlays = state.firestore.ordered.overlays;
  const project = projects ? projects[id] : null;
  return {
    project: {
      id,
      chatList: state.project.chatList,
      chatError: state.project.chatError,
      nextPageToken: state.project.nextPageToken,
      broadcastStatus: state.project.broadcastInfo,
      ...project,
      items: [...items, ...messages],
      overlay: overlays?.length ?? 0,
    },
    auth: state.firebase.auth,
    isAuthenticated: state.project.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.match.params.id;
  return {
    broadcastList: () => dispatch(broadcastList()),
    broadcastInfo: (id) => dispatch(broadcastInfo(id)),
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId)),
    deleteItem: (id) => dispatch(deleteItem(id, projectId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.match.params.id) return [];
    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [{ collection: "items", orderBy: ["savedAt", "desc"] }],
        storeAs: "items",
      },
      {
        collection: "projects",
        doc: props.match.params.id,
        subcollections: [
          {
            collection: "overlays",
          },
        ],
        storeAs: "overlays",
      },
    ];
  })
)(Project);
