






export const addOverlay = (overlay, projectId) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("overlays")
      .add({
        type: overlay.type,
        snippet: overlay,
      })
      .then(() => {
        console.log("New Overlay");
      })
      .catch((error) => {
        console.log("New Overlay Error", error);
      });
  };
};

export const removeOverlay = (projectId) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("overlays")
      .get()
      .then((res) => {
        res.forEach((element) => {
          element.ref.delete();
        });
        console.log("overaly deleted");
      })
      .catch((error) => {
        console.log("new overaly error", error);
      });
  };
};

export const addSubsOverlay = (overlay, projectId) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("overlays")
      .doc("subtitles")
      .set({
        type: "subtitle",
        snippet: overlay,
      })
      .then(() => {
        console.log("New Subs Overlay");
      })
      .catch((error) => {
        console.log("New Subs Overlay Error", error);
      });
  };
};

export const updateSubsOverlay = (overlay, projectId) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("overlays")
      .doc("subtitles")
      .update({
        snippet: overlay,
      })
      .then(() => {
        console.log("New Overlay");
      })
      .catch((error) => {
        console.log("New Overlay Error", error);
      });
  };
};







export const updateVoting = (snippet, pollId, projectId) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    const firestore = getFirestore();
    firestore
      .collection("projects")
      .doc(projectId)
      .collection("overlays")
      .get()
      .then(res => {

        res.docs.map(element => {
          if (element.data().snippet.id == pollId) {
            res.forEach((element) => {
              element.ref.update({
                snippet: snippet,
                lastUpdate: new Date(),
              })
            });
          }
        });
        
      }).catch((error) => {
        console.log("New Overlay Error", error);
      });
  };
};








