import { Helmet } from 'react-helmet'
import OverlayCreate from './OverlayCreate'

function Overlayer(props) {
  return (
    <>
    <Helmet title="Emissor" />
    <main className="">
      <OverlayCreate {...props} title="Missatges" role="subscriber" />
    </main>
    </>
  )
}

export default Overlayer