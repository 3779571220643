export const authenticate = () => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {


    gapi.load("client:auth2", function () {
      gapi.auth2.init({
        client_id: process.env.REACT_APP_GAPI_CLIENT_ID,
      });
    });

    const authenticate = () => {
      return gapi.auth2.getAuthInstance().signIn({ scope: "https://www.googleapis.com/auth/youtube.readonly" }).then(() => {
        localStorage.setItem('ytchatauth1', '1')
        console.log("Sign-in successful");
      }).catch((err) => {
        localStorage.setItem('ytchatauth1', '0')
        console.error("Error signing in", err);
      });
    };

    const loadClient = () => {
      gapi.client.setApiKey(process.env.REACT_APP_GAPI_API_KEY);
      return gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest").then(() => {
        localStorage.setItem('ytchatauth2', '1')
        console.log("GAPI client loaded for API");
        dispatch({ type: "YTAUTH_SUCCESS" });
      }).catch((error) => {
        localStorage.setItem('ytchatauth2', '0')
        console.error("Error loading GAPI client for API", error);
      });
    };

    setTimeout(() => { authenticate().then(loadClient); }, 1000);
  };
};

export const broadcastList = () => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    gapi.client.youtube.liveBroadcasts.list({ broadcastStatus: "active", part: "snippet" }).then((response) => {
      dispatch({
        type: "BROADCASTSLIST_SUCCESS",
        broadcasts: response.result.items,
      });
    }).catch((err) => {
      console.error("Execute error", err);
    });
  };
};

export const broadcastInfo = (id) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    gapi.client.youtube.liveBroadcasts
      .list({
        broadcastStatus: "all",
        part: ["status"],
      })
      .then((response) => {
        dispatch({
          type: "BROADCASTINFO_SUCCESS",
          broadcastInfo: response.result.items.find((item) => item.id === id)
            .status.lifeCycleStatus,
        });
      })
      .catch((error) => {
        console.error("Execute error", error);
        dispatch({
          type: "BROADCASTINFO_ERROR",
          error: error,
        });
      });
  };
};

export const messagesList = (liveChatId, nextPageToken) => {
  return (dispatch, getState, { getFirestore, getFirebase, gapi }) => {
    gapi.client.youtube.liveChatMessages
      .list({
        part: ["snippet", "authorDetails"],
        liveChatId: liveChatId,
        profileImageSize: 256,
      })
      .then((response) => {
        console.log(
          "liveMessages:",
          response,
          getState().project.nextPageToken,
          response.result.nextPageToken
        );
        dispatch({
          type: "LIVECHATMESSAGESLIST_SUCCESS",
          chatList: response.result.items,
          nextPageToken: response.result.nextPageToken,
        });
      })
      .catch((err) => {
        dispatch({
          type: "LIVECHATMESSAGESLIST_ERROR",
          error: err.result.error,
        });
      });
  };
};

export const getChannelInfo = (id) => {};
