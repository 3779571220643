import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { motion } from 'framer-motion'
import DayJS from 'react-dayjs'
import { authenticate, broadcastList, messagesList } from './../actions/youtubeActions'
import { saveItem, connectLiveChat } from './../actions/projectActions'

function LiveChatYouTube(props) {
  
  var { project, broadcasts, isAuthenticated, messagesList, saveItem, authenticate, broadcastList, connectLiveChat } = props

  const { broadcastStatus, chatList, liveChat } = project
  const [newLiveChat, setNewLiveChat] = useState('')
  const [autoScroll, setAutoScroll] = useState(true)
  const [showBroadcastList, setShowBroadcastList] = useState(false)
  var intervalTMP = null;


  const [isAuthenticatedTMP, setIsAuthenticatedTMP] = useState(false)
  const [liveChatTMP, setLiveChatTMP] = useState(null)
  const [chatListTMP, setChatListTMP] = useState(null)
  const [broadcastsTMP, setBroadcastsTMP] = useState(null)


  var repeat = true;
  useEffect(() => {
    if (repeat) {
      repeat = false;
      localStorage.setItem('ytchatauth1', '0')
      localStorage.setItem('ytchatauth2', '0')
    }
  }, [])


  useEffect(() => {
    var test1 = JSON.parse(localStorage.getItem('ytchatauth1'));
    var test2 = JSON.parse(localStorage.getItem('ytchatauth2'));

    if (test1 && test2) {
      setIsAuthenticatedTMP(true)
    } else {
      setIsAuthenticatedTMP(false)
    }

    if (broadcasts && isAuthenticated) {
      setIsAuthenticatedTMP(true)
    }
  }, [project])


  /* useEffect(() => {
    setIsAuthenticatedTMP(isAuthenticated)
  }, [isAuthenticated]) */

  useEffect(() => {
    var chatBox = document.getElementById('smallChat');
    if(autoScroll) {
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    }
  }, [chatListTMP])
  
  
  const deleteStream = (event) => {
    setIsAuthenticatedTMP(false)
    setLiveChatTMP('')
    setChatListTMP(null)
    setBroadcastsTMP(null)
    localStorage.setItem('ytchatauth1', '0')
    localStorage.setItem('ytchatauth2', '0')
  }


  useEffect(() => {
    if (broadcasts) {
      setBroadcastsTMP(broadcasts)
    } else {
      setBroadcastsTMP(null)
    }
  }, [broadcasts])


  function newChatFunc() {
    if (liveChat) {
      setLiveChatTMP(liveChat)
    } else {
      setLiveChatTMP(null)
    }
    if (chatList) {
      setChatListTMP(chatList)
    } else {
      setChatListTMP(null)
    }
  }

  






  const handleSelect = (index) => {
    const { id, snippet } = broadcastsTMP[index]
    setNewLiveChat({
      liveChat: {
        id: snippet.liveChatId,
        broadcastId: id,
        broadcastTitle: snippet.title
      }
    })
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    connectLiveChat(newLiveChat)
    messagesList(newLiveChat.liveChat.id)
    newChatFunc()
  }


  const reloadChatBox = (event) => {
    messagesList(liveChatTMP.id)
    newChatFunc()
  }


  useEffect(() => {
    if (isAuthenticatedTMP && liveChatTMP) {

      if (document.getElementById('reloadChaatYT')) {
        document.getElementById('reloadChaatYT').click();
      }

      intervalTMP = setInterval(function () {
        if (document.getElementById('reloadChaatYT')) {
          document.getElementById('reloadChaatYT').click();
        }
      }, 5000); //1000 = 1000ms = 1s

    }
  }, [isAuthenticatedTMP, liveChatTMP])


  return (
    <div className="border-2 dark:border-gray-700 mb-2">
      <div className="flex items-center justify-between py-2 px-3">
        <div className="flex items-center space-x-3">
          <h2 className="text-sm font-semibold">Xat de YouTube</h2>
          <span className={`w-2 h-2 rounded-full block ${liveChatTMP && isAuthenticatedTMP ? `bg-red-700 animate-pulse` : `bg-gray-700`}`}/>
        </div>

        <div className='flex items-center space-x-3'>

          {isAuthenticatedTMP && liveChatTMP && <label className="whitespace-nowrap flex items-center" style={{ userSelect: 'none' }}>
            <input type="checkbox" style={{ marginRight: '5px', marginLeft: '10px' }} defaultChecked={autoScroll} onChange={ (event) => setAutoScroll(!autoScroll)}/> 
            Scroll
          </label>}

          {!isAuthenticatedTMP ?
            <motion.button whileHover={{ scale: 1.2 }} whileTap={{ scale: .8 }} onClick={authenticate} className="flex items-center space-x-2">
              {/* <p className="px-1 text-xs text-gray-600 text-right">Connect with your broadcaster service account.</p> */}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            </motion.button>
          :
            liveChatTMP && (<motion.button whileHover={{ scale: 1.2 }} whileTap={{ rotate: -200 }} className="hover:text-gray-400" id="reloadChaatYT" disabled={!isAuthenticatedTMP} onClick={reloadChatBox}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
            </motion.button>)
          }

          {isAuthenticatedTMP &&
            <motion.button whileHover={{ scale: 1.2 }} className="hover:text-gray-400" disabled={!isAuthenticatedTMP} onClick={deleteStream}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="3 6 5 6 21 6"/>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                <line x1="10" y1="11" x2="10" y2="17"/><line x1="14" y1="11" x2="14" y2="17"/>
              </svg>
            </motion.button>
          }

        </div>

      </div>
      {isAuthenticatedTMP &&
      <div id="smallChat" className="border-t border-solid border-gray-700 max-h-96 overflow-auto">
        <div className="flex justify-between items-center flex-wrap space-y-2 px-3 py-2 sticky top-0 bg-gray-50">
          {!liveChatTMP &&
            <div className="flex flex-col space-y-4 w-full">
              {(isAuthenticatedTMP && !broadcastsTMP) && <button onClick={broadcastList} className="btn text-sm w-full">Mostrar una emissió activa</button>}
              {broadcastsTMP?.map((broadcast, index) => (
                <div key={broadcast.id}>
                  <div className="border border-solid border-gray-700 rounded overflow-hidden relative mb-4">
                    <div className="flex items-center p-2">
                      <span className="w-2 h-2 bg-red-700 rounded-full block animate-pulse mr-3"/>
                      <p>{broadcast.snippet.title}</p>
                    </div>
                    <img className="w-full" src={broadcast.snippet.thumbnails.maxres.url} alt=""/>
                    <div className="px-2">
                      {/* <p>{broadcast.snippet.actualStartTime}</p>
                      <p>{broadcast.snippet.publishedAt}</p> */}
                    </div>
                    <button onClick={() => handleSelect(index)} className="absolute bottom-0 left-0 top-0 w-full z-30 p-2 flex items-start justify-end hover:bg-gray-700 hover:bg-opacity-20">
                      <svg xmlns="http://www.w3.org/2000/svg" className={newLiveChat ? `opacity-100` : `opacity-20`} width={20} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </button>
                  </div>
                  <button onClick={handleSubmit} className={`btn w-full ${!newLiveChat && `pointer-events-none opacity-20`}`}>Connecta't</button>
                </div>
              ))}
              {isAuthenticatedTMP && broadcastsTMP && !broadcastsTMP.length && <div className="text-center text-sm bg-yellow-500 text-yellow-900 py-2 px-3 rounded">No hi ha emissions actives per mostrar</div>}
            </div>
          }

          {liveChatTMP &&
            <a href={`https://youtu.be/${liveChatTMP.broadcastId}`} rel="noreferrer" target="_blank" className="flex items-center rounded space-x-2 text-gray-500 hover:text-gray-300 text-xs">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
              <h1 className={`pr-2`}>{liveChatTMP.broadcastTitle}</h1>
            </a>
          } 
          {broadcastStatus && <p>{broadcastStatus === 'complete' ? 'The broadcast is finished.' : null}</p>}

        </div>
        {/* {chatError && <p className="p-3 text-xs text-gray-600"><span dangerouslySetInnerHTML={{__html: chatError.message}} /></p>} */}
        { chatListTMP?.map((message) => {
          const { id: youtubeId, snippet: { publishedAt, displayMessage }, authorDetails: { displayName, isChatOwner, profileImageUrl } } = message

          return (
            <button
              key={youtubeId}
              onClick={() => saveItem('chatMessage', { 
                youtubeId, 
                publishedAt, 
                authorInitials: 'YT' , 
                authorInitialsColor: '#FF0000' , 
                currentMessage: displayMessage, 
                originalMessage: displayMessage, 
                displayName, 
                isChatOwner, 
                profileImageUrl, 
                businessName: '', 
                isReady: false,
                fontSize: 150,
              }, project.id)}
              className="flex items-start px-2 py-2 space-x-2 hover:bg-gray-700 hover:bg-opacity-20 w-full overflow-hidden"
            >
              <img className="w-6 h-6 rounded-full mt-1" src={profileImageUrl} alt=""/>
                <div className="text-left space-x-1">
                  <DayJS format="H:mm" className="inline text-xxs text-gray-600 font-semibold">{publishedAt}</DayJS>
                  <span className={`inline text-xs text-gray-400 font-semibold whitespace-nowrap ${isChatOwner && `bg-yellow-400 text-gray-900 rounded-sm px-1`}`}>{displayName}</span>
                <p className="inline text-sm">{displayMessage}</p>
              </div>
            </button>
          )
        })}
      </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.project.isAuthenticated,
    broadcasts: state.project.broadcasts
  } 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const projectId = ownProps.project.id
  return {
    authenticate: () => dispatch(authenticate()),
    broadcastList: () => dispatch(broadcastList()),
    connectLiveChat: (id) => dispatch(connectLiveChat(id, projectId)),
    messagesList: (liveChatId) => dispatch(messagesList(liveChatId)),
    saveItem: (type, item) => dispatch(saveItem(type, item, projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveChatYouTube)