import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyB5AaGr2CvoIdA2b4WPALQTMy0FsQ-ov6c",
  authDomain: "sstrm-9d3dc.firebaseapp.com",
  projectId: "sstrm-9d3dc",
  storageBucket: "sstrm-9d3dc.appspot.com",
  messagingSenderId: "1089670973801",
  appId: "1:1089670973801:web:26f26927aeebef223255f8",
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
