import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { AnimatePresence } from "framer-motion";
import { Helmet } from "react-helmet";
import TextareaAutosize from "react-textarea-autosize";
import YoutubeEmbed from "../YoutubeEmbed";
import Spinner from "../Spinner";

function SubtitlesOverlay(props) {
  const { subtitle, broadcastId } = props;

  const [overlay, setOverlay] = useState(null);
  useEffect(() => {
    setOverlay(subtitle.snippet);
  }, [subtitle]);
  return (
    <>
      {/* <Helmet title="IV Jornada sobre cultura inclusiva i art comunitari - Servei de Desenvolupament Empresarial ICEC" /> */}
      <div className="flex items-center justify-center w-screen h-screen mx-auto z-30">
        <div
          className="w-full max-w-5xl"
          style={{ border: "10px solid #109fe3" }}
        >
          {broadcastId ? (
            <YoutubeEmbed id={broadcastId} />
          ) : (
            <Spinner className="mx-auto p-10" />
          )}
          <AnimatePresence>
            <div className="relative w-full max-w-6xl h-28 mx-auto text-5xl font-regular py-1 overflow-hidden">
              {overlay && (
                <TextareaAutosize
                  className="neutre absolute left-0 px-2 bottom-0.5 text-gray-900 overflow-hidden w-full appearance-none resize-none pointer-events-none"
                  style={{ lineHeight: "1.15em" }}
                  value={overlay.displayText}
                />
              )}
            </div>
          </AnimatePresence>
        </div>
      </div>
      <span
        className="fixed w-screen h-screen top-0 left-0 block"
        style={{ backgroundColor: "white", zIndex: -1 }}
      />
    </>
  );
}

const mapStateToProps = ({ firestore: { ordered } }) => {
  const subtitles = ordered.overlays;
  const subtitle = subtitles ? subtitles[0] : null;
  const projects = ordered.projects;
  const project = projects ? projects[0] : null;
  return {
    broadcastId: project?.subtitles.broadcastId ?? null,
    projectId: project?.id ?? null,
    subtitle: {
      ...subtitle,
    },
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const projectURL = props.match.params.id;
    const projectId = props.projectId ?? null;
    if (!projectURL) return [];
    if (projectId)
      return [
        { collection: "projects", where: [["publicURL", "==", projectURL]] },
        {
          collection: "projects",
          doc: props.projectId,
          subcollections: [
            {
              collection: "overlays",
              where: [["type", "==", "subtitle"]],
            },
          ],
          storeAs: "overlays",
        },
      ];
    return [
      { collection: "projects", where: [["publicURL", "==", projectURL]] },
    ];
  })
)(SubtitlesOverlay);
