import { IntlProvider } from 'react-intl'
import { LOCALES } from './locales'
import messages from './messages'

const Provider = ({ children, locale = LOCALES['ca'] }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    { children }
  </IntlProvider>
)

export default Provider