import { useState, useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { firestoreConnect } from "react-redux-firebase";
import { motion, AnimatePresence } from "framer-motion";
import TextareaAutosize from "react-textarea-autosize";
import {
  addSubsOverlay,
  updateSubsOverlay,
  removeOverlay,
} from "../actions/overlayActions";
import { saveSubtitles, saveSubtitlesBackup } from "../actions/projectActions";
import YoutubeEmbed from "./YoutubeEmbed";
import Spinner from "./Spinner";

function Subtitulator(props) {
  const {
    addSubsOverlay,
    updateSubsOverlay,
    removeOverlay,
    subtitle,
    saveSubtitles,
    saveSubtitlesBackup,
    history,
    clipboard,
    broadcastId,
  } = props;
  
  const [characters, setCharacters] = useState(0);
  const [viewOverlay, setViewOverlay] = useState(null);
  const subtitulatorInput = useRef(null);

  useEffect(() => {
    if (subtitle) setViewOverlay(subtitle.snippet);
  }, [subtitle]);

  const toggleOverlay = () => {
    if (!subtitle.id) {
      addSubsOverlay({
        displayText: "",
      });
    } else {
      removeOverlay();
    }
  };

  const handleOnChange = (event) => {
    const backupInterval = 200; // characters

    if (event.keyCode === 13) {
      saveSubtitlesBackup(event.target.value);
      subtitulatorInput.current.value = "";
    }
  };

  const keyUp = (event) => {
    if (
      event.keyCode === 32 ||
      event.keyCode === 189 ||
      event.keyCode === 190 ||
      event.keyCode === 188 ||
      event.keyCode === 8 ||
      event.keyCode === 13
    ) {
      updateSubsOverlay({
        displayText: event.target.value,
      });
      saveSubtitles({
        clipboard: event.target.value,
      });
    }
  };
  return (
    <>
      <Helmet title="Subtítulador" />
      <div className="text-gray-900">
        <div className="max-w-lg mx-auto m-10 relative z-20">
          {broadcastId ? (
            <YoutubeEmbed id={broadcastId} />
          ) : (
            ""
            // <Spinner className="mx-auto mt-40" />
          )}
        </div>
        {history && (
          <ul className="pointer-events-none hidden z-20 bottom-1/2 pb-12 w-full max-w-md transform flex-col space-y-5 mx-auto text-lg">
            <AnimatePresence>
              {history.map((item) => (
                <motion.li
                  key={item.id}
                  layout
                  initial={viewOverlay ? { y: 100, scale: 1.5 } : false}
                  animate={{ y: 0, scale: 1 }}
                  transition={{ type: "spring", damping: 20 }}
                  className="px-3 py-1 rounded leading-tight text-gray-400 bg-gray-900"
                >
                  <p>{item.displayText}</p>
                </motion.li>
              ))}
            </AnimatePresence>
          </ul>
        )}
        {viewOverlay && (
          <div className="z-20 bottom-1/3 transform w-full mt-20">
            <div
              className="relative h-32 mx-auto text-5xl font-regular leading-tight bg-gray-900 rounded overflow-hidden container_1824"
              style={{ marginLeft: "48px", marginRight: "48px" }}
            >
              dsa
              <TextareaAutosize
                className="px-8 neutre absolute left-0 bottom-2 text-gray-100 overflow-hidden bg-gray-900 w-full appearance-none resize-none px-4"
                value={viewOverlay.displayText}
              />
            </div>
          </div>
        )}
        <div className="m-auto z-20 bottom-0 transform py-5 rounded text-center w-full max-w-6xl">
          <form>
            <textarea
              id="inputSubtitles"
              type="textarea"
              spellCheck="false"
              onKeyDown={keyUp}
              defaultValue={clipboard}
              // onChange={handleOnChange}
              onKeyUp={handleOnChange}
              autoComplete="off"
              ref={subtitulatorInput}
              className="neutre text-left text-5xl px-3 pt-3 w-full h-40 border-2 border-gray-900 bg-gray-100 rounded-none focus:outline-none leading-tight"
            />
          </form>
        </div>
        <div className="m-auto max-w-6xl flex justify-center">
          <button
            //  flex items-center space-x-2 justify-center font-semibold px-4 py-3 w-full bg-red-700 hover:bg-red-600 text-sm text-gray-50 rounded-3xl
            className={`bg-red-700 text-sm text-gray-50 rounded-3xl font-semibold w-full w-button z-20 p-2 border border-gray-900 bg-gradient-to-b ${
              subtitle.id
                ? `from-red-700 to-red-700 border-red-500 hover:bg-red-600`
                : `from-gray-300 to-gray-400`
            }`}
            onClick={() => toggleOverlay()}
          >
            {subtitle.id ? (
              <div className="w-1/3 flex justify-start">
                <span
                  className={`rounded px-2 py-1 bg-gray-900 text-gray-100 transition-all text-sm font-semibold pointer-events-none ${
                    !subtitle.id ? `opacity-0` : `animate-pulse`
                  }`}
                >
                  A L'AIRE
                </span>
              </div>
            ) : (
              ""
            )}
            <div
              className={`${
                subtitle.id ? `w-2/3` : `w-full`
              } flex items-center ${!subtitle.id ? `justify-center` : ``}`}
            >
              {subtitle.id ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                    />
                  </svg>
                  {"\u00A0"}Deixar d'emetre
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                    />
                  </svg>
                  {"\u00A0"}Emetre
                </>
              )}
            </div>
          </button>
        </div>
        <span
          className="fixed w-screen h-screen top-0 left-0 bg-gray-100 block"
          style={{ zIndex: -1 }}
        />
      </div>
    </>
  );
}

export default compose(
  firestoreConnect((props) => {
    const projectId = props.match.params.id;

    if (!projectId) return [];

    return [
      { collection: "projects" },
      {
        collection: "projects",
        doc: projectId,
        subcollections: [
          {
            collection: "overlays",
            where: [["type", "==", "subtitle"]],
          },
        ],
        storeAs: "overlays",
      },
    ];
  }),
  connect(
    (state, ownProps) => {
      const projectId = ownProps.match.params.id;
      const subtitles = state.firestore.ordered.overlays ?? false;
      const subtitle = subtitles ? subtitles[0] : null;
      const projects = state.firestore.data.projects;
      const project = projects ? projects[projectId] : null;

      return {
        broadcastId: project?.subtitles?.broadcastId ?? null,
        subtitle: { ...subtitle },
        history: project?.subtitles?.history ?? null,
        clipboard: project?.subtitles?.clipboard ?? null,
      };

    },
    (dispatch, ownProps) => {
      const projectId = ownProps.match.params.id;
      return {
        addSubsOverlay: (message) => dispatch(addSubsOverlay(message, projectId)),
        saveSubtitles: (subs) => dispatch(saveSubtitles(subs, projectId)),
        saveSubtitlesBackup: (subs) => dispatch(saveSubtitlesBackup(subs, projectId)),
        updateSubsOverlay: (message) => dispatch(updateSubsOverlay(message, projectId)),
        removeOverlay: () => dispatch(removeOverlay(projectId)),
      };
    }
  )
)(Subtitulator);
