import { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { I18nProvider, LOCALES } from '../i18n'

function Layout({ children, settings, lang }) {
  
  useEffect(() => {
    const localSettings = JSON.parse(localStorage.getItem('settings'))
    if (localSettings?.darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [settings])

  return (
    <I18nProvider locale={LOCALES[lang] ?? undefined}>
      <div className="mx-auto">
        { children }
      </div>
    </I18nProvider>
  )
}

export default compose(
  firestoreConnect(['users']),
  connect((state) => {
  const uid = state.firebase.auth.uid
  const { users } = state.firestore.data
  if (users && uid) {
    const user = users[uid]
    return {
      settings:  user.settings ?? null
    }
  }
  return {}
}))(Layout)